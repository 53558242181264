import * as Yup from "yup";
import { Chip, styled } from "@material-ui/core";

export const ChemContactDetailsSchema = Yup.object({
  first_name: Yup.string()
    .required("First Name is a required field")
    .matches(/(^[a-zA-Z\u00C0-\u024F -']+$)/, "Only alphabet characters are allowed"),
  last_name: Yup.string()
    .required("Last Name is a required field")
    .matches(/(^[a-zA-Z\u00C0-\u024F -']+$)/, "Only alphabet characters are allowed"),
  phone: Yup.string()
    .required("Phone Number is a required field")
    .matches(/(^[0-9+]+$)/, "Only numbers are allowed")
    .test("length", "Must be 10 digits", (val) => (val || "").replace(/[\s+]/g, "").length === 10),
  mobile: Yup.string()
    .matches(/(^[0-9+]+$)/, "Only numbers are allowed")
    .test("length", "Must be 10 digits", (val) => (val || "").replace(/[\s+]/g, "").length === 10 || !val),
  email: Yup.string()
    .required("Email is a required field")
    .email("Must be a valid email")
    .matches(/(^[a-z0-9@.!#$%&'*+-/=?^_`{|}~]+$)/, "Restricted characters"),
  qa_program: Yup.string().when("qa_program_enabled", {
    is: (program) => program,
    then: Yup.string().required("Required field"),
    otherwise: Yup.string().strip(),
  }),
  ct_number: Yup.string().when("qa_program_enabled", {
    is: (program) => program,
    then: Yup.string().required("Required field"),
    otherwise: Yup.string().strip(),
  }),
  other_organisation: Yup.string().when("organisation_type", {
    is: (type) => type === "other",
    then: Yup.string().required("Required field"),
    otherwise: Yup.string().strip(),
  }),
});

export const ChemaddressDetailsSchema = Yup.object({
  chem_contact_address_1: Yup.string().required("Address 1 is a required field"),
  chem_contact_suburb: Yup.string().required("Suburb is a required field"),
  chem_contact_state: Yup.string().required("State is a required field"),
  chem_contact_postcode: Yup.string()
    .required("Postcode is a required field")
    .length(4, "Must be exactly 4 characters")
    .matches(/(^[0-9]+$)/, "Only numbers are allowed"),
  chem_address_1: Yup.string().required("Address 1 is a required field"),
  chem_suburb: Yup.string().required("Suburb is a required field"),
  chem_state: Yup.string().required("State is a required field"),
  chem_postcode: Yup.string()
    .required("Postcode is a required field")
    .length(4, "Must be exactly 4 characters")
    .matches(/(^[0-9]+$)/, "Only numbers are allowed"),
});

const statusMap = {
  "FREE COLLECTION": "#64A70B4D",
  "HAVE YOU CONSIDERED DRUMMUSTER?": "#F2994A",
  "QUOTE REQUIRED": "#EB5757",
};

const CustomChip = styled(Chip)(({ theme }) => ({
  width: 300,
  color: theme.palette.success.main,
  "& .MuiChip-label": {
    overflowWrap: "break-word",
    whiteSpace: "normal",
    textAlign: "center",
  },
}));

const renderBadges = (item = {}) => {
  return (
    <div>
      <div>
        <CustomChip
          label={item.status}
          color="primary"
          className="text-white py-6"
          style={{
            width: "150px",
            background: statusMap[item.status],
            borderRadius: "4px",
            fontSize: "10px",
            fontWeight: 500,
          }}
        />
      </div>
    </div>
  );
};

export const renderProductsRow = (headings, item) => {
  return headings.map(([key]) => (
    <td key={key} className={`align-middle no-line border-0 px-5 py-3`}>
      {key === "status"
        ? renderBadges(item)
        : key === "qty_remaining"
        ? `${item[key] || 0} %`
        : item[key]}
    </td>
  ));
};
