import config from "react-global-configuration";
import { modifySearchValue, modifyUserNameSearchValue } from "../helpers";

export const getUsers = (search = "", archived = "", field, direction) =>
  fetch(
    `${config.get("ApiUrl").Rest}/users?${
      search && "&" + modifyUserNameSearchValue(search)
    }${(archived && "&status=all") || ""}${
      (field && "&order_by=" + modifySearchValue(field)) || ""
    }${(direction && "&order_dir=" + direction) || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getUserById = (id) =>
  fetch(`${config.get("ApiUrl").Rest}/users/${id}`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyUser = (userData, userId) =>
  fetch(`${config.get("ApiUrl").Rest}/users/${userId}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userData),
    mode: "cors",
    credentials: "include",
  });

export const addUser = (userData) =>
  fetch(`${config.get("ApiUrl").Rest}/users`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userData),
    mode: "cors",
    credentials: "include",
  });

export const singUpUser = (userData) =>
  fetch(`${config.get("ApiUrl").Rest}/signup`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userData),
    mode: "cors",
    credentials: "include",
  });

export const archiveUser = (id) =>
  fetch(`${config.get("ApiUrl").Rest}/users/${id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const getUserSites = (user_id, search = "", page = 0, page_size = 10) =>
  fetch(
    `${config.get("ApiUrl").Rest}/users/${user_id}/sites?${
      search && "&" + search
    }&page=${page}&page_size=${page_size}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getUserSitesSimple = (user_id, search = "") =>
  fetch(
    `${config.get("ApiUrl").Rest}/users/${user_id}/sites?${
      search && "&" + search
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getUserOrganisations = (
  user_id,
  search = "",
  page = 0,
  page_size = 10
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/users/${user_id}/organisations?${
      search && "&" + search
    }&page=${page}&page_size=${page_size}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getUserGroups = (user_id) =>
  fetch(`${config.get("ApiUrl").Rest}/users/${user_id}/groups`, {
    mode: "cors",
    credentials: "include",
  });

export const removeUserLinks = (siteIds, user_id) =>
  fetch(`${config.get("ApiUrl").Rest}/users/${user_id}/sites`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(siteIds),
    mode: "cors",
    credentials: "include",
  });

export const removeUserOrgLinks = (orgIds, user_id) =>
  fetch(`${config.get("ApiUrl").Rest}/users/${user_id}/organisations`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(orgIds),
    mode: "cors",
    credentials: "include",
  });

export const modifyUserGroups = (groups, user_id) =>
  fetch(`${config.get("ApiUrl").Rest}/users/${user_id}/groups`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(groups),
    mode: "cors",
    credentials: "include",
  });

export const modifyUsersGroups = (groups, user_id) =>
  fetch(`${config.get("ApiUrl").Rest}/users/${user_id}/groups`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(groups),
    mode: "cors",
    credentials: "include",
  });

export const assignGroupToUser = (groups, user_id) =>
  fetch(`${config.get("ApiUrl").Rest}/users/${user_id}/groups`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(groups),
    mode: "cors",
    credentials: "include",
  });

export const removeGroupFromUser = (groups, user_id) =>
  fetch(`${config.get("ApiUrl").Rest}/users/${user_id}/groups`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(groups),
    mode: "cors",
    credentials: "include",
  });

export const getUserPolicies = (user_id) =>
  fetch(`${config.get("ApiUrl").Rest}/users/${user_id}/policies`, {
    mode: "cors",
    credentials: "include",
  });

export const assignPolicyToUser = (policies, user_id) =>
  fetch(`${config.get("ApiUrl").Rest}/users/${user_id}/policies`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(policies),
    mode: "cors",
    credentials: "include",
  });

export const removePolicyFromUser = (policies, user_id) =>
  fetch(`${config.get("ApiUrl").Rest}/users/${user_id}/policies`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(policies),
    mode: "cors",
    credentials: "include",
  });

export const reactivateUser = (user_id) =>
  fetch(`${config.get("ApiUrl").Rest}/users/${user_id}/reactivate`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({}),
    mode: "cors",
    credentials: "include",
  });

export const resetAccount = (user_id) =>
  fetch(`${config.get("ApiUrl").Rest}/users/${user_id}/admin-reset-password`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({}),
    mode: "cors",
    credentials: "include",
  });

export const getUserSitesAmount = (user_id, search = "") =>
  fetch(
    `${config.get("ApiUrl").Rest}/users/${user_id}/sites?count_only=y${
      search && "&" + search
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getUserOrganisationsAmount = (user_id, search = "") =>
  fetch(
    `${config.get("ApiUrl").Rest}/users/${user_id}/organisations?count_only=y${
      search && "&" + search
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getUserSitesAutocomplete = (
  user_id,
  page_size = 80,
  autocompleteField,
  autocompleteText = "",
  search
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/users/${user_id}/sites?&page_size=${page_size}${
      (autocompleteField &&
        "&distinct=" +
          autocompleteField +
          "&" +
          autocompleteField +
          "=_like(" +
          autocompleteText +
          ")_") ||
      ""
    }${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getUserOrgsAutocomplete = (
  user_id,
  page_size = 80,
  autocompleteField,
  autocompleteText = "",
  search
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/users/${user_id}/organisations?&page_size=${page_size}${
      (autocompleteField &&
        "&distinct=" +
          autocompleteField +
          "&" +
          autocompleteField +
          "=_like(" +
          autocompleteText +
          ")_") ||
      ""
    }${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getUserActivity = (id, page = 0, page_size = 25, search) =>
  fetch(
    `${config.get("ApiUrl").Rest}/users/${id}/activity_log?${
      search && "&" + search
    }&page=${page}&page_size=${page_size}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getLogsAmount = (id, search = "") => {
  return fetch(
    `${config.get("ApiUrl").Rest}/users/${id}/activity_log?count_only=y${
      search ? "&" + search : ""
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};
