/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { ACTIVITY_HEADINGS } from "./constants";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { useFetch } from "../../../hooks/fetch.hook";
import { renderRow } from "../../../ui/helpers";
import { getLogsAmount, getUserActivity } from "./_api";
import { FilterLogs } from "./FIlterLogs";

export const Activity = ({ id }) => {
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");

  const fetchUserLogs = () => {
    setLoading(true);
    request(getUserActivity, id, page, pageSize, search)
      .then((data) => {
        if (!data) return;
        setData(data);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchUserLogs();
  }, [search, page, pageSize]);

  useEffect(() => {
    request(getLogsAmount, id, search).then((data) => data && setTotalRecords(data));
  }, [search]);

  return (
    <>
      <FilterLogs setSearch={setSearch} loading={loading} />
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <ListHeader title="Logs List" />
            <Datagrid
              data={data}
              headings={ACTIVITY_HEADINGS}
              renderRow={renderRow}
              loading={loading}
              serverPage={page}
              setServerPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              isServerPagination
              totalRecords={totalRecords}
            />
          </div>
        </div>
      </div>
    </>
  );
};
