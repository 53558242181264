import React, { useState } from "react";
import { Formik } from "formik";
import { Modal } from "../../../ui/components/Modal";
import { TagsField } from "../../../ui/structures/TagsForm/TagsField";
import { TagsAutocomplete } from "../../../ui/structures/TagsForm/TagsAutocomplete";
import { useHistory } from "react-router-dom";
import { useFetch } from "../../../hooks/fetch.hook";
import { deleteProductPackSizes, editProductPackSizes } from "../Settings/PackSizes/_api";
import { info } from "../../../helpers/toasts";
import { Loader } from "../../../ui/components/Loader";
import { SubmitActions } from "../../../ui/structures/SubmitActions";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";

export const PackSizes = ({
  id,
  name,
  packSizes = [],
  productPackSizes = [],
  setProductPackSizes,
  data = {},
}) => {
  const history = useHistory();
  const { request } = useFetch();

  const initialValues = {
    pack_sizes: (productPackSizes || []).map(({ id }) => id),
    legacy_pack_sizes: data.legacy_pack_sizes || "",
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitAction, setSubmitAction] = useState("Save");

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const editPacksRequest = (values) => {
    if (values.pack_sizes.length) {
      return request(
        editProductPackSizes,
        id,
        values.pack_sizes.map((id) => ({ id }))
      );
    } else {
      return request(deleteProductPackSizes, id, productPackSizes);
    }
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    editPacksRequest(values)
      .then((data) => {
        if (!data) return;
        if (submitAction === "Save") {
          setProductPackSizes(packSizes.filter(({ id }) => values.pack_sizes.includes(id)));
        }
        info("Pack sizes have been edited!");
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  const handleCancel = () => history.push("/products-list");

  return (
    <div className="border border-secondary bg-white p-10">
      <Loader isOpen={loading} maxWidth="xs" disableBackdropClick disableEscapeKeyDown />
      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <>
            {modalOpen && (
              <Modal
                maxWidth="md"
                isOpen={modalOpen}
                submitable
                onClose={handleModalClose}
                modalContent={
                  <TagsAutocomplete
                    name="pack_sizes"
                    placeholder="Select Pack Sizes"
                    setValue={setFieldValue}
                    options={packSizes.filter((item) => !values.pack_sizes.includes(item.id))}
                    loading={!packSizes.length}
                    onClose={handleModalClose}
                    currentValue={values.pack_sizes}
                    multiple
                  />
                }
              />
            )}
            <h3 className="mb-5">
              <strong>{id ? `${name} Pack Sizes` : "Pack Sizes"}</strong>
            </h3>
            <div className="row justify-content-between">
              <div className="col-8">
                <MappedInput label="Legacy Pack Sizes" name="legacy_pack_sizes" disabled />
                <TagsField
                  label="Pack Sizes"
                  tags={values.pack_sizes}
                  handleAdd={handleModalOpen}
                  setValue={setFieldValue}
                  predefinedTags={packSizes}
                  fieldName="pack_sizes"
                />
              </div>
            </div>

            <div className="mt-10">
              <SubmitActions
                id={id}
                handleCancel={handleCancel}
                disabled={isSubmitting}
                submitAction={submitAction}
                handleSubmit={handleSubmit}
                setSubmitAction={setSubmitAction}
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
