import { get } from "lodash";
import { useSelector } from "react-redux";
import { AVAILABLE_ROLES } from "../../../app/constants";

export const AccessControl = ({ desiredRoles = [], children, elseContent }) => {
  const user = useSelector(({ auth: { user } }) => user) || {};

  const isNationalAdmin = get(user, "permissions.is_global_admin");
  const isAgsafeAdmin = get(user, "permissions.is_agsafe_admin");
  const isAgsafeStaff = get(user, "permissions.is_agsafe_staff");
  const isRcAccess = get(user, "permissions.is_rc_access");
  const isCaAccess = get(user, "permissions.is_ca_access");
  const isProcessor = get(user, "permissions.is_processor");
  const isEndUser = get(user, "permissions.is_end_user");

  if (
    isNationalAdmin &&
    desiredRoles.includes(AVAILABLE_ROLES.NATIONAL_ADMIN)
  ) {
    return children;
  }

  if (
    !isNationalAdmin &&
    isAgsafeAdmin &&
    desiredRoles.includes(AVAILABLE_ROLES.AGSAFE_ADMIN)
  ) {
    return children;
  }

  if (
    !isNationalAdmin &&
    !isAgsafeAdmin &&
    isAgsafeStaff &&
    desiredRoles.includes(AVAILABLE_ROLES.AGSAFE_STAFF)
  ) {
    return children;
  }

  if (
    !isNationalAdmin &&
    !isAgsafeAdmin &&
    !isAgsafeStaff &&
    isRcAccess &&
    desiredRoles.includes(AVAILABLE_ROLES.RC_ACCESS)
  ) {
    return children;
  }

  if (
    !isNationalAdmin &&
    !isAgsafeAdmin &&
    !isAgsafeStaff &&
    !isRcAccess &&
    isEndUser &&
    desiredRoles.includes(AVAILABLE_ROLES.END_USER)
  ) {
    return children;
  }

  if (
    !isNationalAdmin &&
    !isAgsafeAdmin &&
    !isAgsafeStaff &&
    !isRcAccess &&
    isCaAccess &&
    desiredRoles.includes(AVAILABLE_ROLES.CA_ACCESS)
  ) {
    return children;
  }

  if (
    !isNationalAdmin &&
    !isAgsafeAdmin &&
    !isAgsafeStaff &&
    !isRcAccess &&
    isProcessor &&
    desiredRoles.includes(AVAILABLE_ROLES.PROCESSOR)
  ) {
    return children;
  }

  return elseContent ? elseContent : null;
};
