import React from "react";
import { Link } from "react-router-dom";
import { Box, Chip, LinearProgress, Typography } from "@material-ui/core";
import differenceInMonths from "date-fns/differenceInMonths";
import {
  formatDate,
  getStatusRowStyle,
  isDate,
  isLink,
  isStatus,
  isValidMobile
} from "../../../ui/helpers";
import { get } from "lodash";

const getBadgeColor = (badge = {}) => {
  const monthLeft = differenceInMonths(
    new Date(badge.expires),
    new Date(Date.now())
  );
  if (monthLeft > 1) {
    return "primary";
  } else if (monthLeft < 1) {
    return "danger";
  } else return "warning";
};

export const renderBadges = (item = {}) => {
  if (!item.badge_objects) return;
  return (
    <div>
      {item.badge_objects.map(badge => (
        <div className="my-1">
          <Chip
            label={badge?.name}
            className={`text-white bg-${getBadgeColor(badge)}`}
            style={{ minWidth: "90px" }}
          />
        </div>
      ))}
    </div>
  );
};

export function renderContactsRow(headings, item) {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-5 ${isStatus(key) &&
        getStatusRowStyle(item)}`}
    >
      {isLink(key) ? (
        <Link className="text-dark" to={`/contacts/${item.id}`}>
          {item[key]}
        </Link>
      ) : key === "badges" ? (
        renderBadges(item)
      ) : key === "mobile" && item["mobile"] ? (
        isValidMobile(item)
      ) : (
        item[key]
      )}
    </td>
  ));
}

const renderProgress = value => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          style={{ height: "8px", borderRadius: "4px" }}
          value={value}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="secondary">{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

export function renderTrainingsRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5 py-5">
      {isDate(key)
        ? formatDate(item[key])
        : key === "progress_percentage"
        ? renderProgress(item[key])
        : item[key]}
    </td>
  ));
}

export function modifyContact(contact = {}) {
  if (!(contact.badges || []).length) return contact;
  return {
    ...contact,
    badges: (contact.badges || []).map(({ name }) => name),
    badge_objects: contact.badges
  };
}

export function modifyContactsArray(contacts = []) {
  return contacts.map(item => modifyContact(item));
}

export function modifyTraining(training = {}) {
  return {
    ...training,
    course: get(training, "training.name")
  };
}

export function modifyTrainingsArray(trainings = []) {
  return trainings.map(item => modifyTraining(item));
}
