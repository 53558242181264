import React, { useState, useEffect } from "react";
import { TableSortLabel } from "@material-ui/core";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import SearchIcon from "@material-ui/icons/Search";
import { IconButton } from "@material-ui/core";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { renderClearancesRow } from "../Processing/helpers";
import { ServerSearchBar } from "../../../ui/structures/ServerSearchBar";
import { sortHeaderStyle } from "../../constants";
import { useFetch } from "../../../hooks/fetch.hook";
import { getSitesAutocomplete } from "../Sites/_api";
import { uniq } from "lodash";
import {
  handleCopy,
  handlePaste,
  handleSearch,
  objectCheck,
} from "../../../ui/helpers";
import { modifySitesInventory, rendeTotalVolumeRow } from "./helpers";
import {
  inventorySearchMap,
  INVENTORY_SEARCH_HELPER,
  SITE_HEADINGS,
} from "./constants";
import ToolTip from "../../../ui/components/ToolTip";
import { getRegionsAutocomplete } from "../Settings/Regions/_api";
import { getConsultantsAutocomplete } from "../Settings/Consultants/_api";
import { getSiteInventory, getSiteInventoryAmount } from "./_api";

export const SiteInventory = () => {
  const { request } = useFetch();

  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [copiedSearch, setCopiedSearch] = useState([]);

  const fetchSites = () => {
    setLoading(true);
    request(
      getSiteInventory,
      search,
      null,
      field,
      field && direction,
      page,
      pageSize
    )
      .then((data) => {
        if (!data) return;
        setData(modifySitesInventory(data));
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  const requestOptions = (
    autocompleteValue,
    autocompleteField,
    searchFields,
    archived
  ) => {
    if (autocompleteField === "organisation.region.long_name") {
      return request(
        getRegionsAutocomplete,
        80,
        "long_name",
        autocompleteValue
      );
    } else if (autocompleteField === "organisation.consultant.first_name") {
      return request(
        getConsultantsAutocomplete,
        80,
        "first_name",
        autocompleteValue
      );
    } else if (autocompleteField === "organisation.consultant.last_name") {
      return request(
        getConsultantsAutocomplete,
        80,
        "last_name",
        autocompleteValue
      );
    } else
      return request(
        getSitesAutocomplete,
        80,
        autocompleteField,
        autocompleteValue,
        field,
        field && direction,
        searchFields,
        archived
      );
  };

  const fetchSitesAutocomplete = (
    autocompleteValue,
    autocompleteField,
    searchFields,
    archived
  ) => {
    if (autocompleteValue === "") return;
    if (autocompleteField === "material") {
      setAutocompleteData(["plastic", "steel"]);
      return;
    }
    setAutocompleteLoading(true);
    requestOptions(
      autocompleteValue,
      autocompleteField,
      searchFields,
      archived
    ).then((data) => {
      if (data && Array.isArray(data) && data.length && objectCheck(data)) {
        setAutocompleteData(uniq(data.map((item) => String(item))));
      } else {
        setAutocompleteData(["No option found"]);
      }
      setAutocompleteLoading(false);
    });
  };

  useEffect(() => {
    fetchSites();
    // eslint-disable-next-line
  }, [search, field, direction, page, pageSize]);

  useEffect(() => {
    request(getSiteInventoryAmount, search).then(
      (data) => data && setTotalRecords(data)
    );
    // eslint-disable-next-line
  }, [search]);

  const resetSearch = () => {
    setSearch("");
  };

  const unsortableHeaders = ["consultant_name", "region"];

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) => {
      return unsortableHeaders.includes(key) ? (
        <th key={key} style={sortHeaderStyle} className="px-5 text-nowrap">
          <span>{header}</span>
        </th>
      ) : (
        <th
          key={key}
          style={sortHeaderStyle}
          className="px-5 text-nowrap"
          onClick={() => {
            setDirection(
              key !== field ? "desc" : direction === "desc" ? "asc" : "desc"
            );
            setField(key);
          }}
        >
          <span style={{ cursor: "pointer" }}>{header}</span>
          {key === field && <TableSortLabel active direction={direction} />}
        </th>
      );
    });
  }

  const renderButtons = () => {
    return (
      <>
        <ToolTip
          title="Copy filter criteria"
          arrow
          interactive
          placement="top"
          content={
            <IconButton
              onClick={() => handleCopy(search)}
              data-testid="copy-filter"
            >
              <FileCopyIcon />
            </IconButton>
          }
        />
        <ToolTip
          title="Apply filter criteria"
          arrow
          interactive
          placement="top"
          content={
            <IconButton
              onClick={() =>
                handlePaste(INVENTORY_SEARCH_HELPER, setCopiedSearch, setSearch)
              }
              data-testid="apply-filter"
            >
              <SearchIcon />
            </IconButton>
          }
        />
      </>
    );
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12">
          <ServerSearchBar
            className="mb-5"
            onSearch={(data) =>
              handleSearch(data, inventorySearchMap, setSearch, setPage)
            }
            keyMap={INVENTORY_SEARCH_HELPER}
            loading={autocompleteLoading}
            currentSearchList={autocompleteData}
            fetchAutocompleteFunction={fetchSitesAutocomplete}
            placeholder="Search Sites..."
            clearSearch={resetSearch}
            searchMap={inventorySearchMap}
            searchFields={search}
            copiedSearch={copiedSearch}
            contentLoading={loading}
          />
          <div className="bg-white rounded py-7 px-10">
            <ListHeader title="Site Inventory" renderButtons={renderButtons} />
            <Datagrid
              data={data}
              headings={SITE_HEADINGS}
              renderRow={renderClearancesRow}
              footerRow={rendeTotalVolumeRow}
              renderHeaderWithSorting={renderHeaderWithSorting}
              serverPage={page}
              setServerPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              isServerPagination
              totalRecords={totalRecords}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
};
