import React from "react";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ToolTip from "../../../ui/components/ToolTip";
import {
  formatDate,
  isStatus,
  isDate,
  isPrice,
  formatPrice
} from "../../../ui/helpers";

const tranformDate = date => {
  return date ? formatDate(date) : null;
};

const renderStatus = (item = {}) => {
  if (item.status === "rejected") {
    return (
      <span className="d-flex align-items-center">
        {item.status}
        <span className="mt-1 ml-2">
          {
            <ToolTip
              title={item.reject_comment || "No reject comment"}
              arrow
              interactive
              placement="top"
              content={<HelpOutlineIcon fontSize="small" />}
            />
          }
        </span>
      </span>
    );
  } else return item.status;
};

export function renderReimbursementsRow(headings, item) {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-5 "text-dark" ${
        isStatus(key) ? "text-uppercase" : ""
      }`}
    >
      {isDate(key) ? (
        tranformDate(item[key])
      ) : isPrice(key) ? (
        <div className="text-right">{formatPrice(item[key])}</div>
      ) : key === "status" ? (
        renderStatus(item)
      ) : key === "grouped" ? (
        String(item[key]).toUpperCase()
      ) : (
        item[key]
      )}
    </td>
  ));
}
