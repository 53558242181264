export const HEADINGS = [
    ["id", "User ID"],
    ["first_name", "First Name"],
    ["last_name", "Last Name"],
    ["phone_number", "Mobile"],
    ["email", "Email Address"],
    ["status", "Status"],
    ["region", "Region"],
    ["consultant", "Consultant"],
    ["last_login", "Last Login"],
  ];
  