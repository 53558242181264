import { Skeleton } from "@material-ui/lab";
import { get, uniqBy } from "lodash";
import React, { useState, useEffect } from "react";
import { useFetch } from "../../../hooks/fetch.hook";
import { setID } from "../DrumMuster/Inspections/helpers";
import { ListButton } from "../../../ui/components/ListButton";
import { info } from "../../../helpers/toasts";
import { Loader } from "../../../ui/components/Loader";
import { deleteCollectionRun, getCollectionRunById } from "../Scheduling/_api";
import { SuggestedRoute } from "../Scheduling/SuggestedRoute";
import { useHistory } from "react-router-dom";

export const RouteCard = ({
  match: {
    params: { id }
  }
}) => {
  const { request } = useFetch();
  const history = useHistory();

  const [selectedRun, setSelectedRun] = useState({});
  const [loading, setLoading] = useState(false);
  const [legs, setLegs] = useState([]);
  const [totalVolume, setTotalVolume] = useState(0);
  const [sites, setSites] = useState([]);
  const [processorAddress, setProcessorAddress] = useState("");
  const [actionLoading, setActionLoading] = useState(false);
  const [sitesWithCoordinates, setSitesWithCoordinates] = useState([]);

  useEffect(() => {
    setLoading(true);
    request(getCollectionRunById, id)
      .then(selectedRun => {
        if (!selectedRun) return;
        setSelectedRun(selectedRun);
        const totalVolume = (selectedRun.clearance_jobs || [])
          .map(({ volume }) => volume)
          .reduce((acc, value) => acc + value, 0);
        const processorAddress = [
          get(selectedRun, "processor.physical_address"),
          get(selectedRun, "processor.physical_city"),
          get(selectedRun, "processor.physical_state"),
          get(selectedRun, "processor.physical_postcode")
        ].join(", ");

        const sites = (uniqBy(selectedRun.clearance_jobs, "site_id") || []).map(
          job => ({
            address: [
              get(job, "site.physical_state"),
              get(job, "site.physical_city"),
              get(job, "site.physical_address"),
              get(job, "site.physical_postcode")
            ].join(", "),
            site_name: get(job, "site.name"),
            pickup_contact: [
              get(
                job,
                "site.processor_pickup_scheduling_contact.first_name",
                ""
              ),
              get(job, "site.processor_pickup_scheduling_contact.last_name", "")
            ].join(" "),
            pickup_phone: get(
              job,
              "site.processor_pickup_scheduling_contact.phone",
              ""
            ),
            id: get(job, "site.id")
          })
        );

        setTotalVolume(totalVolume);
        setSites(sites);
        setProcessorAddress(processorAddress);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  const formatDuration = seconds => {
    var h = Math.floor(seconds / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minute " : " minutes ") : "";
    return hDisplay + mDisplay;
  };

  const handleReject = () => {
    if (!selectedRun) return;
    setActionLoading(true);
    request(deleteCollectionRun, selectedRun.id)
      .then(data => {
        if (!data) return;
        info("Route has been deleted!");
        history.push("/drummuster/processing");
      })
      .finally(() => setActionLoading(false));
  };

  const handleClose = () => history.push("/drummuster/processing");

  const findSite = location => {
    const locotaion_lat = location.lat();
    const location_lng = location.lng();
    const site = sitesWithCoordinates.find(({ lat, lng }) => {
      if (
        ![lat, lng, location_lng, location_lng].every(
          item => typeof item === "number"
        )
      )
        return {};
      return (
        lat.toFixed(0) === locotaion_lat.toFixed(0) &&
        lng.toFixed(0) === location_lng.toFixed(0)
      );
    });
    if (!site) return {};
    return site;
  };

  const getSiteData = (start_location, end_location, index, legs = []) => {
    if (index === legs.length - 1) {
      return findSite(start_location);
    } else {
      return findSite(end_location);
    }
  };

  const handlePrint = () => window.print();

  return (
    <div className="border border-secondary bg-white p-10">
      <Loader
        isOpen={actionLoading}
        maxWidth="xs"
        disableBackdropClick
        disableEscapeKeyDown
      />
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="pt-3 mt-10 mb-5">Suggested Route Plan</h3>
        <ListButton label="Print" onClick={handlePrint} />
      </div>
      <>
        <div className="d-flex">
          <p className="mr-3">
            <strong>Total volume: </strong>
          </p>
          <p>{totalVolume}</p>
        </div>
        <div className="d-flex">
          <p className="mr-3">
            <strong>Processor Name: </strong>
          </p>
          <p>{get(selectedRun, "processor.business_name", "")}</p>
        </div>
        <div className="d-flex">
          <p className="mr-3">
            <strong>Processor Address: </strong>
          </p>
          <p>{processorAddress}</p>
        </div>
        <h4 className="mb-5">Clearance locations: </h4>
        {sites.map(({ address }) => (
          <p key={`${address} + ${setID(100000000)}`}>{address}</p>
        ))}
      </>
      <div className="d-flex">
        <div className="w-75">
          <SuggestedRoute
            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDue0HjL6FN1DXF6ghAaa_HrWLtZWOjyyE"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `600px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            processorAddress={processorAddress}
            sites={sites}
            setLegs={setLegs}
            selectedRun={selectedRun}
            setSitesWithCoordinates={setSitesWithCoordinates}
          />
        </div>
        <div className="w-25 ml-5">
          {legs.map(
            (
              {
                distance,
                duration,
                start_address,
                end_address,
                start_location,
                end_location
              },
              index
            ) => (
              <div key={index}>
                <h5>{`Route Segment: ${index + 1}`}</h5>
                <p>
                  <strong>Origin: </strong>
                  {start_address}
                </p>
                <p>
                  <strong>Stop: </strong>
                  {end_address}
                </p>
                <p>
                  <strong>Site Name: </strong>
                  {
                    getSiteData(start_location, end_location, index, legs)
                      ?.site_name
                  }
                </p>
                <p>
                  <strong>Site Pickup Contact: </strong>
                  {
                    getSiteData(start_location, end_location, index, legs)
                      ?.pickup_contact
                  }
                </p>
                <p>
                  <strong>Site Pickup Phone: </strong>
                  {
                    getSiteData(start_location, end_location, index, legs)
                      ?.pickup_phone
                  }
                </p>
                <p>
                  <strong>Distance: </strong>
                  {distance.text}
                </p>
                <p>
                  <strong>Duration: </strong>
                  {duration.text}
                </p>
              </div>
            )
          )}
          <div className="pt-5" style={{ borderTop: "1px solid black" }}>
            <p>
              <strong>Total Distance: </strong>
              {(
                legs.reduce((acc, value) => acc + value.distance.value, 0) /
                1000
              ).toFixed(2)}{" "}
              km
            </p>
            <p>
              <strong>Total Duration: </strong>
              {formatDuration(
                legs.reduce((acc, value) => acc + value.duration.value, 0)
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-10">
        <ListButton
          label="Cancel"
          size="large"
          onClick={handleClose}
          variant="outlined"
          text="#407A28"
          boxShadow={false}
          className="mr-3"
        />
        <ListButton label="Reject" onClick={handleReject} size="large" />
      </div>
    </div>
  );
};
