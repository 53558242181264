import config from "react-global-configuration";

export const addProduct = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/products`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getProducts = (
  search = "",
  archived = "",
  field,
  direction,
  page = 0,
  page_size = 10
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/products?${search &&
      "&" + search}${(archived && "&status=all") || ""}${(field &&
      "&order_by=" + field) ||
      ""}${(direction && "&order_dir=" + direction) ||
      ""}&page=${page}&page_size=${page_size}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getProductsAmount = (search = "", archived = "") => {
  return fetch(
    `${config.get("ApiUrl").Rest}/products?count_only=y${
      search ? "&" + search : ""
    }${(archived && "&status=all") || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};

export const getProductsListAutocomplete = (
  page_size = 80,
  autocompleteField,
  autocompleteText = "",
  field,
  direction,
  search,
  archived
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/products?&page_size=${page_size}${(archived &&
      "&status=all") ||
      ""}${(autocompleteField &&
      `&distinct=${autocompleteField}&${autocompleteField}=${
        autocompleteField === "product_group"
          ? autocompleteText
          : `_like(${autocompleteText})_`
      }`) ||
      ""}${(field && "&order_by=" + field) || ""}${(direction &&
      "&order_dir=" + direction) ||
      ""}${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getProductById = (product_id) =>
  fetch(`${config.get("ApiUrl").Rest}/products/${product_id}`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyProduct = (data, product_id) =>
  fetch(`${config.get("ApiUrl").Rest}/products/${product_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const archiveProduct = (id) =>
  fetch(`${config.get("ApiUrl").Rest}/products/${id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const getUploadFileUrl = (type, fileName) =>
  fetch(`${config.get("ApiUrl").Rest}/files/request-upload-url`, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    body: JSON.stringify({ type: type, file_name: fileName }),
  });

export const getUploadedProducts = (file_name) =>
  fetch(`${config.get("ApiUrl").Rest}/products/file?file_name=${file_name}`, {
    mode: "cors",
    credentials: "include",
  });

export const addImportedProducts = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/products/import`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });
