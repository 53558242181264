import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";

export const Capacity = () => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!Number(values.dm_width) || !Number(values.dm_length) || !Number(values.dm_height)) {
      return;
    }
    setFieldValue("dm_capacity", values.dm_width * values.dm_length * values.dm_height * 20);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.dm_width, values.dm_length, values.dm_height]);
  return <MappedInput label="Capacity" name="dm_capacity" disabled />;
};
