import { get } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import {
  isLink,
  isStatus,
  getStatusRowStyle,
  isPrice,
  formatPrice
} from "../../../../ui/helpers";

const upperCaseFIelds = ["type", "ccd_material"];

const formatFirstLetter = field => {
  return field.charAt(0).toUpperCase() + field.slice(1);
};

export function renderPricesRow(headings, item) {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-5 ${isStatus(key) &&
        getStatusRowStyle(item)}`}
    >
      {isLink(key) ? (
        <Link className="text-dark" to={`/prices/${item.id}`}>
          {item[key]}
        </Link>
      ) : upperCaseFIelds.includes(key) ? (
        formatFirstLetter(item[key])
      ) : isPrice(key) ? (
        <div className="text-right">{formatPrice(item[key])}</div>
      ) : (
        item[key]
      )}
    </td>
  ));
}

export function modifyPrice(item = {}) {
  return {
    ...item,
    organisation_name: get(item, "organisation.business_name", ""),
    site_name: get(item, "site.name"),
    ccd_size_ui: item.ccd_size === "20" ? "<20" : item.ccd_size,
  };
}

export function modifyPricesList(list = []) {
  return list.map(item => modifyPrice(item));
}
