/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { ListButton } from "../../../ui/components/ListButton";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { BookingDetails } from "./BookingDetails";

export const ChemClearBooking = () => {
  const [bookingOpen, setBookingOpen] = useState(false);

  const handleBookingOpen = () => setBookingOpen(true);
  return bookingOpen ? (
    <BookingDetails />
  ) : (
    <div className="bg-white px-12 pt-30 pb-10">
      <div className="d-flex align-items-center">
        <div>
          <span>
            <img src={toAbsoluteUrl("/assets/icons/Chemclear.png")} alt="Logo" />
          </span>
        </div>
        <div className="pl-5">
          <h3 className="mb-10">
            <strong>Welcome to the ChemClear Registration Portal.</strong>
          </h3>
          <div style={{ fontSize: "16px" }}>
            Please note, ChemClear provides a collection service for agricultural and veterinary
            (agvet) chemicals only. <br /> <br />
            Group 1 chemicals* are collected free of charge: *Agvet chemicals that display the
            drumMUSTER logo.
            <br /> Group 2 chemicals** incur a fee for collection: **Agvet chemicals that are not
            participating in the drumMUSTER program.
            <br /> <br /> ChemClear does not accept the following products:
            <ul className="mt-3">
              <li>Household chemicals including cleaning products</li>
              <li>Pool chemicals including chlorine</li>
              <li>Paint products</li>
              <li>Oils</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="text-right mt-20 pb-5">
        <ListButton
          label="continue to booking"
          size="large"
          className="px-20 py-5"
          onClick={handleBookingOpen}
        />
      </div>
    </div>
  );
};
