import { get } from "lodash";
import { AVAILABLE_ROLES } from "../../../app/constants";

export const accessControlFunction = (desiredRoles = [], user = {}) => {
  const isNationalAdmin = get(user, "permissions.is_global_admin");
  const isAgsafeAdmin = get(user, "permissions.is_agsafe_admin");
  const isAgsafeStaff = get(user, "permissions.is_agsafe_staff");
  const isRcAccess = get(user, "permissions.is_rc_access");
  const isCaAccess = get(user, "permissions.is_ca_access");

  if (isNationalAdmin && desiredRoles.includes(AVAILABLE_ROLES.NATIONAL_ADMIN)) {
    return true;
  } else if (
    !isNationalAdmin &&
    isAgsafeAdmin &&
    desiredRoles.includes(AVAILABLE_ROLES.AGSAFE_ADMIN)
  ) {
    return true;
  } else if (
    !isNationalAdmin &&
    !isAgsafeAdmin &&
    isAgsafeStaff &&
    desiredRoles.includes(AVAILABLE_ROLES.AGSAFE_STAFF)
  ) {
    return true;
  } else if (
    !isNationalAdmin &&
    !isAgsafeAdmin &&
    !isAgsafeStaff &&
    isRcAccess &&
    desiredRoles.includes(AVAILABLE_ROLES.RC_ACCESS)
  ) {
    return true;
  } else if (
    !isNationalAdmin &&
    !isAgsafeAdmin &&
    !isAgsafeStaff &&
    isCaAccess &&
    desiredRoles.includes(AVAILABLE_ROLES.CA_ACCESS)
  ) {
    return true;
  } else return false;
};
