/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";
import { format, subDays } from "date-fns";
import { useFetch } from "../../../hooks/fetch.hook";
import { tilesMap } from "../../../app/modules/Dashboard/constants";
import { getOrganisationsAmount } from "../../../app/modules/Organisations/_api";
import { getTasks } from "../../../app/modules/Tasks/_api";
import { ReactComponent as PowerbiLogo } from "../../../app/static/PowerbiLogo.svg";
import "./StatusTile.css";

export const AdminTiles = ({ user = {} }) => {
  const { request } = useFetch();

  const [currentTasks, setCurrentTasks] = useState("");
  const [overdueTasks, setOverduetasks] = useState("");
  const [clients, setClients] = useState("");

  const quantityMap = {
    Clients: clients,
    "Overdue Tasks": overdueTasks,
    "Current Tasks": currentTasks,
  };

  useEffect(() => {
    request(getOrganisationsAmount).then((data) => {
      if (!data && data !== 0) return;
      setClients(data);
    });
  }, []);

  useEffect(() => {
    request(
      getTasks,
      `count_only=y&users.id=${
        user.id
      }&completed_date=_null_&gte-due_date=${format(
        subDays(new Date(Date.now()), 1),
        "yyyy/MM/dd"
      )}`
    ).then((data) => {
      if (!data && data !== 0) return;
      setCurrentTasks(data);
    });
    request(
      getTasks,
      `count_only=y&completed_date=_null_&lte-due_date=${format(
        new Date(Date.now()),
        "yyyy/MM/dd"
      )}`
    ).then((data) => {
      if (!data && data !== 0) return;
      setOverduetasks(data);
    });
  }, []);

  return (
    <div className="d-flex justify-content-between mb-10">
      {tilesMap.map(({ title, icon, path }) => (
        <Link
          to={{
            pathname: path,
            region: user.region_name,
          }}
          key={title}
        >
          <div className="py-7 text-center tile-wrapper">
            <div className="mb-5">{icon}</div>
            <div style={{ fontSize: "2rem" }} className="mb-3 text-center">
              {quantityMap[title] || quantityMap[title] === 0 ? (
                quantityMap[title]
              ) : (
                <div style={{ position: "relative", left: "42%" }}>
                  <Skeleton variant="circle" width={40} height={40} />
                </div>
              )}
            </div>
            <div style={{ fontSize: "1rem" }}>{title}</div>
          </div>
        </Link>
      ))}
      <div>
        <div
          className="py-18 tile-wrapper d-flex justify-content-center align-items-center cursor-pointer"
          onClick={() => {
            const link = document.createElement("a");
            link.href = "https://app.powerbi.com";
            link.setAttribute("target", "_blank");
            link.setAttribute("rel", "noopener noreferrer");
            link.click();
          }}
        >
          <PowerbiLogo />
        </div>
      </div>
    </div>
  );
};
