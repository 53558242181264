/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useSelector, connect } from "react-redux";
import { DevAlertBar } from "../../../ui/components/AlertBar";
import { QuickBooks } from "./QuickBooks";
import { Power } from "./Power";
import { Apvma } from "./Apvma";
import { Auditor } from "./Auditor";
import { Modal } from "../../../ui/components/Modal";
import { ClientDetails } from "./ClientDetails";
import { callBackUrlMap, getXeroAuthUrl } from "./constants";
import { CRSKeyForm } from "./CrsKeyForm";
import { useFetch } from "../../../hooks/fetch.hook";
import { getXeroIntegration } from "./helpers";
import * as auth from "../Auth/_redux/authRedux";
import { deleteIntegration } from "./Power/_api";
import { Abr } from "./Abr";
import { MemberServices } from "./MemberServices";
import { CampaignMonitor } from "./CampaignMonitor";
import { BulkSMS } from "./BulkSMS";
import { BulkDetails } from "./BulkSMS/BulkDetails";
import { Moodle } from "./Moodle";
import { MoodleDetails } from "./Moodle/MoodleDetails";
import { AuditorDetails } from "./Auditor/AuditorDetails";

const Integrations = (props) => {
  const { request } = useFetch();

  const user = useSelector(({ auth: { user } }) => user);
  const integrations =
    useSelector(
      ({
        auth: {
          user: { integrations },
        },
      }) => integrations
    ) || [];

  const xeroIntegration = getXeroIntegration();

  const [connectedComponent, setConnectedComponent] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalComponent, setModalComponent] = useState("");
  const handleModalClose = () => setModalOpen(false);

  useEffect(() => {
    xeroIntegration && setConnectedComponent("xero");
    // eslint-disable-next-line
  }, []);

  const handleClientDetailsSubmit = (values, { setSubmitting }) => {
    // setSubmitting(false);
    // handleModalClose();
    // props.fulfillUser({ ...user, ...values });
    // window.location.href = getXeroAuthUrl(
    //   user.id,
    //   values.client_id,
    //   callBackUrlMap[window.location.hostname]
    // );
  };

  const handleCrsKeyFormSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    setConnectedComponent("crs");
    handleModalClose();
  };

  const handleMyobtDetailsSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    setConnectedComponent("myob");
    handleModalClose();
  };

  const handleXeroConnect = () => {
    setModalComponent("xero");
    setModalOpen(true);
  };

  const handleBulkConnect = () => {
    setModalComponent("bulk");
    setModalOpen(true);
  };

  const handleXeroDisconnect = () => {
    // request(deleteIntegration, xeroIntegration.id).then((data) => {
    //   if (!data) return;
    //   props.fulfillUser({
    //     ...user,
    //     integrations: user.integrations.filter(({ id }) => id !== xeroIntegration.id),
    //   });
    //   setConnectedComponent("");
    // });
  };

  const handleCRSConnect = () => {
    setModalComponent("crs");
    setModalOpen(true);
  };

  const handleCRSDisconnect = () => {
    setConnectedComponent("");
  };

  const handleMYOBConnect = () => {
    setConnectedComponent("myob");
  };

  const handleMYOBDisconnect = () => {
    setConnectedComponent("");
  };

  const modalContentMap = {
    xero: (
      <ClientDetails
        onClose={handleModalClose}
        handleSubmit={handleClientDetailsSubmit}
      />
    ),
    crs: (
      <CRSKeyForm
        onClose={handleModalClose}
        handleSubmit={handleCrsKeyFormSubmit}
      />
    ),
    myob: (
      <ClientDetails
        onClose={handleModalClose}
        handleSubmit={handleMyobtDetailsSubmit}
      />
    ),
    bulk: <BulkDetails onClose={handleModalClose} />,
    moodle: <MoodleDetails onClose={handleModalClose} />,
    auditor: <AuditorDetails onClose={handleModalClose} />,
  };

  const modalSizeMap = {
    xero: "sm",
    crs: "sm",
    myob: "sm",
    bulk: "md",
    moodle: "md",
    auditor: "md",
  };

  return (
    <>
      {modalOpen && (
        <Modal
          maxWidth={modalSizeMap[modalComponent]}
          isOpen={modalOpen}
          submitable
          onClose={handleModalClose}
          modalContent={modalContentMap[modalComponent]}
        />
      )}
      <DevAlertBar message="This Page is Under Development" />
      <div className="bg-white p-10">
        <h4>
          <strong>Integrations</strong>
        </h4>
        <hr />
        <div className="my-10">
          <div className="d-flex justify-content-between flex-wrap">
            <Power
              handleConnect={handleXeroConnect}
              handleDisconnect={handleXeroDisconnect}
              connectedComponent={connectedComponent}
              setConnectedComponent={setConnectedComponent}
              integrations={integrations}
              fulfillUser={props.fulfillUser}
              user={user}
            />
            <Auditor />
            <Apvma />
            <QuickBooks />
            <Abr />
            <CampaignMonitor />
            <MemberServices />
            <Moodle />
            <BulkSMS handleConnect={handleBulkConnect} />
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, auth.actions)(Integrations);
