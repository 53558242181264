/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from "formik";
import React, { useEffect, useState, useMemo } from "react";
import { MappedDatePicker } from "@bit/the-glue.frontendcomponents.datepicker";
import { ListButton } from "../../../ui/components/ListButton";
import { initialValues } from "./constants";
import { Autocomplete } from "../../../ui/components/Autocomplete";
import { useFetch } from "../../../hooks/fetch.hook";
import { getProcessors } from "../Organisations/_api";
import { addClearance } from "./_api";
import { Loader } from "../../../ui/components/Loader";
import { error, info } from "../../../helpers/toasts";
import { schedulingSchema } from "../../../helpers/schemas";
import { TotalVolume } from "./TotalVolume";
import { SiteSelect } from "./SiteSelect";
import { AVAILABLE_ROLES } from "../../constants";
import { AccessControl } from "../../../ui/structures/AccessControl";
import { modifyCollectionRun } from "./helpers";
import { formatDate } from "../../../ui/helpers";

export const Planning = ({ collectionRuns = [], setCollectionRuns }) => {
  const { request } = useFetch();

  const [processors, setProcessors] = useState([]);
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    request(getProcessors).then((data) => data && setProcessors(data));
  }, []);

  const processorOptions = useMemo(
    () =>
      processors.map((organiastion) => ({
        value: organiastion.id,
        label: organiastion.business_name,
      })),
    [processors]
  );

  const handleSubmit = (values, { setSubmitting }) => {
    const selectedSites = Object.keys(selected).filter((key) => selected[key]);
    if (!selectedSites.length) {
      error("Select at least one site");
      setLoading(false);
      return;
    }
    const materials = selectedSites.map((key) => {
      const site = data.find(({ id }) => id === key);
      if (!site) return null;
      return {
        material: site.material,
        site_id: site.site_id,
      };
    });
    const modifiedMaterails = materials.map((item) => {
      const materialsList = materials.filter(
        (material) => material?.site_id === item?.site_id
      );
      if (materialsList.length > 1) {
        return {
          [item.site_id]: ["plastic", "steel"],
        };
      } else
        return {
          [item.site_id]: [item["material"]],
        };
    });
    const payload = {
      ...values,
      type: "site_clearance",
      site_ids_and_materials: modifiedMaterails.reduce(
        (acc, value) => ({ ...acc, ...value }),
        {}
      ),
      planned_pickup_date: formatDate(values.planned_pickup_date),
    };

    setLoading(true);
    request(addClearance, payload)
      .then((data) => {
        if (!data) return;
        setCollectionRuns((state) => [...state, modifyCollectionRun(data)]);
        info("Route has been created!");
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  return (
    <>
      <Loader
        isOpen={loading}
        maxWidth="xs"
        disableBackdropClick
        disableEscapeKeyDown
      />
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={schedulingSchema}
      >
        {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
          <div className="border border-secondary bg-white p-10">
            <h3 className="mb-5">
              <strong>Scheduling</strong>
            </h3>
            <SiteSelect
              selected={selected}
              setSelected={setSelected}
              data={data}
              setData={setData}
            />
            <hr />
            <div className="w-75">
              <h3 className="my-5">Enter Details</h3>
              <TotalVolume data={data} selected={selected} />
              <div className="mb-5">
                <MappedDatePicker
                  name="planned_pickup_date"
                  label="Planned Pickup Date"
                />
              </div>
              <Autocomplete
                name="processor_id"
                placeholder="Select Processor"
                setValue={setFieldValue}
                size="small"
                defaultValueField="value"
                options={processorOptions}
              />
            </div>
            <AccessControl
              desiredRoles={[
                AVAILABLE_ROLES.NATIONAL_ADMIN,
                AVAILABLE_ROLES.AGSAFE_ADMIN,
                AVAILABLE_ROLES.AGSAFE_STAFF,
                AVAILABLE_ROLES.RC_ACCESS,
              ]}
            >
              <div className="d-flex justify-content-end mt-10">
                <ListButton
                  label="Plan Route"
                  onClick={handleSubmit}
                  size="large"
                />
              </div>
            </AccessControl>
          </div>
        )}
      </Formik>
    </>
  );
};
