/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { TableSortLabel } from "@material-ui/core";
import { HEADINGS, SORT_HEADINGS } from "./constants";
import { ListHeader } from "../../../../ui/structures/ListHeader";
import { ListButton } from "../../../../ui/components/ListButton";
import { useFetch } from "../../../../hooks/fetch.hook";
import {
  createReimbursements,
  editDrop,
  getDrops,
  getDropsCount,
} from "../Inspections/_api";
import { Loader } from "../../../../ui/components/Loader";
import { error, info } from "../../../../helpers/toasts";
import {
  modifyDropsArray,
  rendeTotalVolumeRow,
  renderClaimsRow,
} from "./helpers";
import { totalStyle } from "./constants";
import { Modal } from "../../../../ui/components/Modal";
import { SplitInventory } from "./SplitInventory";
import { Checkbox } from "../../../../ui/components/Checkbox";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { AccessControl } from "../../../../ui/structures/AccessControl";
import { AVAILABLE_ROLES, sortHeaderStyle } from "../../../constants";

export const CreateClaim = ({ handleNextStep, ORGANISATION_ID }) => {
  const { request } = useFetch();
  const user = useSelector(({ auth: { user } }) => user) || {};

  const isCaAccess = get(user, "permissions.is_ca_access");
  const isProcessor = get(user, "permissions.is_processor");
  const isEndUser = get(user, "permissions.is_end_user");

  const [CCDData, setCCDData] = useState([]);
  const [eCCDData, setECCDData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selected, setSelected] = useState({});
  const [eSelected, setESelected] = useState({});
  const [pageSize, setPageSize] = useState(200);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [epageSize, setEPageSize] = useState(200);
  const [epage, setEPage] = useState(0);
  const [etotalRecords, setETotalRecords] = useState(0);
  const [eloading, setEloading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [nonModifiedCCDs, setNonModifiedCCDs] = useState([]);
  const [nonModifiedECCDs, setNonModifiedECCDs] = useState([]);
  const [dropSplited, setDropSplited] = useState(false);
  const [instantChecked, setInstantChecked] = useState(false);
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [efield, seteField] = useState("");
  const [edirection, seteDirection] = useState("");

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  useEffect(() => {
    if (!ORGANISATION_ID) return;
    setLoading(true);
    request(
      getDrops,
      `reimbursement_id=_null_&eccd=false&type=inspection${
        ORGANISATION_ID ? "&site.organisation_id=" + ORGANISATION_ID : ""
      }`,
      field,
      direction,
      page,
      pageSize
    )
      .then((data) => {
        if (!data) return;
        setCCDData(modifyDropsArray(data));
        setNonModifiedCCDs(data);
      })
      .finally(() => setLoading(false));
  }, [ORGANISATION_ID, page, pageSize, dropSplited, field, direction]);

  useEffect(() => {
    if (!ORGANISATION_ID) return;
    request(
      getDropsCount,
      `reimbursement_id=_null_&eccd=false&type=inspection${
        ORGANISATION_ID ? "&site.organisation_id=" + ORGANISATION_ID : ""
      }`
    ).then((data) => data && setTotalRecords(data));
  }, [ORGANISATION_ID, dropSplited]);

  useEffect(() => {
    if (!ORGANISATION_ID) return;
    setEloading(true);
    request(
      getDrops,
      `reimbursement_id=_null_&eccd=true${
        ORGANISATION_ID ? "&site.organisation_id=" + ORGANISATION_ID : ""
      }`,
      efield,
      edirection,
      epage,
      epageSize
    )
      .then((data) => {
        if (!data) return;
        setECCDData(modifyDropsArray(data));
        setNonModifiedECCDs(data);
      })
      .finally(() => setEloading(false));
  }, [ORGANISATION_ID, epage, epageSize, dropSplited, efield, edirection]);

  useEffect(() => {
    if (!ORGANISATION_ID) return;
    request(
      getDropsCount,
      `reimbursement_id=_null_&eccd=true${
        ORGANISATION_ID ? "&site.organisation_id=" + ORGANISATION_ID : ""
      }`
    ).then((data) => data && setETotalRecords(data));
  }, [ORGANISATION_ID, dropSplited]);

  const getTotalVolume = () => {
    return [...CCDData, ...eCCDData].reduce(
      (acc, value) =>
        acc +
        (Number(value.plastic_0) || 0) +
        (Number(value.plastic_1) || 0) +
        (Number(value.plastic_2) || 0) +
        (Number(value.plastic_3) || 0) +
        (Number(value.steel_0) || 0) +
        (Number(value.steel_1) || 0) +
        (Number(value.steel_2) || 0),
      0
    );
  };

  const handleDataUpdate = (selectedIds) => {
    setCCDData((state) => state.filter(({ id }) => !selectedIds.includes(id)));
    setECCDData((state) => state.filter(({ id }) => !selectedIds.includes(id)));
    setSelected({});
    setESelected({});
  };

  const handleInstantProcess = (payload, selectedIds, setSubmitting) => {
    createReimbursements(payload)
      // createReimbursementsFake()
      .then((res) => res.json())
      .then((data) => {
        if (!data) return;
        if (data.message !== "200 OK") throw new Error(data.message);
        else {
          info("Reimbursement has been created!");
          handleDataUpdate(selectedIds);
        }
      })
      .finally(() => setSubmitting(false))
      .catch((err) => {
        if (err.message === "Failed to fetch") {
          setSubmitting(false);
          info(
            "Reimbursement will be created in background. It will take about 30 seconds!"
          );
          setTimeout(() => info("Reimbursement has been created!"), 30000);
          handleDataUpdate(selectedIds);
        } else error(err.message || "Something went wrong!");
      });
  };

  const handleSubmit = () => {
    const selectedRecords = { ...selected, ...eSelected };
    const selectedIds = Object.keys(selectedRecords).filter(
      (key) => selectedRecords[key]
    );
    setSubmitting(true);
    const payload = {
      date: new Date(Date.now()),
      type: "inspection",
      volume: getTotalVolume(),
      drop_off_ids: selectedIds,
      instant_process: instantChecked,
    };
    instantChecked
      ? handleInstantProcess(payload, selectedIds, setSubmitting)
      : request(createReimbursements, payload)
          .then((res) => {
            if (!res) return;
            setCCDData((state) =>
              state.filter(({ id }) => !selectedIds.includes(id))
            );
            setECCDData((state) =>
              state.filter(({ id }) => !selectedIds.includes(id))
            );
            info("Reimbursement has been created!");
          })
          .finally(() => {
            setSubmitting(false);
            setSelected({});
            setESelected({});
          });
  };

  const submitDisalbed = useMemo(
    () =>
      Object.values({ ...selected, ...eSelected }).filter(Boolean).length === 0,
    [selected, eSelected]
  );

  const selectedItems = Object.keys(selected).filter((key) => selected[key]);

  const selectedECCDItems = Object.keys(eSelected).filter(
    (key) => eSelected[key]
  );

  const totalDrums = [...CCDData, ...eCCDData]
    .filter(({ id }) => [...selectedItems, ...selectedECCDItems].includes(id))
    .reduce((acc, value) => acc + Number(value.total), 0);

  const splitDisabled = useMemo(
    () =>
      Object.values({ ...selected, ...eSelected }).filter(Boolean).length !== 1,
    [selected]
  );

  const renderButtons = () => (
    <>
      <ListButton
        label="Split"
        size="medium"
        onClick={handleModalOpen}
        disabled={splitDisabled}
        data-testid="split"
      />
    </>
  );

  const handleInstantChange = (e) => {
    setInstantChecked(e.target.checked);
  };

  const selectedItemID = Object.keys(selected).filter(
    (key) => selected[key]
  )[0];

  const handleDelete = (id, type) => {
    setSubmitting(true);
    request(editDrop, { status: "cancelled" }, id)
      .then((data) => {
        if (!data) return;
        if (type === "ccd") {
          setCCDData((state) => state.filter((ccd) => ccd?.id !== id));
        } else setECCDData((state) => state.filter((ccd) => ccd?.id !== id));
        info("Drop-off has been cancelled!");
      })
      .finally(() => setSubmitting(false));
  };

  const sortableFields = ["Site Name", "Date"];

  function renderHeaderWithSorting(headings) {
    return SORT_HEADINGS.map(([key, header]) => {
      return sortableFields.includes(header) ? (
        <th
          key={key}
          style={sortHeaderStyle}
          className="px-5 text-nowrap"
          onClick={() => {
            setDirection(
              key !== field ? "desc" : direction === "desc" ? "asc" : "desc"
            );
            setField(key);
          }}
        >
          <span style={{ cursor: "pointer" }}>{header}</span>
          {key === field && <TableSortLabel active direction={direction} />}
        </th>
      ) : (
        <th key={key} style={sortHeaderStyle} className="px-5 text-nowrap">
          <span>{header}</span>
        </th>
      );
    });
  }

  function renderEHeaderWithSorting(headings) {
    return SORT_HEADINGS.map(([key, header]) => {
      return sortableFields.includes(header) ? (
        <th
          key={key}
          style={sortHeaderStyle}
          className="px-5 text-nowrap"
          onClick={() => {
            seteDirection(
              key !== efield ? "desc" : edirection === "desc" ? "asc" : "desc"
            );
            seteField(key);
          }}
        >
          <span style={{ cursor: "pointer" }}>{header}</span>
          {key === efield && <TableSortLabel active direction={edirection} />}
        </th>
      ) : (
        <th key={key} style={sortHeaderStyle} className="px-5 text-nowrap">
          <span>{header}</span>
        </th>
      );
    });
  }

  return (
    <>
      <Modal
        isOpen={modalOpen}
        submitable
        onClose={handleModalClose}
        maxWidth="md"
        modalContent={
          <SplitInventory
            setDropSplited={setDropSplited}
            dropSplited={dropSplited}
            handleClose={handleModalClose}
            data={[...CCDData, ...eCCDData].find(
              ({ id }) => id === selectedItemID
            )}
            nonModifiedData={[...nonModifiedCCDs, ...nonModifiedECCDs].find(
              ({ id }) => id === selectedItemID
            )}
          />
        }
      />
      <Loader
        isOpen={submitting}
        maxWidth="xs"
        disableBackdropClick
        disableEscapeKeyDown
      />
      <div
        className="row justify-content-center mt-2"
        data-testid="ccd-wrapper"
      >
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <ListHeader
              title="Drop Off Summary"
              renderButtons={renderButtons}
            />
            <AccessControl
              desiredRoles={[
                AVAILABLE_ROLES.NATIONAL_ADMIN,
                AVAILABLE_ROLES.AGSAFE_ADMIN,
                AVAILABLE_ROLES.AGSAFE_STAFF,
              ]}
              elseContent={
                <Datagrid
                  data={CCDData}
                  headings={HEADINGS}
                  renderRow={renderClaimsRow}
                  renderHeaderWithSorting={renderHeaderWithSorting}
                  loading={loading}
                  selectable
                  selected={selected}
                  setSelected={setSelected}
                  paginationDefaultValue={20}
                  footerRow={(data) => rendeTotalVolumeRow(data, selectedItems)}
                  editable
                  link="claims"
                  serverPage={page}
                  setServerPage={setPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  isServerPagination
                  totalRecords={totalRecords}
                  rowsPerPageOptions={[10, 25, 50, 200]}
                  handleDelete={(id) => handleDelete(id, "ccd")}
                />
              }
            >
              <div data-testid="admin-ccd-grid">
                <Datagrid
                  data={CCDData}
                  headings={HEADINGS}
                  renderRow={renderClaimsRow}
                  renderHeaderWithSorting={renderHeaderWithSorting}
                  loading={loading}
                  selectable
                  selected={selected}
                  setSelected={setSelected}
                  paginationDefaultValue={20}
                  footerRow={(data) => rendeTotalVolumeRow(data, selectedItems)}
                  editable
                  link="claims"
                  serverPage={page}
                  setServerPage={setPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  isServerPagination
                  totalRecords={totalRecords}
                  rowsPerPageOptions={[10, 25, 50, 200]}
                  deletable
                  handleDelete={(id) => handleDelete(id, "ccd")}
                />
              </div>
            </AccessControl>
          </div>
        </div>
      </div>

      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <ListHeader
              title="Pre-Booked Drop Offs"
              renderButtons={renderButtons}
            />
            <AccessControl
              desiredRoles={[
                AVAILABLE_ROLES.NATIONAL_ADMIN,
                AVAILABLE_ROLES.AGSAFE_ADMIN,
                AVAILABLE_ROLES.AGSAFE_STAFF,
              ]}
              elseContent={
                <Datagrid
                  data={eCCDData}
                  headings={HEADINGS}
                  renderRow={renderClaimsRow}
                  renderHeaderWithSorting={renderEHeaderWithSorting}
                  loading={eloading}
                  selectable
                  selected={eSelected}
                  setSelected={setESelected}
                  paginationDefaultValue={20}
                  footerRow={(data) =>
                    rendeTotalVolumeRow(data, selectedECCDItems)
                  }
                  editable
                  link="claims"
                  serverPage={epage}
                  setServerPage={setEPage}
                  pageSize={epageSize}
                  setPageSize={setEPageSize}
                  isServerPagination
                  totalRecords={etotalRecords}
                  rowsPerPageOptions={[10, 25, 50, 200]}
                  handleDelete={(id) => handleDelete(id, "eccd")}
                />
              }
            >
              <Datagrid
                data={eCCDData}
                headings={HEADINGS}
                renderRow={renderClaimsRow}
                renderHeaderWithSorting={renderEHeaderWithSorting}
                loading={eloading}
                selectable
                selected={eSelected}
                setSelected={setESelected}
                paginationDefaultValue={20}
                footerRow={(data) =>
                  rendeTotalVolumeRow(data, selectedECCDItems)
                }
                editable
                link="claims"
                serverPage={epage}
                setServerPage={setEPage}
                pageSize={epageSize}
                setPageSize={setEPageSize}
                isServerPagination
                totalRecords={etotalRecords}
                rowsPerPageOptions={[10, 25, 50, 200]}
                deletable
                handleDelete={(id) => handleDelete(id, "eccd")}
              />
            </AccessControl>
          </div>
        </div>
      </div>
      <div className="mt-5 py-10 bg-white d-flex px-10 align-items-center">
        <div className="w-25" style={totalStyle}>
          <span>Drop Off Count</span>
          <span className="ml-10">
            {selectedItems.length + selectedECCDItems.length}
          </span>
        </div>
        <div className="w-25" style={totalStyle}>
          <span>Total Drums</span>
          <span className="ml-10">{totalDrums}</span>
        </div>
        <div className="text-right w-50">
          <AccessControl
            desiredRoles={[
              AVAILABLE_ROLES.NATIONAL_ADMIN,
              AVAILABLE_ROLES.AGSAFE_ADMIN,
              AVAILABLE_ROLES.AGSAFE_STAFF,
              AVAILABLE_ROLES.RC_ACCESS,
            ]}
          >
            <Checkbox
              checked={instantChecked}
              onChange={handleInstantChange}
              label="Process Instantly"
            />
          </AccessControl>

          {isCaAccess && isProcessor && isEndUser ? (
            <Checkbox
              checked={instantChecked}
              onChange={handleInstantChange}
              label="Process Instantly"
            />
          ) : null}

          <ListButton
            label="Claims"
            size="large"
            onClick={handleNextStep}
            className="mr-3"
            variant="outlined"
            text="#407A28"
            data-testid="next-step"
          />
          <ListButton
            label="Create Claim"
            size="large"
            onClick={handleSubmit}
            disabled={submitting || submitDisalbed}
            data-testid="create-claim"
          />
        </div>
      </div>
    </>
  );
};
