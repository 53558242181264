import React, { useState } from "react";
import { ListButton } from "./ListButton";

export const ConfirmDelete = ({
  name = "this item",
  handleClose,
  handleSubmit,
  id,
  isGroupedItem,
  getGroupedItemsAmount = 0,
  allowCustomArchive,
  customArchiveLabel,
  allowCustomArchiveCondition,
  customArchiveCondition,
}) => {
  const [submitting, setSubmitting] = useState(false);

  return (
    <div className="pb-5" data-testid="confirm-delete">
      <div className="text-center">
        <h3 className="text-danger text-center">
          {isGroupedItem ? (
            <div className="mb-5">
              Deleting this item will result into deleting{" "}
              {getGroupedItemsAmount - 1} more grouped{" "}
              {getGroupedItemsAmount - 1 > 1 ? "items" : "item"}
            </div>
          ) : null}
          <p>Are you sure you want to archive</p>
          <p>{name} ?</p>
        </h3>
      </div>
      <div className="mt-10 d-flex justify-content-end mr-4">
        <div className="mr-3">
          <ListButton
            label="Cancel"
            size="small"
            onClick={handleClose}
            data-testid="cancel"
            variant="outlined"
            text="#407A28"
            boxShadow={false}
          />
        </div>
        <div className="mr-3">
          <ListButton
            label="Archive"
            size="small"
            disabled={submitting}
            onClick={() => handleSubmit(id, setSubmitting)}
            data-testid="submit"
          />
        </div>
        {allowCustomArchive && (
          <div>
            <ListButton
              label={customArchiveLabel}
              size="small"
              disabled={submitting}
              className={
                allowCustomArchiveCondition
                  ? customArchiveCondition
                    ? "d-block"
                    : "d-none"
                  : "d-block"
              }
              onClick={() => handleSubmit(id, setSubmitting, true)}
              data-testid="submit-custom"
            />
          </div>
        )}
      </div>
    </div>
  );
};
