/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { getSelectedId, handleSearch, objectCheck } from "../../../ui/helpers";
import { HEADINGS, SEARCH_HELPER } from "./constants";
import {
  removeProduct,
  setProductsList,
  updateProductsList,
} from "./_redux/actions";
import { renderProductsRow } from "./helpers";
import { ListButton } from "../../../ui/components/ListButton";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  archiveProduct,
  getProductById,
  getProducts,
  getProductsAmount,
  getProductsListAutocomplete,
  modifyProduct,
} from "./_api";
import { searchMap } from "./constants";
import { UnarchiveButton } from "../../../ui/components/UnarchiveButton";
import { Modal } from "../../../ui/components/Modal";
import { ConfirmDelete } from "../../../ui/components/ConfirmDelete";
import { ArchiveCheckbox } from "../../../ui/components/ArchiveCheckbox";
import { sortHeaderStyle } from "../../constants";
import { TableSortLabel } from "@material-ui/core";
import { Loader } from "../../../ui/components/Loader";
import { info } from "../../../helpers/toasts";
import { ServerSearchBar } from "../../../ui/structures/ServerSearchBar";
import { uniq } from "lodash";
import { ImportProducts } from "./ImportProducts";

export const List = () => {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [actionLoading, setActionLoading] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [unArchiving, setUnarchiving] = useState(false);
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [importOpen, setImportOpen] = useState(false);

  const handleImportOpen = () => setImportOpen(true);
  const handleImportClose = () => setImportOpen(false);

  const handleModalClose = () => setConfirmDeleteOpen(false);

  const data = useSelector(({ products: { productsList } }) => productsList);

  const fetchProducts = () => {
    setLoading(true);
    request(
      getProducts,
      search,
      archived,
      field,
      field && direction,
      page,
      pageSize
    )
      .then((data) => {
        if (!data) return;
        dispatch(setProductsList(data));
        setLoading(false);
      })
      .finally(() => setArchivedLoading(false));
  };

  const fetchProductsAutocomplete = (
    autocompleteValue,
    autocompleteField,
    searchFields,
    archived
  ) => {
    if (autocompleteValue === "") return;
    if (autocompleteField === "status") {
      setAutocompleteData(["active", "imported"]);
      return;
    }
    if (autocompleteField === "pack_sizes") {
      setAutocompleteData(["Missing"]);
      return;
    }
    setAutocompleteLoading(true);
    request(
      getProductsListAutocomplete,
      80,
      autocompleteField,
      autocompleteValue,
      field,
      field && direction,
      autocompleteField === "product_group" ? null : searchFields,
      archived
    ).then((data) => {
      if (data && Array.isArray(data) && data.length && objectCheck(data)) {
        setAutocompleteData(uniq(data.map((item) => String(item))));
      } else {
        setAutocompleteData(["No option found"]);
      }
      setAutocompleteLoading(false);
    });
  };

  useEffect(() => {
    fetchProducts();
  }, [search, archived, field, direction, page, pageSize]);

  useEffect(() => {
    request(getProductsAmount, search, archived).then(
      (data) => data && setTotalRecords(data)
    );
  }, [search, archived]);

  const unArchiveDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const handleArchive = (id, setSubmitting) => {
    setSubmitting(true);
    setActionLoading(true);
    setConfirmDeleteOpen(false);
    request(archiveProduct, id)
      .then((data) => {
        if (!data) return;
        if (archived) {
          request(getProductById, id).then(
            (data) => data && dispatch(updateProductsList(data))
          );
        } else dispatch(removeProduct(id));
        info("Product has been archived!");
        setSelected({});
      })
      .finally(() => {
        setSubmitting(false);
        setActionLoading(false);
      });
  };

  const handleUnarchive = () => {
    const productID = getSelectedId(selected);
    const status = (data.find(({ id }) => id === productID) || {}).status;
    if (status !== "archived") return;
    setUnarchiving(true);
    setActionLoading(true);
    request(modifyProduct, { status: "active" }, productID)
      .then((data) => {
        if (!data) return;
        dispatch(updateProductsList(data));
        setSelected({});
        info("Product has been unarchived!");
      })
      .finally(() => {
        setUnarchiving(false);
        setActionLoading(false);
      });
  };

  const renderButtons = () => (
    <>
      {archived && (
        <UnarchiveButton
          handleClick={handleUnarchive}
          disabled={unArchiveDisabled}
          loading={unArchiving}
        />
      )}
      <ListButton
        label="Import"
        className="mr-5"
        onClick={handleImportOpen}
        data-testid="import"
      />
      <div className="mr-5">
        <Link
          to={{
            pathname: `/add-product`,
          }}
          data-testid="new"
        >
          <ListButton label="New" />
        </Link>
      </div>
    </>
  );

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  const handleOpenModal = (id) => {
    setConfirmDeleteOpen(true);
    setSelectedItem(id);
  };

  const resetSearch = () => {
    setSearch("");
  };

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) =>
      key === "badges" ? (
        <th key={key} style={sortHeaderStyle} className="px-5 text-nowrap">
          <span>{header}</span>
        </th>
      ) : (
        <th
          key={key}
          style={sortHeaderStyle}
          className="px-5 text-nowrap"
          onClick={() => {
            setDirection(
              key !== field ? "desc" : direction === "desc" ? "asc" : "desc"
            );
            setField(key);
          }}
        >
          <span style={{ cursor: "pointer" }}>{header}</span>
          {key === field && <TableSortLabel active direction={direction} />}
        </th>
      )
    );
  }

  return (
    <>
      <Loader isOpen={actionLoading} maxWidth="xs" />
      <Modal
        isOpen={confirmDeleteOpen}
        submitable
        onClose={handleModalClose}
        maxWidth="sm"
        modalContent={
          <ConfirmDelete
            handleClose={handleModalClose}
            handleSubmit={handleArchive}
            id={selectedItem}
            name={(data.find(({ id }) => id === selectedItem) || {}).name}
          />
        }
      />
      <ImportProducts
        isOpen={importOpen}
        handleClose={handleImportClose}
        existingProducts={data}
      />
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div>
            <ServerSearchBar
              className="mb-5"
              onSearch={(data) =>
                handleSearch(data, searchMap, setSearch, setPage)
              }
              keyMap={SEARCH_HELPER}
              loading={autocompleteLoading}
              currentSearchList={autocompleteData}
              fetchAutocompleteFunction={fetchProductsAutocomplete}
              placeholder="Search Products..."
              clearSearch={resetSearch}
              searchMap={searchMap}
              searchFields={search}
              archived={archived}
              contentLoading={loading}
            />
          </div>
          <div className="bg-white rounded py-7 px-10">
            <ListHeader title="Products List" renderButtons={renderButtons} />
            <ArchiveCheckbox
              archivedLoading={archivedLoading}
              archived={archived}
              showArchived={showArchived}
            />
            <Datagrid
              data={data}
              headings={HEADINGS}
              renderRow={renderProductsRow}
              selected={selected}
              setSelected={setSelected}
              editable
              isNewTabLink
              loading={loading}
              selectable
              handleDelete={handleOpenModal}
              deletable
              renderHeaderWithSorting={renderHeaderWithSorting}
              link="products"
              serverPage={page}
              setServerPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              isServerPagination
              totalRecords={totalRecords}
            />
          </div>
        </div>
      </div>
    </>
  );
};
