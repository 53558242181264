import React from "react";
import { useField } from "formik";
import { Switch, withStyles } from "@material-ui/core";

const ThemedSwitch = withStyles((theme) => ({
  switchBase: {
    color: "grey",
    "&$checked": {
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  track: {
    backgroundColor: "rgba(0,0,0,0.3)",
  },
  checked: {},
}))(Switch);

export function MappedSwitch(props) {
  const [field] = useField(props.name);

  return <ThemedSwitch {...field} {...props} data-testid="switch" />;
}
