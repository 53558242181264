import { sortHelper } from "../../../ui/helpers";

export const HEADINGS = [
  ["name", "Site Name"],
  ["phone", "Site Phone"],
  ["email", "Site Email"],
  ["status", "Status"],
  ["region", "Region"],
  ["consultant", "Consultant"],
];

export const AUDIT_HEADINGS = [
  ["id", "Audit ID"],
  ["auditor", "Auditor "],
  ["created_at", "Audit Date"],
  ["status", "Audit Status"],
  ["consultant", "Regional Consultant"],
  ["report", "Report"],
  ["link", "iAuditor Link"],
];

export const statusOptions = [
  {
    value: "active",
    label: "Active",
  },
];

export const booleanOptions = [
  {
    value: true,
    label: "Yes",
  },
  {
    value: false,
    label: "No",
  },
];

export const surfaceTypeOptions = [
  { label: "Gravel", value: "Gravel" },
  { label: "Bitumen", value: "Bitumen" },
  { label: "Concrete", value: "Concrete" },
  { label: "Other", value: "Other" },
].sort(sortHelper);
