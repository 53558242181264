import React, { useState, useEffect, useMemo } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { CLEARANCES_HEADINGS, searchMap } from "./constants";
import {
  modifyClearances,
  renderClearancesRow,
  rendeTotalVolumeRow
} from "./helpers";
import { CompleteClearance } from "./CompleteClearance";
import { Modal } from "../../../ui/components/Modal";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import { useFetch } from "../../../hooks/fetch.hook";
import { completeClearance, getActiveClearances } from "./_api";
import { handleSearch } from "../../../ui/helpers";
import { Loader } from "../../../ui/components/Loader";
import { info } from "../../../helpers/toasts";
import { ListButton } from "../../../ui/components/ListButton";
import { InstantProcess } from "./InstantProcess";

const completeBtnStyle = {
  background: "rgba(100, 167, 11, 0.3)",
  outline: "none",
  border: "1px solid #64A70B4D",
  color: "#64A70B",
  fontWeight: "500"
};

const sizeKeys = ["20", "20-25", "26-120", "121-205"];

export const Clearances = ({
  processors = [],
  sites = [],
  processorsLoading,
  sitesLoading
}) => {
  const { request } = useFetch();

  const [componentID, setComponentID] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [focused, setFocused] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [instantModalOpen, setInstantModalOpen] = useState(false);

  const handleInstantModalOpen = () => setInstantModalOpen(true);
  const handleInstantModalClose = () => setInstantModalOpen(false);

  const fetchClearances = () => {
    setLoading(true);
    request(getActiveClearances, search)
      .then(data => data && setData(modifyClearances(data)))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchClearances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleModalClose = () => setModalOpen(false);

  const handleExpand = id => () => {
    setComponentID(id);
    setModalOpen(true);
  };

  const getSizes = (values = {}) => {
    const copiedValues = { ...values };
    Object.keys(copiedValues).forEach(key => {
      if (!sizeKeys.includes(key) || copiedValues[key] === "")
        delete copiedValues[key];
      else copiedValues[key] = Number(copiedValues[key]);
    });
    return copiedValues;
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setActionLoading(true);
    const payload = {
      processed_sizes: getSizes(values),
      pickup_date: values.pickup_date,
      site_cleared: values.site_cleared,
    };
    request(completeClearance, payload, componentID)
      .then(data => {
        if (!data) return;
        setData(state => state.filter(item => item.id !== componentID));
        handleModalClose();
        info("Clearance has been completed!");
      })
      .finally(() => {
        setSubmitting(false);
        setActionLoading(false);
      });
  };

  const KEY_MAP = {
    ID: data.map(item => item.code)
  };

  const resetSearch = () => {
    setSearch("");
  };

  const renderButtons = () => {
    return (
      <>
        <ListButton label="Instant Process" onClick={handleInstantModalOpen} />
      </>
    );
  };

  const processorOptions = useMemo(
    () =>
      processors.map(organiastion => ({
        value: organiastion.id,
        label: organiastion.business_name
      })),
    [processors]
  );

  const siteOptions = useMemo(
    () =>
      sites.map(site => ({
        value: site.id,
        label: site.name
      })),
    [sites]
  );

  return (
    <>
      <Loader
        isOpen={actionLoading}
        maxWidth="xs"
        disableBackdropClick
        disableEscapeKeyDown
      />
      <Modal
        isOpen={instantModalOpen}
        submitable
        onClose={handleInstantModalClose}
        maxWidth="md"
        modalContent={
          <InstantProcess
            handleClose={handleInstantModalClose}
            processorsLoading={processorsLoading}
            processorOptions={processorOptions}
            siteOptions={siteOptions}
            sitesLoading={sitesLoading}
          />
        }
      />
      <Modal
        isOpen={modalOpen}
        submitable
        onClose={handleModalClose}
        maxWidth="sm"
        modalContent={
          <CompleteClearance
            data={data.find(item => item.id === componentID)}
            handleSubmit={handleSubmit}
          />
        }
      />
      <div className="row justify-content-center">
        <div className="col-12">
          <SearchBar
            onSearch={data => handleSearch(data, searchMap, setSearch)}
            clearSearch={resetSearch}
            keyMap={KEY_MAP}
            placeholder="Filter pickups..."
            elevation={2}
            chipBackgroundColor="#E8F5E1"
            chipColor="#255915"
            focused={focused}
            setFocused={setFocused}
          />
          <div className="bg-white rounded py-7 px-10">
            <ListHeader title="Clearance Jobs" renderButtons={renderButtons} />
            <Datagrid
              data={data}
              loading={loading}
              headings={CLEARANCES_HEADINGS}
              renderRow={renderClearancesRow}
              expandable
              handleExpand={handleExpand}
              expandHeader=""
              expandIcon={
                <span
                  className="rounded px-4 text-uppercase"
                  style={completeBtnStyle}
                >
                  Complete
                </span>
              }
              footerRow={rendeTotalVolumeRow}
            />
          </div>
        </div>
      </div>
    </>
  );
};
