import React, { useEffect, useRef } from "react";
import { useFormikContext } from "formik";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";

export const InductionType = () => {
  const { values, setFieldValue } = useFormikContext();

  const firstUpdate = useRef(true);

  const renderInductionType = (type) => {
    if (type === "online") {
      return <MappedInput label="Online Induction URL" name="induction_text" />;
    } else if (type === "manual") {
      return <MappedInput label="Induction Notes" name="induction_text" multiline rows={4} />;
    } else return null;
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setFieldValue("induction_text", "");
    // eslint-disable-next-line
  }, [values.induction_type]);

  return <div>{renderInductionType(values.induction_type)}</div>;
};
