import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { CheckCircle as CheckCircleIcon } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core/";
import { useFetch } from "../../../../hooks/fetch.hook";
import {
  getIntegrationByType,
  modifyIntegration,
  createIntegration,
  deleteIntegration,
} from "../_api/";

function getStatus(status) {
  switch (status) {
    case "loading":
      return <CircularProgress className="text-warning" size="2rem" />;
    case "needAPI":
      return "Need API Key, API Secret";
    case "notValidAPI":
      return "The saved API Key / API Secret pair is not valid!";
    case "notSaved":
      return "Not Saved";
    case "disConnected":
      return "Disconnected";
    case "connected":
      return <CheckCircleIcon className="text-success" fontSize="large" />;
    default:
      return;
  }
}

export function BulkDetails() {
  const [loading, setLoading] = useState(true);
  const [apiKey, setApiKey] = useState("");
  const [apiSecret, setApiSecret] = useState("");
  const [hasBulkSMSIntegration, setHasBulkSMSIntegration] = useState(false);
  const [integrationId, setIntegrationId] = useState("");
  const [status, setStatus] = useState("");

  const initialValues = {
    apiKey: apiKey,
    apiSecret: apiSecret,
  };
  const { request } = useFetch();

  function fetchBulkSMSIntegration() {
    request(getIntegrationByType, "burstsms").then((response) => {
      if (response) {
        if (response[0]) {
          setHasBulkSMSIntegration(true);
          setApiKey(response[0].api_key);
          setApiSecret(response[0].api_secret);
          setIntegrationId(response[0].id);
          setStatus("connected");
        } else {
          setStatus("needAPI");
        }
      }
      setLoading(false);
    });
  }

  function resetBulkSMSIntegration() {
    setApiKey("");
    setApiSecret("");
    setIntegrationId("");
    setStatus("disConnected");
    setHasBulkSMSIntegration(false);
  }

  useEffect(() => {
    setStatus("loading");
    fetchBulkSMSIntegration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading) {
      setStatus("notSaved");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKey, setApiKey, apiSecret, setApiSecret]);

  const handleDelete = () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm(
      "Are you sure you want to disconnect BulkSMS? This process is not reversible"
    );
    if (confirmation) {
      try {
        deleteIntegration(integrationId).then((res) => {
          if (res.status === 200 || res.status === 204) {
            resetBulkSMSIntegration();
          }
        });
      } catch {}
    }
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setStatus("loading");
    const data = {
      status: "active",
      integration_type: "burstsms",
      content: {
        api_key: values.apiKey,
        api_secret: values.apiSecret,
      },
    };
    if (hasBulkSMSIntegration) {
      request(modifyIntegration, integrationId, data)
        .then((response) => {
          if (!response) {
            setStatus("notSaved");
            return;
          }
          setStatus("connected");
          setHasBulkSMSIntegration(true);
        })
        .finally(() => setSubmitting(false));
    } else {
      request(createIntegration, data)
        .then((response) => {
          if (!response) {
            setStatus("notSaved");
            return;
          }
          setStatus("connected");
          setIntegrationId(response.id);
          setHasBulkSMSIntegration(true);
        })
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object({
        apiKey: Yup.string().required(),
        apiSecret: Yup.string().required(),
      })}
      onSubmit={handleSubmit}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <div className="p-5">
          <span className="mb-10 font-weight-bold">BulkSMS Integration</span>
          <div className="row justify-content-between py-10">
            <div className="d-flex flex-column col-8">
              {status === "connected" ? (
                <h3>Integration is connected</h3>
              ) : (
                <>
                  <div className="row mb-3">
                    <div className="col-2 my-auto">
                      <p className="text-right text-muted mb-0">Api Key</p>
                    </div>
                    <div className="col-8 mr-auto">
                      <MappedInput
                        name="apiKey"
                        wrapperClassName="w-100"
                        onChange={(e) => setApiKey(e.target.value)}
                        disabled={loading}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-2 my-auto">
                      <p className="text-right text-muted mb-0">Api Secret</p>
                    </div>
                    <div className="col-8 mr-auto">
                      <MappedInput
                        name="apiSecret"
                        wrapperClassName="w-100"
                        onChange={(e) => setApiSecret(e.target.value)}
                        disabled={loading}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="d-flex flex-column ml-10 col-md text-center">
              <span className="mb-2 text-center font-weight-bold">Status</span>
              <span style={{ lineHeight: "3" }}>{getStatus(status)}</span>
            </div>
          </div>

          <div className="row align-items-end justify-content-end mt-10">
            <span>
              <button
                type="submit"
                className="px-10 mx-5 btn btn-primary"
                onClick={handleSubmit}
                disabled={isSubmitting || status === "loading"}
              >
                Save
              </button>
            </span>
            {hasBulkSMSIntegration ? (
              <span>
                <button className="px-10 mr-5 btn btn-outline-primary" onClick={handleDelete}>
                  Disconnect
                </button>
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </Formik>
  );
}
