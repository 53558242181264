import React, { useState, useEffect } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import { useFetch } from "../../../hooks/fetch.hook";
import { historySearchMap, HISTORY_HEADINGS } from "./constants";
import { handleSearch } from "../../../ui/helpers";
import { getOrganisationHistory } from "../../modules/Organisations/_api";
import { modifyOrgHistory, renderHistoryRow } from "./helpers";

export const OrgSystem = ({ data = [], setData, organisationId }) => {
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const fetchData = () => {
    setLoading(true);
    request(getOrganisationHistory, organisationId, search)
      .then(data => data && setData(modifyOrgHistory(data)))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const resetSearch = () => {
    setSearch("");
  };

  const KEY_MAP = {
    ID: data.map(item => item.history_id),
    Action: data.map(item => item.history_action)
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <div className="mb-10">
              <SearchBar
                onSearch={data =>
                  handleSearch(data, historySearchMap, setSearch)
                }
                clearSearch={resetSearch}
                keyMap={KEY_MAP}
                placeholder="Filter..."
                elevation={2}
                chipBackgroundColor="#E8F5E1"
                chipColor="#255915"
              />
            </div>
            <Datagrid
              data={data}
              headings={HISTORY_HEADINGS}
              renderRow={renderHistoryRow}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
};
