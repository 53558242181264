import React from "react";
import { ListButton } from "../components/ListButton";
import { SelectButton } from "../components/SelectButton";

export const SubmitActions = ({
  id,
  handleCancel,
  disabled,
  submitAction,
  handleSubmit,
  setSubmitAction,
  submitLabel = "Save"
}) => {
  return (
    <div className="mt-10 d-flex justify-content-end mr-4">
      <div className="mr-3">
        <ListButton
          label="Cancel"
          size="large"
          onClick={handleCancel}
          data-testid="cancel"
          variant="outlined"
          boxShadow={false}
          text="#407A28"
        />
      </div>
      <div>
        {id ? (
          <SelectButton
            submitAction={submitAction}
            disabled={disabled}
            setSubmitAction={setSubmitAction}
            handleSubmit={handleSubmit}
          />
        ) : (
          <ListButton
            label={submitLabel}
            size="large"
            onClick={handleSubmit}
            boxShadow={false}
            disabled={disabled}
            data-testid="submit"
          />
        )}
      </div>
    </div>
  );
};
