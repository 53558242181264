import React from "react";
import { buttonStyle, itemStyle } from "../constants";
import CampaignMonitorIcon from "../static/CampaignMonitor.png";

export const CampaignMonitor = ({
  handleConnect,
  handleDisconnect,
  connectedComponent,
  tokenExpired = false,
}) => {
  const integrationDisabled = connectedComponent && connectedComponent !== "CampaignMonitor";
  const connected = connectedComponent === "CampaignMonitor";
  return (
    <>
      <div
        style={itemStyle}
        className={`${integrationDisabled ? "bg-secondary" : "bg-white"} p-7 mt-10`}
      >
        <div className="text-center mb-12">
          <img src={CampaignMonitorIcon} alt="logo" width="30%"/>
        </div>
        <h3 className="mb-2 text-dark">
          <strong>Campaign Monitor</strong>
        </h3>
        <div style={{ fontSize: "14px" }}>
          Sync your contacts and audiences to Campaign Monitor.
        </div>
        <div className="mt-25">
          {tokenExpired ? (
            <button className={`${buttonStyle} text-primary`}>Reconnect Campaign Monitor</button>
          ) : connected ? (
            <button className={`${buttonStyle} text-danger`}>Disconnect</button>
          ) : (
            <button className={`${buttonStyle} text-secondary`} disabled>
              Connect
            </button>
          )}
        </div>
      </div>
    </>
  );
};
