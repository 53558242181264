/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { getConsultant } from "./_redux/selectors";
import { useFetch } from "../../../../hooks/fetch.hook";
import {
  addConsultant,
  getConstultantRegions,
  getConsultantById,
  linkRegionToContact,
  modifyConsultant
} from "./_api";
import { ConsultantDetailSchema } from "../../../../helpers/schemas";
import { Loader } from "../../../../ui/components/Loader";
import { SubmitActions } from "../../../../ui/structures/SubmitActions";
import { info } from "../../../../helpers/toasts";
import { stateOptions } from "../../Organisations/constants";
import { modifyEmptyFields, sortHelper } from "../../../../ui/helpers";
import { getUsers, modifyUser } from "../../Users/_api";
import { Autocomplete } from "../../../../ui/components/Autocomplete";
import { AutocompleteMultiple } from "../../../../ui/components/AutocompleteMultiple";
import { uniqBy } from "lodash";
import { getRegions, getLGARegionByPostcode } from "../Regions/_api";
import { PhysicalPostcode } from "../../../../ui/components/PhysicalPostcode";
import { LGARegionSelect } from "../../../../ui/components/LGARegionSelect";

export const Card = ({
  match: {
    params: { id }
  }
}) => {
  const { request } = useFetch();
  const data = useSelector(getConsultant(id));

  const [cardData, setCardData] = useState({});
  const [submitAction, setSubmitAction] = useState("Save");
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [regions, setRegions] = useState([]);
  const [lgaRegions, setLgaRegions] = useState([]);
  const [linkedRegions, setLinkedRegions] = useState([]);
  const [linkedRegionsLoading, setLinkedRegionsLoading] = useState(false);
  const [lgaLoading, setLgaLoading] = useState(false);

  const fetchLgaRegions = (values, setFieldValue) => {
    setLgaLoading(true);
    return request(getLGARegionByPostcode, values.physical_postcode)
      .then(data => {
        if (!data) return;
        setLgaRegions(data.map(region => ({ value: region, label: region })));
        if (!data.includes(values.lga_region)) setFieldValue("lga_region", "");
      })
      .finally(() => setLgaLoading(false));
  };

  useEffect(() => {
    if (id && !data) {
      request(getConsultantById, id).then(data => data && setCardData(data));
    } else {
      data && setCardData(data);
    }
  }, []);

  useEffect(() => {
    request(getRegions).then(data => data && setRegions(data));
    request(getUsers).then(data => data && setUsers(data));
    if (id) {
      setLinkedRegionsLoading(true);
      request(getConstultantRegions, id)
        .then(
          data => data && setLinkedRegions((data || []).map(({ id }) => id))
        )
        .finally(() => setLinkedRegionsLoading(false));
    }
  }, []);

  const userOptions = useMemo(
    () =>
      users.map(({ id, name }) => ({
        value: id,
        label: name
      })),
    [users]
  );

  const regionOptions = useMemo(
    () =>
      regions.map(region => ({
        value: region.id,
        label: region.long_name
      })),
    [regions]
  );

  const history = useHistory();
  const handleClose = () => history.push("/settings/consultants");

  const createConsultant = (values, setSubmitting) => {
    request(addConsultant, values)
      .then(data => data && history.push("/settings/consultants"))
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  const updateConsultant = (values, setSubmitting) => {
    if (
      linkedRegions.sort().join(",") ===
      (values.region_id || []).sort().join(",")
    ) {
      Promise.all([
        request(modifyConsultant, values, id),
        request(modifyUser, { consultant_id: id }, values.user_id)
      ])
        .then(([consultantData, userLinkage]) => {
          if (!consultantData || !userLinkage) return;
          info("Consultant details have been updated!");
          submitAction === "Save & Exit" &&
            history.push("/settings/consultants");
        })
        .finally(() => {
          setSubmitting(false);
          setLoading(false);
        });
    } else {
      Promise.all([
        request(modifyConsultant, values, id),
        request(
          linkRegionToContact,
          id,
          values.region_id.map(id => ({
            id
          }))
        ),
        request(modifyUser, { consultant_id: id }, values.user_id)
      ])
        .then(([consultantData, regionLinkage, userLinkage]) => {
          if (!consultantData || !regionLinkage || !userLinkage) return;
          info("Consultant details have been updated!");
          submitAction === "Save & Exit" &&
            history.push("/settings/consultants");
        })
        .finally(() => {
          setSubmitting(false);
          setLoading(false);
        });
    }
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    modifyEmptyFields(values);
    id
      ? updateConsultant(values, setSubmitting)
      : createConsultant(values, setSubmitting);
  };

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  if (id && linkedRegionsLoading) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }
  const initialValues = {
    first_name: cardData.first_name || "",
    last_name: cardData.last_name || "",
    phone: cardData.phone || "",
    email: cardData.email || "",
    physical_address: cardData.physical_address || "",
    physical_address2: cardData.physical_address2 || "",
    physical_city: cardData.physical_city || "",
    physical_postcode: cardData.physical_postcode || "",
    physical_state: cardData.physical_state || "",
    user_id: cardData?.user && cardData.user.length ? cardData.user[0].id : "",
    region_id: id ? linkedRegions : "",
    lga_region: cardData?.lga_region || ""
  };

  return (
    <div className="border border-secondary bg-white p-10">
      {loading && (
        <Loader
          isOpen={loading}
          maxWidth="xs"
          disableBackdropClick
          disableEscapeKeyDown
        />
      )}
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={ConsultantDetailSchema}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue, ...formik }) => (
          <>
            <h3 className="mb-10">
              <strong>
                {id
                  ? [cardData.first_name, cardData.last_name].join(" ")
                  : "Add Consultant"}
              </strong>
            </h3>
            <div className="row justify-content-between">
              <div className="col-8">
                <MappedInput
                  label="First Name"
                  name="first_name"
                  data-testid="first_name"
                />
                <MappedInput
                  label="Last Name"
                  name="last_name"
                  data-testid="last_name"
                />
                <MappedInput label="Email" name="email" data-testid="email" />
                <MappedInput label="Phone" name="phone" data-testid="phone" />
                <MappedInput
                  label="Address 1"
                  name="physical_address"
                  data-testid="physical_address"
                />
                <MappedInput
                  label="Address 2"
                  name="physical_address2"
                  data-testid="physical_address2"
                />
                <MappedInput
                  label="Suburb"
                  name="physical_city"
                  data-testid="physical_city"
                />
                <div className="row">
                  <div className="col-6">
                    <MappedSelect
                      label="State"
                      name="physical_state"
                      data-testid="physical_state"
                      options={stateOptions}
                    />
                  </div>
                  <div className="col-6">
                    <PhysicalPostcode fetchLgaRegions={fetchLgaRegions} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <LGARegionSelect
                      values={values}
                      lgaLoading={lgaLoading}
                      lgaRegions={lgaRegions}
                    />
                  </div>
                </div>
                {userOptions.length ? (
                  <div className="mt-5">
                    <Autocomplete
                      name="user_id"
                      placeholder="Link User"
                      setValue={setFieldValue}
                      options={userOptions}
                      defaultValueField="value"
                      defaultValue={values.user_id}
                    />
                    {formik.touched.user_id && formik.errors.user_id ? (
                      <div className="text-danger">{formik.errors.user_id}</div>
                    ) : null}
                  </div>
                ) : (
                  <Skeleton variant="rect" width={"100%"} height={55} />
                )}
                {regionOptions.length ? (
                  <div className="mt-5 col-12" data-testid="regions-wrapper">
                    <AutocompleteMultiple
                      name="region_id"
                      placeholder="Regions"
                      setValue={setFieldValue}
                      options={uniqBy(regionOptions, "label").sort(sortHelper)}
                      defaultValueField="value"
                      defaultValue={linkedRegions}
                      variant="standard"
                    />
                    {formik.touched.region_id && formik.errors.region_id ? (
                      <div className="text-danger">
                        {formik.errors.region_di}
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="mt-3 col-12">
                    <Skeleton variant="rect" width={"100%"} height={55} />
                  </div>
                )}
              </div>
            </div>
            <SubmitActions
              id={id}
              handleCancel={handleClose}
              disabled={isSubmitting}
              submitAction={submitAction}
              handleSubmit={handleSubmit}
              setSubmitAction={setSubmitAction}
            />
          </>
        )}
      </Formik>
    </div>
  );
};
