import React from "react";
import WarningIcon from "@material-ui/icons/Warning";
import { ListButton } from "../../../../../../ui/components/ListButton";

export const DuplicateWarning = ({ handleClose, handleSubmit }) => {
  return (
    <div className="text-center">
      <div className="mb-5">
        <WarningIcon fontSize="large" color="action" />
      </div>
      <h3 className="text-danger mb-2">Organisation with entered ABN already exists!</h3>
      <div>Are you sure you want to create organisation anyway?</div>
      <div className="my-5 d-flex justify-content-end mr-3">
        <div className="mr-3">
          <ListButton
            label="Cancel"
            size="large"
            onClick={handleClose}
            variant="outlined"
            text="#407A28"
            boxShadow={false}
          />
        </div>
        <div>
          <ListButton label="Submit" onClick={handleSubmit} size="large" />
        </div>
      </div>
    </div>
  );
};
