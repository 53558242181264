import config from "react-global-configuration";

export const getReimbursements = (query = "") =>
  fetch(`${config.get("ApiUrl").Rest}/reimbursements?${query}`, {
    mode: "cors",
    credentials: "include"
  });

export const recalculateReimbursement = id =>
  fetch(`${config.get("ApiUrl").Rest}/reimbursements/${id}/recalculate_value`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({}),
    mode: "cors",
    credentials: "include"
  });
