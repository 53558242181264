/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import SearchIcon from "@material-ui/icons/Search";
import {
  getSelectedId,
  handleCopy,
  handlePaste,
  handleSearch,
  objectCheck,
} from "../../../ui/helpers";
import { COPY_HELPER, HEADINGS, searchMap, SEARCH_HELPER } from "./constants";
import {
  removeOrganisation,
  setOrganisationsList,
  updateOrganisationsList,
} from "./_redux/actions";
import {
  modifyOrganisationData,
  modifyOrganisations,
  renderOrganisationsRow,
} from "./helpers";
import { ListButton } from "../../../ui/components/ListButton";
import { ListHeader } from "../../../ui/structures/ListHeader";
import {
  getOrganisations,
  archiveOrganisation,
  getOrganisationById,
  modifyOrganisation,
  getOrganisationsListAutocomplete,
  getOrganisationsAmount,
} from "./_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { UnarchiveButton } from "../../../ui/components/UnarchiveButton";
import { Modal } from "../../../ui/components/Modal";
import { ConfirmDelete } from "../../../ui/components/ConfirmDelete";
import { ArchiveCheckbox } from "../../../ui/components/ArchiveCheckbox";
import { IconButton, TableSortLabel } from "@material-ui/core";
import { AVAILABLE_ROLES, sortHeaderStyle } from "../../constants";
import { info } from "../../../helpers/toasts";
import { Loader } from "../../../ui/components/Loader";
import { ServerSearchBar } from "../../../ui/structures/ServerSearchBar";
import { getTagsListAutocomplete } from "../../modules/Settings/Tags/_api/index";
import { get, uniq } from "lodash";
import ToolTip from "../../../ui/components/ToolTip";
import { AccessControl } from "../../../ui/structures/AccessControl";

export const List = () => {
  const dispatch = useDispatch();
  const { request } = useFetch();
  const history = useHistory();

  const region = get(history, "location.region", "");

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [search, setSearch] = useState(
    region ? `region.long_name=_like(${encodeURIComponent(region)})_` : ""
  );
  const [selectedItem, setSelectedItem] = useState("");
  const [unArchiving, setUnarchiving] = useState(false);
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [actionLoading, setActionLoading] = useState(false);
  const [copiedSearch, setCopiedSearch] = useState([]);

  const handleModalClose = () => setConfirmDeleteOpen(false);

  const data = useSelector(
    ({ organisations: { organisationsList } }) => organisationsList
  );

  const fetchOrganisations = () => {
    setLoading(true);
    request(
      getOrganisations,
      "",
      search,
      archived,
      field,
      field && direction,
      page,
      pageSize
    )
      .then((data) => data && modifyOrganisations(data))
      .then((data) => {
        if (!data) return;
        dispatch(setOrganisationsList(data));
        setLoading(false);
      })
      .finally(() => setArchivedLoading(false));
  };

  const requestOptions = (
    autocompleteValue,
    autocompleteField,
    searchFields,
    archived
  ) => {
    if (autocompleteField === "tag") {
      return request(
        getTagsListAutocomplete,
        80,
        "name",
        autocompleteValue,
        "category=_like(Organisation)_"
      );
    } else
      return request(
        getOrganisationsListAutocomplete,
        80,
        autocompleteField,
        autocompleteValue,
        field,
        field && direction,
        searchFields,
        archived
      );
  };

  const fetchOrganisationsAutocomplete = (
    autocompleteValue,
    autocompleteField,
    searchFields,
    archived
  ) => {
    if (autocompleteValue === "") return;
    if (autocompleteField === "abn_verified") {
      setAutocompleteData(["Verified", "Not Verified"]);
      return;
    }
    if (autocompleteField === "status") {
      setAutocompleteData(["new", "active"]);
      return;
    }
    setAutocompleteLoading(true);
    // SearchFields are needed to apply previous filters
    requestOptions(
      autocompleteValue,
      autocompleteField,
      searchFields,
      archived
    ).then((data) => {
      if (data && Array.isArray(data) && data.length && objectCheck(data)) {
        setAutocompleteData(uniq(data.map((item) => String(item))));
      } else {
        setAutocompleteData(["No option found"]);
      }
      setAutocompleteLoading(false);
    });
  };

  useEffect(() => {
    fetchOrganisations();
  }, [search, archived, field, direction, page, pageSize]);

  useEffect(() => {
    request(getOrganisationsAmount, search, archived).then(
      (data) => data && setTotalRecords(data)
    );
  }, [search, archived]);

  const unArchiveDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const handleArchive = (id, setSubmitting) => {
    setActionLoading(true);
    setConfirmDeleteOpen(false);
    request(archiveOrganisation, id)
      .then((data) => {
        if (!data) return;
        if (archived) {
          request(getOrganisationById, id).then(
            (data) =>
              data &&
              dispatch(updateOrganisationsList(modifyOrganisationData(data)))
          );
        } else dispatch(removeOrganisation(id));
        info("Organisation has been archived!");
        setSelected({});
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const handleUnarchive = () => {
    const organisationID = getSelectedId(selected);
    const status = (data.find(({ id }) => id === organisationID) || {}).status;
    if (status !== "archived") return;
    setActionLoading(true);
    setUnarchiving(true);
    request(modifyOrganisation, { status: "active" }, organisationID)
      .then((data) => {
        if (!data) return;
        dispatch(updateOrganisationsList(modifyOrganisationData(data)));
        setSelected({});
        info("Organisation has been unarchived!");
      })
      .finally(() => {
        setUnarchiving(false);
        setActionLoading(false);
      });
  };

  const renderButtons = () => (
    <>
      {archived && (
        <AccessControl
          desiredRoles={[
            AVAILABLE_ROLES.NATIONAL_ADMIN,
            AVAILABLE_ROLES.AGSAFE_ADMIN,
            AVAILABLE_ROLES.AGSAFE_STAFF,
            AVAILABLE_ROLES.RC_ACCESS,
          ]}
        >
          <UnarchiveButton
            handleClick={handleUnarchive}
            disabled={unArchiveDisabled}
            loading={unArchiving}
          />
        </AccessControl>
      )}
      <AccessControl
        desiredRoles={[
          AVAILABLE_ROLES.NATIONAL_ADMIN,
          AVAILABLE_ROLES.AGSAFE_ADMIN,
          AVAILABLE_ROLES.AGSAFE_STAFF,
          AVAILABLE_ROLES.RC_ACCESS,
        ]}
      >
        <div className="mr-5">
          <Link
            to={{
              pathname: `/add-organisation`,
            }}
            data-testid="new"
          >
            <ListButton label="New" />
          </Link>
        </div>
      </AccessControl>
      <ToolTip
        title="Copy filter criteria"
        arrow
        interactive
        placement="top"
        content={
          <IconButton
            onClick={() => handleCopy(search)}
            data-testid="copy-filter"
          >
            <FileCopyIcon />
          </IconButton>
        }
      />
      <ToolTip
        title="Apply filter criteria"
        arrow
        interactive
        placement="top"
        content={
          <IconButton
            onClick={() => handlePaste(COPY_HELPER, setCopiedSearch, setSearch)}
            data-testid="apply-filter"
          >
            <SearchIcon />
          </IconButton>
        }
      />
    </>
  );

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  const handleOpenModal = (id) => {
    setConfirmDeleteOpen(true);
    setSelectedItem(id);
  };

  const resetSearch = () => {
    setSearch("");
  };

  const modifySortKey = (key) => {
    if (key === "region") return "region.long_name";
    else if (key === "consultant") return "consultant.first_name";
    else return key;
  };

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) => (
      <th
        key={key}
        style={sortHeaderStyle}
        className="px-5 text-nowrap"
        onClick={() => {
          const sortKey = modifySortKey(key);
          setDirection(
            sortKey !== field ? "desc" : direction === "desc" ? "asc" : "desc"
          );
          setField(sortKey);
        }}
      >
        <span style={{ cursor: "pointer" }}>{header}</span>
        {key === field && <TableSortLabel active direction={direction} />}
      </th>
    ));
  }

  return (
    <>
      {actionLoading && <Loader isOpen={actionLoading} maxWidth="xs" />}
      {confirmDeleteOpen && (
        <Modal
          isOpen={confirmDeleteOpen}
          submitable
          onClose={handleModalClose}
          maxWidth="sm"
          modalContent={
            <ConfirmDelete
              handleClose={handleModalClose}
              handleSubmit={handleArchive}
              id={selectedItem}
              name={
                (data.find(({ id }) => id === selectedItem) || {}).legal_name
              }
            />
          }
        />
      )}
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div data-testid="search-wrapper">
            <ServerSearchBar
              className="mb-5"
              onSearch={(data) =>
                handleSearch(data, searchMap, setSearch, setPage)
              }
              keyMap={SEARCH_HELPER}
              loading={autocompleteLoading}
              currentSearchList={autocompleteData}
              fetchAutocompleteFunction={fetchOrganisationsAutocomplete}
              placeholder="Search Organisations..."
              clearSearch={resetSearch}
              searchMap={searchMap}
              searchFields={search}
              archived={archived}
              copiedSearch={copiedSearch}
              defaultValue={region ? [{ key: "Region", value: region }] : []}
              defaultKey={region ? "Region" : "Business Name"}
              contentLoading={loading}
            />
          </div>
          <div className="bg-white rounded py-7 px-10">
            <ListHeader
              title="Organisations List"
              renderButtons={renderButtons}
            />
            <ArchiveCheckbox
              archivedLoading={archivedLoading}
              archived={archived}
              showArchived={showArchived}
            />
            <AccessControl
              desiredRoles={[
                AVAILABLE_ROLES.NATIONAL_ADMIN,
                AVAILABLE_ROLES.AGSAFE_ADMIN,
                AVAILABLE_ROLES.AGSAFE_STAFF,
                AVAILABLE_ROLES.RC_ACCESS,
              ]}
              elseContent={
                <Datagrid
                  data={data}
                  headings={HEADINGS}
                  renderRow={renderOrganisationsRow}
                  selected={selected}
                  setSelected={setSelected}
                  isNewTabLink
                  loading={loading}
                  selectable
                  renderHeaderWithSorting={renderHeaderWithSorting}
                  serverPage={page}
                  setServerPage={setPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  isServerPagination
                  totalRecords={totalRecords}
                />
              }
            >
              <Datagrid
                data={data}
                headings={HEADINGS}
                renderRow={renderOrganisationsRow}
                selected={selected}
                setSelected={setSelected}
                editable
                isNewTabLink
                loading={loading}
                selectable
                deletable
                handleDelete={handleOpenModal}
                link="organisations-list"
                renderHeaderWithSorting={renderHeaderWithSorting}
                serverPage={page}
                setServerPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                isServerPagination
                totalRecords={totalRecords}
              />
            </AccessControl>
          </div>
        </div>
      </div>
    </>
  );
};
