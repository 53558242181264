export const chemClearActions = {
  SET_DATA: "CHEMCLEAR/SET_DATA",
  RESET: "CHEMCLEAR/RESET_DATA",
};

export function setData(data) {
  return {
    type: chemClearActions.SET_DATA,
    payload: data,
  };
}

export const resetState = () => {
  return {
    type: chemClearActions.RESET,
  };
};
