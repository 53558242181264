export const stateOptions = [
  { value: "ACT", label: "ACT" },
  { value: "NSW", label: "NSW" },
  { value: "NT", label: "NT" },
  { value: "QLD", label: "QLD" },
  { value: "SA", label: "SA" },
  { value: "TAS", label: "TAS" },
  { value: "WA", label: "WA" },
  { value: "VIC", label: "VIC" },
];

export const HEADINGS = [
  ["id", "ID"],
  ["material", "Material"],
  ["container_size", "Container Size"],
  ["container_qty", "Container Qty"],
  ["qty_remaining", "Qty Remaining"],
  ["containers_clean", "Containers Clean"],
  ["notes", "Notes"],
  ["status", "Status"],
];
