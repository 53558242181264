import React from "react";
import { formatDate, isDate, isStatus } from "../../../ui/helpers";

export const renderUsersRow = (headings, item) => {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-5 ${isStatus(key) ? "text-uppercase" : ""}`}
    >
      {isDate(key) && item[key] ? formatDate(item[key]) : item[key]}
    </td>
  ));
};
