import React, { useState, useMemo, useEffect } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { getSelectedId, handleSearch, objectCheck } from "../../../ui/helpers";
import { TableSortLabel } from "@material-ui/core";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  archiveSite,
  getSiteById,
  getSites,
  getSitesAmount,
  getSitesAutocomplete,
  modifySite,
} from "./_api";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { useDispatch, useSelector } from "react-redux";
import { setSitesList, removeSite, updateSitesList } from "./_redux/actions";
import { Modal } from "../../../ui/components/Modal";
import { ConfirmDelete } from "../../../ui/components/ConfirmDelete";
import { HEADINGS, searchMap, SEARCH_HELPER } from "./constants";
import { renderSitesRow } from "../../submodules/Sites/helpers";
import { ArchiveCheckbox } from "../../../ui/components/ArchiveCheckbox";
import { info } from "../../../helpers/toasts";
import { UnarchiveButton } from "../../../ui/components/UnarchiveButton";
import { Loader } from "../../../ui/components/Loader";
import { sortHeaderStyle } from "../../constants";
import { modifySitesArray } from "./helpers";
import { uniq } from "lodash";
import { ServerSearchBar } from "../../../ui/structures/ServerSearchBar";
import { getTagsListAutocomplete } from "../Settings/Tags/_api";

export const List = () => {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const data = useSelector(({ sites: { sitesList } }) => sitesList);

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [actionLoading, setActionLoading] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [unArchiving, setUnarchiving] = useState(false);
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  const handleModalClose = () => setConfirmDeleteOpen(false);

  const fetchSites = () => {
    setLoading(true)
    request(
      getSites,
      search,
      archived,
      field,
      field && direction,
      page,
      pageSize
    )
      .then((data) => {
        if (!data) return;
        dispatch(setSitesList(modifySitesArray(data)));
        setLoading(false);
      })
      .finally(() => setArchivedLoading(false));
  };

  const requestOptions = (
    autocompleteValue,
    autocompleteField,
    searchFields,
    archived
  ) => {
    if (autocompleteField === "tag") {
      return request(
        getTagsListAutocomplete,
        80,
        "name",
        autocompleteValue,
        "category=_like(Site)_"
      );
    } else
      return request(
        getSitesAutocomplete,
        80,
        autocompleteField,
        autocompleteValue,
        field,
        field && direction,
        searchFields,
        archived
      );
  };

  const fetchSitesAutocomplete = (
    autocompleteValue,
    autocompleteField,
    searchFields,
    archived
  ) => {
    if (autocompleteValue === "") return;
    setAutocompleteLoading(true);
    requestOptions(
      autocompleteValue,
      autocompleteField,
      searchFields,
      archived
    ).then((data) => {
      if (data && Array.isArray(data) && data.length && objectCheck(data)) {
        setAutocompleteData(uniq(data.map((item) => String(item))));
      } else {
        setAutocompleteData(["No option found"]);
      }
      setAutocompleteLoading(false);
    });
  };

  useEffect(() => {
    fetchSites();
    // eslint-disable-next-line
  }, [search, archived, field, direction, page, pageSize]);

  useEffect(() => {
    request(getSitesAmount, search, archived).then(
      (data) => data && setTotalRecords(data)
    );
    // eslint-disable-next-line
  }, [search, archived]);

  const unArchiveDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const renderButtons = () => (
    <>
      {archived && (
        <UnarchiveButton
          handleClick={handleUnarchive}
          disabled={unArchiveDisabled}
          loading={unArchiving}
        />
      )}
      {/* <div className="ml-1">
        <Link
          to={{
            pathname: `/add-site`,
          }}
          data-testid="new"
        >
          <ListButton label="New" />
        </Link>
      </div> */}
    </>
  );

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) => (
      <th
        key={key}
        style={sortHeaderStyle}
        className="px-5 text-nowrap"
        onClick={() => {
          setDirection(
            key !== field ? "desc" : direction === "desc" ? "asc" : "desc"
          );
          setField(key);
        }}
      >
        <span style={{ cursor: "pointer" }}>{header}</span>
        {key === field && <TableSortLabel active direction={direction} />}
      </th>
    ));
  }

  const handleArchive = (id, setSubmitting) => {
    setActionLoading(true);
    setConfirmDeleteOpen(false);
    request(archiveSite, id)
      .then((data) => {
        if (!data) return;
        if (archived) {
          request(getSiteById, id).then(
            (data) => data && dispatch(updateSitesList(data))
          );
        } else dispatch(removeSite(id));
        info("Site has been archived!");
        setSelected({});
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const handleUnarchive = () => {
    const siteID = getSelectedId(selected);
    const status = (data.find(({ id }) => id === siteID) || {}).status;
    if (status !== "archived") return;
    setUnarchiving(true);
    setActionLoading(true);
    request(modifySite, { status: "active" }, siteID)
      .then((data) => {
        if (!data) return;
        dispatch(updateSitesList(data));
        setSelected({});
        info("Site has been unarchived!");
      })
      .finally(() => {
        setUnarchiving(false);
        setActionLoading(false);
      });
  };

  const handleOpenModal = (id) => {
    setConfirmDeleteOpen(true);
    setSelectedItem(id);
  };

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  const resetSearch = () => {
    setSearch("");
  };

  return (
    <>
      {actionLoading && (
        <Loader
          isOpen={actionLoading}
          maxWidth="xs"
          disableBackdropClick
          disableEscapeKeyDown
        />
      )}
      {confirmDeleteOpen && (
        <Modal
          isOpen={confirmDeleteOpen}
          submitable
          onClose={handleModalClose}
          maxWidth="sm"
          modalContent={
            <ConfirmDelete
              handleClose={handleModalClose}
              handleSubmit={handleArchive}
              id={selectedItem}
              name={(data.find(({ id }) => id === selectedItem) || {}).name}
            />
          }
        />
      )}
      <div className="row justify-content-center">
        <div className="col-12">
          <div data-testid="search-wrapper">
            <ServerSearchBar
              className="mb-5"
              onSearch={(data) =>
                handleSearch(data, searchMap, setSearch, setPage)
              }
              keyMap={SEARCH_HELPER}
              loading={autocompleteLoading}
              currentSearchList={autocompleteData}
              fetchAutocompleteFunction={fetchSitesAutocomplete}
              placeholder="Search Sites..."
              clearSearch={resetSearch}
              searchMap={searchMap}
              searchFields={search}
              archived={archived}
              contentLoading={loading}
              defaultKey="Site Name"
            />
          </div>
          <div className="bg-white rounded py-7 px-10">
            <ListHeader title="Sites List" renderButtons={renderButtons} />
            <ArchiveCheckbox
              archivedLoading={archivedLoading}
              archived={archived}
              showArchived={showArchived}
            />
            <Datagrid
              data={data}
              headings={HEADINGS}
              renderRow={renderSitesRow}
              selected={selected}
              setSelected={setSelected}
              editable
              isNewTabLink
              loading={loading}
              selectable
              deletable
              handleDelete={handleOpenModal}
              link="sites"
              renderHeaderWithSorting={renderHeaderWithSorting}
              serverPage={page}
              setServerPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              isServerPagination
              totalRecords={totalRecords}
            />
          </div>
        </div>
      </div>
    </>
  );
};
