export const searchMap = {
  "Site Name": "name",
  "Site Phone": "phone",
  "Site Email": "email",
  Tag: "tag",
};

export const SEARCH_HELPER = [
  ["name", "Site Name"],
  ["phone", "Site Phone"],
  ["email", "Site Email"],
  ["tag", "Tag"],
];

export const HEADINGS = [
  ["name", "Site Name"],
  ["phone", "Site Phone"],
  ["email", "Site Email"],
  ["status", "Status"],
  ["region", "Region"],
  ["consultant", "Consultant"],
];

export const inductionOptions = [
  { value: "manual", label: "Manual" },
  { value: "online", label: "Online" },
  { value: "not_required", label: "Not Required" },
];
