import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

export const BackupPage = () => {
  return (
    <div className="col-12">
      <div className="bg-white rounded py-20 px-10 text-center">
        <h2 className="mb-3">Oops!</h2>
        <h4 className="mb-5">Feature coming soon</h4>
        <Link
          to={{
            pathname: `/dashboard`,
          }}
        >
          <button className="px-7 btn btn-primary text-uppercase">
            Go to homepage
          </button>
        </Link>
        <div className="containter w-100 h-100 mt-10">
          <div className="p-5 bg-white">
            <span>
              <img
                className="img-fluid"
                src={toAbsoluteUrl("/assets/icons/DDLogo.png")}
                alt="Logo"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
