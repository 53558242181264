import React, { useState } from "react";
import { ListButton } from "../../../ui/components/ListButton";

export const ConfirmReverse = ({
  handleClose,
  handleSubmit,
  id,
  isGroupedItem,
  groupedItemsAmount = 0,
}) => {
  const [submitting, setSubmitting] = useState(false);

  return (
    <div className="pb-5" data-testid="confirm-reverse">
      <div className="text-center">
        <h3 className="text-danger text-center">
          {isGroupedItem ? (
            <div className="mb-5">
              Reversing this item will result into reversing{" "}
              {groupedItemsAmount - 1} more grouped{" "}
              {groupedItemsAmount - 1 > 1 ? "items" : "item"}
            </div>
          ) : null}
          <p>This action is irreversible!</p>
          <p>Are you sure you want to proceed ?</p>
        </h3>
      </div>
      <div className="mt-10 d-flex justify-content-end mr-4">
        <div className="mr-3">
          <ListButton
            label="Cancel"
            size="small"
            onClick={handleClose}
            data-testid="cancel"
            variant="outlined"
            text="#407A28"
            boxShadow={false}
          />
        </div>
        <div>
          <ListButton
            label="Submit"
            size="small"
            disabled={submitting}
            onClick={() => handleSubmit(id, setSubmitting)}
            data-testid="submit"
          />
        </div>
      </div>
    </div>
  );
};
