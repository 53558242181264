import React, { useState } from "react";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { LinearProgress } from "@material-ui/core";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { Formik } from "formik";
import { addBusinessSchema } from "../../../../../../helpers/schemas";
import { info } from "../../../../../../helpers/toasts";
import { useFetch } from "../../../../../../hooks/fetch.hook";
import { addOrganisation } from "../../../../Organisations/_api";
import { uniq } from "lodash";
import { sortHelper } from "../../../../../../ui/helpers";
import { ListButton } from "../../../../../../ui/components/ListButton";

export const DetailsSubmit = ({
  handleClose,
  setOrganisations,
  selectedOrg = {},
  handlePrevStep,
}) => {
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);

  const filteredNames = uniq(selectedOrg.trading_names);
  const tradingNamesOptions = [
    ...filteredNames.map((name) => ({
      value: name,
      label: name,
    })),
    { value: selectedOrg.legal_name, label: selectedOrg.legal_name },
  ].sort(sortHelper);

  const initialValues = {
    ABN: selectedOrg.ABN || "",
    business_name: (tradingNamesOptions[0] || {}).value || "",
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const payload = {
      ABN: values.ABN,
      business_name: values.business_name,
      status: "inspection_new",
      notes: [values.first_name + values.last_name, values.mobile, values.email].join(", "),
    };
    setLoading(true);
    request(addOrganisation, payload)
      .then((data) => {
        if (!data) return;
        setOrganisations((state) => [...state, data]);
        info("Organisation has been created!");
        handleClose();
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={addBusinessSchema}
    >
      {({ isSubmitting, handleSubmit, values, setTouched, setFieldValue }) => (
        <div className="bg-white pb-5">
          <h3 className="mb-5">Add Business</h3>
          <div>
            <MappedInput label="ABN" name="ABN" disabled />
            <MappedSelect
              label="Business Name"
              options={tradingNamesOptions}
              name="business_name"
              data-testid="business_name"
            />
          </div>
          <h3 className="mt-10 mb-5">Optional Contact Details (for Online Access)</h3>
          <div className="w-50">
            <MappedInput name="first_name" label="First Name" />
            <MappedInput name="last_name" label="Surname" />
            <MappedInput name="mobile" label="Mobile" />

            <MappedInput name="email" label="Email" />
          </div>
          <div className="my-5 d-flex justify-content-end">
            <div className="mr-3">
              <ListButton
                label="Cancel"
                size="large"
                onClick={handleClose}
                variant="outlined"
                text="#407A28"
                boxShadow={false}
                disabled={isSubmitting || loading}
              />
            </div>
            <div className="mr-3">
              <ListButton
                label="Back"
                size="large"
                onClick={handlePrevStep}
                variant="outlined"
                text="#407A28"
                boxShadow={false}
                disabled={isSubmitting || loading}
              />
            </div>
            <div>
              <ListButton
                label="Submit"
                onClick={handleSubmit}
                size="large"
                disabled={isSubmitting || loading}
              />
            </div>
          </div>
          {loading ? (
            <div className="mt-5">
              <LinearProgress color="secondary" />
            </div>
          ) : null}
        </div>
      )}
    </Formik>
  );
};
