/* eslint-disable no-unused-vars */
import React from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { useFetch } from "../../../hooks/fetch.hook";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { formatDate, isDate } from "../../../ui/helpers";

const HEADINGS = [
  ["id", "ID"],
  ["state", "State"],
  ["region", "Region"],
  ["date_requested", "Date Requested"],
  ["volume", "Volume"],
  ["group", "Group"],
  ["source", "Source"],
  ["status", "Status"],
  ["nearest_cp", "Nearest CP"],
];

function renderRequestsRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className={`align-middle no-line border-0 px-5 py-5 "text-dark"`}>
      {isDate(key) ? formatDate(item[key]) : item[key]}
    </td>
  ));
}
export const Requests = ({ data = [] }) => {
  const { request } = useFetch();

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <div className="bg-white rounded py-7 px-10">
          <ListHeader title="Booking Requests" />
          <Datagrid
            data={data}
            headings={HEADINGS}
            renderRow={renderRequestsRow}
            editable
            isNewTabLink
          />
        </div>
      </div>
    </div>
  );
};
