import React from "react";
import WarningIcon from "@material-ui/icons/Warning";

export const DuplicateWarning = ({ onClose }) => {
  return (
    <div className="text-center">
      <div className="mb-5">
        <WarningIcon fontSize="large" color="action" />
      </div>
      <h3 className="text-danger mb-2">Organisation with entered ABN already exists!</h3>
      <div>Are you sure you want to create organisation anyway?</div>
      <div className="my-8 text-right">
        <button onClick={onClose} className="btn btn-primary mr-3">
          <strong>OK</strong>
        </button>
      </div>
    </div>
  );
};
