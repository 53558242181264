import React, { useState } from "react";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedDatePicker } from "../../../ui/components/DatePicker";
import { Formik } from "formik";
import { ListButton } from "../../../ui/components/ListButton";
import { format } from "date-fns";
import { ActivitySchema } from "../../../helpers/schemas";

export const FilterLogs = ({ loading, setSearch }) => {
  const [resetKey, setResetKey] = useState(0);
  const initialValues = {
    start_date: "",
    end_date: "",
    url: "",
    status_code: "",
  };

  const handleSubmit = (values) => {
    let search;
    const { start_date, end_date, url, status_code } = values;
    if (start_date) {
      search = `gt-created_at=${format(start_date, "yyyy/MM/dd")}`;
    }
    if (end_date && format(start_date, "yyyy/MM/dd") !== format(end_date, "yyyy/MM/dd")) {
      search += `&lt-created_at=${format(end_date, "yyyy/MM/dd")}`;
    }
    if (url) {
      search += `&url=${url}`;
    }
    if (status_code) {
      search += `&status_code=${status_code}`;
    }
    setSearch(search);
  };

  const handleReset = () => {
    setResetKey(Date.now());
    setSearch("");
  };
  return (
    <div key={resetKey}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={ActivitySchema}
      >
        {({ handleSubmit, values }) => (
          <div className="bg-white p-5 rounded">
            <div className="d-flex align-items-center mb-4">
              <p className="mr-3">Start Date:</p>
              <div style={{ minHeight: "60px" }}>
                <MappedDatePicker
                  name="start_date"
                  label="Start Date"
                  shouldCloseOnSelect
                  isClearable
                />
              </div>
              <p className="mx-3">End Date:</p>
              <div style={{ minHeight: "60px" }}>
                <MappedDatePicker
                  name="end_date"
                  label="End Date"
                  shouldCloseOnSelect
                  isClearable
                />
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-between w-50">
              <div className="w-50 mr-3">
                <MappedInput name="url" label="URL" />
              </div>
              <div className="w-50">
                <MappedInput name="status_code" label="Status" />
              </div>
            </div>

            <div className="mt-3 d-flex justify-content-end w-50">
              <div className="mr-3">
                <ListButton
                  label="Reset"
                  size="small"
                  onClick={handleReset}
                  data-testid="rest"
                  disabled={loading}
                />
              </div>
              <div>
                <ListButton
                  label="Search"
                  size="small"
                  onClick={handleSubmit}
                  data-testid="submit"
                  disabled={loading}
                />
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};
