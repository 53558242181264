import { get } from "lodash";
import {
  formatDate,
  formatLongRow,
  getStatusRowStyle,
  isDate,
  isStatus
} from "../../../ui/helpers";
import { calculateInvoiceTotalGST } from "../../modules/ReimbursementsApprovals/helpers";
import AvatarIcon from "../../static/Avatar.png";

const renderUserName = (item = {}) => {
  if (!item.creator_name) return "";
  else
    return (
      <div className="d-flex align-items-center">
        <div>
          <img src={AvatarIcon} alt="Avatar" />
        </div>
        <div className="ml-3">{item.creator_name}</div>
      </div>
    );
};

const modifyDate = date => {
  if (date) return formatDate(date);
  else return "";
};

export function renderTasksRow(headings, item, handleEditOpen) {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-5 ${isStatus(key) &&
        getStatusRowStyle(item)}`}
    >
      {isDate(key) ? (
        modifyDate(item[key])
      ) : key === "creator_name" ? (
        renderUserName(item)
      ) : key === "task_name" ? (
        <span onClick={handleEditOpen} className="cursor-pointer">
          {item[key]}
        </span>
      ) : key === "detail" ? (
        formatLongRow(item[key], 40)
      ) : (
        item[key]
      )}
    </td>
  ));
}

export function renderHistoryRow(headings, item) {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-5 ${isStatus(key) &&
        getStatusRowStyle(item)}`}
    >
      {isDate(key) ? modifyDate(item[key]) : item[key]}
    </td>
  ));
}

export const modifyTasks = data => data.map(task => modifyTaskData(task));

export const modifyTaskData = (task = {}) => {
  return {
    ...task,
    creator_name: get(task, "creator_user.name", "")
  };
};

export const modifyOrgReimbursements = (data, users) =>
  data.map(item => modifyOrgReimbursement(item, users));

export const modifyOrgReimbursement = (item = {}, users = []) => {
  return {
    ...item,
    type: item.type.charAt(0).toUpperCase() + item.type.slice(1),
    invoice_number: get(item, "invoice.code", ""),
    date_requested: item.created_at,
    state: get(item, "organisation.physical_state", ""),
    region: get(item, "organisation.region.long_name", ""),
    organisation_name: get(item, "organisation.business_name", ""),
    approver: (users.find(({ value }) => value === item.approved_by) || {})
      .label,
    reviewer: (users.find(({ value }) => value === item.reviewed_by) || {})
      .label,
    total_value: item.total_value,
    total_value_gst: calculateInvoiceTotalGST(item)
  };
};

export const modifyOrgHistory = data =>
  data.map(item => modifyHistoryItem(item));

export const modifyHistoryItem = (item = {}) => {
  return {
    ...item,
    id: item.history_id
  };
};
