import * as React from "react";
import { Fab } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import { useState } from "react";
import { HelpForm } from "./HelpForm";

export function FloatingButton() {
  const [formOpened, setFormOpened] = useState(false);
  return formOpened ? (
    <HelpForm hideForm={() => setFormOpened(false)} />
  ) : (
    <Fab
      color="primary"
      style={{ position: "fixed", bottom: 20, right: 20 }}
      onClick={() => setFormOpened(true)}
    >
      <HelpIcon style={{ color: "white" }} />
    </Fab>
  );
}
