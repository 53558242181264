/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { TableSortLabel } from "@material-ui/core";
import { HEADINGS } from "../../modules/Settings/Prices/constants";
import { useFetch } from "../../../hooks/fetch.hook";
import { ListButton } from "../../../ui/components/ListButton";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { Loader } from "../../../ui/components/Loader";
import { Modal } from "../../../ui/components/Modal";
import { ConfirmDelete } from "../../../ui/components/ConfirmDelete";
import { info } from "../../../helpers/toasts";
import { ArchiveCheckbox } from "../../../ui/components/ArchiveCheckbox";
import { getSelectedId } from "../../../ui/helpers";
import { UnarchiveButton } from "../../../ui/components/UnarchiveButton";
import { sortHeaderStyle } from "../../constants";
import {
  deleteOrganisationPrice,
  getOrganisationPriceById,
  getOrganisationPrices,
  modifyOrganisationPrice,
} from "../../modules/Settings/Prices/_api";
import { modifyPricesList, renderPricesRow } from "../../modules/Settings/Prices/helpers";

export const PricesList = ({
  data = [],
  name,
  handleNew,
  handleExpand,
  setPricesList,
  organisationId,
}) => {
  const { request } = useFetch();

  const [selected, setSelected] = useState({});
  const [actionLoading, setActionLoading] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [unArchiving, setUnarchiving] = useState(false);
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    request(getOrganisationPrices, organisationId, "", archived, field, direction)
      .then((data) => data && setPricesList(modifyPricesList(data)))
      .finally(() => {
        setArchivedLoading(false);
        setLoading(false);
      });
  }, [archived, field, direction]);

  const handleModalClose = () => setConfirmDeleteOpen(false);

  const handleOpenModal = (id) => {
    setConfirmDeleteOpen(true);
    setSelectedItem(id);
  };

  const unArchiveDisabled = useMemo(() => Object.values(selected).filter(Boolean).length !== 1, [
    selected,
  ]);

  const updateCardData = (item = {}) => {
    const index = data.findIndex((price) => (price || {}).id === (item || {}).id);
    setPricesList((state) => [...state.slice(0, index), item, ...state.slice(index + 1)]);
  };

  const handleArchive = (id, setSubmitting) => {
    setSubmitting(true);
    setActionLoading(true);
    setConfirmDeleteOpen(false);
    request(deleteOrganisationPrice, id, organisationId)
      .then((data) => {
        if (!data) return;
        if (archived) {
          request(getOrganisationPriceById, organisationId, id).then((price) => {
            if (!price) return;
            updateCardData(price);
          });
        } else {
          setPricesList((state) => state.filter((item) => id !== item.id));
        }
        info("Price has been archived!");
        setSelected({});
      })
      .finally(() => {
        setSubmitting(false);
        setActionLoading(false);
      });
  };

  const handleUnarchive = () => {
    const priceID = getSelectedId(selected);
    const status = (data.find(({ id }) => id === priceID) || {}).status;
    if (status !== "archived") return;
    setUnarchiving(true);
    setActionLoading(true);
    request(modifyOrganisationPrice, { status: "active" }, priceID, organisationId)
      .then((data) => {
        if (!data) return;
        updateCardData(data);
        setSelected({});
        info("Price has been unarchived!");
      })
      .finally(() => {
        setUnarchiving(false);
        setActionLoading(false);
      });
  };

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  const renderButtons = () => (
    <>
      {archived && (
        <UnarchiveButton
          handleClick={handleUnarchive}
          disabled={unArchiveDisabled}
          loading={unArchiving}
        />
      )}
      <div>
        <ListButton label="New" onClick={handleNew} data-testid="new" className="my-2 mr-5" />
      </div>
    </>
  );

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) => (
      <th
        key={key}
        style={sortHeaderStyle}
        className="px-5 text-nowrap"
        onClick={() => {
          setDirection(key !== field ? "desc" : direction === "desc" ? "asc" : "desc");
          setField(key);
        }}
      >
        <span style={{ cursor: "pointer" }}>{header}</span>
        {key === field && <TableSortLabel active direction={direction} />}
      </th>
    ));
  }

  return (
    <>
      {actionLoading && <Loader isOpen={actionLoading} maxWidth="xs" />}
      {confirmDeleteOpen && (
        <Modal
          isOpen={confirmDeleteOpen}
          submitable
          onClose={handleModalClose}
          maxWidth="sm"
          modalContent={
            <ConfirmDelete
              handleClose={handleModalClose}
              handleSubmit={handleArchive}
              id={selectedItem}
              name={(data.find(({ id }) => id === selectedItem) || {}).first_name}
            />
          }
        />
      )}
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <ListHeader title={name} renderButtons={renderButtons} />
            <ArchiveCheckbox
              archivedLoading={archivedLoading}
              archived={archived}
              showArchived={showArchived}
            />
            <Datagrid
              data={data}
              headings={HEADINGS}
              renderRow={renderPricesRow}
              renderHeaderWithSorting={renderHeaderWithSorting}
              selectable
              selected={selected}
              setSelected={setSelected}
              deletable
              handleDelete={handleOpenModal}
              loading={loading}
              expandable
              handleExpand={handleExpand}
              expandHeaderClassName="text-left grid-header"
              paginationOptions={[50, 100, 200]}
              paginationDefaultValue={50}
            />
          </div>
        </div>
      </div>
    </>
  );
};
