import { organisationTypes } from "./actions";

const initialState = {
  organisationsList: [],
};

export function organisationsReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case organisationTypes.SET_DATA:
      return {
        ...state,
        organisationsList: payload,
      };

    case organisationTypes.REMOVE_ORGANISATION:
      return {
        ...state,
        organisationsList: [...state.organisationsList.filter(({ id }) => id !== payload)],
      };

    case organisationTypes.UPDATE_DATA:
      const index = state.organisationsList.findIndex(
        (item) => (item || {}).id === (payload || {}).id
      );
      return {
        ...state,
        organisationsList: [
          ...state.organisationsList.slice(0, index),
          payload,
          ...state.organisationsList.slice(index + 1),
        ],
      };

    default:
      return state;
  }
}
