import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";

export const GST = ({ gstStatus }) => {
  const { values, setFieldValue } = useFormikContext();

  const getInvoiceTotal = () => {
    if (values.calculated_invoice_total) {
      return Number(values.calculated_invoice_total);
    }
    else return 0;
  };

  useEffect(() => {
    if (!gstStatus) return;
    setFieldValue("gst", (getInvoiceTotal() * 0.1).toFixed(2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.calculated_invoice_total]);

  return (
    <MappedInput
      name="gst"
      label="GST"
      inputWrapperStyle={{ marginBottom: "20px" }}
      disabled
    />
  );
};
