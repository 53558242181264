import React, { useState } from "react";
import { LinearProgress } from "@material-ui/core";
import { useFetch } from "../../../hooks/fetch.hook";
import { ListButton } from "../../../ui/components/ListButton";
import { createAudit } from "./_api";
import { differenceInMonths } from "date-fns";
import { formatDate } from "../../../ui/helpers";

export const ConfirmNewAudit = ({ id, handleClose, siteAudits = [] }) => {
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);

  const sortByDate = (a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  };

  const recentAudits = siteAudits
    .filter(({ created_at }) => {
      const currentData = new Date(Date.now());
      const createdAt = new Date(created_at);
      const monthPast = differenceInMonths(currentData, createdAt);
      return monthPast <= 18;
    })
    .sort(sortByDate);

  const handleSubmit = () => {
    setLoading(true);
    request(createAudit, { site_id: id })
      .then((data) => {
        if (!data) return;
        if (!data.link_to_audit) return;
        const link = document.createElement("a");
        link.href = data.link_to_audit;
        link.setAttribute("target", "_blank");
        link.setAttribute("rel", "noopener noreferrer");
        link.click();
        handleClose();
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="pb-4" data-testid="confirm-new">
      <h4 className="text-center mb-10">
        <p>
          {recentAudits.length
            ? `Last audit was ${formatDate(recentAudits[0]?.created_at)}`
            : null}
        </p>
        Are you sure you want to create new Audit?
      </h4>
      <div className="d-flex justify-content-end">
        <ListButton
          label="Cancel"
          disabled={loading}
          onClick={handleClose}
          variant="outlined"
          text="#407A28"
          boxShadow={false}
          className="mr-3"
        />
        <ListButton label="Create" disabled={loading} onClick={handleSubmit} />
      </div>
      {loading ? (
        <div className="mt-3">
          <LinearProgress color="secondary" />
        </div>
      ) : null}
    </div>
  );
};
