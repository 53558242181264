import React from "react";
import { useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

export const GuestPage = () => {
  const user = useSelector(({ auth: { user } }) => user) || {};
  return (
    <div className="bg-white rounded px-10 py-40 d-flex flex-column align-items-center justify-content-center">
      <h4 className="mb-3">{`Hello, ${user.fullname} !`}</h4>
      <h4>
        You don't have enough permissions to continue. Please, contact your
        Administrator.
      </h4>
      <div className="containter h-100 mt-10">
        <div className="p-5 bg-white">
          <span>
            <img
              className="img-fluid"
              src={toAbsoluteUrl("/assets/icons/DDLogo.png")}
              alt="Logo"
            />
          </span>
        </div>
      </div>
    </div>
  );
};
