import React, { useState, useMemo } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import WarningIcon from "@material-ui/icons/Warning";
import { ListHeader } from "../../../../ui/structures/ListHeader";
import { useFetch } from "../../../../hooks/fetch.hook";
import { Loader } from "../../../../ui/components/Loader";
import { renderImportedProductsRow } from "../helpers";
import { IMPORT_HEADINGS } from "../constants";
import { ListButton } from "../../../../ui/components/ListButton";
import { addImportedProducts } from "../_api";
import { info } from "../../../../helpers/toasts";
import { useDispatch } from "react-redux";
import { setProductsList } from "../_redux/actions";

export const ImportedList = ({ data = [], handleClose, existingProducts = [] }) => {
  const { request } = useFetch();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);

  const submitDisabled = useMemo(() => Object.values(selected).filter(Boolean).length < 1, [
    selected,
  ]);

  const handleSubmit = () => {
    setLoading(true);
    request(addImportedProducts, data)
      .then((data) => {
        if (!data) return;
        const payload = data.map((item) => delete item.id);
        dispatch(setProductsList([...existingProducts, ...payload]));
        handleClose();
        info("Products have been submitted");
      })
      .finally(() => setLoading(false));
  };

  const handleSubmitSelected = () => {
    setLoading(true);
    const selectedIds = Object.keys(selected).filter((key) => selected[key]);
    const payload = data
      .filter(({ id }) => selectedIds.includes(id))
      .map(({ id, ...item }) => item);
    request(addImportedProducts, payload)
      .then((data) => {
        if (!data) return;
        dispatch(setProductsList([...existingProducts, ...data]));
        handleClose();
        info("Products have been submitted");
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="row justify-content-center bg-white px-10">
      <Loader isOpen={loading} maxWidth="xs" disableBackdropClick disableEscapeKeyDown />
      <div className="col-12">
        <div className="text-warning mb-5 d-flex align-items-center" style={{ fontSize: "16px" }}>
          <p>
            <WarningIcon fontSize="large" color="danger" />
          </p>
          <p className="ml-3">
            Note: in case if product's pack sizes don't exist in the system, they will not be
            linked!
          </p>
        </div>
        <div>
          <ListHeader title="Products" />
          <Datagrid
            data={data}
            headings={IMPORT_HEADINGS}
            renderRow={renderImportedProductsRow}
            selected={selected}
            setSelected={setSelected}
            selectable
          />
        </div>

        <div className="d-flex justify-content-between mt-10 align-items-center">
          <ListButton
            label="Cancel"
            boxShadow={false}
            onClick={handleClose}
            size="medium"
            className="mr-5"
            variant="outlined"
            text="#407A28"
            disabled={loading}
          />
          <div>
            <ListButton
              label="Submit Selected"
              size="medium"
              className="mr-5"
              disabled={loading || submitDisabled}
              onClick={handleSubmitSelected}
            />
            <ListButton
              label="Submit All"
              size="medium"
              boxShadow={false}
              disabled={loading}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
