import React, { Fragment } from "react";
import { Chip, makeStyles, Paper } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import { ReactComponent as AddIcon } from "../../static/AddIcon.svg";
import ToolTip from "../../components/ToolTip";

const tagsStyleMap = {
  Organisation: "#033300",
  Site: "#471061",
  Contact: "#033300"
};

const useStyles = makeStyles({
  root: {
    background: "rgba(0, 0, 0, 0.04)",
    borderBottom: "1px solid #033300",
    marginBottom: "15px",
    minHeight: "251px",
    paddingLeft: "10px"
  },
  colorPrimary: {
    color: "#E8F5E1"
  },
  deleteIcon: {
    color: "white"
  }
});

export const TagsField = ({
  label,
  tags = [],
  predefinedTags = [],
  handleAdd,
  setValue,
  fieldName = "tags",
  disabled
}) => {
  const classes = useStyles();

  const handleTagRemove = tag => {
    setValue(
      fieldName,
      tags.filter(name => name !== tag)
    );
  };

  return (
    <Paper className={classes.root} square elevation={0}>
      {label && (
        <div className="ml-4 pt-3" style={{ color: "#033300" }}>
          {label}
        </div>
      )}
      <div className="d-flex flex-wrap align-items-center">
        {tags.map(tag => {
          const tagObject =
            predefinedTags.filter(({ id }) => id === tag)[0] || {};
          return (
            <Fragment key={tag}>
              {tagObject.description ? (
                <ToolTip
                  description={tagObject.description}
                  key={tag}
                  placement="bottom"
                  content={
                    <div className="m-3">
                      <Chip
                        label={tagObject.name}
                        color="primary"
                        onDelete={disabled ? null : () => handleTagRemove(tag)}
                        avatar={
                          <DoneIcon
                            style={{
                              color: "white",
                              background: "transparent"
                            }}
                          />
                        }
                        style={{ background: tagsStyleMap[tagObject.category] }}
                        classes={{
                          colorPrimary: classes.colorPrimary,
                          deleteIcon: classes.deleteIcon
                        }}
                      />
                    </div>
                  }
                />
              ) : (
                <div className="m-3">
                  <Chip
                    label={tagObject.name}
                    color="primary"
                    onDelete={disabled ? null : () => handleTagRemove(tag)}
                    avatar={
                      <DoneIcon
                        style={{ color: "white", background: "transparent" }}
                      />
                    }
                    style={{ background: tagsStyleMap[tagObject.category] }}
                    classes={{
                      colorPrimary: classes.colorPrimary,
                      deleteIcon: classes.deleteIcon
                    }}
                  />
                </div>
              )}
            </Fragment>
          );
        })}
        {disabled ? null : (
          <div className="cursor-pointer">
            <AddIcon onClick={handleAdd} style={{ color: "#64A70B" }} />
          </div>
        )}
      </div>
    </Paper>
  );
};
