import config from "react-global-configuration";

export const addContact = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/contacts`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getContacts = (
  search = "",
  archived = "",
  field,
  direction,
  page = 0,
  page_size = 10
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/contacts?${search &&
      "&" + search}${(archived && "&status=all") || ""}${(field &&
      "&order_by=" + field) ||
      ""}${(direction && "&order_dir=" + direction) ||
      ""}&page=${page}&page_size=${page_size}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getContactsSimple = (query = "") =>
  fetch(`${config.get("ApiUrl").Rest}/contacts${query ? `?${query}` : ""}`, {
    mode: "cors",
    credentials: "include",
  });

export const getContactsAmount = (search = "", archived = "") => {
  return fetch(
    `${config.get("ApiUrl").Rest}/contacts?count_only=y${
      search ? "&" + search : ""
    }${(archived && "&status=all") || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};

export const getContactsListAutocomplete = (
  page_size = 80,
  autocompleteField,
  autocompleteText = "",
  field,
  direction,
  search,
  archived
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/contacts?&page_size=${page_size}${(archived &&
      "&status=all") ||
      ""}${(autocompleteField &&
      "&distinct=" +
        autocompleteField +
        "&" +
        autocompleteField +
        "=_like(" +
        autocompleteText +
        ")_") ||
      ""}${(field && "&order_by=" + field) || ""}${(direction &&
      "&order_dir=" + direction) ||
      ""}${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getContactById = (contact_id) =>
  fetch(`${config.get("ApiUrl").Rest}/contacts/${contact_id}`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyContact = (data, contact_id) =>
  fetch(`${config.get("ApiUrl").Rest}/contacts/${contact_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const archiveContact = (id) =>
  fetch(`${config.get("ApiUrl").Rest}/contacts/${id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const inviteUser = (contact_id, payload) =>
  fetch(`${config.get("ApiUrl").Rest}/contacts/${contact_id}/invite`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
    mode: "cors",
    credentials: "include",
  });

export const getContactOrganisations = (contact_id) =>
  fetch(`${config.get("ApiUrl").Rest}/contacts/${contact_id}/organisations`, {
    mode: "cors",
    credentials: "include",
  });

export const getContactBadges = (contact_id) =>
  fetch(`${config.get("ApiUrl").Rest}/contacts/${contact_id}/badges`, {
    mode: "cors",
    credentials: "include",
  });

export const linkOrganisationToContact = (contact_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/contacts/${contact_id}/organisations`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const editContactBadges = (contact_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/contacts/${contact_id}/badges`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getContactTags = (contact_id) =>
  fetch(`${config.get("ApiUrl").Rest}/contacts/${contact_id}/tags`, {
    mode: "cors",
    credentials: "include",
  });

export const editContactTags = (contact_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/contacts/${contact_id}/tags`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const deleteContactTags = (contact_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/contacts/${contact_id}/tags`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const postSendBulkSms = (sms_data) =>
  fetch(`${config.get("ApiUrl").Rest}/contacts/sms/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(sms_data),
    mode: "cors",
    credentials: "include",
  });

export const sendBulkSmsSingle = (contact_id, sms_data) =>
  fetch(`${config.get("ApiUrl").Rest}/contacts/${contact_id}/sms/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(sms_data),
    mode: "cors",
    credentials: "include",
  });

export const syncTrainings = () =>
  fetch(`${config.get("ApiUrl").Rest}/sync-moodle-trainings`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({}),
    mode: "cors",
    credentials: "include",
  });

export const syncUsersProgress = () =>
  fetch(`${config.get("ApiUrl").Rest}/sync-moodle-user-data`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({}),
    mode: "cors",
    credentials: "include",
  });

export const getTrainings = (contact_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/contacts/${contact_id}/training-progress`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const inviteForTraining = (contact_id, data) =>
  fetch(
    `${config.get("ApiUrl").Rest}/contacts/${contact_id}/invite-to-trainings`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include",
    }
  );

export const completeUserTraining = (contact_id, data) =>
  fetch(
    `${config.get("ApiUrl").Rest}/contacts/${contact_id}/complete-trainings`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include",
    }
  );
