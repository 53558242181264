import { Formik } from "formik";
import React, { useState } from "react";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { useFetch } from "../../../../hooks/fetch.hook";
import { MoodleTrainingSchema } from "../../../../helpers/schemas";
import { ListButton } from "../../../../ui/components/ListButton";
import { modifyTraining } from "../../Settings/Badges/_api";
import { info } from "../../../../helpers/toasts";
import { LinearProgress } from "@material-ui/core";

export const TrainingLine = ({
  training = {},
  setTrainings,
  trainingsList = []
}) => {
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);

  const {
    name,
    badge_name,
    expiry_in_months,
    moodle_training_id,
    id
  } = training;

  const initialValues = {
    name: name,
    badge_name: badge_name || name,
    expiry_in_months: expiry_in_months || 12,
    moodle_training_id: moodle_training_id
  };

  const handleDataUpdate = (payload = {}) => {
    const index = trainingsList.findIndex(item => item?.id === id);
    if (index === -1) return;
    const updatedData = [
      ...trainingsList.slice(0, index),
      payload,
      ...trainingsList.slice(index + 1)
    ];
    setTrainings(updatedData);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    request(modifyTraining, values, id)
      .then(data => {
        if (!data) return;
        info("Training has been updated!");
        handleDataUpdate(data);
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={MoodleTrainingSchema}
    >
      {({ handleSubmit, isSubmitting, values, setSubmitting }) => (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <p className="mr-5" style={{ width: "22%" }}>
              {name}
            </p>
            <div style={{ width: "45%" }}>
              <MappedInput
                name="badge_name"
                label="Badge Name"
                disabled={loading}
              />
            </div>
            <div style={{ width: "12%" }} className="ml-5">
              <MappedInput
                name="expiry_in_months"
                label="Expires in (month)"
                disabled={loading}
              />
            </div>
            <div>
              <ListButton
                label="Save"
                onClick={handleSubmit}
                disabled={isSubmitting || loading}
              />
            </div>
          </div>
          {loading ? (
            <div className="mt-2">
              <LinearProgress color="secondary" />
            </div>
          ) : null}
          <hr />
        </>
      )}
    </Formik>
  );
};
