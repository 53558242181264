export const HEADINGS = [
  ["long_name", "Long Name"],
  ["short_name", "Short Name"],
  ["definition", "Definition"],
  ["status", "Status"],
];

export const searchMap = {
  "Long Name": "long_name",
  "Short Name": "short_name",
};
