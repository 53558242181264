import React, { useState } from "react";
import { Formik } from "formik";
import { Auth } from "aws-amplify";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { DevAlertBar } from "../../../../ui/components/AlertBar";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { error } from "../../../../helpers/toasts";

const initialValues = {
  email: "",
};

export function ForgotPassword(props) {
  const { MyTheme } = props;
  const [isRequested, setIsRequested] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .required()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols"),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    Auth.forgotPassword(values.email)
      .then((data) => {
        if (!data) return;
        setIsRequested(true);
      })
      .catch((err) => {
        error(err.message);
        setIsRequested(false);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      <div>
        <DevAlertBar
          severity="info"
          message="If you have a valid account you will receive an email with password reset instructions
              in the next 5 minutes"
        />
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={ForgotPasswordSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <>
            {!isRequested && (
              <div
                className="d-flex justify-content-center bg-white p-7 mt-10 flex-column"
                style={{ width: "35%", position: "relative", left: "33%" }}
              >
                <div className="text-center mb-10 mb-lg-20">
                  <h3 className="font-size-h1">Forgot Password ?</h3>
                  <div className="text-muted font-weight-bold">
                    Enter your email to reset your password
                  </div>
                </div>
                <MappedInput
                  style={{ padding: ".5rem" }}
                  inputWrapperStyle={{ border: "none" }}
                  name="email"
                  placeholder="Email Address"
                />
                <div className="form-group d-flex flex-wrap flex-center">
                  <button
                    onClick={handleSubmit}
                    style={MyTheme.button}
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                  <div className="w-100">
                    <Link to="/auth">
                      <button
                        style={{
                          ...MyTheme.button,
                          backgroundColor: "#E1F0FF",
                          color: "#64A70B",
                        }}
                      >
                        Cancel
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Formik>
    </>
  );
}
