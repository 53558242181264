import config from "react-global-configuration";

export const getIntegrationByType = (integration_type) =>
  fetch(
    `${config.get("ApiUrl").Rest}/integrations/${integration_type &&
      "?integration_type=" + integration_type}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      credentials: "include",
    }
  );

export const getIntegrationByID = (id) =>
  fetch(`${config.get("ApiUrl").Rest}/integrations/${id}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
    credentials: "include",
  });

export const getIntegration = (query) =>
  fetch(`${config.get("ApiUrl").Rest}/integrations/?${query}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
    credentials: "include",
  });

export const createIntegration = (integration_data) =>
  fetch(`${config.get("ApiUrl").Rest}/integrations`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(integration_data),
    mode: "cors",
    credentials: "include",
  });

export const modifyIntegration = (integration_id, integration_data) =>
  fetch(`${config.get("ApiUrl").Rest}/integrations/${integration_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(integration_data),
    mode: "cors",
    credentials: "include",
  });

export const deleteIntegration = (integration_id) =>
  fetch(`${config.get("ApiUrl").Rest}/integrations/${integration_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const navigateQiuckBooksIntegration = () =>
  fetch(
    `${config.get("ApiUrl").Rest}/integrations/quickbooks_parameters
      `,
    {
      mode: "cors",
      credentials: "include",
    }
  );
