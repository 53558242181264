import config from "react-global-configuration";

export const getSites = (
  search = "",
  archived = "",
  field,
  direction,
  page = 0,
  pageSize = 10
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/sites?${search && "&" + search}${(archived &&
      "&status=all") ||
      ""}${(field && "&order_by=" + field) || ""}${(direction &&
      "&order_dir=" + direction) ||
      ""}&page=${page}&page_size=${pageSize}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getSitesSimple = (query = "") =>
  fetch(`${config.get("ApiUrl").Rest}/sites${query ? `?${query}` : ""}`, {
    mode: "cors",
    credentials: "include"
  });

export const getSitesAmount = (search = "", archived = "") => {
  return fetch(
    `${config.get("ApiUrl").Rest}/sites?count_only=y${
      search ? "&" + search : ""
    }${(archived && "&status=all") || ""}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );
};

export const getSitesAutocomplete = (
  page_size = 80,
  autocompleteField,
  autocompleteText = "",
  field,
  direction,
  search,
  archived
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/sites?&page_size=${page_size}${(archived &&
      "&status=all") ||
      ""}${(autocompleteField &&
      "&distinct=" +
        autocompleteField +
        "&" +
        autocompleteField +
        "=_like(" +
        autocompleteText +
        ")_") ||
      ""}${(field && "&order_by=" + field) || ""}${(direction &&
      "&order_dir=" + direction) ||
      ""}${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const addSite = data =>
  fetch(`${config.get("ApiUrl").Rest}/sites`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const getSiteById = site_id =>
  fetch(`${config.get("ApiUrl").Rest}/sites/${site_id}`, {
    mode: "cors",
    credentials: "include"
  });

export const modifySite = (data, site_id) =>
  fetch(`${config.get("ApiUrl").Rest}/sites/${site_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const archiveSite = id =>
  fetch(`${config.get("ApiUrl").Rest}/sites/${id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include"
  });

export const getSitesOrganisations = site_id =>
  fetch(`${config.get("ApiUrl").Rest}/sites/${site_id}/organisations`, {
    mode: "cors",
    credentials: "include"
  });

export const getSiteTags = site_id =>
  fetch(`${config.get("ApiUrl").Rest}/sites/${site_id}/tags`, {
    mode: "cors",
    credentials: "include"
  });

export const editSiteTags = (site_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/sites/${site_id}/tags`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const deleteSiteTags = (site_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/sites/${site_id}/tags`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const getSitesInspetors = site_id =>
  fetch(`${config.get("ApiUrl").Rest}/sites/${site_id}/inspectors`, {
    mode: "cors",
    credentials: "include"
  });

export const createAudit = data =>
  fetch(`${config.get("ApiUrl").Rest}/site_audits`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const unarchiveAudit = id =>
  fetch(`${config.get("ApiUrl").Rest}/site_audits/${id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ status: "active" }),
    mode: "cors",
    credentials: "include"
  });

export const archiveSiteAudit = id =>
  fetch(`${config.get("ApiUrl").Rest}/site_audits/${id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include"
  });

export const getSiteAudits = (site_id, archived) =>
  fetch(
    `${config.get("ApiUrl").Rest}/sites/${site_id}/site_audits?${(archived &&
      "&status=all") ||
      ""}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getAudit = id =>
  fetch(`${config.get("ApiUrl").Rest}/site_audits/${id}`, {
    mode: "cors",
    credentials: "include"
  });

export const getSiteInspectors = (site_id, training_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/sites/${site_id}/inspectors?training_id=${training_id}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getOrganisationInspectors = org_id =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/organisations/${org_id}/inspectors?training_id=2b32e8c1-aa3c-4192-aa0c-9151bfd3621d`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getSitesStatisitc = site_id => {
  return fetch(
    `${config.get("ApiUrl").Rest}/sites/${site_id}/collection_statistics`,
    {
      mode: "cors",
      credentials: "include"
    }
  );
};
