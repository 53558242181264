/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import SearchIcon from "@material-ui/icons/Search";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import {
  getSelectedId,
  handleCopy,
  handlePaste,
  handleSearch,
  objectCheck,
  sortHelper,
} from "../../../ui/helpers";
import { COPY_HELPER, HEADINGS, SEARCH_HELPER } from "./constants";
import {
  removeContact,
  setContactsList,
  updateContactsList,
} from "./_redux/actions";
import { modifyContactsArray, renderContactsRow } from "./helpers";
import { ListButton } from "../../../ui/components/ListButton";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  archiveContact,
  getContactById,
  getContacts,
  getContactsAmount,
  getContactsListAutocomplete,
  inviteForTraining,
  modifyContact,
  postSendBulkSms,
} from "./_api";
import { searchMap } from "./constants";
import { UnarchiveButton } from "../../../ui/components/UnarchiveButton";
import { Modal } from "../../../ui/components/Modal";
import { ConfirmDelete } from "../../../ui/components/ConfirmDelete";
import { ArchiveCheckbox } from "../../../ui/components/ArchiveCheckbox";
import { AVAILABLE_ROLES, sortHeaderStyle } from "../../constants";
import { IconButton, TableSortLabel } from "@material-ui/core";
import { SMSForm } from "../../../ui/structures/SMSForm";
import { Loader } from "../../../ui/components/Loader";
import { error, info } from "../../../helpers/toasts";
import { ServerSearchBar } from "../../../ui/structures/ServerSearchBar";
import { uniq } from "lodash";
import { TrainingInvite } from "./TrainingInvite";
import { getTrainings } from "../Settings/Badges/_api";
import ToolTip from "../../../ui/components/ToolTip";
import { AccessControl } from "../../../ui/structures/AccessControl";
import { getTagsListAutocomplete } from "../Settings/Tags/_api";

export const List = () => {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [actionLoading, setActionLoading] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [unArchiving, setUnarchiving] = useState(false);
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [bulkModalOpen, setBulkModalOpen] = useState(false);
  const [bulkStep, setBulkStep] = useState("1");
  const [confirmedMessgae, setConfirmedMessage] = useState("");
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [trainingModalOpen, setTrainingModalOpen] = useState(false);
  const [trainings, setTrainings] = useState([]);
  const [copiedSearch, setCopiedSearch] = useState([]);

  const handleModalClose = () => setConfirmDeleteOpen(false);

  const handleBulkOpen = () => setBulkModalOpen(true);

  const handleTrainingOpen = () => {
    const contactID = getSelectedId(selected);
    setSelectedItem(contactID);
    setTrainingModalOpen(true);
  };
  const handleTrainingClose = () => {
    setSelectedItem("");
    setTrainingModalOpen(false);
  };

  const data = useSelector(({ contacts: { contactsList } }) => contactsList);

  // eslint-disable-next-line no-unused-vars
  const user = useSelector(({ auth: { user } }) => user) || {};

  const fetchContacts = () => {
    setLoading(true);
    request(
      getContacts,
      search,
      archived,
      field,
      field && direction,
      page,
      pageSize
    )
      .then((data) => {
        if (!data) return;
        dispatch(setContactsList(modifyContactsArray(data)));
        setLoading(false);
      })
      .finally(() => setArchivedLoading(false));
  };

  const requestOptions = (
    autocompleteValue,
    autocompleteField,
    searchFields,
    archived
  ) => {
    if (autocompleteField === "tag") {
      return request(
        getTagsListAutocomplete,
        80,
        "name",
        autocompleteValue,
        "category=_like(Contact)_"
      );
    } else
      return request(
        getContactsListAutocomplete,
        80,
        autocompleteField,
        autocompleteValue,
        field,
        field && direction,
        searchFields,
        archived
      );
  };

  const fetchContactsAutocomplete = (
    autocompleteValue,
    autocompleteField,
    searchFields,
    archived
  ) => {
    if (autocompleteValue === "") return;
    setAutocompleteLoading(true);

    requestOptions(
      autocompleteValue,
      autocompleteField,
      searchFields,
      archived
    ).then((data) => {
      if (data && Array.isArray(data) && data.length && objectCheck(data)) {
        setAutocompleteData(uniq(data.map((item) => String(item))));
      } else {
        setAutocompleteData(["No option found"]);
      }
      setAutocompleteLoading(false);
    });
  };

  useEffect(() => {
    fetchContacts();
  }, [search, archived, field, direction, page, pageSize]);

  useEffect(() => {
    request(getContactsAmount, search, archived).then(
      (data) => data && setTotalRecords(data)
    );
  }, [search, archived]);

  useEffect(() => {
    request(getTrainings).then(
      (data) =>
        data &&
        setTrainings(
          data
            .map(({ id, name }) => ({
              value: id,
              label: name,
            }))
            .sort(sortHelper)
        )
    );
  }, []);

  const unArchiveDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const bulkDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 0,
    [selected]
  );

  const selectedItems = Object.keys(selected).filter((key) =>
    Boolean(selected[key])
  );

  const handleBulkClose = () => {
    setBulkModalOpen(false);
    setBulkStep("1");
    setConfirmedMessage("");
  };

  const handleBulkConfirm = (values, { setSubmitting }) => {
    setBulkStep("2");
    setConfirmedMessage(values);
    setSubmitting(false);
  };

  const handleBulkSubmit = (values, { setSubmitting }) => {
    request(postSendBulkSms, {
      contact_ids: selectedItems,
      ...values,
    })
      .then((response) => {
        if (response) {
          info("SMS has been sent!");
        }
      })
      .finally(() => {
        setSubmitting(false);
        handleBulkClose();
      });
  };

  const handleArchive = (id, setSubmitting) => {
    setSubmitting(true);
    setActionLoading(true);
    setConfirmDeleteOpen(false);
    request(archiveContact, id)
      .then((data) => {
        if (!data) return;
        if (archived) {
          request(getContactById, id).then(
            (data) => data && dispatch(updateContactsList(data))
          );
        } else dispatch(removeContact(id));
        info("Contact has been archived!");
        setSelected({});
      })
      .finally(() => {
        setSubmitting(false);
        setActionLoading(false);
      });
  };

  const handleUnarchive = () => {
    const contactID = getSelectedId(selected);
    const status = (data.find(({ id }) => id === contactID) || {}).status;
    if (status !== "archived") return;
    setUnarchiving(true);
    setActionLoading(true);
    request(modifyContact, { status: "active" }, contactID)
      .then((data) => {
        if (!data) return;
        dispatch(updateContactsList(data));
        setSelected({});
        info("Contact has been unarchived!");
      })
      .finally(() => {
        setUnarchiving(false);
        setActionLoading(false);
      });
  };

  const handleOnsiteInvite = () => {
    setActionLoading(true);
    const contactID = getSelectedId(selected);
    const training = trainings.find(
      ({ label }) => label === "drumMUSTER Inspector Training Onsite"
    );
    if (!contactID || !training) {
      error("Contact or training not found");
      setLoading(false);
      return;
    }
    request(inviteForTraining, contactID, [{ id: training?.value }])
      .then((data) => {
        if (!data) return;
        info("Contact has been invited for the training!");
      })
      .finally(() => setActionLoading(false));
  };

  const renderButtons = () => (
    <>
      {archived && (
        <UnarchiveButton
          handleClick={handleUnarchive}
          disabled={unArchiveDisabled}
          loading={unArchiving}
        />
      )}
      <AccessControl
        desiredRoles={[
          AVAILABLE_ROLES.NATIONAL_ADMIN,
          AVAILABLE_ROLES.AGSAFE_ADMIN,
          AVAILABLE_ROLES.AGSAFE_STAFF,
          AVAILABLE_ROLES.RC_ACCESS,
        ]}
      >
        <ListButton
          label="Onsite Training"
          className="mr-5"
          disabled={unArchiveDisabled}
          onClick={handleOnsiteInvite}
          data-testid="onsite_training"
        />
        <ListButton
          label="Online Training"
          className="mr-5"
          disabled={unArchiveDisabled}
          onClick={handleTrainingOpen}
          data-testid="online_training"
        />
      </AccessControl>
      <div className="mr-5">
        <Link
          to={{
            pathname: `/add-contact`,
          }}
          data-testid="new"
        >
          <ListButton label="New" />
        </Link>
      </div>
      <AccessControl
        desiredRoles={[
          AVAILABLE_ROLES.NATIONAL_ADMIN,
          AVAILABLE_ROLES.AGSAFE_ADMIN,
          AVAILABLE_ROLES.AGSAFE_STAFF,
        ]}
      >
        <ListButton
          label="Bulk SMS"
          className="mr-5"
          disabled={!bulkDisabled}
          onClick={handleBulkOpen}
          data-testid="bulk_sms"
        />
      </AccessControl>
      <ToolTip
        title="Copy filter criteria"
        arrow
        interactive
        placement="top"
        content={
          <IconButton
            onClick={() => handleCopy(search)}
            data-testid="copy-filter"
          >
            <FileCopyIcon />
          </IconButton>
        }
      />
      <ToolTip
        title="Apply filter criteria"
        arrow
        interactive
        placement="top"
        content={
          <IconButton
            onClick={() => handlePaste(COPY_HELPER, setCopiedSearch, setSearch)}
            data-testid="apply-filter"
          >
            <SearchIcon />
          </IconButton>
        }
      />
    </>
  );

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  const handleOpenModal = (id) => {
    setConfirmDeleteOpen(true);
    setSelectedItem(id);
  };

  const resetSearch = () => {
    setSearch("");
  };

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) =>
      key === "badges" ? (
        <th key={key} style={sortHeaderStyle} className="px-5 text-nowrap">
          <span>{header}</span>
        </th>
      ) : (
        <th
          key={key}
          style={sortHeaderStyle}
          className="px-5 text-nowrap"
          onClick={() => {
            setDirection(
              key !== field ? "desc" : direction === "desc" ? "asc" : "desc"
            );
            setField(key);
          }}
        >
          <span style={{ cursor: "pointer" }}>{header}</span>
          {key === field && <TableSortLabel active direction={direction} />}
        </th>
      )
    );
  }

  return (
    <>
      <Loader isOpen={actionLoading} maxWidth="xs" data-testid="loader" />
      <Modal
        isOpen={trainingModalOpen}
        submitable
        onClose={handleTrainingClose}
        maxWidth="md"
        modalContent={
          <TrainingInvite
            handleClose={handleTrainingClose}
            contact={data.find(({ id }) => id === selectedItem)}
            trainingOptions={trainings}
          />
        }
      />
      <Modal
        isOpen={confirmDeleteOpen}
        submitable
        onClose={handleModalClose}
        maxWidth="sm"
        modalContent={
          <ConfirmDelete
            handleClose={handleModalClose}
            handleSubmit={handleArchive}
            id={selectedItem}
            name={(data.find(({ id }) => id === selectedItem) || {}).first_name}
          />
        }
      />
      <Modal
        isOpen={bulkModalOpen}
        submitable
        onClose={handleBulkClose}
        maxWidth={`${bulkStep === "1" ? "xs" : "md"}`}
        modalContent={
          bulkStep === "1" ? (
            <SMSForm
              handleClose={handleBulkClose}
              handleSubmit={handleBulkConfirm}
              title={`Send SMS to ${selectedItems.length} contacts`}
            />
          ) : (
            <SMSForm
              handleClose={handleBulkClose}
              handleSubmit={handleBulkSubmit}
              title={`Are you sure you want to send SMS to ${selectedItems.length} recipients?`}
              data={confirmedMessgae}
              titleClassName="text-danger"
            />
          )
        }
      />
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div data-testid="search-wrapper">
            <ServerSearchBar
              className="mb-5"
              onSearch={(data) =>
                handleSearch(data, searchMap, setSearch, setPage)
              }
              keyMap={SEARCH_HELPER}
              loading={autocompleteLoading}
              currentSearchList={autocompleteData}
              fetchAutocompleteFunction={fetchContactsAutocomplete}
              placeholder="Search Contacts..."
              clearSearch={resetSearch}
              searchMap={searchMap}
              searchFields={search}
              archived={archived}
              copiedSearch={copiedSearch}
              contentLoading={loading}
              defaultKey="Last Name"
            />
          </div>
          <div className="bg-white rounded py-7 px-10">
            <ListHeader title="Contacts List" renderButtons={renderButtons} />
            <ArchiveCheckbox
              archivedLoading={archivedLoading}
              archived={archived}
              showArchived={showArchived}
            />
            <Datagrid
              data={data}
              headings={HEADINGS}
              renderRow={renderContactsRow}
              selected={selected}
              setSelected={setSelected}
              editable
              isNewTabLink
              loading={loading}
              selectable
              handleDelete={handleOpenModal}
              deletable
              renderHeaderWithSorting={renderHeaderWithSorting}
              link="contacts"
              serverPage={page}
              setServerPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              isServerPagination
              totalRecords={totalRecords}
            />
          </div>
        </div>
      </div>
    </>
  );
};
