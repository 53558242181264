import React from "react";
import { ListButton } from "../../../../ui/components/ListButton";
import { itemStyle } from "../constants";
import AuditorIcon from "../static/AuditorIcon.png";

export const Auditor = () => {
  return (
    <>
      <div style={itemStyle} className="bg-white p-7 mt-10">
        <div className="text-center mb-5">
          <img src={AuditorIcon} alt="logo" />
        </div>
        <h3 className="mb-2  text-dark">
          <strong>iAuditor</strong>
        </h3>
        <div style={{ fontSize: "14px" }}>
          Access Read Only Audit Content from your iAuditor subscription
        </div>
        <div className="mt-19">
          <ListButton label="Connected" size="small" />
        </div>
      </div>
    </>
  );
};
