import React, { useState } from "react";
import { Formik } from "formik";
import { TagsField } from "./TagsField";
import { Modal } from "../../components/Modal";
import { TagsAutocomplete } from "./TagsAutocomplete";
import { ListButton } from "../../components/ListButton";
import { AccessControl } from "../AccessControl";
import { AVAILABLE_ROLES } from "../../../app/constants";

export const TagsForm = ({
  id,
  tags = [],
  linkedTags = [],
  handleSubmit,
  wrapperClassName = "col-4",
  editRestricted = false
}) => {
  const initialValues = {
    tags: linkedTags.map(({ id }) => id)
  };

  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  return (
    <>
      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <>
            {modalOpen && (
              <Modal
                maxWidth="md"
                isOpen={modalOpen}
                submitable
                onClose={handleModalClose}
                modalContent={
                  <TagsAutocomplete
                    name="tags"
                    placeholder="Select Tags"
                    setValue={setFieldValue}
                    options={tags.filter(
                      item => !values.tags.includes(item.id)
                    )}
                    loading={!tags.length}
                    onClose={handleModalClose}
                    currentValue={values.tags}
                    multiple
                  />
                }
              />
            )}
            <div className={wrapperClassName}>
              <TagsField
                label="Tags"
                tags={values.tags}
                handleAdd={handleModalOpen}
                setValue={setFieldValue}
                predefinedTags={tags}
                fieldName="tags"
              />
              {editRestricted ? (
                <AccessControl
                  desiredRoles={[
                    AVAILABLE_ROLES.NATIONAL_ADMIN,
                    AVAILABLE_ROLES.AGSAFE_ADMIN,
                    AVAILABLE_ROLES.AGSAFE_STAFF,
                    AVAILABLE_ROLES.RC_ACCESS
                  ]}
                >
                  <div className="mt-6 text-right">
                    <ListButton
                      label="Submit Tags"
                      disabled={isSubmitting}
                      onClick={handleSubmit}
                      size="medium"
                    />
                  </div>
                </AccessControl>
              ) : (
                <div className="mt-6 text-right">
                  <ListButton
                    label="Submit Tags"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                    size="medium"
                  />
                </div>
              )}
            </div>
          </>
        )}
      </Formik>
    </>
  );
};
