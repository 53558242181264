/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import subMonths from "date-fns/subMonths";
import { TaskColumn } from "./TaskColumn";
import { ListButton } from "../../../ui/components/ListButton";
import { Modal } from "../../../ui/components/Modal";
import { TaskDetails } from "./TaskDetails";
import { TaskForm } from "./TaskForm";
import { useFetch } from "../../../hooks/fetch.hook";
import { columnsMap, searchOptions } from "./constants";
import {
  getTasks,
  addTask,
  modifyTask,
  addTaskUser,
  modifyTaskUser
} from "./_api";
import { getContactsSimple } from "../Contacts/_api";
import {
  modifyTasks,
  modifyContacts,
  modifyUsers,
  modifyOrganisations,
  modifySites,
  modifyTaskData
} from "./helpers";
import { getUsers } from "../Users/_api";
import { getSitesSimple } from "../Sites/_api";
import { getSimpleOrganisations } from "../Organisations/_api";
import { AutocompleteMultiple } from "../../../ui/components/AutocompleteMultiple";
import { format } from "date-fns/esm";
import { useHistory } from "react-router-dom";
import { removeEmptyFields } from "../../../ui/helpers";
import { ArchiveCheckbox } from "../../../ui/components/ArchiveCheckbox";

export const Tasks = () => {
  const { request } = useFetch();
  const history = useHistory();

  const taskID = history.location.taskID;

  const [tasks, setTasks] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [taskDetailsOpen, setTaskDetailsOpen] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState("");
  const [selectedTaskStatusChange, setSelectedTaskStatusChange] = useState("");
  const [contacts, setContacts] = useState([]);
  const [users, setUsers] = useState([]);
  const [sites, setSites] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [typeFilter, setTypeFilter] = useState([]);
  const [groupFilter, setGroupFilter] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [currentTasks, setCurrentTasks] = useState([]);
  const [completedLoading, setCompletedLoading] = useState(false);
  const [currentLoading, setCurrentLoading] = useState(false);
  const [overdueTasks, setOverdueTasks] = useState([]);
  const [overdueLoading, setOverdueLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [archivedLoading, setArchivedLoading] = useState(false);

  const typeOptions = [
    "audit",
    "event",
    "reseller_visit",
    "training",
    "other",
    "finance",
    "contact"
  ];

  const handleSearch = (name, value = []) => {
    setGroupFilter(
      value.filter(item => item === "group" || item === "individual")
    );
    setTypeFilter(value.filter(item => typeOptions.includes(item)));
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedTaskStatusChange("");
    setSelectedTaskId("");
  };

  const handleTaskDetailsClose = () => {
    setTaskDetailsOpen(false);
    setSelectedTaskStatusChange("");
  };

  const handleTaskDetailsOpen = () => setTaskDetailsOpen(true);

  const handleStatusChange = (id, statusChange = "") => {
    setTaskDetailsOpen(true);
    setSelectedTaskId(id);
    setSelectedTaskStatusChange(statusChange);
  };

  const handleModalOpen = id => {
    setModalOpen(true);
    setSelectedTaskId(id);
  };

  const handleDataUpdate = (item = {}, array = [], setData) => {
    const index = array.findIndex(task => (task || {}).id === (item || {}).id);
    setData(state => [
      ...state.slice(0, index),
      item,
      ...state.slice(index + 1)
    ]);
  };

  const handleAddTask = (values, { setSubmitting }) => {
    const { user_id, ...taskValues } = values;
    setCompletedLoading(true);
    setCurrentLoading(true);
    setOverdueLoading(true);
    handleTaskDetailsClose();
    removeEmptyFields(taskValues);
    request(addTask, taskValues)
      .then(data => {
        if (!data && !user_id) {
          setCompletedLoading(false);
          setCurrentLoading(false);
          setOverdueLoading(false);
          return;
        }
        request(
          addTaskUser,
          data.id,
          user_id.map(id => ({ id }))
        )
          .then(ids => {
            if (!ids) return;
            const payload = {
              ...data,
              users: users
                .filter(({ value }) => user_id.includes(value))
                .map(user => ({ ...user, name: user.label }))
            };
            setTasks(state => [...state, modifyTaskData(payload)]);
            setInitialData(state => [...state, modifyTaskData(payload)]);
          })
          .finally(() => {
            setCurrentLoading(false);
            setOverdueLoading(false);
            setCompletedLoading(false);
          });
      })
      .finally(() => setSubmitting(false));
  };

  const handleEditTask = (values, { setSubmitting }) => {
    const { user_id, ...taskValues } = values;
    setCurrentLoading(true);
    setCompletedLoading(true);
    setOverdueLoading(true);
    handleTaskDetailsClose();
    handleModalClose();
    removeEmptyFields(taskValues);
    request(modifyTask, selectedTaskId, taskValues)
      .then(data => {
        if (!data && !user_id) {
          setCurrentLoading(false);
          setOverdueLoading(false);
          setCompletedLoading(false);
          return;
        }
        request(
          modifyTaskUser,
          data.id,
          user_id.map(id => ({ id: id }))
        )
          .then(ids => {
            if (!ids) return;
            const payload = {
              ...data,
              users: users
                .filter(({ value }) => user_id.includes(value))
                .map(user => ({ ...user, name: user.label, id: user.value }))
            };
            handleDataUpdate(modifyTaskData(payload), tasks, setTasks);
            handleDataUpdate(
              modifyTaskData(payload),
              initialData,
              setInitialData
            );
          })
          .finally(() => {
            setCurrentLoading(false);
            setOverdueLoading(false);
            setCompletedLoading(false);
          });
      })
      .finally(() => setSubmitting(false));
  };

  const fetchContacts = () => {
    request(getContactsSimple, "simple=true&fields=id,first_name,last_name")
      .then(data => data && modifyContacts(data))
      .then(data => {
        if (!data) return;
        setContacts(data);
      });
  };

  const fetchUsers = () => {
    request(getUsers)
      .then(data => data && modifyUsers(data))
      .then(data => {
        if (!data) return;
        setUsers(data);
      });
  };

  const fetchSites = () => {
    request(getSitesSimple, "simple=true&fields=id,name")
      .then(data => data && modifySites(data))
      .then(data => {
        if (!data) return;
        setSites(data);
      });
  };

  const fetchOrganisations = () => {
    request(getSimpleOrganisations)
      .then(data => data && modifyOrganisations(data))
      .then(data => {
        if (!data) return;
        setOrganisations(data);
      });
  };

  const handleSort = (a, b) => {
    return (
      new Date(a.due_date) - new Date(b.due_date) ||
      (a.task_name || "")
        .toLowerCase()
        .localeCompare((b.task_name || "").toLowerCase())
    );
  };

  const fetchCompletedTasks = () => {
    setCompletedLoading(true);
    const monthAgo = format(subMonths(new Date(Date.now()), 1), "yyyy/MM/dd");
    request(
      getTasks,
      `page=0&page_size=250&gte-completed_date=${monthAgo}${
        archived ? "&status=all" : ""
      }`
    )
      .then(data => {
        if (!data) return;
        setCompletedTasks(modifyTasks(data).sort(handleSort));
        if (data.find(({ id }) => id === taskID)) handleModalOpen(taskID);
      })
      .finally(() => {
        setCompletedLoading(false);
        setArchivedLoading(false);
      });
  };

  const fetchCurrentTasks = () => {
    setCurrentLoading(true);
    const today = format(new Date(Date.now()), "yyyy/MM/dd");
    request(
      getTasks,
      `page=0&page_size=250&completed_date=_null_&gte-due_date=${today}${
        archived ? "&status=all" : ""
      }`
    )
      .then(data => {
        if (!data) return;
        setCurrentTasks(modifyTasks(data).sort(handleSort));
        if (data.find(({ id }) => id === taskID)) handleModalOpen(taskID);
      })
      .finally(() => setCurrentLoading(false));
  };

  const fetchOverdueTasks = () => {
    setOverdueLoading(true);
    const today = format(new Date(Date.now()), "yyyy/MM/dd");
    request(
      getTasks,
      `page=0&page_size=250&completed_date=_null_&lte-due_date=${today}${
        archived ? "&status=all" : ""
      }`
    )
      .then(data => {
        if (!data) return;
        setOverdueTasks(modifyTasks(data).sort(handleSort));
        if (data.find(({ id }) => id === taskID)) handleModalOpen(taskID);
      })
      .finally(() => setOverdueLoading(false));
  };

  useEffect(() => {
    fetchOverdueTasks();
    fetchCompletedTasks();
    fetchCurrentTasks();
  }, [archived]);

  useEffect(() => {
    setTasks([...completedTasks, ...currentTasks, ...overdueTasks]);
    setInitialData(
      modifyTasks([...completedTasks, ...currentTasks, ...overdueTasks])
    );
  }, [completedTasks, currentTasks, overdueTasks]);

  useEffect(() => {
    fetchContacts();
    fetchUsers();
    fetchSites();
    fetchOrganisations();
  }, []);

  const filterByMembers = (users = [], groupFilter = []) => {
    if (!groupFilter.length) return true;
    else if (users.length > 3 && groupFilter.includes("group")) {
      return true;
    } else if (users.length <= 3 && groupFilter.includes("individual")) {
      return true;
    } else return false;
  };

  useEffect(() => {
    if (!groupFilter.length && !typeFilter.length) {
      setTasks(initialData);
    } else if (!groupFilter.length && typeFilter.length) {
      setTasks(
        initialData.filter(({ task_type }) => typeFilter.includes(task_type))
      );
    } else if (groupFilter.length && !typeFilter.length) {
      setTasks(
        initialData.filter(({ users }) => filterByMembers(users, groupFilter))
      );
    } else {
      setTasks(
        initialData.filter(
          ({ task_type, users }) =>
            typeFilter.includes(task_type) &&
            filterByMembers(users, groupFilter)
        )
      );
    }
  }, [groupFilter, typeFilter]);

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <>
        {modalOpen && (
          <Modal
            maxWidth="md"
            isOpen={modalOpen}
            submitable
            onClose={handleModalClose}
            className="mx-0"
            modalContent={
              <TaskDetails
                data={tasks.find(({ id }) => id === selectedTaskId) || {}}
                tasks={tasks}
                contacts={contacts}
                users={users}
                organisations={organisations}
                sites={sites}
                setTasks={setTasks}
                handleEditTask={handleEditTask}
                handleClose={handleModalClose}
                archived={archived}
              />
            }
          />
        )}
        {taskDetailsOpen && (
          <Modal
            maxWidth="md"
            isOpen={taskDetailsOpen}
            submitable
            onClose={handleTaskDetailsClose}
            modalContent={
              <TaskForm
                data={tasks.find(({ id }) => id === selectedTaskId) || {}}
                selectedTaskStatusChange={selectedTaskStatusChange}
                handleClose={handleTaskDetailsClose}
                handleSubmit={
                  selectedTaskStatusChange ? handleEditTask : handleAddTask
                }
                contacts={contacts}
                users={users}
                organisations={organisations}
                sites={sites}
              />
            }
          />
        )}
        <div className="d-flex justify-content-end align-items-center mb-5">
          <div className="w-50 mr-3">
            <AutocompleteMultiple
              name="organisation_id"
              placeholder="Filter Tasks"
              setValue={handleSearch}
              options={searchOptions}
              defaultValueField="value"
              size="medium"
            />
          </div>
          <ListButton
            label="New Task"
            className="px-15 py-5"
            size="large"
            onClick={handleTaskDetailsOpen}
          />
        </div>
        <ArchiveCheckbox
          archivedLoading={archivedLoading}
          archived={archived}
          showArchived={showArchived}
        />
        <div className="d-flex justify-content-between">
          {columnsMap.map(({ title, status }, i) => (
            <TaskColumn
              key={title}
              title={title}
              data={tasks.filter(task => task.status === status)}
              contacts={contacts}
              setTasks={setTasks}
              tasks={tasks}
              handleModalOpen={handleModalOpen}
              handleStatusChange={handleStatusChange}
              selectedTaskStatusChange={selectedTaskStatusChange}
              completedLoading={completedLoading}
              currentLoading={currentLoading}
              overdueLoading={overdueLoading}
            />
          ))}
        </div>
      </>
    </DndProvider>
  );
};
