import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";
import { ListButton } from "../../components/ListButton";
import { Skeleton } from "@material-ui/lab";

export const Location = withScriptjs(
  withGoogleMap(({ handleClose }) => {
    const google = window.google;
    const geocoder = new google.maps.Geocoder();

    const { values, setFieldValue } = useFormikContext();

    const {
      physical_address,
      physical_city,
      physical_state,
      physical_postcode,
    } = values;

    const address = [
      physical_address,
      physical_city,
      physical_state,
      physical_postcode,
    ].join(", ");

    const [markerInfo, setMarkerInfo] = useState(address);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (
        !physical_address ||
        !physical_city ||
        !physical_state ||
        !physical_postcode
      )
        return;
      setLoading(true);
      geocoder.geocode({ address }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const latitude = results[0].geometry.location.lat();
          const longitude = results[0].geometry.location.lng();
          latitude && setFieldValue("physical_latitude", latitude);
          longitude && setFieldValue("physical_longitude", longitude);
        }
        setLoading(false);
      });
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [physical_address, physical_city, physical_state, physical_postcode]);

    const handleDrop = (e) => {
      if (!e) return;
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      setFieldValue("physical_latitude", lat);
      setFieldValue("physical_longitude", lng);
      geocoder.geocode({ location: { lat, lng } }).then((res) => {
        if (!res) return;
        const address = res.results[0].address_components;
        if (!address) return;
        const postcode = (
          address.find((item) => item.types.includes("postal_code")) || {}
        ).long_name;
        const city = (
          address.find((item) => item.types.includes("locality")) || {}
        ).long_name;
        const state = (
          address.find((item) =>
            item.types.includes("administrative_area_level_1")
          ) || {}
        ).short_name;
        const street = (
          address.find((item) => item.types.includes("route")) || {}
        ).long_name;
        const building = (
          address.find((item) => item.types.includes("street_number")) || {}
        ).long_name;
        setFieldValue("physical_postcode", postcode || "");
        setFieldValue("physical_state", state || "");
        setFieldValue("physical_city", city || "");
        if (building && street) {
          setFieldValue("physical_address", [building, street].join(", "));
        }
        setMarkerInfo(res.results[0].formatted_address);
      });
    };

    if (!values.physical_latitude || !values.physical_longitude || loading) {
      return <Skeleton variant="rect" width={"100%"} height={400} />;
    }
    return (
      <>
        <GoogleMap
          defaultZoom={15}
          defaultCenter={{
            lat: values.physical_latitude,
            lng: values.physical_longitude,
          }}
        >
          {
            <MarkerWithLabel
              position={{
                lat: values.physical_latitude,
                lng: values.physical_longitude,
              }}
              labelAnchor={new google.maps.Point(150, 70)}
              labelStyle={{
                backgroundColor: "#ede7e6",
                fontSize: "16px",
                padding: "4px",
                border: "1px solid black",
                borderRadius: "4px",
              }}
              draggable={true}
              onDragEnd={handleDrop}
            >
              <div>{markerInfo}</div>
            </MarkerWithLabel>
          }
        </GoogleMap>
        <div className="my-5 d-flex justify-content-end mr-4">
          <div>
            <ListButton
              label="OK"
              size="large"
              onClick={handleClose}
              variant="outlined"
              text="#407A28"
              boxShadow={false}
            />
          </div>
        </div>
      </>
    );
  })
);
