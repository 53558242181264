/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { TableSortLabel } from "@material-ui/core";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  TASKS_SEARCHMAP,
  TASK_HEADINGS,
  TASKS_SEARCH_HELPER,
} from "./constants";
import { modifyTaskData, modifyTasks, renderTasksRow } from "./helpers";
import {
  handleSearch,
  objectCheck,
  removeEmptyFields,
} from "../../../ui/helpers";
import {
  getOrganisationTasks,
  getOrganisationTasksCount,
  getOrganisationTasksAutocomplete,
} from "../../modules/Organisations/_api";
import { ServerSearchBar } from "../../../ui/structures/ServerSearchBar";
import { uniq } from "lodash";
import { Modal } from "../../../ui/components/Modal";
import { TaskDetails } from "../../modules/Tasks/TaskDetails";
import { TaskForm } from "../../modules/Tasks/TaskForm";
import {
  addTask,
  addTaskUser,
  modifyTask,
  modifyTaskUser,
} from "../../modules/Tasks/_api";
import { info } from "../../../helpers/toasts";
import { sortHeaderStyle } from "../../constants";
import { ListButton } from "../../../ui/components/ListButton";
import { Loader } from "../../../ui/components/Loader";

export const Tasks = ({
  data = [],
  organisationId,
  setTasks,
  users = [],
  sites = [],
  contacts = [],
  organisations = [],
  isContactTasks,
  isSiteTasks,
}) => {
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [reviewedComponentID, setReviewedComponentID] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [field, setField] = useState("created_at");
  const [direction, setDirection] = useState("desc");
  const [newTaskOpen, setNewTaskOpen] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const handleNewTaskOpen = () => setNewTaskOpen(true);
  const handleNewTaskClose = () => setNewTaskOpen(false);

  const handleDetailsModalClose = () => setDetailsModalOpen(false);

  const handleEditOpen = (id) => {
    setReviewedComponentID(id);
    setEditModalOpen(true);
  };
  const handleEditClose = () => setEditModalOpen(false);

  const getType = () => {
    if (isContactTasks) return "contact";
    else if (isSiteTasks) return "site";
    else return "organisation";
  };

  const fetchTasks = () => {
    setLoading(true);
    request(
      getOrganisationTasks,
      organisationId,
      search,
      field,
      field && direction,
      page,
      pageSize,
      getType()
    )
      .then((data) => data && setTasks(modifyTasks(data)))
      .finally(() => setLoading(false));
  };

  const fetchContactsAutocomplete = (
    autocompleteValue,
    autocompleteField,
    searchFields
  ) => {
    if (autocompleteValue === "") return;
    setAutocompleteLoading(true);
    request(
      getOrganisationTasksAutocomplete,
      organisationId,
      80,
      autocompleteField,
      autocompleteValue,
      searchFields
    ).then((data) => {
      if (data && Array.isArray(data) && data.length && objectCheck(data)) {
        setAutocompleteData(uniq(data.map((item) => String(item))));
      } else {
        setAutocompleteData(["No option found"]);
      }
      setAutocompleteLoading(false);
    });
  };

  useEffect(() => {
    fetchTasks();
  }, [search, field, direction, page, pageSize]);

  useEffect(() => {
    request(getOrganisationTasksCount, organisationId, search).then(
      (data) => data && setTotalRecords(data)
    );
  }, [search]);

  const resetSearch = () => {
    setSearch("");
  };

  const handleExpand = (id) => () => {
    setDetailsModalOpen(true);
    setReviewedComponentID(id);
  };

  const handleDataUpdate = (item = {}) => {
    const index = data.findIndex((task) => (task || {}).id === (item || {}).id);
    setTasks((state) => [
      ...state.slice(0, index),
      item,
      ...state.slice(index + 1),
    ]);
  };

  const handleEditTask = (values, { setSubmitting }) => {
    const { user_id, ...taskValues } = values;
    setActionLoading(true);
    handleEditClose();
    request(modifyTask, reviewedComponentID, taskValues)
      .then((data) => {
        if (!data && !user_id) {
          setActionLoading(false);
          return;
        }
        request(
          modifyTaskUser,
          data.id,
          user_id.map((id) => ({ id: id }))
        )
          .then((ids) => {
            if (!ids) return;
            const payload = {
              ...data,
              users: users
                .filter(({ value }) => user_id.includes(value))
                .map((user) => ({ ...user, name: user.label, id: user.value })),
            };
            handleDataUpdate(modifyTaskData(payload, data, setTasks));
            info("Task details have been updated!");
          })
          .finally(() => setActionLoading(false));
      })
      .finally(() => setSubmitting(false));
  };

  const handleCreateTask = (values, { setSubmitting }) => {
    const { user_id, ...taskValues } = values;
    removeEmptyFields(taskValues);
    setActionLoading(true);
    handleNewTaskClose();
    request(addTask, taskValues)
      .then((data) => {
        if (!data && !user_id) {
          setActionLoading(false);
          return;
        }
        request(
          addTaskUser,
          data.id,
          user_id.map((id) => ({ id }))
        )
          .then((ids) => {
            if (!ids) return;
            const payload = {
              ...data,
              users: users
                .filter(({ value }) => user_id.includes(value))
                .map((user) => ({ ...user, name: user.label })),
            };
            setTasks((state) => [...state, modifyTaskData(payload)]);
          })
          .finally(() => {
            setActionLoading(false);
          });
      })
      .finally(() => setSubmitting(false));
  };

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) =>
      key === "badges" ? (
        <th key={key} style={sortHeaderStyle} className="px-5 text-nowrap">
          <span>{header}</span>
        </th>
      ) : (
        <th
          key={key}
          style={sortHeaderStyle}
          className="px-5 text-nowrap"
          onClick={() => {
            setDirection(
              key !== field ? "desc" : direction === "desc" ? "asc" : "desc"
            );
            setField(key);
          }}
        >
          <span style={{ cursor: "pointer" }}>{header}</span>
          {key === field && <TableSortLabel active direction={direction} />}
        </th>
      )
    );
  }

  return (
    <>
      <Loader
        isOpen={actionLoading}
        maxWidth="xs"
        disableBackdropClick
        disableEscapeKeyDown
      />
      <Modal
        maxWidth="md"
        isOpen={detailsModalOpen}
        onClose={handleDetailsModalClose}
        modalContent={
          <TaskDetails
            data={data.find(({ id }) => id === reviewedComponentID)}
            contacts={contacts}
            users={users}
            organisations={organisations}
            sites={sites}
            editable={false}
          />
        }
      />
      <Modal
        maxWidth="md"
        isOpen={editModalOpen}
        onClose={handleEditClose}
        submitable
        modalContent={
          <TaskForm
            data={data.find(({ id }) => id === reviewedComponentID)}
            handleClose={handleEditClose}
            handleSubmit={handleEditTask}
            contacts={contacts}
            users={users}
            organisations={organisations}
            sites={sites}
          />
        }
      />
      <Modal
        maxWidth="md"
        isOpen={newTaskOpen}
        onClose={handleNewTaskClose}
        submitable
        modalContent={
          <TaskForm
            handleClose={handleNewTaskClose}
            handleSubmit={handleCreateTask}
            contacts={contacts}
            users={users}
            organisations={organisations}
            sites={sites}
            organisationId={organisationId}
          />
        }
      />
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <div className="mb-10">
              <ServerSearchBar
                className="mb-5"
                onSearch={(data) =>
                  handleSearch(data, TASKS_SEARCHMAP, setSearch, setPage)
                }
                keyMap={TASKS_SEARCH_HELPER}
                loading={autocompleteLoading}
                currentSearchList={autocompleteData}
                fetchAutocompleteFunction={fetchContactsAutocomplete}
                placeholder="Filter Tasks..."
                clearSearch={resetSearch}
                searchMap={TASKS_SEARCHMAP}
                searchFields={search}
                contentLoading={loading}
              />
            </div>
            <div className="mt-5 mb-10 text-right">
              <ListButton label="New Task" onClick={handleNewTaskOpen} />
            </div>
            <Datagrid
              data={data}
              headings={TASK_HEADINGS}
              renderRow={(headings, item) =>
                renderTasksRow(headings, item, () => handleEditOpen(item.id))
              }
              loading={loading}
              expandable
              handleExpand={handleExpand}
              expandHeader=""
              expandIcon={<MoreHorizIcon />}
              serverPage={page}
              setServerPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              isServerPagination
              totalRecords={totalRecords}
              renderHeaderWithSorting={renderHeaderWithSorting}
            />
          </div>
        </div>
      </div>
    </>
  );
};
