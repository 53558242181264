import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import React, { useState, useEffect } from "react";
import { useFetch } from "../../../hooks/fetch.hook";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { modifyTrainingsArray, renderTrainingsRow } from "./helpers";
import { getTrainings } from "./_api";

const HEADINGS = [
  ["course", "Training Course"],
  ["created_at", "Start Date"],
  ["completed_at", "Completion Date"],
  ["expires", "Expiry Date"],
  ["progress_percentage", "Progress"],
];

export const ContactTrainings = ({ contact = {} }) => {
  const { request } = useFetch();
  const { first_name, last_name } = contact;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    request(getTrainings, contact.id)
      .then((data) => {
        if (!data) return;
        setData(modifyTrainingsArray(data));
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row justify-content-center bg-white py-15 px-10">
      <div className="col-12">
        <div className="mb-15">
          <ListHeader title={`${[first_name, last_name].join(" ")} Training Profile`} />
        </div>
        <Datagrid
          data={data}
          loading={loading}
          headings={HEADINGS}
          renderRow={renderTrainingsRow}
        />
      </div>
    </div>
  );
};
