import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { getRegion } from "./_redux/selectors";
import { useFetch } from "../../../../hooks/fetch.hook";
import { addRegion, getRegionById, modifyRegion } from "./_api";
import { RegionDetailsSchema } from "../../../../helpers/schemas";
import { SubmitActions } from "../../../../ui/structures/SubmitActions";
import { Loader } from "../../../../ui/components/Loader";
import { info } from "../../../../helpers/toasts";
import { modifyEmptyFields } from "../../../../ui/helpers";

export const Card = ({
  match: {
    params: { id },
  },
}) => {
  const { request } = useFetch();
  const data = useSelector(getRegion(id));

  const [cardData, setCardData] = useState({});
  const [submitAction, setSubmitAction] = useState("Save");
  const [loading, setLoading] = useState(false);

  const initialValues = {
    short_name: cardData.short_name || "",
    long_name: cardData.long_name || "",
    definition: cardData.definition || "",
  };

  useEffect(() => {
    if (id && !data) {
      request(getRegionById, id).then((data) => data && setCardData(data));
    } else {
      data && setCardData(data);
    }
    // eslint-disable-next-line
  }, []);

  const history = useHistory();

  const handleClose = () => history.push("/settings/regions");

  const createRegion = (values, setSubmitting) => {
    request(addRegion, values)
      .then((data) => data && history.push("/settings/regions"))
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  const updateRegion = (values, setSubmitting) => {
    request(modifyRegion, values, id)
      .then((data) => {
        if (!data) return;
        info("Region details have been updated!");
        submitAction === "Save & Exit" && history.push("/settings/regions");
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    modifyEmptyFields(values)
    id ? updateRegion(values, setSubmitting) : createRegion(values, setSubmitting);
  };

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  return (
    <div className="border border-secondary bg-white p-10">
      {loading && (
        <Loader isOpen={loading} maxWidth="xs" disableBackdropClick disableEscapeKeyDown />
      )}
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={RegionDetailsSchema}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <>
            <h3 className="mb-10">
              <strong>{id ? cardData.long_name : "Add Region"}</strong>
            </h3>
            <div className="row justify-content-between">
              <div className="col-8">
                <MappedInput label="Long Name" name="long_name" data-testid="long_name" />
                <MappedInput label="Short Name" name="short_name" data-testid="short_name" />
                <MappedInput
                  label="Region Definition"
                  name="definition"
                  multiline
                  rows={6}
                  data-testid="definition"
                />
              </div>
            </div>
            <SubmitActions
              id={id}
              handleCancel={handleClose}
              disabled={isSubmitting}
              submitAction={submitAction}
              handleSubmit={handleSubmit}
              setSubmitAction={setSubmitAction}
            />
          </>
        )}
      </Formik>
    </div>
  );
};
