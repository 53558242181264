import React from "react";
import { MappedDatePicker } from "@bit/the-glue.frontendcomponents.datepicker";
import { ColumnChart } from "./ColumnChart";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { booleanOptions, surfaceTypeOptions } from "./constants";
import "react-circular-progressbar/dist/styles.css";
import { get } from "lodash";
import { Skeleton } from "@material-ui/lab";

export const groupedArgs = {
  type: "grouped",
  horizontal: false,
  categories: ["Plastic", "Steel"],
  color: "#71BF44",
  secondBarColor: "#FECA0A",
  titleColor: "#263238",
  title: "Statistics",
  titlePosition: "left",
};

export const Chemclear = ({ name, chartData = {}, chartLoading }) => {
  const currentYear = new Date().getFullYear();
  const currentPlasticAmount = get(chartData, `${currentYear}.plastic`, 0);
  const currentSteelAmount = get(chartData, `${currentYear}.steel`, 0);
  const lastPlasticAmount = get(chartData, `${currentYear - 1}.plastic`, 0);
  const lastSteelAmount = get(chartData, `${currentYear - 1}.steel`, 0);

  const groupedSeries = [
    {
      name: "This Year",
      data: [currentPlasticAmount, currentSteelAmount],
    },
    {
      name: "Last Year",
      data: [lastPlasticAmount, lastSteelAmount],
    },
  ];

  return (
    <div className="border p-5">
      <div className="row justify-content-between">
        <h4 className="col-4">{name} Profile</h4>
        <h4 className="col-2">Planned Collection</h4>
        <h4 className="mb-5 col-6">Collection Statistics</h4>
      </div>
      <div className="row justify-content-between">
        <div className="col-4">
          <div>
            <MappedSelect
              name="surface_type"
              label="CC Surface Type"
              options={surfaceTypeOptions}
            />

            <MappedSelect
              name="cc_dry_weather_access"
              label="Dry Weather Access"
              options={booleanOptions}
            />
            <MappedSelect name="cc_nearby_waterways" label="Waterways" options={booleanOptions} />
            <MappedSelect
              name="cc_forklift_available"
              label="Forklift Available"
              options={booleanOptions}
            />
            <MappedSelect name="cc_level_surface" label="Level Surface" options={booleanOptions} />
          </div>
        </div>
        <div className="col-2">
          <MappedDatePicker name="cc_collection_date_from" label="Start Date" />

          <MappedDatePicker name="cc_collection_date_to" label="End Date" />
        </div>
        <div className="col-6 border text-center">
          {chartLoading ? (
            <Skeleton variant="rect" width={"100%"} height={320} />
          ) : (
            <ColumnChart options={groupedArgs} series={groupedSeries} id="sales" height="307" />
          )}
        </div>
      </div>
    </div>
  );
};
