import { Skeleton } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AVAILABLE_ROLES } from "../../../app/constants";
import { getTopReimbursements } from "../../../app/modules/Dashboard/_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { ListButton } from "../../components/ListButton";
import { formatPrice } from "../../helpers";
import { AccessControl } from "../AccessControl";

export const ReimbursementsTile = ({
  isCollectionDashboard,
  isProcessorDashboard,
}) => {
  const history = useHistory();
  const { request } = useFetch();
  const [loading, setLoading] = useState(false);
  const [inspectionTotal, setInspectionTotal] = useState(0);
  const [processorTotal, setProcessorTotal] = useState(0);

  useEffect(() => {
    setLoading(true);
    request(getTopReimbursements)
      .then((data) => {
        if (!data) return;
        setInspectionTotal(data.inspection);
        setProcessorTotal(data.processing);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClaim = () => {
    if (isCollectionDashboard && isProcessorDashboard) {
      if (inspectionTotal === 0) history.push("/drummuster/processing");
      else history.push("/drummuster/claims");
    } else if (isCollectionDashboard) {
      history.push("/drummuster/claims");
    } else if (isProcessorDashboard) {
      history.push("/drummuster/processing");
    }
  };

  if (loading) return <Skeleton variant="rect" width={"100%"} height={370} />;

  return (
    <div
      className="bg-white p-7"
      style={{
        boxShadow:
          " 0px 6px 5px rgba(0, 0, 0, 0.24), 0px 9px 18px rgba(0, 0, 0, 0.18)",
      }}
    >
      <div
        style={{ fontSize: "16px", fontWeight: 500 }}
        className="text-center mt-5 text-dark"
      >
        You are eligible for a reimbursement of approximately
      </div>
      {isCollectionDashboard && isProcessorDashboard ? (
        <div className="d-flex justify-content-between px-6 mt-15 mb-21">
          <div className="text-center text-dark">
            <p style={{ fontSize: "50px" }}>
              <strong>{formatPrice(inspectionTotal)}</strong>
            </p>
            <p>in inspection fees</p>
          </div>
          <p style={{ fontSize: "50px" }} className="text-dark">
            <strong>&amp;</strong>
          </p>
          <div className="text-center text-dark">
            <p style={{ fontSize: "50px" }}>
              <strong>{formatPrice(processorTotal)}</strong>
            </p>
            <p>in processing fees</p>
          </div>
        </div>
      ) : (
        <>
          <AccessControl desiredRoles={[AVAILABLE_ROLES.CA_ACCESS]}>
            <div className="text-center mt-10 mb-21">
              <p style={{ fontSize: "60px" }}>
                <strong>{formatPrice(inspectionTotal)}</strong>
              </p>
              <p>in inspection fees</p>
            </div>
          </AccessControl>
          <AccessControl desiredRoles={[AVAILABLE_ROLES.PROCESSOR]}>
            <div className="text-center mt-10 mb-21">
              <p style={{ fontSize: "60px" }}>
                <strong>{formatPrice(processorTotal)}</strong>
              </p>
              <p>in processing fees</p>
            </div>
          </AccessControl>
        </>
      )}
      <div className="text-right">
        <ListButton label="Claim" size="large" onClick={handleClaim} />
      </div>
    </div>
  );
};
