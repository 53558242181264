/* eslint-disable no-restricted-imports */
import React from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { withStyles } from "@material-ui/core/styles";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteOutlineRounded";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#E8F5E1",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#033300",
      },
    },
  },
}))(MenuItem);

const StyleMenuIconn = withStyles((theme) => ({
  root: {
    minWidth: "30px",
  },
}))(ListItemIcon);

export default function Options({ handleDelete, handleEdit, id }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div onClick={handleClick}>
        <span className="cursor-pointer">
          <MoreVertIcon />
        </span>
      </div>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={() => handleEdit(id)}>
          <StyleMenuIconn>
            <EditIcon fontSize="small" />
          </StyleMenuIconn>
          <ListItemText primary="Edit Comment" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => handleDelete(id)}>
          <StyleMenuIconn>
            <DeleteIcon fontSize="small" />
          </StyleMenuIconn>
          <ListItemText primary="Delete Comment" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
