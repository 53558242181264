import React, { useEffect, useState, useRef } from "react";
import { Skeleton } from "@material-ui/lab";
import { Autocomplete } from "../../../../../ui/components/Autocomplete";
import { useFetch } from "../../../../../hooks/fetch.hook";
import { getOrganisationInspectors } from "../../../Sites/_api";

export const InspectorSelect = ({
  inspectorsOptions = [],
  setInspectors,
  handleUpdateField,
  data = {},
  sites = [],
  defaultValue,
  rootStyle,
}) => {
  const { request } = useFetch();
  const firstUpdate = useRef(true);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!data.site_id) return;
    const selectedSite = sites.find((item) => (item || {}).id === data.site_id);
    if (!selectedSite || !(selectedSite || {}).organisation_id) return;
    setLoading(true);
    request(getOrganisationInspectors, selectedSite.organisation_id)
      .then((data) => {
        if (!data) return;
        setInspectors(data);
        if (firstUpdate.current) {
          firstUpdate.current = false;
          return;
        }
        handleUpdateField("inspector_id", "");
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.site_id]);

  return (
    <>
      {loading ? (
        <Skeleton variant="rect" width={"100%"} height={38} />
      ) : (
        <Autocomplete
          name="inspector_id"
          placeholder="Select Inspector"
          setValue={handleUpdateField}
          size="small"
          defaultValueField="value"
          defaultValue={defaultValue}
          options={inspectorsOptions}
          inputStyle={{}}
          rootStyle={rootStyle}
        />
      )}
    </>
  );
};
