import { get } from "lodash";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { ListButton } from "../../../../ui/components/ListButton";
import ToolTip from "../../../../ui/components/ToolTip";
import {
  formatDate,
  formatPrice,
  isDate,
  isPrice,
  isStatus,
} from "../../../../ui/helpers";
import { getTotalContainers } from "../Inspections/helpers";
import { totalStyle } from "./constants";
import { calculateInvoiceTotalGST } from "../../ReimbursementsApprovals/helpers";

export const rendeTotalVolumeRow = (data, selected = []) => {
  const sum = data
    .filter(({ id }) => selected.includes(id))
    .reduce((acc, value) => acc + Number(value.total), 0);
  return (
    <tr className="border-bottom" style={totalStyle}>
      <td colSpan="3"></td>
      <td className="no-line border-0 align-middle px-5 pt-10 pb-1">
        Sub-Total
      </td>
      <td className="pt-10 pb-1 pl-5">{sum}</td>
    </tr>
  );
};

export function modifyClaim(item = {}) {
  return {
    ...item,
    site_name: get(item, "site.name", ""),
    organisation_name: get(item, "organisation.business_name", ""),
    eccd: get(item, "eccd"),
    material: getMaterialValue(item),
    type: item.type.charAt(0).toUpperCase() + item.type.slice(1),
    total_value_gst: calculateInvoiceTotalGST(item),
    total_value: item.total_value,
    status:
      item.reject_comment && item.status === "waiting_for_invoice"
        ? "rejected"
        : item.status.split("_").join(" "),
  };
}

export function modifyClaimsArray(list = []) {
  return list.map((item) => modifyClaim(item));
}

const getMaterialValue = (item = {}) => {
  if (!Array.isArray(item.ccds)) return "";
  const ccd = item.ccds || [];
  const steel = ccd.filter(({ material }) => material === "steel");
  const plastic = ccd.filter(({ material }) => material === "plastic");
  if (steel.length && !plastic.length) return "Steel";
  else if (plastic.length && !steel.length) return "Plastic";
  else if (steel.length && plastic.length) return "Steel & Plastic";
  else return "";
};

export function modifyDrop(item = {}) {
  let ccds;
  if (Array.isArray(item.ccds)) {
    ccds = item.ccds;
  } else ccds = [];
  return {
    ...item,
    site_name: get(item, "site.name"),
    source: item.source.charAt(0).toUpperCase() + item.source.slice(1),
    total: getTotalContainers(item),
    eccd: get(item, "eccd"),
    consultant: [
      get(item, "organisation.consultant.first_name", ""),
      get(item, "organisation.consultant.last_name", ""),
    ].join(" "),
    material: getMaterialValue(item),
    plastic_0:
      (
        ccds.find(
          ({ size, material }) => size === "20" && material === "plastic"
        ) || {}
      ).quantity || "",
    plastic_1:
      (
        ccds.find(
          ({ size, material }) => size === "20-25" && material === "plastic"
        ) || {}
      ).quantity || "",
    plastic_2:
      (
        ccds.find(
          ({ size, material }) => size === "26-120" && material === "plastic"
        ) || {}
      ).quantity || "",
    plastic_3:
      (
        ccds.find(
          ({ size, material }) => size === "121-205" && material === "plastic"
        ) || {}
      ).quantity || "",
    steel_0:
      (
        ccds.find(
          ({ size, material }) => size === "20" && material === "steel"
        ) || {}
      ).quantity || "",
    steel_1:
      (
        ccds.find(
          ({ size, material }) => size === "20-25" && material === "steel"
        ) || {}
      ).quantity || "",
    steel_2:
      (
        ccds.find(
          ({ size, material }) => size === "26-120" && material === "steel"
        ) || {}
      ).quantity || "",
    steel_3:
      (
        ccds.find(
          ({ size, material }) => size === "121-205" && material === "steel"
        ) || {}
      ).quantity || "",
  };
}

export function modifyDropsArray(list = []) {
  return list.map((item) => modifyDrop(item));
}

const tranformDate = (date) => {
  return date ? formatDate(date) : null;
};

const getStatusColor = (item = {}) => {
  if (item.status === "active") {
    return "#7CB342";
  } else if (item.status === "rejected") return "#F44336";
  else return "#FF9800";
};

const getButtonColor = (item = {}) => {
  if (item.status === "rejected") {
    return "#FF9800";
  } else return "#64A70B";
};

const renderStatus = (item = {}) => {
  if (item.status === "rejected") {
    return (
      <span className="d-flex align-items-center">
        {item.status}
        <span className="mt-1 ml-2">
          {
            <ToolTip
              title={item.reject_comment || "No reject comment"}
              arrow
              interactive
              placement="top"
              content={<HelpOutlineIcon fontSize="small" />}
            />
          }
        </span>
      </span>
    );
  } else return item.status;
};

export function renderClaimsRow(
  headings,
  item,
  handleModalOpen,
  handleRejectedItem,
  isCAUser
) {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-5 "text-dark" ${
        isStatus(key) ? "text-uppercase" : ""
      }`}
    >
      {isDate(key) ? (
        tranformDate(item[key])
      ) : isPrice(key) ? (
        <div className="text-right">{formatPrice(item[key])}</div>
      ) : key === "indicator" ? (
        <div
          style={{
            background: getStatusColor(item),
            width: 20,
            height: 20,
            borderRadius: "50%",
            margin: "0 auto",
          }}
        ></div>
      ) : key === "invoice_upload" ? (
        <ListButton
          label={item.status === "rejected" ? "Resubmit" : "Upload"}
          data-testid="invoice-upload"
          onClick={() =>
            item.status === "rejected"
              ? handleRejectedItem(item)
              : handleModalOpen(item.id)
          }
          disabled={item.status === "active" || item.status === "admin review"}
          background={getButtonColor(item)}
        />
      ) : key === "status" ? (
        renderStatus(item)
      ) : key === "grouped" ? (
        String(item[key]).toUpperCase()
      ) : (
        item[key]
      )}
    </td>
  ));
}

export const setCCDPayload = (
  plastic_0,
  plastic_1,
  plastic_2,
  plastic_3,
  steel_0,
  steel_1,
  steel_2,
  steel_3
) => {
  return [
    { size: "20", material: "plastic", quantity: Number(plastic_0) },
    {
      size: "20-25",
      material: "plastic",
      quantity: Number(plastic_1),
    },
    {
      size: "26-120",
      material: "plastic",
      quantity: Number(plastic_2),
    },
    {
      size: "121-205",
      material: "plastic",
      quantity: Number(plastic_3),
    },
    { size: "20", material: "steel", quantity: Number(steel_0) },
    { size: "20-25", material: "steel", quantity: Number(steel_1) },
    { size: "26-120", material: "steel", quantity: Number(steel_2) },
    { size: "121-205", material: "steel", quantity: Number(steel_3) },
  ].filter(({ quantity }) => quantity);
};

export const getInvoicePayload = (type = "", values = {}) => {
  const inspectionPayload = {
    inspection_misc_charge: values.inspection_misc_charge
      ? Number(values.inspection_misc_charge)
      : 0,
    inspection_fees: values.inspection_fees
      ? Number(values.inspection_fees)
      : 0,
    code: values.code,
    date: values.date,
  };

  const processingPayload = {
    processing_misc_charge: values.processing_misc_charge
      ? Number(values.processing_misc_charge)
      : 0,
    processing_fees: values.processing_fees
      ? Number(values.processing_fees)
      : 0,
    code: values.code,
    date: values.date,
  };

  const combinedPayload = {
    ...inspectionPayload,
    ...processingPayload,
    code: values.code,
    date: values.date,
  };

  if (type.toLocaleLowerCase() === "Inspection".toLocaleLowerCase()) {
    return inspectionPayload;
  } else if (type.toLocaleLowerCase() === "Processing".toLocaleLowerCase()) {
    return processingPayload;
  } else if (type.toLocaleLowerCase() === "Combined".toLocaleLowerCase()) {
    return combinedPayload;
  }
};
