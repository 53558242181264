import React, { useState, useEffect } from "react";
import { ListButton } from "../../../../ui/components/ListButton";
import { itemStyle } from "../constants";
import MoodleIcon from "../static/Moodle.png";
import { useFetch } from "../../../../hooks/fetch.hook";
import { info } from "../../../../helpers/toasts";
import { syncTrainings, syncUsersProgress } from "../../Contacts/_api";
import { Loader } from "../../../../ui/components/Loader";
import { MoodleConfig } from "./MoodleConfig";
import { Modal } from "../../../../ui/components/Modal";
import { Skeleton } from "@material-ui/lab";
import { getTrainings } from "../../Settings/Badges/_api";

export const Moodle = () => {
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [trainings, setTrainings] = useState([]);
  const [trainingsLoading, setTrainingsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  useEffect(() => {
    setTrainingsLoading(true);
    request(getTrainings)
      .then(data => data && setTrainings(data))
      .finally(() => setTrainingsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSyncTrainings = () => {
    setLoading(true);
    request(syncTrainings)
      .then(data => data && info("Syncing trainings in the background..."))
      .finally(() => setLoading(false));
  };

  const syncUserPrgoress = () => {
    setLoading(true);
    request(syncUsersProgress)
      .then(data => data && info("Syncing users progress in the background..."))
      .finally(() => setLoading(false));
  };
  return (
    <>
      <Modal
        isOpen={modalOpen}
        submitable
        onClose={handleModalClose}
        maxWidth="lg"
        disableBackdropClick
        disableEscapeKeyDown
        className="mx-1"
        modalContent={
          <MoodleConfig
            handleClose={handleModalClose}
            trainings={trainings}
            setTrainings={setTrainings}
          />
        }
      />
      <Loader isOpen={loading} maxWidth="xs" />
      <div style={itemStyle} className="bg-white p-7 mt-10">
        <div className="text-center mb-20">
          <img src={MoodleIcon} alt="logo" />
        </div>
        <h3 className="mb-2  text-dark">
          <strong>Moodle LMS</strong>
        </h3>
        <div style={{ fontSize: "14px" }}>
          Connect your contacts training records for visibility.
        </div>
        <div className=" d-flex justify-content-between mt-5">
          <ListButton
            label="Sync Trainings"
            size="small"
            variant="outlined"
            boxShadow={false}
            text="#407A28"
            onClick={handleSyncTrainings}
            disabled={trainingsLoading}
          />
          <ListButton
            label="Sync Users Progress"
            size="small"
            variant="outlined"
            boxShadow={false}
            text="#407A28"
            onClick={syncUserPrgoress}
            disabled={trainingsLoading}
          />
        </div>
        <div className="mt-18 d-flex justify-content-between">
          <ListButton label="Connected" size="small" />
          {trainingsLoading ? (
            <Skeleton variant="rect" width={"25%"} height={30} />
          ) : (
            <ListButton
              label="Configure"
              size="small"
              onClick={handleModalOpen}
            />
          )}
        </div>
      </div>
    </>
  );
};
