import React, { forwardRef } from "react";
import { Input as MuiInput } from "@material-ui/core";

export const SearchInput = forwardRef(function(
  { wrapperClassName, inputClassName, inputGroupClassName, handleEnterPress, ...other },
  ref
) {
  return (
    <label className={`${wrapperClassName} mb-0 w-100`}>
      <div className={`input-group border border-secondary rounded px-0 ${inputGroupClassName}`}>
        <MuiInput
          className={`form-control form-control-solid bg-white ${inputClassName} py-2`}
          disableUnderline
          inputRef={ref}
          data-testid="input"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.stopPropagation();
              handleEnterPress();
            }
          }}
          {...other}
        />
      </div>
    </label>
  );
});
