import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { getPackSize } from "./_redux/selectors";
import { useFetch } from "../../../../hooks/fetch.hook";
import { addPackSize, getPackSizeById, modifyPackSize } from "./_api";
import { PacksDetailsSchema } from "../../../../helpers/schemas";
import { SubmitActions } from "../../../../ui/structures/SubmitActions";
import { info } from "../../../../helpers/toasts";
import { Loader } from "../../../../ui/components/Loader";
import { modifyEmptyFields } from "../../../../ui/helpers";

export const Card = ({
  match: {
    params: { id },
  },
}) => {
  const { request } = useFetch();
  const data = useSelector(getPackSize(id));

  const [cardData, setCardData] = useState({});
  const [submitAction, setSubmitAction] = useState("Save");
  const [loading, setLoading] = useState(false);

  const initialValues = {
    name: cardData.name || "",
  };

  useEffect(() => {
    if (id && !data) {
      request(getPackSizeById, id).then((data) => data && setCardData(data));
    } else {
      data && setCardData(data);
    }
    // eslint-disable-next-line
  }, []);

  const history = useHistory();

  const createPackSize = (values, setSubmitting) => {
    request(addPackSize, values)
      .then((data) => data && history.push("/settings/pack-sizes"))
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  const updatePackSize = (values, setSubmitting) => {
    request(modifyPackSize, values, id)
      .then((data) => {
        if (!data) return;
        info("Pack Size details have been updated!");
        submitAction === "Save & Exit" && history.push("/settings/pack-sizes");
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    modifyEmptyFields(values);
    id ? updatePackSize(values, setSubmitting) : createPackSize(values, setSubmitting);
  };

  const handleClose = () => history.push("/settings/pack-sizes");

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  return (
    <div className="border border-secondary bg-white p-10">
      {loading && (
        <Loader isOpen={loading} maxWidth="xs" disableBackdropClick disableEscapeKeyDown />
      )}
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={PacksDetailsSchema}
      >
        {({ handleSubmit, isSubmitting }) => (
          <>
            <h3 className="mb-10">
              <strong>{id ? cardData.name : "Add Pack Size"}</strong>
            </h3>
            <div className="row justify-content-between">
              <div className="col-8">
                <MappedInput label="Name" name="name" data-testid="name" />
              </div>
            </div>
            <SubmitActions
              id={id}
              handleCancel={handleClose}
              disabled={isSubmitting}
              submitAction={submitAction}
              handleSubmit={handleSubmit}
              setSubmitAction={setSubmitAction}
            />
          </>
        )}
      </Formik>
    </div>
  );
};
