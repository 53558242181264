import config from "react-global-configuration";

export const addPrice = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/prices`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getPrices = (
  search = "",
  archived = "",
  field,
  direction,
  page = 0,
  pageSize = 10
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/prices?${search && "&" + search}${
      (archived && "&status=all") || ""
    }${(field && "&order_by=" + field) || ""}${
      (direction && "&order_dir=" + direction) || ""
    }&page=${page}&page_size=${pageSize}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getPricesAmount = (search = "") => {
  return fetch(
    `${config.get("ApiUrl").Rest}/prices?count_only=y${
      search ? "&" + search : ""
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};

export const getPricesAutocomplete = (
  page_size = 80,
  autocompleteField,
  autocompleteText = "",
  field,
  direction,
  search,
  archived
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/prices?&page_size=${page_size}${
      (archived && "&status=all") || ""
    }${
      (autocompleteField &&
        "&distinct=" +
          autocompleteField +
          "&" +
          autocompleteField +
          "=_like(" +
          autocompleteText +
          ")_") ||
      ""
    }${(field && "&order_by=" + field) || ""}${
      (direction && "&order_dir=" + direction) || ""
    }${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getPriceById = (price_id) =>
  fetch(`${config.get("ApiUrl").Rest}/prices/${price_id}`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyPrice = (data, price_id) =>
  fetch(`${config.get("ApiUrl").Rest}/prices/${price_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const deletePrice = (price_id) =>
  fetch(`${config.get("ApiUrl").Rest}/prices/${price_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const addOrganisationPrice = (organisation_id, data) =>
  fetch(
    `${config.get("ApiUrl").Rest}/organisations/${organisation_id}/prices`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include",
    }
  );

export const getOrganisationPrices = (
  organisation_id,
  search = "",
  archived = "",
  field,
  direction
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/organisations/${organisation_id}/prices?${
      search && "&" + search
    }${(archived && "&status=all") || ""}${
      (field && "&order_by=" + field) || ""
    }${(direction && "&order_dir=" + direction) || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getOrganisationPriceById = (price_id, organisation_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/organisations/${organisation_id}/prices/${price_id}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const modifyOrganisationPrice = (data, price_id, organisation_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/organisations/${organisation_id}/prices/${price_id}`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include",
    }
  );

export const deleteOrganisationPrice = (price_id, organisation_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/organisations/${organisation_id}/prices/${price_id}`,
    {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
    }
  );
