import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { Formik } from "formik";
import { LinearProgress } from "@material-ui/core";
import * as React from "react";
import * as Yup from "yup";
import { ListButton } from "../ListButton";
import { IconButton } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState } from "react";
import { useFetch } from "../../../hooks/fetch.hook";
import { info } from "../../../helpers/toasts";
import { submitSupportEmail } from "./_api";

const initialValues = {
  message: "",
};

export function HelpForm({ hideForm }) {
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);

  const handleSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    request(submitSupportEmail, values)
      .then((data) => {
        if (!data) return;
        info("Request has been sent!");
        hideForm();
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  return (
    <div>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={Yup.object({
          message: Yup.string().required(),
        })}
        enableReinitialize
      >
        {({ handleSubmit, isSubmitting }) => (
          <div
            style={{ position: "fixed", bottom: 20, right: 20, minWidth: 350 }}
          >
            <div
              className="px-4 py-3"
              style={{
                background: "rgb(118, 173, 28",
                borderTop: "1px solid #c6ccc8",
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
              }}
            >
              <div style={{ position: "absolute", top: 0, right: 5 }}>
                <IconButton onClick={hideForm}>
                  <ExpandMoreIcon />
                </IconButton>
              </div>
              <div
                style={{ fontWeight: 700, fontSize: 15, textAlign: "center" }}
              >
                drumDrive
              </div>
            </div>
            <div className="border border-secondary bg-white p-10 rounded">
              <h4 className="mb-8 d-flex justify-content-center">
                Submit your message
              </h4>
              <MappedInput label="Message" name="message" multiline rows={12} />
              <div className="mt-10 d-flex justify-content-end">
                <ListButton
                  label="Submit"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                />
              </div>
              {loading ? (
                <div className="mt-5">
                  <LinearProgress color="secondary" />
                </div>
              ) : null}
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}
