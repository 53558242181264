import React, { useState, useEffect } from "react";
import { TableSortLabel } from "@material-ui/core";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { ServerSearchBar } from "../../../ui/structures/ServerSearchBar";
import { sortHeaderStyle } from "../../constants";
import { useFetch } from "../../../hooks/fetch.hook";
import { getTagsListAutocomplete } from "../Settings/Tags/_api";
import { uniq } from "lodash";
import { handleSearch, objectCheck } from "../../../ui/helpers";
import { searchMap, SEARCH_HELPER } from "./constants";
import { SCHEDULING_HEADINGS } from "./constants";
import { getSiteInventory, getSiteInventoryAmount } from "./_api";
import { modifySitesInventorySchedule, renderSiteInvetories } from "./helpers";
import { getSitesAutocomplete } from "../Sites/_api";
import { getRegionsAutocomplete } from "../Settings/Regions/_api";
import ToolTip from "../../../ui/components/ToolTip";

export const SiteSelect = ({ selected, setSelected, data = [], setData }) => {
  const { request } = useFetch();
  const [search, setSearch] = useState("");
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  const fetchSites = () => {
    setLoading(true);
    request(
      getSiteInventory,
      search,
      null,
      field,
      field && direction,
      page,
      pageSize,
      true
    )
      .then((data) => {
        if (!data) return;
        setData(modifySitesInventorySchedule(data));
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  const requestOptions = (
    autocompleteValue,
    autocompleteField,
    searchFields,
    archived
  ) => {
    if (autocompleteField === "tag") {
      return request(
        getTagsListAutocomplete,
        80,
        "name",
        autocompleteValue,
        "category=_like(Site)_"
      );
    } else if (autocompleteField === "organisation.region.long_name") {
      return request(
        getRegionsAutocomplete,
        80,
        "long_name",
        autocompleteValue
      );
    } else
      return request(
        getSitesAutocomplete,
        80,
        autocompleteField,
        autocompleteValue,
        field,
        field && direction,
        searchFields,
        archived
      );
  };

  const fetchSitesAutocomplete = (
    autocompleteValue,
    autocompleteField,
    searchFields,
    archived
  ) => {
    if (autocompleteValue === "") return;
    if (autocompleteField === "material") {
      setAutocompleteData(["plastic", "steel"]);
      return;
    }
    setAutocompleteLoading(true);
    requestOptions(
      autocompleteValue,
      autocompleteField,
      searchFields,
      archived
    ).then((data) => {
      if (data && Array.isArray(data) && data.length && objectCheck(data)) {
        setAutocompleteData(uniq(data.map((item) => String(item))));
      } else {
        setAutocompleteData(["No option found"]);
      }
      setAutocompleteLoading(false);
    });
  };

  useEffect(() => {
    fetchSites();
    // eslint-disable-next-line
  }, [search, field, direction, page, pageSize]);

  useEffect(() => {
    request(getSiteInventoryAmount, search, true).then(
      (data) => data && setTotalRecords(data)
    );
    // eslint-disable-next-line
  }, [search]);

  const resetSearch = () => {
    setSearch("");
  };

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) => (
      <th
        key={key}
        style={sortHeaderStyle}
        className="px-5 text-nowrap"
        onClick={() => {
          setDirection(
            key !== field ? "desc" : direction === "desc" ? "asc" : "desc"
          );
          setField(key);
        }}
      >
        <span style={{ cursor: "pointer" }}>{header}</span>
        {key === field && <TableSortLabel active direction={direction} />}
      </th>
    ));
  }

  const getSelectedSites = () => {
    const selectedItems = Object.keys(selected).filter((key) => selected[key]);
    if (!selectedItems.length) return "";
    const formatedItmes = (selectedItems || []).map((key) => {
      const fieldsAraray = key.split("+");
      return `${fieldsAraray[3]} (${fieldsAraray[2]}, ${fieldsAraray[0]})`;
    });
    return (formatedItmes || []).map((item) => <p key={item}>{item}</p>);
  };

  const renderButtons = () => {
    return (
      <>
        <ToolTip
          title={getSelectedSites()}
          arrow
          interactive
          placement="left"
          content={<h3 className="text-dark">Selected Items</h3>}
        />
      </>
    );
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12" data-testid="search-wrapper">
          <ServerSearchBar
            className="mb-5"
            onSearch={(data) =>
              handleSearch(data, searchMap, setSearch, setPage)
            }
            keyMap={SEARCH_HELPER}
            loading={autocompleteLoading}
            currentSearchList={autocompleteData}
            fetchAutocompleteFunction={fetchSitesAutocomplete}
            placeholder="Search Sites..."
            clearSearch={resetSearch}
            searchMap={searchMap}
            searchFields={search}
            contentLoading={loading}
          />
          <div className="bg-white rounded py-7 px-10">
            <ListHeader title="Site Inventory" renderButtons={renderButtons} />
            <Datagrid
              data={data}
              headings={SCHEDULING_HEADINGS}
              renderRow={renderSiteInvetories}
              renderHeaderWithSorting={renderHeaderWithSorting}
              serverPage={page}
              setServerPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              isServerPagination
              totalRecords={totalRecords}
              loading={loading}
              selected={selected}
              setSelected={setSelected}
              selectable
            />
          </div>
        </div>
      </div>
    </>
  );
};
