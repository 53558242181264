import React, { useEffect } from "react";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { useFormikContext } from "formik";

export const TotalVolume = ({ selected = {}, data = [] }) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    const selectedSites = Object.keys(selected).filter(key => selected[key]);
    const volume = data
      .filter(item => selectedSites.includes(item.id))
      .reduce((acc, value) => acc + (Number(value.quantity) || 0), 0);
    setFieldValue("volume", volume);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, data]);
  return (
    <div className="mb-5">
      <MappedInput name="volume" label="Total Volume" disabled />
    </div>
  );
};
