import { get } from "lodash";
import React from "react";
import {
  formatDate,
  isStatus,
  isDate,
  isPrice,
  formatPrice,
} from "../../../ui/helpers";
import { totalStyle } from "../DrumMuster/Claims/constants";
import {
  getTotalContainers,
  getTotalPlastic,
  getTotalSteel,
} from "../DrumMuster/Inspections/helpers";

const tranformDate = (date) => {
  return date ? formatDate(date) : null;
};

export function renderReimbursementsRow(headings, item) {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-5 "text-dark" ${
        isStatus(key) ? "text-uppercase" : ""
      }`}
    >
      {isDate(key) ? (
        tranformDate(item[key])
      ) : isPrice(key) ? (
        <div className="text-right">{formatPrice(item[key])}</div>
      ) : key === "grouped" ? (
        String(item[key]).toUpperCase()
      ) : (
        item[key]
      )}
    </td>
  ));
}

export function modifyLine(ccd = {}, item = {}) {
  return {
    ...item,
    business: get(item, "organisation.business_name", ""),
    name: [
      get(item, "contact.first_name", ""),
      get(item, "contact.last_name", ""),
    ].join(" "),
    material: ccd.material.charAt(0).toUpperCase() + ccd.material.slice(1),
    size: get(ccd, "size"),
    quantity: get(ccd, "quantity"),
  };
}

export function modifyLines(item = {}) {
  return item.ccds.map((ccd) => modifyLine(ccd, item));
}

export const calculateInvoiceTotalGST = (item = {}) => {
  const gstResistered = get(item, "organisation.gst_status");
  const invoiceTotal =
    get(item, "invoice.inspection_fees", 0) +
    get(item, "invoice.processing_fees", 0);
  const miscCharge =
    get(item, "invoice.inspection_misc_charge", 0) +
    get(item, "invoice.processing_misc_charge", 0);
  if (item.status === "waiting_for_invoice") {
    return gstResistered
      ? item.total_value + item.total_value * 0.1
      : item.total_value;
  } else
    return gstResistered
      ? (invoiceTotal + miscCharge) * 1.1
      : invoiceTotal + miscCharge;
};

export function modifyReimbursument(item = {}) {
  return {
    ...item,
    associated_site: get(item, "site.name"),
    status:
      item.reject_comment && item.status === "waiting_for_invoice"
        ? "rejected"
        : item.status.split("_").join(" "),
    type: item.type.charAt(0).toUpperCase() + item.type.slice(1),
    invoice_number: get(item, "invoice.code", ""),
    grouped_search: String(item.grouped),
    date_requested: item.created_at,
    total_steel: getTotalSteel(item),
    total_plastic: getTotalPlastic(item),
    total: getTotalContainers(item),
    state: get(item, "organisation.physical_state"),
    region: get(item, "organisation.region.long_name"),
    organisation_name: get(item, "organisation.business_name"),
    total_value: item.total_value,
    total_value_gst: calculateInvoiceTotalGST(item),
    plastic_0: (
      (item.ccds || []).find(
        ({ size, material }) => size === "20" && material === "plastic"
      ) || {}
    ).quantity,
    plastic_1: (
      (item.ccds || []).find(
        ({ size, material }) => size === "20-25" && material === "plastic"
      ) || {}
    ).quantity,
    plastic_2: (
      (item.ccds || []).find(
        ({ size, material }) => size === "26-120" && material === "plastic"
      ) || {}
    ).quantity,
    plastic_3: (
      (item.ccds || []).find(
        ({ size, material }) => size === "121-205" && material === "plastic"
      ) || {}
    ).quantity,
    steel_0: (
      (item.ccds || []).find(
        ({ size, material }) => size === "20" && material === "steel"
      ) || {}
    ).quantity,
    steel_1: (
      (item.ccds || []).find(
        ({ size, material }) => size === "20-25" && material === "steel"
      ) || {}
    ).quantity,
    steel_2: (
      (item.ccds || []).find(
        ({ size, material }) => size === "26-120" && material === "steel"
      ) || {}
    ).quantity,
    steel_3: (
      (item.ccds || []).find(
        ({ size, material }) => size === "121-205" && material === "steel"
      ) || {}
    ).quantity,
  };
}

export function modifyReimbursementsArray(list = []) {
  return list.map((item) => modifyReimbursument(item));
}

export const rendeTotalDropsRow = (drops = [], reimbursement = {}) => {
  const totalPlastic = drops.reduce(
    (acc, value) => acc + Number(value.total_plastic),
    0
  );
  const totalSteel = drops.reduce(
    (acc, value) => acc + Number(value.total_steel),
    0
  );
  const total = totalPlastic + totalSteel;
  const inspectionFees = get(reimbursement, "invoice.inspection_fees", 0);
  const inspectionMiscCharge = get(
    reimbursement,
    "invoice.inspection_misc_charge",
    0
  );
  const processingFees = get(reimbursement, "invoice.processing_fees", 0);
  const processingMiscCharge = get(
    reimbursement,
    "invoice.processing_misc_charge",
    0
  );
  const gstStatus = get(reimbursement, "organisation.gst_status");
  const invoiceTotalExGST =
    inspectionFees +
    inspectionMiscCharge +
    processingFees +
    processingMiscCharge;

  const invoiceTotalIncGST = gstStatus
    ? invoiceTotalExGST * 1.1
    : invoiceTotalExGST;

  const possibleToClaim =
    invoiceTotalIncGST - processingMiscCharge - inspectionMiscCharge;

  return (
    <>
      <tr className="border-bottom" style={totalStyle}>
        <td colSpan="4"></td>
        <td className="no-line border-0 align-middle px-5 pt-10 pb-1">
          Total Plastic : {totalPlastic}{" "}
        </td>
        <td className="pt-10 pb-1 pl-5">Total Steel: {totalSteel}</td>
        <td className="pt-10 pb-1 pl-5">Total : {total}</td>
      </tr>
      {reimbursement.status === "waiting for invoice" ? null : (
        <>
          {reimbursement.grouped ? (
            <>
              <tr className="border-bottom" style={totalStyle}>
                <td colSpan="4"></td>
                <td className="no-line border-0 align-middle px-5 pt-10 pb-1">
                  Inspection Fees : {formatPrice(inspectionFees)}
                </td>
                <td className="no-line border-0 align-middle px-5 pt-10 pb-1">
                  Inspection Misc Charges : {formatPrice(inspectionMiscCharge)}
                </td>
              </tr>
              <tr className="border-bottom" style={totalStyle}>
                <td colSpan="4"></td>
                <td className="no-line border-0 align-middle px-5 pt-10 pb-1">
                  Processing Fees : {formatPrice(processingFees)}
                </td>
                <td className="no-line border-0 align-middle px-5 pt-10 pb-1">
                  Processing Misc Charges : {formatPrice(processingMiscCharge)}
                </td>
              </tr>
            </>
          ) : reimbursement.type === "Inspection" ? (
            <tr className="border-bottom" style={totalStyle}>
              <td colSpan="4"></td>
              <td className="no-line border-0 align-middle px-5 pt-10 pb-1">
                Inspection Fees : {formatPrice(inspectionFees)}
              </td>
              <td className="no-line border-0 align-middle px-5 pt-10 pb-1">
                Inspection Misc Charges : {formatPrice(inspectionMiscCharge)}
              </td>
            </tr>
          ) : (
            <tr className="border-bottom" style={totalStyle}>
              <td colSpan="4"></td>
              <td className="no-line border-0 align-middle px-5 pt-10 pb-1">
                Processing Fees : {formatPrice(processingFees)}
              </td>
              <td className="no-line border-0 align-middle px-5 pt-10 pb-1">
                Processing Misc Charges : {formatPrice(processingMiscCharge)}
              </td>
            </tr>
          )}
          <tr className="border-bottom" style={totalStyle}>
            <td colSpan="4"></td>
            <td className="no-line border-0 align-middle px-5 pt-10 pb-1">
              Invoice Total : {formatPrice(invoiceTotalIncGST)}
            </td>
            <td className="no-line border-0 align-middle px-5 pt-10 pb-1">
              Possible to Claim : {formatPrice(possibleToClaim)}
            </td>
          </tr>
        </>
      )}
    </>
  );
};
