import React, { useState, useEffect } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { ListButton } from "../../../ui/components/ListButton";
import PDFIcon from "../../../ui/static/PDFIcon.png";
import { getReimbursementInvoicURL } from "../../submodules/Files/_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { get } from "lodash";
import { Loader } from "../../../ui/components/Loader";
import {
  renderReimbursementsRow,
  rendeTotalDropsRow
} from "../../modules/ReimbursementsApprovals/helpers";
import { downloadFile } from "../../modules/AWS-S3/download";
import { getReimbursementDrops } from "../../modules/DrumMuster/Inspections/_api";
import {
  renderExtendedRow,
  modifyCCDsArray
} from "../../modules/DrumMuster/Inspections/helpers";
import { LINE_HEADINGS } from "../../modules/ReimbursementsReconciliation/constants";

export const ReimbursementLine = ({ item = {}, setExpanded }) => {
  const { request } = useFetch();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    request(getReimbursementDrops, item.id)
      .then(data => {
        if (!data) return;
        setData(modifyCCDsArray(data));
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDownload = () => {
    setActionLoading(true);
    downloadFile(
      item.id,
      get(item, "invoice.id"),
      setActionLoading,
      getReimbursementInvoicURL,
      get(item, "invoice.files[0].id")
    );
  };

  const renderHeader = () => {
    const invoiceCode = get(item, "invoice.code", "");
    const organisationName = get(item, "organisation.business_name", "");
    if (invoiceCode && organisationName) {
      return invoiceCode + ", " + organisationName;
    } else if (invoiceCode && !organisationName) return invoiceCode;
    else if (!invoiceCode && organisationName) return organisationName;
    else return "";
  };

  return (
    <>
      <Loader
        isOpen={actionLoading}
        maxWidth="xs"
        disableBackdropClick
        disableEscapeKeyDown
      />
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <h3 className="text-dark mb-5">{renderHeader()}</h3>
            <div className="mb-10 mr-5 d-flex justify-content-between">
              <ListButton
                label="Back"
                size="small"
                onClick={() => setExpanded(false)}
                variant="outlined"
                text="#407A28"
                boxShadow={false}
              />
            </div>
            <Datagrid
              data={data}
              headings={LINE_HEADINGS}
              renderRow={renderReimbursementsRow}
              loading={loading}
              extendable
              renderExtendedRow={renderExtendedRow}
              defaultExtendable
              paginationDefaultValue={20}
              footerRow={() => rendeTotalDropsRow(data, item)}
            />
          </div>
        </div>
      </div>

      <div className="mt-15 d-flex justify-content-between">
        <div className="w-25">
          <div className="d-flex align-items-center mb-9">
            <div onClick={handleDownload} className="cursor-pointer">
              <img src={PDFIcon} alt="pdf" />
            </div>
            <div className="ml-5">
              <strong>{get(item, "invoice.files[0].file_name")}</strong>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
