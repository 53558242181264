import React, { useState, useEffect } from "react";
import Input from "@bit/the-glue.frontendcomponents.input";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { ListButton } from "../../../ui/components/ListButton";
import PDFIcon from "../../../ui/static/PDFIcon.png";
import { renderReimbursementsRow, rendeTotalDropsRow } from "./helpers";
import { getReimbursementInvoicURL } from "../../submodules/Files/_api";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  editReimbursementDrops,
  getReimbursementDrops,
  modifyReimbursement
} from "../DrumMuster/Inspections/_api";
import { error, info } from "../../../helpers/toasts";
import { get } from "lodash";
import {
  modifyCCDsArray,
  renderExtendedRow
} from "../DrumMuster/Inspections/helpers";
import { Loader } from "../../../ui/components/Loader";
import { EditReimbursement } from "./EditReimbursement";
import { Modal } from "../../../ui/components/Modal";
import { downloadFile } from "../AWS-S3/download";
import { PreviewPDF } from "../../../ui/structures/PreviewPDF";
import { Skeleton } from "@material-ui/lab";
import { LINE_HEADINGS } from "../ReimbursementsReconciliation/constants";

export const Line = ({
  item = {},
  setExpanded,
  setReimbursementsData,
  reimbursemenets = [],
  fetchReimbursements
}) => {
  const { request } = useFetch();

  const [data, setData] = useState([]);
  const [unreimbursedData, setUnreimbursedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notesValue, setNotesValue] = useState("");
  const [actionLoading, setActionLoading] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [donwloadURL, setDownloadURL] = useState("");

  useEffect(() => {
    setLoading(true);
    request(getReimbursementDrops, item.id)
      .then(data => {
        if (!data) return;
        setData(modifyCCDsArray(data));
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fileID = get(item, "invoice.files[0].id");
    if (!fileID) {
      error("Invoice not found!");
      return;
    }
    request(getReimbursementInvoicURL, item.id, get(item, "invoice.id"), [
      fileID
    ]).then(data => data && setDownloadURL(data.download_url));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditOpen = () => setEditOpen(true);
  const handleEditClose = () => setEditOpen(false);

  const handleNotesChange = e => {
    setNotesValue(e.target.value);
  };

  const handleReject = () => {
    if (!notesValue) {
      error("Notes are required for rejected record!");
      return;
    }
    setActionLoading(true);
    const payload = {
      status: "waiting_for_invoice",
      reject_comment: notesValue
    };
    request(modifyReimbursement, payload, item.id)
      .then(data => {
        if (!data) return;
        setReimbursementsData(state =>
          state.filter(({ id }) => id !== item.id)
        );
        info("Reimbursement has been rejected!");
        setExpanded(false);
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const handleSave = () => {
    setActionLoading(true);
    request(
      editReimbursementDrops,
      item.id,
      data.map(({ id }) => ({ id }))
    )
      .then(() => {
        if (!data) return;
        info("Reimbursement has been updated!");
        setExpanded(false);
        fetchReimbursements();
      })
      .finally(() => setActionLoading(false));
  };

  const handleApprove = () => {
    setActionLoading(true);
    const payload = {
      status: "approved",
      notes: notesValue
    };
    request(modifyReimbursement, payload, item.id)
      .then(res => {
        if (!res) return;
        const updatedData = reimbursemenets
          .filter(({ invoice_id }) => invoice_id === res.invoice_id)
          .map(({ id }) => id);
        setReimbursementsData(state =>
          state.filter(item => !updatedData.includes(item?.id))
        );

        info("Reimbursement has been approved!");
        setExpanded(false);
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const handleDelete = itemID => {
    setData(state => state.filter(({ id }) => id !== itemID));
    setUnreimbursedData(state => [
      ...state,
      data.find(({ id }) => id === itemID)
    ]);
  };

  const handleDownload = () => {
    const fileID = get(item, "invoice.files[0].id");
    if (!fileID) {
      error("Invoice not found!");
      return;
    }
    setActionLoading(true);
    downloadFile(
      item.id,
      get(item, "invoice.id"),
      setActionLoading,
      getReimbursementInvoicURL,
      fileID
    );
  };

  const renderHeader = () => {
    const invoiceCode = get(item, "invoice.code", "");
    const organisationName = get(item, "organisation.business_name", "");
    if (invoiceCode && organisationName) {
      return invoiceCode + ", " + organisationName;
    } else if (invoiceCode && !organisationName) return invoiceCode;
    else if (!invoiceCode && organisationName) return organisationName;
    else return "";
  };

  const groupedItems = reimbursemenets.filter(
    ({ invoice_id }) => invoice_id === item.invoice_id
  );

  return (
    <>
      <Modal
        isOpen={editOpen}
        submitable
        onClose={handleEditClose}
        maxWidth="lg"
        modalContent={
          <EditReimbursement
            data={unreimbursedData}
            setData={setUnreimbursedData}
            setReimbursedData={setData}
            handleClose={handleEditClose}
            organisationId={item.organisation_id}
          />
        }
      />
      <Loader
        isOpen={actionLoading}
        maxWidth="xs"
        disableBackdropClick
        disableEscapeKeyDown
      />

      <div className="w-100 approval-content-wrapper">
        {donwloadURL ? (
          <div className="mt-10 mr-5 approval-content-preview">
            <PreviewPDF donwloadURL={donwloadURL} />
          </div>
        ) : (
          <div
            className="mt-10 mr-5 approval-content-preview"
            style={{ width: "40%" }}
          >
            <Skeleton variant="rect" width={700} height={990} />
          </div>
        )}
        <div className="row justify-content-center mt-10 w-100 approval-content-data">
          <div className="col-12">
            <div className="bg-white rounded py-7 px-10">
              <h3 className="text-dark mb-5">{renderHeader()}</h3>
              <div className="mb-10 mr-5 d-flex justify-content-between">
                <ListButton
                  label="Back"
                  size="small"
                  onClick={() => setExpanded(false)}
                  variant="outlined"
                  text="#407A28"
                  boxShadow={false}
                />
                <ListButton label="Add" onClick={handleEditOpen} />
              </div>
              <Datagrid
                data={data}
                headings={LINE_HEADINGS}
                renderRow={renderReimbursementsRow}
                loading={loading}
                extendable
                renderExtendedRow={renderExtendedRow}
                defaultExtendable
                paginationDefaultValue={20}
                deletable
                handleDelete={handleDelete}
                footerRow={() => rendeTotalDropsRow(data, item)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-15 d-flex justify-content-between">
        <div className="w-25">
          <div className="d-flex align-items-center mb-9">
            <div onClick={handleDownload} className="cursor-pointer">
              <img src={PDFIcon} alt="pdf" />
            </div>
            <div className="ml-5">
              <strong>{get(item, "invoice.files[0].file_name")}</strong>
            </div>
          </div>
        </div>
        <div className="w-75">
          <Input
            label="Reject Notes"
            multiline
            rows={16}
            value={notesValue}
            onChange={handleNotesChange}
          />
        </div>
      </div>
      {/* 
      <div className="text-right mt-10" style={{ fontSize: "14px" }}>
        <div className="text-uppercase" style={{ color: "#64A70B" }}>
          <strong>This claim is recommended for approval</strong>
        </div>
        <div className="my-3" style={{ color: "#F2994A" }}>
          <strong>THIS CLAIM REQUIRES VALIDATION</strong>
        </div>
        <div style={{ color: "#EB5757" }}>
          <strong>THIS CLAIM IS INCOMPLETE</strong>
        </div>
      </div> */}

      {item.grouped ? (
        <div className="my-3 text-warning text-right font-weight-bold">
          *Actions done to this item will result into same action for{" "}
          {groupedItems.length - 1} more grouped{" "}
          {groupedItems.length - 1 > 1 ? "items" : "item"}
        </div>
      ) : null}

      <div className="d-flex justify-content-end mt-20">
        <ListButton
          label="reject"
          className="px-18 py-3"
          variant="outlined"
          text="#EB5757"
          onClick={handleReject}
        />
        <ListButton
          label="save"
          className="mx-7 px-18 py-3"
          variant="outlined"
          text="#407A28"
          onClick={handleSave}
        />
        <ListButton
          label="approve"
          className="px-18 py-3"
          onClick={handleApprove}
          disabled={item.status !== "active"}
        />
      </div>
    </>
  );
};
