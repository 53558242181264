import config from "react-global-configuration";

export const addClearance = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/collection_runs`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const modifyClearance = (id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/collection_runs/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getCollectionRunsPaginated = (
  search = "",
  field,
  direction,
  page = 0,
  page_size = 10,
  status = ""
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/collection_runs?${search &&
      "&" + search}${(field && "&order_by=" + field) || ""}${(direction &&
      "&order_dir=" + direction) ||
      ""}&page=${page}&page_size=${page_size}&status=${status}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getRunsAutocomplete = (
  page_size = 80,
  autocompleteField,
  autocompleteText = "",
  field,
  direction,
  search,
  status = ""
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/collection_runs?&page_size=${page_size}${(autocompleteField &&
      "&distinct=" +
        autocompleteField +
        "&" +
        autocompleteField +
        "=_like(" +
        autocompleteText +
        ")_") ||
      ""}${(field && "&order_by=" + field) || ""}${(direction &&
      "&order_dir=" + direction) ||
      ""}${search ? "&" + search : ""}&status=${status}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getRunsAmount = (search = "") => {
  return fetch(
    `${config.get("ApiUrl").Rest}/collection_runs?count_only=y${
      search ? "&" + search : ""
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};

export const getCollectionRuns = (query = "") =>
  fetch(`${config.get("ApiUrl").Rest}/collection_runs?${query}`, {
    mode: "cors",
    credentials: "include",
  });

export const deleteCollectionRun = (id) =>
  fetch(`${config.get("ApiUrl").Rest}/collection_runs/${id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const getSiteInventory = (
  search = "",
  archived = "",
  field,
  direction,
  page = 0,
  pageSize = 10,
  schedulling_mode = ""
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/site_inventories?${schedulling_mode &&
      "scheduling_mode=y"}${search && "&" + search}${(archived &&
      "&status=all") ||
      ""}${(field && "&order_by=" + field) || ""}${(direction &&
      "&order_dir=" + direction) ||
      ""}&page=${page}&page_size=${pageSize}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getSiteInventoryAmount = (search = "", schedulling_mode = "") => {
  return fetch(
    `${
      config.get("ApiUrl").Rest
    }/site_inventories?count_only=y&${schedulling_mode && "scheduling_mode=y"}${
      search ? "&" + search : ""
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};

export const getCollectionRunById = (id) =>
  fetch(`${config.get("ApiUrl").Rest}/collection_runs/${id}`, {
    mode: "cors",
    credentials: "include",
  });
