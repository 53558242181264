import React from "react";
import { Formik } from "formik";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedDatePicker } from "@bit/the-glue.frontendcomponents.datepicker";
import { invoiceUpladSchema } from "../../../../../helpers/schemas";
import { ListButton } from "../../../../../ui/components/ListButton";
import { File } from "../../../../../ui/structures/FileUploadForm/File";
import { CalculatedTotal } from "./CalculatedTotal";
import { InvoiceTotal } from "./InvoiceTotal";
import { GST } from "./GST";

export const UploadInvoice = ({
  handleClose,
  handleSubmit,
  uploadedFile,
  uploadFile,
  fileData,
  setLoading,
  data = {},
  gstStatus,
  isGroupedItem,
}) => {
  const getSingleTypeFee = () => {
    if (
      typeof data.total_value === "number" ||
      typeof data.total_value === "string"
    ) {
      return Number(data.total_value).toFixed(2);
    } else return "0.00";
  };

  const getCombinedTypeFee = (field) => {
    if (typeof data[field] === "number" || typeof data[field] === "string") {
      return Number(data[field]).toFixed(2);
    } else return "0.00";
  };

  const initialValues = {
    code: "",
    date: "",
    inspection_fees:
      data.type === "Combined"
        ? getCombinedTypeFee("inspection_fees")
        : getSingleTypeFee(),
    processing_fees:
      data.type === "Combined"
        ? getCombinedTypeFee("processing_fees")
        : getSingleTypeFee(),
    inspection_misc_charge: "",
    processing_misc_charge: "",
    calculated_invoice_total: "",
    gst: "0.00",
    invoice_total: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(
          values,
          setSubmitting,
          uploadFile,
          fileData,
          setLoading,
          handleClose,
          data.type
        );
      }}
      validationSchema={invoiceUpladSchema}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <div>
          <h3>Invoice Upload</h3>
          <div className="mb-10">
            For the fastest possible claims processing please upload your
            invoice here in the portal. If you need assistance with invoicing,
            access the help menu in the bottom right, or get in touch with us
            via phone on 1800 008 707.
            {gstStatus ? null : (
              <div className="text-warning mt-5">
                *GST is not registered for the claim payee organisation. GST is
                $0.00
              </div>
            )}
            {isGroupedItem ? (
              <div className="text-warning mt-5">*Reimbursement is grouped</div>
            ) : null}
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            <div style={{ width: "45%" }}>
              <MappedInput
                name="code"
                label="Your Invoice Number"
                inputWrapperStyle={{ marginBottom: "20px" }}
              />
              <MappedDatePicker
                name="date"
                label="Your Invoice Date"
                shouldCloseOnSelect
              />
            </div>
            <div style={{ width: "45%" }}>
              {data.type === "Inspection" || data.type === "Combined" ? (
                <MappedInput
                  name="inspection_fees"
                  label="Inspection Fees (ex GST)"
                  inputWrapperStyle={{ marginBottom: "20px" }}
                />
              ) : null}
              {data.type === "Processing" || data.type === "Combined" ? (
                <MappedInput
                  name="processing_fees"
                  label="Processing Fees (ex GST)"
                  inputWrapperStyle={{ marginBottom: "20px" }}
                />
              ) : null}

              {data.type === "Inspection" || data.type === "Combined" ? (
                <MappedInput
                  name="inspection_misc_charge"
                  label="Add Pre-Approved Inspection Miscellaneous Charges (ex GST)"
                  inputWrapperStyle={{ marginBottom: "20px" }}
                />
              ) : null}
              {data.type === "Processing" || data.type === "Combined" ? (
                <MappedInput
                  name="processing_misc_charge"
                  label="Add Pre-Approved Processing Miscellaneous Charges (ex GST)"
                  inputWrapperStyle={{ marginBottom: "20px" }}
                />
              ) : null}

              <CalculatedTotal type={data.type} />
              <GST gstStatus={gstStatus} />
              <InvoiceTotal />

              <div className="d-flex justify-content-end mt-10">
                <ListButton
                  label="Cancel"
                  boxShadow={false}
                  onClick={handleClose}
                  size="medium"
                  className="mr-5"
                  variant="outlined"
                  text="#407A28"
                  disabled={isSubmitting}
                />
                <ListButton
                  label="Submit"
                  size="medium"
                  boxShadow={false}
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                />
              </div>
            </div>
          </div>

          {uploadedFile ? (
            <div className="mt-5">
              <File file={uploadedFile} uploadFile={uploadFile} />
            </div>
          ) : null}
        </div>
      )}
    </Formik>
  );
};
