/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import Button from "@bit/the-glue.frontendcomponents.button";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { Skeleton } from "@material-ui/lab";
import { uniqBy } from "lodash";
import { ContactDetailsSchema } from "../../../helpers/schemas";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  addContact,
  deleteContactTags,
  editContactTags,
  getContactOrganisations,
  getContacts,
  getContactTags,
  inviteUser,
  linkOrganisationToContact,
  modifyContact,
  sendBulkSmsSingle,
} from "./_api";
import { archiveUser } from "../Users/_api";
import { Modal } from "../../../ui/components/Modal";
import { SMSForm } from "../../../ui/structures/SMSForm";
import { modifyEmptyFields, sortHelper } from "../../../ui/helpers";
import { AutocompleteMultiple } from "../../../ui/components/AutocompleteMultiple";
import { Loader } from "../../../ui/components/Loader";
import { info } from "../../../helpers/toasts";
import { TagsForm } from "../../../ui/structures/TagsForm";
import { DublicateEmail } from "./DublicateEmail";
import { SubmitActions } from "../../../ui/structures/SubmitActions";
import { getSimpleOrganisations } from "../Organisations/_api";
import { getTags } from "../Settings/Tags/_api";
import { getLGARegionByPostcode } from "../Settings/Regions/_api";
import { PhysicalPostcode } from "../../../ui/components/PhysicalPostcode";
import { LGARegionSelect } from "../../../ui/components/LGARegionSelect";
import { AccessControl } from "../../../ui/structures/AccessControl";
import { AVAILABLE_ROLES } from "../../constants";

export const ContactDetails = ({ data = {}, id, organisationId }) => {
  const history = useHistory();
  const { request } = useFetch();

  const initialValues = {
    first_name: "",
    preferred_name: "",
    last_name: "",
    phone: "",
    mobile: "",
    email: "",
    position_title: "",
    twitter: "",
    facebook: "",
    notes: "",
    organisation_id: organisationId || "",
    physical_postcode: "",
    lga_region: "",
  };

  const [loading, setLoading] = useState(false);
  const [bulkModalOpen, setBulkModalOpen] = useState(false);
  const [dublicateModalOpen, setDublicateModalOpen] = useState(false);
  const [dublicateId, setDuplicateId] = useState("");
  const [submitAction, setSubmitAction] = useState("Save");
  const [organisations, setOrganisations] = useState([]);
  const [linkedOrganisations, setLinkedOrganisations] = useState([]);
  const [linkedOrgsLoading, setLinkedOrgsLoading] = useState(false);
  const [linkedTags, setLinkedTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [helpersLoading, setHelpersLoading] = useState(false);
  const [lgaRegions, setLgaRegions] = useState([]);
  const [lgaLoading, setLgaLoading] = useState(false);

  const handleBulkClose = () => setBulkModalOpen(false);
  const handleBulkOpen = () => setBulkModalOpen(true);

  const handleDublicateClose = () => setDublicateModalOpen(false);

  const name = [data.first_name, data.last_name].join(" ");

  const fetchLgaRegions = (values, setFieldValue) => {
    setLgaLoading(true);
    return request(getLGARegionByPostcode, values.physical_postcode)
      .then((data) => {
        if (!data) return;
        setLgaRegions(data.map((region) => ({ value: region, label: region })));
        if (!data.includes(values.lga_region)) setFieldValue("lga_region", "");
      })
      .finally(() => setLgaLoading(false));
  };

  useEffect(() => {
    request(getSimpleOrganisations).then(
      (data) => data && setOrganisations(data)
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      setLinkedOrgsLoading(true);
      Promise.all([
        request(getContactTags, id),
        request(getContactOrganisations, id),
      ])
        .then(([tags, organisations]) => {
          tags && setLinkedTags(tags);
          organisations &&
            setLinkedOrganisations((organisations || []).map(({ id }) => id));
        })
        .finally(() => setLinkedOrgsLoading(false));
    }
  }, []);

  useEffect(() => {
    if (id) {
      setHelpersLoading(true);
      Promise.all([request(getTags, "category=_like(Contact)_")])
        .then(([tags]) => {
          tags && setTags(tags);
        })
        .finally(() => setHelpersLoading(false));
    }
  }, []);

  const organiastionOptions = useMemo(
    () =>
      organisations.map((organiastion) => ({
        value: organiastion.id,
        label: organiastion.business_name,
      })),
    [organisations]
  );

  if (id && linkedOrgsLoading) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  if (helpersLoading) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  const handleDuplicate = (email = "") => {
    return request(
      getContacts,
      `email=${encodeURIComponent(`${email}`)}`,
      true
    );
  };
  const handleBlur = (values, setTouched) => () => {
    if (!values.email || values.email === data.email) return;
    setLoading(true);
    handleDuplicate(values.email)
      .then((data) => {
        if ((data || []).length && ((data || [])[0] || {}).id !== id) {
          setDuplicateId(((data || [])[0] || {}).id);
          setDublicateModalOpen(true);
        }
      })
      .finally(() => setLoading(false));
    setTouched({ email: true }, true);
  };

  const handleDublicateSubmit = () => {
    setDublicateModalOpen(false);
    history.push(`/contacts/${dublicateId}`);
  };

  const handleBulkSubmit = (values, { setSubmitting }) => {
    request(sendBulkSmsSingle, id, values)
      .then((response) => {
        if (!response) return;
        info("SMS has been sent!");
      })
      .finally(() => {
        setSubmitting(false);
        handleBulkClose();
      });
  };

  const createContact = (values, setSubmitting) => {
    setLoading(true);
    request(addContact, values)
      .then((data) => data && history.push("/contacts-list"))
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const updateContact = (values, setSubmitting) => {
    setLoading(true);
    if (
      linkedOrganisations.sort().join(",") ===
      (values.organisation_id || []).sort().join(",")
    ) {
      request(modifyContact, values, id)
        .then((data) => {
          if (!data) return;
          info("Contact details have been updated!");
          submitAction === "Save & Exit" && history.push("/contacts-list");
        })
        .finally(() => {
          setSubmitting(false);
          setLoading(false);
        });
    } else {
      Promise.all([
        request(modifyContact, values, id),
        request(
          linkOrganisationToContact,
          id,
          values.organisation_id.map((id) => ({
            id,
          }))
        ),
      ])
        .then(([contactData, contactLinkage]) => {
          if (!contactData || !contactLinkage) return;
          info("Contact details have been updated!");
          submitAction === "Save & Exit" && history.push("/contacts-list");
        })
        .finally(() => {
          setSubmitting(false);
          setLoading(false);
        });
    }
  };

  const handleSubmit = (values, { setSubmitting }) => {
    modifyEmptyFields(values);
    id
      ? updateContact(values, setSubmitting)
      : createContact(values, setSubmitting);
  };

  const editTagsRequest = (values) => {
    if (values.tags.length) {
      return request(
        editContactTags,
        id,
        values.tags.map((tagID) => ({ id: tagID }))
      );
    } else {
      return request(
        deleteContactTags,
        id,
        linkedTags.map(({ id }) => ({ id }))
      );
    }
  };

  const handleSubmitTags = (values, { setSubmitting }) => {
    setLoading(true);
    editTagsRequest(values)
      .then((data) => {
        if (!data) return;
        info("Contact tags have been updated!");
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const inviteUserToPortal = () => {
    setLoading(true);
    request(inviteUser, id)
      .then((data) => {
        if (!data) return;
        info(`${name} has been Invited to the portal!`);
      })
      .finally(() => setLoading(false));
  };

  const revokeAccess = () => {
    setLoading(true);
    if (!data.user_id) {
      setLoading(false);
      info(`${name} is not invited to the portal!`);
    } else
      request(archiveUser, data.user_id)
        .then((data) => {
          if (!data) return;
          info("Portal access has been revoked!");
        })
        .finally(() => setLoading(false));
  };

  const handleCancel = () => {
    history.push("/contacts-list");
  };

  return (
    <div className="border border-secondary bg-white p-10">
      <Loader
        isOpen={loading}
        maxWidth="xs"
        disableBackdropClick
        disableEscapeKeyDown
      />
      <Modal
        isOpen={dublicateModalOpen}
        submitable
        onClose={handleDublicateClose}
        maxWidth="md"
        modalContent={
          <DublicateEmail
            onClose={handleDublicateClose}
            handleSubmit={handleDublicateSubmit}
          />
        }
      />
      <Modal
        isOpen={bulkModalOpen}
        submitable
        onClose={handleBulkClose}
        maxWidth="xs"
        modalContent={
          <SMSForm
            handleClose={handleBulkClose}
            handleSubmit={handleBulkSubmit}
            title={`Send SMS to ${data.mobile}`}
          />
        }
      />
      <Formik
        onSubmit={handleSubmit}
        initialValues={
          id
            ? {
                ...data,
                organisation_id: linkedOrganisations,
              }
            : initialValues
        }
        validationSchema={ContactDetailsSchema}
        enableReinitialize
      >
        {({
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched,
          values,
          ...formik
        }) => (
          <>
            <div className="text-right text-danger">
              {data.status === "archived" ? "Archived" : ""}
            </div>
            {id && (
              <AccessControl
                desiredRoles={[
                  AVAILABLE_ROLES.NATIONAL_ADMIN,
                  AVAILABLE_ROLES.AGSAFE_ADMIN,
                  AVAILABLE_ROLES.AGSAFE_STAFF,
                  AVAILABLE_ROLES.RC_ACCESS,
                ]}
              >
                <div className="my-5 d-flex justify-content-center">
                  <div className="mr-3">
                    <Button
                      label="Send SMS"
                      size="small"
                      text="#599D36"
                      background="light"
                      boxShadow
                      textTransform="uppercase"
                      fontWeight="600"
                      onClick={handleBulkOpen}
                    />
                  </div>
                  <div className="mr-3">
                    <Button
                      label="Invite User to Portal"
                      size="small"
                      text="#599D36"
                      background="light"
                      boxShadow
                      textTransform="uppercase"
                      fontWeight="600"
                      onClick={inviteUserToPortal}
                    />
                  </div>
                  <Button
                    label="Revoke Portal Access"
                    size="small"
                    text="#599D36"
                    background="light"
                    boxShadow
                    textTransform="uppercase"
                    fontWeight="600"
                    onClick={revokeAccess}
                  />
                </div>
              </AccessControl>
            )}
            <h3 className="mb-5">
              <strong>{id ? name : "New Contact"}</strong>
            </h3>
            <div className="row justify-content-between">
              <div className="col-8">
                <MappedInput
                  label="First Name"
                  name="first_name"
                  data-testid="first_name"
                />
                <MappedInput
                  label="Preferred Name"
                  name="preferred_name"
                  data-testid="preferred_name"
                />
                <MappedInput
                  label="Last Name"
                  name="last_name"
                  data-testid="last_name"
                />
                <MappedInput label="Phone" name="phone" data-testid="phone" />
                <MappedInput
                  label="Mobile"
                  name="mobile"
                  data-testid="mobile"
                />
                <MappedInput
                  label="Email"
                  name="email"
                  data-testid="email"
                  onBlur={handleBlur(values, setTouched)}
                />
                <MappedInput
                  label="Position"
                  name="position_title"
                  data-testid="position_title"
                />
                <MappedInput
                  label="Twitter"
                  name="twitter"
                  data-testid="twitter"
                />
                <MappedInput
                  label="Facebook"
                  name="facebook"
                  data-testid="facebook"
                />

                <div className="d-flex mt-3">
                  <div className="col-3 pl-0">
                    <PhysicalPostcode fetchLgaRegions={fetchLgaRegions} />
                  </div>
                  <div className="col-9 pr-0">
                    <LGARegionSelect
                      values={values}
                      lgaLoading={lgaLoading}
                      lgaRegions={lgaRegions}
                      lgaRequired={false}
                    />
                  </div>
                </div>
                {organiastionOptions.length ? (
                  <div className="mt-5 col-12">
                    <AutocompleteMultiple
                      name="organisation_id"
                      placeholder="Organisations"
                      setValue={setFieldValue}
                      options={uniqBy(organiastionOptions, "label").sort(
                        sortHelper
                      )}
                      defaultValueField="value"
                      defaultValue={linkedOrganisations}
                      variant="standard"
                      disableClearable
                    />
                    {formik.touched.organisation_id &&
                    formik.errors.organisation_id ? (
                      <div className="text-danger">
                        {formik.errors.organisation_id}
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="mt-3 col-12">
                    <Skeleton variant="rect" width={"100%"} height={55} />
                  </div>
                )}
              </div>
              <div className="col-4">
                {id && (
                  <TagsForm
                    id={id}
                    tags={tags}
                    linkedTags={linkedTags}
                    handleSubmit={handleSubmitTags}
                    wrapperClassName="w-100 mb-15"
                  />
                )}
                <div>
                  <MappedInput
                    label="Notes"
                    name="notes"
                    data-testid="notes"
                    rows={15}
                    multiline
                  />
                </div>
              </div>
            </div>
            <SubmitActions
              id={id}
              handleCancel={handleCancel}
              disabled={isSubmitting}
              submitAction={submitAction}
              handleSubmit={handleSubmit}
              setSubmitAction={setSubmitAction}
            />
          </>
        )}
      </Formik>
    </div>
  );
};
