import config from "react-global-configuration";

export const createOrganisationFile = (organisation_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}/files`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const createReimbursementFile = (reimbursement_id, data, invoice_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/reimbursements/${reimbursement_id}/invoices/${invoice_id}/files`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include"
    }
  );

export const deleteReimbursementFile = (
  reimbursement_id,
  invoice_id,
  file_id
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/reimbursements/${reimbursement_id}/invoices/${invoice_id}/files/${file_id}`,
    {
      method: "DELETE",
      mode: "cors",
      credentials: "include"
    }
  );

export const getReimbursementFiles = (reimbursement_id, invoice_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/reimbursements/${reimbursement_id}/invoices/${invoice_id}/files`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getReimbursementInvoicURL = (
  reimbursement_id,
  invoice_id,
  props = []
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/reimbursements/${reimbursement_id}/invoices/${invoice_id}/files/${
      props[0]
    }`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getOrganisationFiles = (organisation_id, archived) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/organisations/${organisation_id}/files?${(archived && "&status=all") ||
      ""}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const deleteFile = file_id =>
  fetch(`${config.get("ApiUrl").Rest}/files/${file_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include"
  });

export const modifyFile = (data, file_id) =>
  fetch(`${config.get("ApiUrl").Rest}/files/${file_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const getFileById = file_id =>
  fetch(`${config.get("ApiUrl").Rest}/files/${file_id}`, {
    mode: "cors",
    credentials: "include"
  });
