import config from "react-global-configuration";

export const addSite = data =>
  fetch(`${config.get("ApiUrl").Rest}/sites`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const getSites = () =>
  fetch(`${config.get("ApiUrl").Rest}/sites`, {
    mode: "cors",
    credentials: "include"
  });

export const getSiteById = site_id =>
  fetch(`${config.get("ApiUrl").Rest}/sites/${site_id}`, {
    mode: "cors",
    credentials: "include"
  });

export const modifySite = (data, site_id) =>
  fetch(`${config.get("ApiUrl").Rest}/sites/${site_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const archiveSite = site_id =>
  fetch(`${config.get("ApiUrl").Rest}/sites/${site_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include"
  });

export const downloadReport = (site_id, file_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/site_audits/${site_id}/files/${file_id}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getSiteStatistics = site_id =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/sites/${site_id}/collection_statistics?years=10`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getBulkEntryStatistics = site_id =>
  fetch(`${config.get("ApiUrl").Rest}/sites/${site_id}/bulk_entry_statistics`, {
    mode: "cors",
    credentials: "include"
  });
