export const inputCellStyle = {
  width: "100px",
};

export const quantityCellStyle = {
  maxWidth: "100px",
  fontWeight: "400",
  fontSize: "12px",
  color: "#828282",
};

export const headerStyle = {
  fontWeight: "500",
  fontSize: "14px",
  color: "#9E9E9E",
};

export const totalStyle = {
  fontWeight: "700",
  fontSize: "12px",
  color: "#828282",
};
