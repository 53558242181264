import React, { useState } from "react";
import { Dialog, DialogContent, LinearProgress } from "@material-ui/core";
import { startUpload } from "../../AWS-S3/upload";
import { getUploadedProducts, getUploadFileUrl } from "../_api";
import { UploadProductsFile } from "./UploadProductsFile";
import { useFetch } from "../../../../hooks/fetch.hook";
import { error } from "../../../../helpers/toasts";
import { ImportedList } from "./ImportedList";
import { get } from "lodash";

export const ImportProducts = ({ handleClose, isOpen , existingProducts}) => {
  const { request } = useFetch();

  const [step, setStep] = useState(0);
  const [fileData, setFileData] = useState({});
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState("");
  const [productsList, setProductsList] = useState([]);

  const handleNextStep = () => setStep(step + 1);

  const onClose = () => {
    setFileData({});
    setUploadedFile("");
    handleClose();
    setStep(0);
  };

  const handleUploadFile = () => {
    return request(getUploadFileUrl, "products", uploadedFile.name).then((data) => {
      if (!data) {
        throw new Error("Something went wrong!");
      }
      const payload = {
        url: data.url,
        fields: { ...data.fields, file: uploadedFile },
      };
      setFileData(data);
      return startUpload(fileData.file_name, payload).catch((err) => {
        error((err || {}).message);
      });
    });
  };

  const getUploadedData = () => {
    setLoading(true);
    request(getUploadedProducts, get(fileData, "fields.key", ""))
      .then((data) => {
        if (!data) return;
        setProductsList(data.map((item) => ({ ...item, id: `${item.legacy_id}/${item.name}` })));
        handleNextStep();
      })
      .finally(() => setLoading(false));
  };

  const stepsContent = [
    <UploadProductsFile
      handleUploadFile={handleUploadFile}
      handleClose={onClose}
      setUploadedFile={setUploadedFile}
      uploadedFile={uploadedFile}
      loading={loading}
      getUploadedData={getUploadedData}
    />,
    <ImportedList data={productsList} handleClose={onClose} existingProducts={existingProducts} />,
  ];

  return (
    <>
      <Dialog
        onClose={onClose}
        open={isOpen}
        fullWidth={true}
        maxWidth={`${step === 0 ? "xs" : "lg"}`}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogContent className="p-10">
          <div className="mt-5">{stepsContent.find((item, index) => index === step)}</div>
          <div className="py-2">{loading && <LinearProgress />}</div>
        </DialogContent>
      </Dialog>
    </>
  );
};
