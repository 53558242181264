import React from "react";
import { Formik } from "formik";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { clientDetailsSchema } from "../../../helpers/schemas";

export const ClientDetails = ({ handleSubmit, onClose }) => {
  const initialValues = {
    client_id: "",
    client_secret: "",
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={clientDetailsSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <div className="py-10">
          <h4 className="mb-10 text-center">
            <strong>Provide Client Details</strong>
          </h4>
          <div className="d-flex">
            <div className="w-100">
              <MappedInput name="client_id" label="Client ID" testId="client_id" />
              <MappedInput name="client_secret" label="Client Secret" data-testid="client_secret" />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-15 mb-5">
            <button onClick={onClose} className="btn btn-white mr-3">
              <strong>Cancel</strong>
            </button>
            <button
              onClick={handleSubmit}
              className=" py-3 px-5 text-uppercase text-primary bg-primary text-white border border-primary rounded"
              disabled={isSubmitting}
              type="submit"
            >
              <strong>Submit</strong>
            </button>
          </div>
        </div>
      )}
    </Formik>
  );
};
