import React, { useEffect } from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core";
import get from "lodash/get";
import { uniqBy } from "lodash";
import "./label.css";

const useStyles = makeStyles({
  root: {
    backgroundColor: "rgba(0,0,0,0.04)",
    paddingTop: "10px"
  },
  popupIndicator: {
    paddingRight: "15px"
  }
});

const defaultInputStyle = {
  letterSpacing: "0.4px",
  color: "#255915",
  marginLeft: "3px"
};

export const Autocomplete = ({
  options = [],
  tip,
  placeholder = "Select option",
  loading,
  setValue,
  name,
  defaultValue = null,
  defaultValueField = "id",
  disabled,
  label = "label",
  isObjectValue = false,
  hanldeFocus,
  variant = "standard",
  defaultStyle,
  size = "medium",
  sortable = true,
  filterOptions = createFilterOptions({
    limit: 70
  }),
  inputStyle = defaultInputStyle,
  rootStyle,
  ...other
}) => {
  const formInputClasses = useStyles();
  const classes = rootStyle || formInputClasses;

  const sortHelper = function(a, b) {
    if (a[label] > b[label]) return 1;
    if (a[label] < b[label]) return -1;
    return 0;
  };

  const handleChange = (_, value) => {
    setValue(name, get(value, "value", ""));
  };

  const getWholeObject = (_, value) => {
    setValue(name, value);
  };

  useEffect(() => {
    if (!defaultValue || !setValue) return;
    setValue(name, defaultValue);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <MuiAutocomplete
        options={
          sortable
            ? uniqBy(options, label).sort(sortHelper)
            : uniqBy(options, label)
        }
        id={name}
        size={size}
        filterOptions={filterOptions}
        onChange={isObjectValue ? getWholeObject : handleChange}
        getOptionLabel={item => item[label]}
        getOptionSelected={(option, value) => option.value === value.value}
        defaultValue={
          options.find(item => item[defaultValueField] === defaultValue) || null
        }
        disabled={disabled}
        classes={defaultStyle ? {} : classes}
        onFocus={hanldeFocus || null}
        {...other}
        renderInput={params => (
          <TextField
            {...params}
            label={placeholder}
            color="secondary"
            variant={variant}
            InputLabelProps={{
              style: inputStyle
            }}
          />
        )}
      />
      {tip && <div>{tip}</div>}
    </div>
  );
};
