import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

export const Captcha = ({ onChange, values, setSubmitting }) => {
  const handleSubmit = () => {
    onChange(values, setSubmitting);
  };
  return (
    <div  className="pb-10 pl-15 bg-white">
      <ReCAPTCHA sitekey="6LdT1JUbAAAAAEzO2wW9ZX7O5O3VkutDIz7Gm8Af" onChange={handleSubmit} />
    </div>
  );
};
