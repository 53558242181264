import { useFormikContext } from "formik";
import { get } from "lodash";
import React from "react";
import { ListButton } from "../../../ui/components/ListButton";

export const WebPreview = ({ site = {}, onClose, siteStatistic = {} }) => {
  const { values } = useFormikContext();
  const {
    name,
    physical_address,
    physical_city,
    physical_state,
    physical_postcode,
    organisation,
  } = site;
  return (
    <div className="pb-5">
      <h3 className="mb-5">
        <strong>drumMUSTER Website Preview</strong>
      </h3>
      <div className="mb-10">
        Data from this tab is published on the drumMUSTER website via a Public
        API from the drumDRIVE application. The below preview is indicative of
        what will be avaialble on the public drumMUSTER website as soon as the
        Site Card changes are saved.
      </div>
      <div
        className="border border-primary rounded p-5 mb-5 text-dark"
        style={{ fontSize: "16px" }}
      >
        <h3>
          <strong>{name}</strong>
        </h3>
        <p>
          <strong>
            {" "}
            {[
              physical_state,
              physical_city,
              physical_address,
              physical_postcode,
            ]
              .filter(Boolean)
              .join(", ")}
          </strong>
        </p>
        <div className="my-15">{values.public_website_general_description}</div>
        <div className="mb-15">
          This site has collected {siteStatistic.total} drums since inception.
        </div>
        <div className="mb-5">
          For additional drumMuster program information in this region contact
        </div>
        {organisation.consultant ? (
          <div className="pb-15">
            {[
              get(organisation, "consultant.first_name", ""),
              get(organisation, "consultant.last_name", ""),
            ].join(" ")}{" "}
            on {get(organisation, "consultant.phone")}
          </div>
        ) : null}
      </div>
      <div className="text-right">
        <ListButton
          label="Close"
          size="large"
          onClick={onClose}
          variant="outlined"
          boxShadow={false}
          text="#407A28"
        />
      </div>
    </div>
  );
};
