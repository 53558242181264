import { get } from "lodash";

export const modifyTasks = (data) => data.map((task) => modifyTaskData(task));

const getLinkedEntity = (task = {}) => {
  if (task.flag === "sites") {
    return get(task, "site.name", "");
  } else if (task.flag === "contacts") {
    return [
      get(task, "contact.first_name", ""),
      get(task, "contact.last_name"),
    ].join(" ");
  } else if (task.flag === "organisations") {
    return get(task, "organisation.business_name", "");
  } else return "";
};

export const modifyTaskData = (task = {}) => {
  return {
    ...task,
    accountable: [
      get(task, "contact.first_name", get(task, "contact.last_name")),
    ].join(" "),
    linked_entity: getLinkedEntity(task),
    archive_status: task.status,
    status: get(task, "completed_date")
      ? "completed"
      : new Date(get(task, "due_date")) < new Date()
      ? "overdue"
      : "current",
  };
};

export const modifyContacts = (data) =>
  data.map((contact) => modifyContactData(contact));

export const modifyContactData = (contact = {}) => {
  return {
    label: `${get(contact, "first_name")} ${get(contact, "last_name")}`,
    value: get(contact, "id"),
  };
};

export const modifyUsers = (data) => data.map((user) => modifyUserData(user));

export const modifyUserData = (user = {}) => {
  return {
    label: get(user, "name"),
    value: get(user, "id"),
  };
};

export const modifySites = (data) => data.map((site) => modifySiteData(site));

export const modifySiteData = (site = {}) => {
  return {
    label: get(site, "name"),
    value: get(site, "id"),
  };
};

export const modifyComments = (data) =>
  data.map((site) => modifyCommmentData(site));

export const modifyCommmentData = (comment = {}) => {
  return {
    ...comment,
    author: get(comment, "user.name"),
  };
};

export const modifyOrganisations = (data) =>
  data.map((organisation) => modifyOrganisationData(organisation));

export const modifyOrganisationData = (organisation = {}) => {
  return {
    label: get(organisation, "business_name"),
    value: get(organisation, "id"),
  };
};
