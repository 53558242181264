import React from "react";
import { Formik } from "formik";
import Dropzone from "react-dropzone";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { ListButton } from "../../components/ListButton";
import { ReactComponent as UploadIcon } from "../../static/UploadIcon.svg";

export const FileNameForm = ({
  handleClose,
  handleSubmit,
  setUploadedFile,
  uploadedFile,
  loading,
}) => {
  const initialValues = {};

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ handleSubmit, isSubmitting }) => (
        <div>
          <div className="text-center">
            <h3 className="mb-5">Upload Your File</h3>
            {uploadedFile ? (
              <div className="mx-5 mb-5">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="mr-5">{uploadedFile.name}</div>
                  <div>{<CheckCircleIcon className="text-success" fontSize="large" />}</div>
                </div>
              </div>
            ) : null}
            <div>
              <Dropzone onDrop={(acceptedFiles) => setUploadedFile((acceptedFiles || [])[0])}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps()}
                      style={{
                        padding: 60,
                        borderWidth: 2,
                        borderRadius: 2,
                        borderColor: "#64A70B",
                        borderStyle: "solid",
                        backgroundColor: "#ffffff",
                        color: "#828282",
                        outline: "none",
                        transition: "border .24s ease-in-out",
                        cursor: "pointer",
                      }}
                    >
                      <input {...getInputProps()} />
                      <p className="mb-7">
                        <UploadIcon />
                      </p>
                      <p style={{ fontSize: "16px" }}>
                        Drag File on to this zone or click to browse for the PDF
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          </div>

          <div className="d-flex justify-content-end mt-10">
            <ListButton
              label="Cancel"
              boxShadow={false}
              onClick={handleClose}
              size="medium"
              className="mr-3"
              variant="outlined"
              text="#407A28"
              disabled={isSubmitting}
            />
            <ListButton
              label="Submit"
              size="medium"
              boxShadow={false}
              onClick={handleSubmit}
              disabled={isSubmitting || !uploadedFile}
            />
          </div>
        </div>
      )}
    </Formik>
  );
};
