export const productTypes = {
  SET_DATA: "PRODUCTS/SET_DATA",
  REMOVE_DATA: "PRODUCTS/REMOVE_DATA",
  UPDATE_DATA: "PRODUCTS/UPDATE_DATA",
};

export function setProductsList(data) {
  return {
    type: productTypes.SET_DATA,
    payload: data,
  };
}

export function removeProduct(data) {
  return {
    type: productTypes.REMOVE_DATA,
    payload: data,
  };
}

export function updateProductsList(data) {
  return {
    type: productTypes.UPDATE_DATA,
    payload: data,
  };
}
