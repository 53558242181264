export const HEADINGS = [
  ["first_name", "First Name"],
  ["last_name", "Last Name"],
  ["badges", "Badges"],
  ["position_title", "Job Title"],
  ["phone", "Phone"],
  ["mobile", "Mobile"],
  ["email", "Email"],
  ["status", "Status"],
];

export const searchMap = {
  "First Name": "first_name",
  "Last Name": "last_name",
  Email: "email",
  Phone: "phone",
  Mobile: "mobile",
  Tag: "tag",
  "LGA Region": "lga_region",
};

export const SEARCH_HELPER = [
  ["first_name", "First Name"],
  ["last_name", "Last Name"],
  ["email", "Email"],
  ["phone", "Phone"],
  ["mobile", "Mobile"],
  ["tag", "Tag"],
  ["lga_region", "LGA Region"],
];

export const COPY_HELPER = [
  ["first_name", "First Name"],
  ["last_name", "Last Name"],
  ["email", "Email"],
  ["phone", "Phone"],
  ["mobile", "Mobile"],
  ["tags.name", "Tag"],
  ["lga_region", "LGA Region"],
];
