import { Formik } from "formik";
import React, { useState } from "react";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { LinearProgress } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { ListButton } from "../../../ui/components/ListButton";
import { InstantProcessSchema } from "../../../helpers/schemas";
import { useFetch } from "../../../hooks/fetch.hook";
import { createReimbursements } from "../DrumMuster/Inspections/_api";
import { info } from "../../../helpers/toasts";
import { Autocomplete } from "../../../ui/components/Autocomplete";

export const InstantProcess = ({
  handleClose,
  processorsLoading,
  processorOptions,
  siteOptions,
  sitesLoading
}) => {
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const initialValues = {
    site_id: "",
    organisation_id: "",
    plastic_0: "",
    plastic_1: "",
    plastic_2: "",
    plastic_3: "",
    steel_0: "",
    steel_1: "",
    steel_2: "",
    steel_3: ""
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const ccds = [
      { size: "20", material: "plastic", quantity: Number(values.plastic_0) },
      {
        size: "20-25",
        material: "plastic",
        quantity: Number(values.plastic_1)
      },
      {
        size: "26-120",
        material: "plastic",
        quantity: Number(values.plastic_2)
      },
      {
        size: "121-205",
        material: "plastic",
        quantity: Number(values.plastic_3)
      },
      { size: "20", material: "steel", quantity: Number(values.steel_0) },
      { size: "20-25", material: "steel", quantity: Number(values.steel_1) },
      { size: "26-120", material: "steel", quantity: Number(values.steel_2) },
      { size: "121-205", material: "steel", quantity: Number(values.steel_3) }
    ].filter(({ quantity }) => quantity);
    const payload = {
      ccds,
      instant_inspect_and_process: true,
      force: true,
      ...values
    };
    setLoading(true);
    request(createReimbursements, payload)
      .then(data => {
        if (!data) return;
        info("Success!");
        handleClose();
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  return (
    <div className="px-6 pb-10">
      <h3 className="mb-10">Instant Processing</h3>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={InstantProcessSchema}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue, ...formik }) => (
          <div>
            {!processorsLoading ? (
              <div className="mb-5">
                <Autocomplete
                  name="organisation_id"
                  placeholder="Select Processor"
                  setValue={setFieldValue}
                  options={processorOptions}
                  defaultValueField="value"
                />
                {formik.touched.organisation_id &&
                formik.errors.organisation_id ? (
                  <div className="text-danger">
                    {formik.errors.organisation_id}
                  </div>
                ) : null}
              </div>
            ) : (
              <Skeleton variant="rect" width={"100%"} height={55} />
            )}
            {!sitesLoading ? (
              <div className="mb-2">
                <Autocomplete
                  name="site_id"
                  placeholder="Select Site"
                  setValue={setFieldValue}
                  options={siteOptions}
                  defaultValueField="value"
                />
                {formik.touched.site_id && formik.errors.site_id ? (
                  <div className="text-danger">{formik.errors.site_id}</div>
                ) : null}
              </div>
            ) : (
              <Skeleton variant="rect" width={"100%"} height={55} />
            )}
            <div className="d-flex justify-content-between mt-5">
              <div className="w-50">
                <h4>Plastic</h4>
                <div className="d-flex align-items-center">
                  <div className="col-3">Less than 20</div>
                  <div className="col-5 ">
                    <MappedInput name="plastic_0" />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="col-3">20-25</div>
                  <div className="col-5 ">
                    <MappedInput name="plastic_1" />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="col-3">26-120</div>
                  <div className="col-5 ">
                    <MappedInput name="plastic_2" />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="col-3">121-205</div>
                  <div className="col-5 ">
                    <MappedInput name="plastic_3" />
                  </div>
                </div>
              </div>
              <div className="w-50">
                <h4>Steel</h4>
                <div className="d-flex align-items-center">
                  <div className="col-3">Less than 20</div>
                  <div className="col-5 ">
                    <MappedInput name="steel_0" />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="col-3">20-25</div>
                  <div className="col-5 ">
                    <MappedInput name="steel_1" />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="col-3">26-120</div>
                  <div className="col-5 ">
                    <MappedInput name="steel_2" />
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="col-3">121-205</div>
                  <div className="col-5 ">
                    <MappedInput name="steel_3" />
                  </div>
                </div>
              </div>
            </div>
            <div className=" mt-8 text-right">
              <ListButton
                label="Submit"
                size="medium"
                className="px-12"
                boxShadow={false}
                onClick={handleSubmit}
                disabled={isSubmitting}
              />
            </div>
            {loading ? (
              <div className="mt-5">
                <LinearProgress color="secondary" />
              </div>
            ) : null}
          </div>
        )}
      </Formik>
    </div>
  );
};
