import config from "react-global-configuration";

export const submitSupportEmail = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/send_support_email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });
