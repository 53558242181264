import React, { useState } from "react";
import { Calendar as CalendarComponent } from "react-multi-date-picker";
import { useHistory } from "react-router-dom";
import { Modal } from "../../../ui/components/Modal";
import { SelectTask } from "./SelectTask";
import "./CalendarStyle.css";
import { format } from "date-fns";

export function Calendar({ otherTasks = [], eventTasks = [] }) {
  const history = useHistory();

  const eventDates = eventTasks.map(({ date }) => date);
  const otherDates = otherTasks.map(({ date }) => date);
  const allDates = [
    format(new Date(Date.now()), "yyyy,M,d"),
    ...eventDates,
    ...otherDates,
  ];
  const tasksList = [...eventTasks, ...otherTasks];

  const [multipleTasksSelected, setMultipleTasksSelected] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = (tasks) => {
    setModalOpen(true);
    setMultipleTasksSelected(tasks);
  };
  const handleModalClose = () => {
    setModalOpen(false);
    setMultipleTasksSelected([]);
  };

  const handleClick = (date) => {
    const tasks = tasksList.filter((task) => task.date === date);
    if (!tasks.length) return;
    else if (tasks.length === 1) {
      history.push({
        pathname: "/tasks",
        taskID: tasks[0].id,
      });
    } else if (tasks.length > 1) {
      handleModalOpen(tasks);
    }
  };

  const isToday = (date) => {
    return date === format(new Date(Date.now()), "yyyy,M,d");
  };

  return (
    <>
      {modalOpen && (
        <Modal
          maxWidth="md"
          isOpen={modalOpen}
          submitable
          onClose={handleModalClose}
          modalContent={
            <SelectTask
              tasks={multipleTasksSelected}
              handleClose={handleModalClose}
            />
          }
        />
      )}
      <CalendarComponent
        multiple
        shadow={false}
        value={allDates}
        mapDays={({ date }) => {
          const formatedDate = `${date.year},${date.monthIndex + 1},${
            date.day
          }`;
          if (
            eventDates.includes(formatedDate) &&
            otherDates.includes(formatedDate)
          )
            return {
              style: { backgroundColor: "#5B8D88" },
              onClick: () => handleClick(formatedDate),
            };
          else if (eventDates.includes(formatedDate))
            return {
              style: { backgroundColor: "#2196F3" },
              onClick: () => handleClick(formatedDate),
            };
          else if (otherDates.includes(formatedDate))
            return {
              style: { backgroundColor: "#64A70B" },
              onClick: () => handleClick(formatedDate),
            };
          else if (isToday(formatedDate))
            return {
              style: { backgroundColor: "#7fdbff" },
            };
          else
            return {
              disabled: true,
              style: { color: "black" },
            };
        }}
      />
    </>
  );
}
