import React from "react";
import { Chip } from "@material-ui/core";
import { useDrag } from "react-dnd";

const getChipStyle = type => {
  if (type === "event")
    return {
      color: "#00C853",
      backgroundColor: "#ECF9F3"
    };
  else
    return {
      color: "#2196F3",
      backgroundColor: "#E3F2FD"
    };
};

export const TaskCard = ({
  handleModalOpen,
  data = {},
  contacts,
  selectedTaskStatusChange
}) => {
  const {
    due_date,
    task_type,
    status,
    task_name,
    id,
    linked_entity,
    archive_status
  } = data;
  const [, drag] = useDrag(() => ({
    type: "task",
    item: { id }
  }));

  const getStatusColor = () => {
    if (archive_status === "archived") return "#F44336";
    else return "#7CB342";
  };

  const renderTaskStatus = () => {
    return (
      <div
        style={{
          background: getStatusColor(),
          width: 16,
          height: 16,
          borderRadius: "50%"
        }}
      ></div>
    );
  };

  return (
    <div
      className={`bg-white shadow-sm px-5 ${
        status === "completed" ? "py-2" : "py-4"
      } mb-4 border cursor-pointer`}
      ref={drag}
      onClick={() => handleModalOpen(id)}
    >
      {status === "completed" ? (
        <>
          <div className="d-flex justify-content-end">{renderTaskStatus()}</div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p>
                <strong>{task_name}</strong>
              </p>
              <p>{linked_entity}</p>
            </div>
            <div>
              {task_type ? (
                <Chip
                  label={task_type.split("_").join(" ")}
                  style={{
                    minWidth: "90px",
                    fontSize: "14px",
                    textTransform: "uppercase",
                    ...getChipStyle(task_type)
                  }}
                />
              ) : null}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <h5>
              <strong>{task_name}</strong>
            </h5>
            <div>{renderTaskStatus()}</div>
          </div>
          <div className="row mt-5 mb-2">
            <div className="col-3">
              <p className="text-muted">Due Date</p>
            </div>
            <div className="col-5 mr-auto">
              {due_date && new Date(due_date).toDateString()}
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-6">
              <p className="mb-0">{linked_entity}</p>
            </div>
            <div className="col-6 text-right">
              {task_type ? (
                <Chip
                  label={task_type.split("_").join(" ")}
                  style={{
                    minWidth: "90px",
                    fontSize: "14px",
                    textTransform: "uppercase",
                    ...getChipStyle(task_type)
                  }}
                />
              ) : null}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
