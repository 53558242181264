/* eslint-disable react-hooks/exhaustive-deps */
import { Skeleton } from "@material-ui/lab";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useFetch } from "../../../hooks/fetch.hook";
import { SiteAudits } from "../../submodules/Sites/SiteAudits";
import { SiteDetails } from "./SiteDetails";
import { getTags } from "../Settings/Tags/_api";
import {
  getOrganisationInspectors,
  getSiteAudits,
  getSiteById,
  getSitesStatisitc,
  getSiteTags,
} from "./_api";
import { getSite } from "./_redux/selectors";
import { sortHelper } from "../../../ui/helpers";
import { getSiteCCDs } from "../DrumMuster/Inspections/_api";
import {
  getOrgContactsSimple,
  getSimpleOrganisations,
} from "../Organisations/_api";
import { get } from "lodash";
import {
  getBulkEntryStatistics,
  getSiteStatistics,
} from "../../submodules/Sites/_api";

export const Card = ({
  history,
  match: {
    params: { id },
  },
}) => {
  const { request } = useFetch();
  const data = useSelector(getSite(id));

  const [cardData, setCardData] = useState({});
  const [component, setComponent] = useState("details");
  const [tags, setTags] = useState([]);
  const [linkedTags, setLinkedTags] = useState([]);
  const [linkedTagsLoading, setLinkedTagsLoading] = useState(false);
  const [inspectorsList, setInspectorsList] = useState([]);
  const [containersAmount, setContainersAmount] = useState(0);
  const [orgContacts, setOrgContacts] = useState([]);
  const [chartData, setChartData] = useState({});
  const [chartLoading, setChartLoading] = useState(false);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [siteStatistic, setSiteStatistic] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [organisationsLoading, setOrganisationsLoading] = useState(false);
  const [entryStatistic, setEntryStatistic] = useState(0);
  const [siteAudits, setSiteAudits] = useState([]);
  const [siteAuditsLoading, setSiteAuidtsLoading] = useState(false);

  useEffect(() => {
    setSiteAuidtsLoading(true);
    request(getSiteAudits, id)
      .then((data) => data && setSiteAudits(data))
      .finally(() => setSiteAuidtsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!data && id) {
      request(getSiteById, id).then((data) => {
        if (!data) return;
        setCardData(data);
        if (data.organisation_id) {
          setOptionsLoading(true);
          Promise.all([
            request(getOrganisationInspectors, data.organisation_id),
            request(
              getOrgContactsSimple,
              data.organisation_id,
              "simple=true&fields=id,first_name,last_name"
            ),
          ])
            .then(([inspectors, contacts]) => {
              inspectors && setInspectorsList(inspectors);
              contacts && setOrgContacts(contacts);
            })
            .finally(() => setOptionsLoading(false));
        }
      });
    } else {
      setCardData(data);
      if (data.organisation_id) {
        setOptionsLoading(true);
        Promise.all([
          request(getOrganisationInspectors, data.organisation_id),
          request(
            getOrgContactsSimple,
            data.organisation_id,
            "simple=true&fields=id,first_name,last_name"
          ),
        ])
          .then(([inspectors, contacts]) => {
            inspectors && setInspectorsList(inspectors);
            contacts && setOrgContacts(contacts);
          })
          .finally(() => setOptionsLoading(false));
      }
    }
  }, [id]);

  useEffect(() => {
    setOrganisationsLoading(true);
    request(getSimpleOrganisations)
      .then((data) => data && setOrganisations(data))
      .finally(() => setOrganisationsLoading(false));
  }, []);

  useEffect(() => {
    request(getTags, "category=_like(Site)_").then(
      (tags) => tags && setTags(tags)
    );
  }, []);

  useEffect(() => {
    setChartLoading(true);
    request(getSitesStatisitc, id)
      .then((data) => data && setChartData(data))
      .finally(() => setChartLoading(false));
  }, []);

  useEffect(() => {
    if (id) {
      setLinkedTagsLoading(true);
      request(getSiteTags, id)
        .then((linkedTags) => linkedTags && setLinkedTags(linkedTags))
        .finally(() => setLinkedTagsLoading(false));
      request(getSiteCCDs, id).then(
        (data = []) =>
          data &&
          setContainersAmount(
            data
              .map(({ quantity }) => quantity)
              .reduce((acc, value) => acc + value, 0)
          )
      );
    }
  }, []);

  useEffect(() => {
    if (!id) return;
    request(getSiteStatistics, id).then(
      (data) => data && setSiteStatistic(data)
    );
    request(getBulkEntryStatistics, id).then(
      (data) => data && setEntryStatistic(data)
    );
  }, []);

  const handleCancel = () => history.push("/sites-list");

  const handleExpand = (id) => () => {
    setComponent("details");
  };

  const handleAudit = () => {
    setComponent("audit");
  };

  const organiastionOptions = useMemo(
    () =>
      organisations.map((organiastion) => ({
        value: organiastion.id,
        label: organiastion.business_name,
      })),
    [organisations]
  );

  const inspectorsOptions = useMemo(
    () =>
      inspectorsList
        .map((contact) => ({
          value: contact.id,
          label: [contact.first_name, contact.last_name].join(" "),
        }))
        .sort(sortHelper),
    [inspectorsList]
  );

  const orgContactsOptions = orgContacts
    .map((contact) => ({
      value: get(contact, "id"),
      label: [get(contact, "first_name"), get(contact, "last_name")].join(" "),
    }))
    .sort(sortHelper);

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  const componentsMap = {
    details: (
      <SiteDetails
        data={cardData}
        id={id}
        handleCancel={handleCancel}
        handleAudit={handleAudit}
        tags={tags}
        linkedTags={linkedTags}
        inspectorsOptions={inspectorsOptions}
        containersAmount={containersAmount}
        orgContactsOptions={orgContactsOptions}
        chartData={chartData}
        chartLoading={chartLoading}
        optionsLoading={optionsLoading}
        siteStatistic={siteStatistic}
        organiastionOptions={organiastionOptions}
        organisationsLoading={organisationsLoading}
        linkedTagsLoading={linkedTagsLoading}
        entryStatistic={entryStatistic}
        siteAuditsLoading={siteAuditsLoading}
        siteAudits={siteAudits}
      />
    ),
    audit: <SiteAudits handleExpand={handleExpand} id={id} site={cardData} />,
  };

  return <>{componentsMap[component]}</>;
};
