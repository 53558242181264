import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { LinearProgress } from "@material-ui/core";
import MoodleIcon from "../Integrations/static/Moodle.png";
import { ListButton } from "../../../ui/components/ListButton";
import { Autocomplete } from "../../../ui/components/Autocomplete";
import { useFetch } from "../../../hooks/fetch.hook";
import { inviteForTraining } from "./_api";
import { info } from "../../../helpers/toasts";

const initialValues = {
  training_id: "",
};

export function TrainingInvite({
  handleClose,
  contact = {},
  trainingOptions = [],
}) {
  const { first_name, last_name, email } = contact;
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);

  const handleSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    request(inviteForTraining, contact.id, [{ id: values.training_id }])
      .then((data) => {
        if (!data) return;
        info("Contact has been invited for the training!");
        handleClose();
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object({
        training_id: Yup.string().required(),
      })}
      onSubmit={handleSubmit}
    >
      {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
        <div className="px-5 pb-10">
          <div className="d-flex justify-content-between align-items-end">
            <h4>
              <strong>
                Create Training Program for {[first_name, last_name].join(" ")}
              </strong>
            </h4>

            <img src={MoodleIcon} alt="logo" />
          </div>
          <div className="px-12 mt-15 mb-10">
            <p className="mb-10">
              drumDRIVE is about to create a training invitation in Moodle.
              Before you click proceed you should verify that the user’s email
              address below is correct and specific to the user completing
              training. The email address must be unique to the user to ensure
              their training is mapped correctly to their profile.
            </p>
            <h4 className="mb-10">
              <strong>Email: {email}</strong>
            </h4>
            <Autocomplete
              name="training_id"
              placeholder="Select Training Course"
              setValue={setFieldValue}
              defaultValueField="value"
              options={trainingOptions}
            />
          </div>
          <div className="d-flex justify-content-end mt-20">
            <ListButton
              label="Cancel"
              className="mr-4 px-16"
              size="small"
              variant="outlined"
              boxShadow={false}
              text="#407A28"
              onClick={handleClose}
              disabled={isSubmitting}
              data-testid="cancel_training"
            />
            <ListButton
              label="Invite"
              size="small"
              className="px-20"
              disabled={isSubmitting}
              onClick={handleSubmit}
              data-testid="submit_training"
            />
          </div>
          {loading ? (
            <div className="mt-5">
              <LinearProgress color="secondary" />
            </div>
          ) : null}
        </div>
      )}
    </Formik>
  );
}
