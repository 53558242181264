/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { Stepper, Step, StepLabel, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { CreateClaim } from "./CreateClaim";
import { SubmitInvoice } from "./SubmitInvoice";

import { useFetch } from "../../../../hooks/fetch.hook";
import { getSimpleOrganisations } from "../../Organisations/_api";
import { Autocomplete } from "../../../../ui/components/Autocomplete";
import { sortHelper } from "../../../../ui/helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
  },
}));

export const Claims = () => {
  const [step, setStep] = useState(0);
  const { request } = useFetch();
  const [claimsListLoading, setClaimsListLoading] = useState(false);
  const [orgsLoading, setOrgsLoading] = useState(false);
  const [organisations, setOrganisations] = useState([]);
  const [organisation, setOrganisation] = useState({});

  useEffect(() => {
    setOrgsLoading(true);
    request(getSimpleOrganisations)
      .then((data) => {
        data && setOrganisations(data);
        (data || []).length === 1 &&
          setOrganisation({ value: data[0].id, label: data[0].business_name });
      })
      .finally(() => setOrgsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const organisationsOptions = useMemo(
    () => [
      ...(organisations || [])
        .map((organiastion) => ({
          value: organiastion.id,
          label: organiastion.business_name,
        }))
        .sort(sortHelper),
    ],
    [organisations]
  );

  const handleNextStep = () => setStep(step + 1);

  const handlePrevStep = () => setStep(step - 1);

  const stepsContent = [
    <CreateClaim
      handleNextStep={handleNextStep}
      ORGANISATION_ID={organisation?.value || ""}
    />,
    <SubmitInvoice
      handlePrevStep={handlePrevStep}
      handleNextStep={handleNextStep}
      ORGANISATION_ID={organisation?.value || ""}
      loading={claimsListLoading}
      setLoading={setClaimsListLoading}
    />,
    <SubmitInvoice
      handlePrevStep={handlePrevStep}
      handleNextStep={handleNextStep}
      ORGANISATION_ID={organisation?.value || ""}
      loading={claimsListLoading}
      setLoading={setClaimsListLoading}
    />,
  ];

  const steps = [
    {
      label: "Select Drop Offs",
      icon: <PersonAddIcon fontSize="large" />,
      key: "claim",
    },
    {
      label: "Create Claim",
      icon: <PersonAddIcon fontSize="large" />,
      key: "invoice",
    },
    {
      label: "Submit Invoice",
      icon: <PersonAddIcon fontSize="large" />,
      key: "invoice",
    },
  ];

  const classes = useStyles();

  const getDefaultValue = () => {
    if (organisation !== null && typeof organisation === "object") {
      return Object.keys(organisation).length ? organisation : null;
    } else return null;
  };

  return (
    <div>
      <Stepper activeStep={step} classes={classes} alternativeLabel>
        {steps.map(({ label }, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <div className="mt-5 py-10 bg-white d-flex px-10 align-items-center">
        <div className="d-flex">
          <span>Claim Payee</span>

          <div className="ml-7 px-2" style={{ minWidth: "280px" }}>
            {orgsLoading || claimsListLoading ? (
              <Skeleton variant="rect" width={"100%"} height={40} />
            ) : (
              <Autocomplete
                size="small"
                placeholder="Select Organisation"
                defaultValueField="value"
                value={organisation}
                defaultValue={getDefaultValue()}
                onChange={(event, newValue) => {
                  setOrganisation(newValue);
                }}
                options={organisationsOptions}
                sortable={false}
                getOptionLabel={(option) => option.label || ""}
                getOptionSelected={(option, value) =>
                  option.value === value.value
                }
                inputStyle={{}}
              />
            )}
          </div>
        </div>
      </div>

      <div>{stepsContent.find((item, index) => index === step)}</div>
    </div>
  );
};
