import { Formik } from "formik";
import React from "react";
import Input, { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedSwitch } from "../../../ui/components/MappedSwitch";
import { MappedDatePicker } from "@bit/the-glue.frontendcomponents.datepicker";
import { ListButton } from "../../../ui/components/ListButton";
import { CompleteClearanceSchema } from "../../../helpers/schemas";
import { get } from "lodash";

export const CompleteClearance = ({ handleSubmit, data = {} }) => {
  const initialValues = {
    site_name: data.site_name || "",
    pickup_date: new Date(Date.now()),
    site_cleared: false,
    collected_quantity: data.volume || "",
    volume_remaining: 0,
    material: data.material || "",
    20: "",
    "20-25": "",
    "26-120": "",
    "121-205": "",
  };
  return (
    <div className="px-6 pb-10" data-testid="complete-clearance">
      <h3 className="mb-10">Complete Clearance</h3>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={CompleteClearanceSchema}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <div>
            <MappedInput
              name="site_name"
              label="Site"
              disabled
              data-testid="site"
            />
            <Input
              label="Processor"
              disabled
              value={get(data, "collection_run.processor.business_name", "")}
              data-testid="processor"
            />
            <MappedDatePicker
              name="pickup_date"
              label="Pickup Date"
              data-testid="pickup_date"
            />
            <div className="d-flex justify-content-between">
              <MappedInput
                name="collected_quantity"
                label="Volume Collected"
                data-testid="collected_quantity"
              />
              <div className="ml-5 w-100">
                <MappedInput
                  name="volume_remaining"
                  label="Volume Remaining"
                  data-testid="volume_remaining"
                />
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <MappedInput
                name="material"
                label="Material"
                disabled
                data-testid="material"
              />
            </div>
            <div className="d-flex align-items-center mt-2">
              <div>Site Cleared</div>
              <MappedSwitch name="site_cleared" checked={values.site_cleared} />
            </div>
            <h3>Processed sizes</h3>
            <div className="d-flex align-items-center">
              <div className="col-3">Less than 20</div>
              <div className="col-5 ">
                <MappedInput name="20" data-testid="size-20" />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="col-3">20-25</div>
              <div className="col-5 ">
                <MappedInput name="20-25" />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="col-3">26-120</div>
              <div className="col-5 ">
                <MappedInput name="26-120" />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="col-3">121-205</div>
              <div className="col-5 ">
                <MappedInput name="121-205" />
              </div>
            </div>
            <div className=" mt-5 text-right">
              <ListButton
                label="Confirm"
                size="medium"
                className="px-12"
                boxShadow={false}
                onClick={handleSubmit}
                disabled={isSubmitting}
              />
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};
