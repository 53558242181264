import React from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { IconButton } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import CustomerDropdown from "./CustomerDropdown";
import { get } from "lodash";
import { formatPrice } from "../../helpers";

const loadingItemsState = "12345";

const getConsultantName = (organisation = {}) => {
  if (get(organisation, "consultant.first_name", "")) {
    return `(${[
      get(organisation, "consultant.first_name", ""),
      get(organisation, "consultant.last_name", "")
    ].join(" ")})`;
  } else return null;
};

export const CustomerList = ({ title, data = [], loading }) => {
  if (loading)
    return loadingItemsState.split("").map(item => (
      <div className="d-flex align-items-center mb-4" key={item}>
        <div className="ml-5 w-100">
          <div>
            <Skeleton variant="text" width="80%" />
          </div>
          <div style={{ fontSize: "12px" }}>
            <Skeleton variant="text" width="30%" />
          </div>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <IconButton>
            <MoreVertIcon />
          </IconButton>
        </div>
      </div>
    ));
  return (
    <>
      <div className="bg-white py-5 px-6" style={{ minHeight: "335px" }}>
        <div className="mb-10" style={{ fontSize: "14px" }}>
          <strong>{title}</strong>
        </div>
        {data.length ? (
          data.map(({ organisation, total }) => (
            <div
              className="d-flex align-items-center mb-4"
              key={get(organisation, "id", "")}
            >
              <div className="ml-5">
                <div>
                  <strong>
                    {get(organisation, "business_name", "")}{" "}
                    {getConsultantName(organisation)}
                  </strong>
                </div>
                <div style={{ fontSize: "12px" }}>{formatPrice(total)}</div>
              </div>
              <div style={{ marginLeft: "auto" }}>
                <CustomerDropdown id={get(organisation, "id", "")} />
              </div>
            </div>
          ))
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <strong>No data found</strong>
          </div>
        )}
      </div>
    </>
  );
};
