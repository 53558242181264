import Input from "@bit/the-glue.frontendcomponents.input";
import React, { useState } from "react";
import { inputCellStyle, quantityCellStyle } from "./constants";

export const InputsRow = ({ header, plasticName, steelName, data = {}, handleUpdateField }) => {
  const [plasticValue, setPlasticValue] = useState(data[plasticName]);
  const [steelValue, setSteelValue] = useState(data[steelName]);

  const handlePlasticChange = (e) => {
    setPlasticValue(e.target.value);
    handleUpdateField(plasticName, e.target.value);
  };

  const handleSteelChange = (e) => {
    setSteelValue(e.target.value);
    handleUpdateField(steelName, e.target.value);
  };

  return (
    <tr>
      <td className="text-center pt-5 px-2" style={quantityCellStyle}>
        {header}
      </td>
      <td style={inputCellStyle} className="pt-5 px-2">
        <Input value={plasticValue} onChange={handlePlasticChange} />
      </td>
        <>
          <td className="text-center pt-5 px-2" style={quantityCellStyle}>
            {header}
          </td>
          <td style={inputCellStyle} className="pt-5 px-2">
            <Input value={steelValue} onChange={handleSteelChange} />
          </td>
        </>
    </tr>
  );
};
