import React, { useState, useEffect } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { useHistory } from "react-router-dom";
import { ListButton } from "../../../../ui/components/ListButton";
import { EditableGrid } from "./EditableGrid";
import { setID } from "./helpers";
import { useFetch } from "../../../../hooks/fetch.hook";
import { createDrops, editDrop, getDropById } from "./_api";
import { error, info } from "../../../../helpers/toasts";
import { Loader } from "../../../../ui/components/Loader";
import { removeEmptyFields } from "../../../../ui/helpers";
import { modifyDrop } from "../Claims/helpers";

const CCDItem = {
  id: 0,
  plastic_0: "",
  plastic_1: "",
  plastic_2: "",
  plastic_3: "",
  steel_0: "",
  steel_1: "",
  steel_2: "",
  steel_3: "",
  drop_off_date: Date.now(),
  contact_id: "",
  site_id: "",
  organisation_id: "",
  inspector_id: "",
  rejected: "",
  bulk_entry: false,
  number_of_deliveries: "",
  initial_status: "new"
};

export const Inspections = ({
  match: {
    params: { id }
  }
}) => {
  const { request } = useFetch();
  const history = useHistory();

  const [data, setData] = useState([CCDItem]);
  const [failedItems, setFailedItmes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dropLoading, setDropLoading] = useState(false);
  const [resetKey, setResetKey] = useState(0);

  useEffect(() => {
    if (!id) return;
    setDropLoading(true);
    request(getDropById, id)
      .then(data => data && setData([modifyDrop(data)]))
      .finally(() => setDropLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (dropLoading) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  const handleNewItem = () => {
    setData(oldData => [
      ...oldData,
      { ...CCDItem, id: setID(10000000, oldData) }
    ]);
  };

  const handleRemoveItem = id => {
    setData(state => state.filter(item => (item || {}).id !== id));
  };

  const transformData = (data = {}) => {
    return data.map(
      ({
        drop_off_date,
        organisation_id,
        inspector_id,
        rejected,
        site_id,
        contact_id,
        bulk_entry,
        number_of_deliveries,
        plastic_0,
        plastic_1,
        plastic_2,
        plastic_3,
        steel_0,
        steel_1,
        steel_2,
        steel_3
      }) => {
        const ccds = [
          { size: "20", material: "plastic", quantity: Number(plastic_0) },
          { size: "20-25", material: "plastic", quantity: Number(plastic_1) },
          { size: "26-120", material: "plastic", quantity: Number(plastic_2) },
          { size: "121-205", material: "plastic", quantity: Number(plastic_3) },
          { size: "20", material: "steel", quantity: Number(steel_0) },
          { size: "20-25", material: "steel", quantity: Number(steel_1) },
          { size: "26-120", material: "steel", quantity: Number(steel_2) },
          { size: "121-205", material: "steel", quantity: Number(steel_3) }
        ].filter(({ quantity }) => quantity);
        const payload = {
          drop_off_date: new Date(drop_off_date),
          organisation_id,
          inspector_id,
          rejected,
          site_id,
          contact_id,
          number_of_deliveries,
          bulk_entry,
          ccds,
          type: "inspection"
        };
        removeEmptyFields(payload);
        return payload;
      }
    );
  };

  const getFailedItems = () => {
    setData(
      data.map(item => ({
        ...item,
        siteError: item.site_id ? null : "Site is a required field!",
        dateError: item.drop_off_date ? null : "Date is a required field!",
        initial_status: item.site_id && item.drop_off_date ? "new" : "failed"
      }))
    );
    return data.filter(item => !item.site_id || !item.drop_off_date);
  };

  const submitRequest = (payload = []) => {
    return id
      ? request(editDrop, payload[0], id).then(data => {
          if (!data) return;
          info("Inspections details have been saved!!");
          history.push("/drummuster/claims");
        })
      : request(createDrops, payload).then(data => {
          if (!data) return;
          setData([CCDItem]);
          setFailedItmes([]);
          setResetKey(Date.now());
          info("Inspections have been submitted!");
        });
  };

  const handleSubmit = () => {
    const notValidatedItems = getFailedItems().map(item => (item || {}).id);
    setFailedItmes(notValidatedItems);
    if (notValidatedItems.length) {
      error("Some of the items are missing required fields!");
    } else {
      const payload = transformData(data);
      if (!payload.length) {
        error("Data should have at least one container!");
        return;
      }
      setLoading(true);
      submitRequest(payload).finally(() => setLoading(false));
    }
  };

  return (
    <>
      <Loader
        isOpen={loading}
        maxWidth="xs"
        disableBackdropClick
        disableEscapeKeyDown
      />
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div>
            <EditableGrid
              data={data}
              handleNewItem={handleNewItem}
              handleRemoveItem={handleRemoveItem}
              setData={setData}
              failedItems={failedItems}
              id={id}
              key={resetKey}
            />
            <div className="d-flex justify-content-end mt-10">
              {id ? (
                <ListButton
                  label="Cancel"
                  size="large"
                  onClick={() => history.push("/drummuster/claims")}
                  variant="outlined"
                  boxShadow={false}
                  text="#407A28"
                  className="mr-5"
                />
              ) : null}
              <ListButton
                label={id ? "Submit" : `Submit Inspections`}
                size="large"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
