/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import Tabs from "@bit/the-glue.frontendcomponents.tabs";
import { booleanOptions, statusOptions } from "./constants";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  addSite,
  getBulkEntryStatistics,
  getSiteStatistics,
  modifySite,
} from "./_api";
import { SiteCardSchema } from "../../../helpers/schemas";
import { ListButton } from "../../../ui/components/ListButton";
import { Chemclear } from "./ChemClear";
import { DrumMuster } from "./DrumMuster";
import {
  deleteSiteTags,
  editSiteTags,
  getSiteAudits,
  getSitesStatisitc,
  getSiteTags,
} from "../../modules/Sites/_api";
import { Loader } from "../../../ui/components/Loader";
import { TagsForm } from "../../../ui/structures/TagsForm";
import { info } from "../../../helpers/toasts";
import { SubmitActions } from "../../../ui/structures/SubmitActions";
import { modifyEmptyFields } from "../../../ui/helpers";
import { MappedCheckbox } from "../../../ui/components/Checkbox";
import { inductionOptions } from "../../modules/Sites/constants";
import { InductionType } from "../../modules/Sites/InductionType";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { getLGARegionByPostcode } from "../../modules/Settings/Regions/_api";
import { Skeleton } from "@material-ui/lab";
import { Location } from "../../../ui/structures/Location";
import { Modal } from "../../../ui/components/Modal";
import { SiteAddress } from "../../modules/Sites/SiteAddress";
import { MappedDatePicker } from "@bit/the-glue.frontendcomponents.datepicker";
import { get } from "lodash";
import { Autocomplete } from "../../../ui/components/Autocomplete";
import { LGARegionSelect } from "../../../ui/components/LGARegionSelect";
import { AccessControl } from "../../../ui/structures/AccessControl";
import { AVAILABLE_ROLES } from "../../constants";
import { ConfirmNewAudit } from "../../modules/Sites/ConfirmNewAudit";

const options = {
  key: "MN71-JP16-MJ29-NK46",
  search: { countries: "AUS" },
};

export const SiteCard = ({
  data = {},
  id,
  handleCancel,
  handleAudit,
  organisationId,
  tags = [],
  linkedTags = [],
  inspectorsOptions = [],
  orgContactsOptions = [],
  orgContactsLoading,
  setLinkedTags,
  containersAmount = 0,
  linkedTagsLoading,
  organisationsLoading,
  organiastionOptions = [],
}) => {
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [submitAction, setSubmitAction] = useState("Save");
  const [chartData, setChartData] = useState({});
  const [chartLoading, setChartLoading] = useState(false);
  const [mapModalOpen, setMapModalOpen] = useState(false);
  const [siteControl, setSiteControl] = useState({});
  const [siteValues, setSiteValues] = useState({});
  const [lgaRegions, setLgaRegions] = useState([]);
  const [siteStatistic, setSiteStatistic] = useState([]);
  const [entryStatistic, setEntryStatistic] = useState(0);
  const [lgaLoading, setLgaLoading] = useState(false);
  const [auditOpen, setAuditOpen] = useState(false);
  const [siteAudits, setSiteAudits] = useState([]);
  const [siteAuditsLoading, setSiteAuidtsLoading] = useState(false);

  useEffect(() => {
    if (!id) return;
    setSiteAuidtsLoading(true);
    request(getSiteAudits, id)
      .then((data) => data && setSiteAudits(data))
      .finally(() => setSiteAuidtsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMapOpen = () => setMapModalOpen(true);
  const handleMapClose = () => setMapModalOpen(false);

  const handleAuditOpen = () => setAuditOpen(true);
  const handleAuditClose = () => setAuditOpen(false);

  const initialValues = {
    name: data.name || "",
    phone: data.phone || "",
    email: data.email || "",
    status: data.status || "",
    invoice_email: data.invoice_email || "",
    notes: data.notes || "",
    physical_address: data.physical_address || "",
    physical_address2: data.physical_address2 || "",
    physical_city: data.physical_city || "",
    physical_state: data.physical_state || "",
    physical_postcode: data.physical_postcode || "",
    physical_latitude: data.physical_latitude || "",
    physical_longitude: data.physical_longitude || "",
    surface_type: data.surface_type || "",
    cc_collection_date_from: data.cc_collection_date_from
      ? new Date(data.cc_collection_date_from)
      : "",
    cc_collection_date_to: data.cc_collection_date_to
      ? new Date(data.cc_collection_date_to)
      : "",
    cc_level_surface: data.cc_level_surface || false,
    cc_forklift_available: data.cc_forklift_available || false,
    cc_nearby_waterways: data.cc_nearby_waterways || false,
    cc_program_participation: data.cc_program_participation || false,
    cc_dry_weather_access: data.cc_dry_weather_acces || false,
    dm_program_participation: data.dm_program_participation || false,
    dm_width: data.dm_width || "",
    dm_length: data.dm_length || "",
    dm_height: data.dm_height || "",
    dm_capacity: data.dm_capacity || "",
    dm_type: data.dm_type || "",
    dm_dry_weather_access: data.dm_dry_weather_acces || false,
    interference: data.interference || "",
    induction_type: data.induction_type || "",
    induction_text: data.induction_text || "",
    primary_inspector_id: data.primary_inspector_id || "",
    processor_pickup_scheduling_contact_id:
      data.processor_pickup_scheduling_contact_id || "",
    public_website_general_description:
      data.public_website_general_description || "",
    last_audit_date: get(data, "latest_site_audit.completed_at")
      ? new Date(data.latest_site_audit.completed_at)
      : "",
    lga_region: data.lga_region || "",
    organisation_id: data.organisation_id || organisationId,
  };

  useEffect(() => {
    if (data && data.physical_postcode) {
      request(getLGARegionByPostcode, data.physical_postcode).then((data) =>
        setLgaRegions(data.map((region) => ({ value: region, label: region })))
      );
    }
  }, [data]);

  useEffect(() => {
    setChartLoading(true);
    if (!id) return;
    request(getSitesStatisitc, id)
      .then((data) => data && setChartData(data))
      .finally(() => setChartLoading(false));
    request(getBulkEntryStatistics, id).then(
      (data) => data && setEntryStatistic(data)
    );
  }, []);

  useEffect(() => {
    if (!id) return;
    request(getSiteStatistics, id).then(
      (data) => data && setSiteStatistic(data)
    );
  }, []);

  useEffect(() => {
    const pca = window.pca;
    const siteFields = [
      { element: "site_address", field: "Line2", mode: pca.fieldMode.SEARCH },
      {
        element: "physical_address",
        field: "Line1",
        mode: pca.fieldMode.POPULATE,
      },
      {
        element: "physical_city",
        field: "City",
        mode: pca.fieldMode.POPULATE,
      },
      {
        element: "physical_postcode",
        field: "PostalCode",
        mode: pca.fieldMode.SEARCH | pca.fieldMode.POPULATE,
      },
      {
        element: "physical_state",
        field: "Province",
        mode: pca.fieldMode.POPULATE,
      },
    ];
    const siteControl = new window.pca.Address(siteFields, options);
    setSiteControl(siteControl);
    // eslint-disable-next-line
  }, []);

  const fetchLgaRegions = (values, setFieldValue) => {
    setLgaLoading(true);
    return request(getLGARegionByPostcode, values.physical_postcode)
      .then((data) => {
        if (!data) return;
        setLgaRegions(data.map((region) => ({ value: region, label: region })));
        if (!data.includes(values.lga_region)) setFieldValue("lga_region", "");
      })
      .finally(() => setLgaLoading(false));
  };

  const createSite = (values, setSubmitting) => {
    setLoading(true);
    request(addSite, { ...values, organisation_id: organisationId })
      .then((data) => {
        if (!data) return;
        handleCancel();
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const updateSite = (values, setSubmitting) => {
    setLoading(true);
    request(modifySite, values, id)
      .then((data) => {
        if (!data) return;
        info("Site details have been updated!");
        submitAction === "Save & Exit" && handleCancel();
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    modifyEmptyFields(values);
    id ? updateSite(values, setSubmitting) : createSite(values, setSubmitting);
  };

  const editTagsRequest = (values) => {
    if (values.tags.length) {
      return request(
        editSiteTags,
        id,
        values.tags.map((tagID) => ({ id: tagID }))
      );
    } else {
      return request(
        deleteSiteTags,
        id,
        linkedTags.map(({ id }) => ({ id }))
      );
    }
  };

  const handleSubmitTags = (values, { setSubmitting }) => {
    setLoading(true);
    editTagsRequest(values).then((data) => {
      if (!data) {
        setLoading(false);
        setSubmitting(false);
        return;
      }
      request(getSiteTags, id)
        .then((data) => data && setLinkedTags(data))
        .finally(() => {
          setSubmitting(false);
          setLoading(false);
        });
      info("Site tags have been updated!");
    });
  };

  const renderTabs = () => {
    let tabs = [];
    if (data.cc_program_participation) {
      tabs = [
        ...tabs,
        {
          label: "CHEMCLEAR",
          tabPanel: (
            <Chemclear chartData={chartData} chartLoading={chartLoading} />
          ),
        },
      ];
    }
    if (data.dm_program_participation) {
      tabs = [
        ...tabs,
        {
          label: "DRUMMUSTER",
          tabPanel: (
            <DrumMuster
              name={data.name}
              containersAmount={containersAmount}
              site={data}
              siteStatistic={siteStatistic}
              entryStatistic={entryStatistic}
            />
          ),
        },
      ];
    }
    return tabs;
  };

  const renderTagsFrom = () => {
    if (!id) return null;
    if (linkedTagsLoading) {
      return <Skeleton variant="rect" width={"100%"} height={260} />;
    } else
      return (
        <TagsForm
          id={id}
          tags={tags}
          linkedTags={linkedTags}
          handleSubmit={handleSubmitTags}
          wrapperClassName="col-12 mb-6"
          editRestricted
        />
      );
  };

  const handleSiteListen = () => {
    siteControl.listen("populate", function (address, variations) {
      const values = {
        physical_address: address.FormattedLine1,
        physical_city: address.City,
        physical_postcode: address.PostalCode,
        physical_state: address.Province,
      };
      setSiteValues(values);
      handleMapOpen();
    });
  };

  return (
    <div className="border border-secondary bg-white p-10">
      {loading && (
        <Loader
          isOpen={loading}
          maxWidth="xs"
          disableBackdropClick
          disableEscapeKeyDown
        />
      )}
      <Modal
        isOpen={auditOpen}
        submitable
        onClose={handleAuditClose}
        maxWidth="xs"
        modalContent={
          <ConfirmNewAudit
            handleClose={handleAuditClose}
            id={id}
            siteAudits={siteAudits}
          />
        }
      />
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={SiteCardSchema}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue, ...formik }) => (
          <>
            <Modal
              isOpen={mapModalOpen}
              onClose={handleMapClose}
              maxWidth="md"
              submitable
              modalContent={
                <>
                  <h3>Confirm your location</h3>
                  <Location
                    googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDue0HjL6FN1DXF6ghAaa_HrWLtZWOjyyE"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `85%` }} />}
                    handleClose={handleMapClose}
                  />
                </>
              }
            />
            {id && (
              <div className="d-flex align-items-center justify-content-between  mb-5">
                <h3>
                  <strong>{data.name}</strong>
                </h3>
                <div>
                  <ListButton
                    label="New Audit"
                    size="medium"
                    variant="outlined"
                    text="#407A28"
                    boxShadow={false}
                    className="mr-5"
                    onClick={handleAuditOpen}
                    disabled={siteAuditsLoading}
                  />
                  <ListButton
                    label="Site Audits"
                    size="medium"
                    onClick={handleAudit}
                    variant="outlined"
                    text="#407A28"
                    boxShadow={false}
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-6">
                <MappedInput label="Site Name" name="name" />
              </div>
              <div className="col-6">
                <MappedSelect
                  label="Status"
                  name="status"
                  options={statusOptions}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <MappedInput label="Site Email" name="email" />
              </div>
              <div className="col-6">
                <MappedSelect
                  name="primary_inspector_id"
                  label="Inspector"
                  options={inspectorsOptions}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <MappedInput label="Site Phone" name="phone" />
              </div>
              <div className="col-6">
                {orgContactsLoading ? (
                  <Skeleton variant="rect" width={"100%"} height={55} />
                ) : (
                  <MappedSelect
                    name="processor_pickup_scheduling_contact_id"
                    label="Processor Pickup Scheduling Contact"
                    options={orgContactsOptions}
                  />
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <MappedInput label="Invoice Email" name="invoice_email" />
              </div>
              <div className="col-6">
                <MappedSelect
                  name="induction_type"
                  label="Induction Type"
                  options={inductionOptions}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <MappedInput
                  label="Site Notes"
                  name="notes"
                  multiline
                  rows={6}
                />
              </div>
              <div className="col-6">
                <InductionType />
                {!organisationsLoading ? (
                  <div className="mb-2">
                    <Autocomplete
                      name="organisation_id"
                      placeholder="Link Organisation"
                      setValue={setFieldValue}
                      options={organiastionOptions}
                      defaultValueField="value"
                      defaultValue={values.organisation_id}
                      disabled
                    />
                    {formik.touched.organisation_id &&
                    formik.errors.organisation_id ? (
                      <div className="text-danger">
                        {formik.errors.organisation_id}
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <Skeleton variant="rect" width={"100%"} height={55} />
                )}
                <div className="mt-3">
                  <MappedDatePicker
                    label="Last Audit"
                    name="last_audit_date"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="row justify-content-between mt-10">
              <SiteAddress
                siteValues={siteValues}
                handleSiteListen={handleSiteListen}
                handleOpen={handleMapOpen}
                initialValues={initialValues}
                fetchLgaRegions={fetchLgaRegions}
              />
              <div className="col-6 mt-11">
                <LGARegionSelect
                  values={values}
                  lgaLoading={lgaLoading}
                  lgaRegions={lgaRegions}
                />
                <MappedSelect
                  name="interference"
                  label="Held Without Interference"
                  options={booleanOptions}
                />
                {renderTagsFrom()}
              </div>
            </div>

            {id && (
              <div className="mt-10">
                <Tabs
                  defaultActive="0"
                  tabs={renderTabs()}
                  align="right"
                  text="rgba(241, 251, 243, 0.7)"
                  selectedTabColor="rgba(64, 122, 40, 0.9)"
                />
              </div>
            )}

            <div className="row">
              <h4 className="my-10 col-12">
                <strong>Linked Organisation</strong>
              </h4>

              <Link
                className="text-dark ml-10 px-12 py-6 rounded border"
                to={`/organisations-list/${data?.organisation?.id}`}
              >
                <div className="col-12 font-weight-bold pb-2">
                  {data?.organisation?.business_name}{" "}
                  {data?.organisation?.region?.short_name}
                </div>
                <div className="col-12">{data?.organisation?.phone}</div>
                <div className="col-12">{data?.organisation?.email}</div>
                <div className="col-12">
                  {data?.organisation?.consultant?.first_name}{" "}
                  {data?.organisation?.consultant?.last_name}
                </div>
              </Link>
              {/* <div className="col-2">{data?.organisation?.region}</div> */}
            </div>

            <div className="row">
              <h4 className="my-10 col-12">
                <strong>Program Participation</strong>
              </h4>
              <div className="col-2 ml-10">
                <MappedCheckbox
                  name="cc_program_participation"
                  label={
                    <img
                      src={toAbsoluteUrl("/assets/icons/Chemclear.png")}
                      alt="Logo"
                      width="90%"
                    />
                  }
                  checked={values.cc_program_participation}
                />
              </div>

              <div className="col-3 d-flex align-items-center">
                <MappedCheckbox
                  name="dm_program_participation"
                  label={
                    <img
                      src={toAbsoluteUrl("/assets/icons/DrumMusterLogo.svg")}
                      alt="Logo"
                      width="170px"
                      style={{ marginLeft: 12 }}
                    />
                  }
                  checked={values.dm_program_participation}
                />
              </div>
            </div>

            {id ? (
              <AccessControl
                desiredRoles={[
                  AVAILABLE_ROLES.NATIONAL_ADMIN,
                  AVAILABLE_ROLES.AGSAFE_ADMIN,
                  AVAILABLE_ROLES.AGSAFE_STAFF,
                  AVAILABLE_ROLES.RC_ACCESS,
                ]}
                elseContent={
                  <div className="text-right">
                    <ListButton
                      label="Cancel"
                      size="large"
                      onClick={handleCancel}
                      variant="outlined"
                      text="#407A28"
                      boxShadow={false}
                    />
                  </div>
                }
              >
                <SubmitActions
                  id={id}
                  handleCancel={handleCancel}
                  disabled={isSubmitting}
                  submitAction={submitAction}
                  handleSubmit={handleSubmit}
                  setSubmitAction={setSubmitAction}
                />
              </AccessControl>
            ) : (
              <SubmitActions
                id={id}
                handleCancel={handleCancel}
                disabled={isSubmitting}
                submitAction={submitAction}
                handleSubmit={handleSubmit}
                setSubmitAction={setSubmitAction}
              />
            )}
          </>
        )}
      </Formik>
    </div>
  );
};
