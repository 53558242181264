/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  getClaimsListAutocomplete,
  getReimbursements,
  getReimbursementsAmount,
  getReimbursementsPaginated,
  modifyReimbursement
} from "../DrumMuster/Inspections/_api";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { HEADINGS, searchMap } from "../ReimbursementsApprovals/constants";
import {
  renderReimbursementsRow,
  modifyReimbursementsArray
} from "../ReimbursementsApprovals/helpers";
import { handleSearch, objectCheck } from "../../../ui/helpers";
import { Loader } from "../../../ui/components/Loader";
import { ReactComponent as ReturnIcon } from "../../static/Return.svg";
import { Modal } from "../../../ui/components/Modal";
import { ConfirmReverse } from "./ConfirmReverse";
import { info } from "../../../helpers/toasts";
import { ServerSearchBar } from "../../../ui/structures/ServerSearchBar";
import { uniq } from "lodash";
import { SEARCH_HELPER } from "./constants";

export const Backtrack = () => {
  const { request } = useFetch();

  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [data, setData] = useState([]);
  const [actionLoading, setActionLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [groupedItemsAmount, setGroupedItemsAmount] = useState([]);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const fetchReimbursements = () => {
    setLoading(true);
    return request(
      getReimbursementsPaginated,
      search,
      "approved",
      page,
      pageSize
    )
      .then(data => data && setData(modifyReimbursementsArray(data)))
      .finally(() => setLoading(false));
  };

  const fetchReimbursementsAmount = () => {
    return request(getReimbursementsAmount, search, "approved").then(
      data => data && setTotalRecords(data)
    );
  };

  useEffect(() => {
    fetchReimbursementsAmount();
  }, [search]);

  useEffect(() => {
    fetchReimbursements();
  }, [search, page, pageSize]);

  const fetchAutocomplete = (
    autocompleteValue,
    autocompleteField,
    searchFields,
    archived
  ) => {
    if (autocompleteValue === "") return;
    setAutocompleteLoading(true);

    request(
      getClaimsListAutocomplete,
      80,
      autocompleteField,
      autocompleteValue,
      searchFields,
      archived
    ).then(data => {
      if (data && Array.isArray(data) && data.length && objectCheck(data)) {
        setAutocompleteData(uniq(data.map(item => String(item))));
      } else {
        setAutocompleteData(["No option found"]);
      }
      setAutocompleteLoading(false);
    });
  };
  const resetSearch = () => setSearch("");

  const handleExpand = id => () => {
    const item = data.find(claim => claim.id === id);
    setSelectedItem(item);
    if (item?.grouped) {
      setActionLoading(true);
      request(
        getReimbursements,
        "approved",
        `invoice_id=${item.invoice_id}&count_only=y`
      )
        .then(data => {
          if (!data) return;
          setGroupedItemsAmount(data);
          handleModalOpen();
        })
        .finally(() => setActionLoading(false));
    } else handleModalOpen();
  };

  const handleReverse = (id, setSubmitting) => {
    setActionLoading(true);
    handleModalClose();
    request(modifyReimbursement, { status: "reverted" }, id)
      .then(res => {
        if (!res) return;
        info("Item has been reverted!");
        fetchReimbursements();
        fetchReimbursementsAmount();
        setSelectedItem({});
      })
      .finally(() => {
        setActionLoading(false);
        setSubmitting(false);
      });
  };

  return (
    <>
      <Loader
        isOpen={actionLoading}
        maxWidth="xs"
        disableBackdropClick
        disableEscapeKeyDown
      />
      <Modal
        isOpen={modalOpen}
        submitable
        onClose={handleModalClose}
        maxWidth="sm"
        modalContent={
          <ConfirmReverse
            handleClose={handleModalClose}
            handleSubmit={handleReverse}
            id={selectedItem?.id}
            isGroupedItem={selectedItem?.grouped}
            groupedItemsAmount={groupedItemsAmount}
          />
        }
      />
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div>
            <ServerSearchBar
              className="mb-5"
              onSearch={data =>
                handleSearch(data, searchMap, setSearch, setPage)
              }
              keyMap={SEARCH_HELPER}
              loading={autocompleteLoading}
              currentSearchList={autocompleteData}
              fetchAutocompleteFunction={fetchAutocomplete}
              placeholder="Search Reimbursements..."
              clearSearch={resetSearch}
              searchMap={searchMap}
              searchFields={search}
              contentLoading={loading}
            />
          </div>
          <div className="bg-white rounded py-7 px-10">
            <ListHeader title="Backtrack" />
            <Datagrid
              data={data}
              headings={HEADINGS}
              renderRow={renderReimbursementsRow}
              selected={selected}
              setSelected={setSelected}
              loading={loading}
              expandable
              handleExpand={handleExpand}
              expandHeader="Reverse"
              expandIcon={
                <span className="ml-5">
                  <ReturnIcon data-testid="reverse"/>
                </span>
              }
              serverPage={page}
              setServerPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              isServerPagination
              totalRecords={totalRecords}
            />
          </div>
        </div>
      </div>
    </>
  );
};
