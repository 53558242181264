import React, { useState } from "react";
import { Formik } from "formik";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { singUpUser } from "../../Users/_api";
import { useFetch } from "../../../../hooks/fetch.hook";
import { userProfileSchema } from "../../../../helpers/schemas";
import AlertBar from "../../../../ui/components/AlertBar";

const initialValues = {
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
  preferred_mfa: "SOFTWARE_TOKEN_MFA",
};

export const SignUp = ({ handleSignIn, MyTheme }) => {
  const { request } = useFetch();

  const [openModal, setOpenModal] = useState(false);

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    if (values.preferred_mfa === "SOFTWARE_TOKEN_MFA")
      delete values["phone_number"];
    request(singUpUser, {
      ...values,
      name: [values.first_name, values.last_name].join(" "),
    })
      .then((data) => {
        if (data) {
          setOpenModal(true);
          resetForm();
        }
      })
      .finally(() => setSubmitting(false));
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={userProfileSchema}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <>
          {openModal && (
            <AlertBar
              severity="info"
              message="Account details have been sent to your email!"
              handleClose={handleCloseModal}
            />
          )}
          <div className="d-flex justify-content-center">
            <div className="bg-white p-7 mt-10" style={{ width: "35%" }}>
              <div style={MyTheme.sectionHeader} className="Create Account">
                Create Account
              </div>
              <div className="mb-5">
                <MappedInput
                  style={{ padding: ".5rem" }}
                  inputWrapperStyle={{ border: "none" }}
                  name="first_name"
                  placeholder="First Name"
                />
              </div>
              <div className="mb-5">
                <MappedInput
                  style={{ padding: ".5rem" }}
                  inputWrapperStyle={{ border: "none" }}
                  name="last_name"
                  placeholder="Last Name"
                />
              </div>
              <div className="mb-5">
                <MappedInput
                  style={{ padding: ".5rem" }}
                  inputWrapperStyle={{ border: "none" }}
                  name="email"
                  placeholder="Email"
                />
              </div>
              <div>
                <MappedSelect
                  label="Multi-factor authentication (MFA)"
                  name="preferred_mfa"
                  options={[
                    {
                      value: "SMS_MFA",
                      label: "SMS",
                    },
                    {
                      value: "SOFTWARE_TOKEN_MFA",
                      label: "Authenticator",
                    },
                  ]}
                />
                {values.preferred_mfa === "SMS_MFA" || values.phone_number ? (
                  <MappedInput
                    style={{ padding: ".5rem" }}
                    inputWrapperStyle={{ border: "none" }}
                    label="Phone Number"
                    name="phone_number"
                    data-testid="phone_number"
                  />
                ) : (
                  ""
                )}
              </div>

              <div>
                <button
                  style={MyTheme.button}
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  Sign Up
                </button>
              </div>
              <div
                style={MyTheme.hint}
                className="cursor-pointer"
                onClick={handleSignIn}
              >
                Back to Sign In
              </div>
              <div className="text-center mt-15">
                <img
                  src={toAbsoluteUrl("/assets/icons/illuminance-labs.png")}
                  alt="logo"
                  className="img-fluid w-25"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};
