import React, { useState } from "react";
import { Formik } from "formik";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { useFetch } from "../../../hooks/fetch.hook";
import { PriceDetailsSchema } from "../../../helpers/schemas";
import { info } from "../../../helpers/toasts";
import { Loader } from "../../../ui/components/Loader";
import { modifyEmptyFields } from "../../../ui/helpers";
import { SubmitActions } from "../../../ui/structures/SubmitActions";
import { addOrganisationPrice, modifyOrganisationPrice } from "../../modules/Settings/Prices/_api";

export const PriceCard = ({ id, data = {}, handleClose, organisationId }) => {
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [submitAction, setSubmitAction] = useState("Save");

  const initialValues = {
    ccd_material: data.ccd_material || "",
    ccd_size: data.ccd_size || "",
    type: data.type || "",
    price: data.price || "",
    organisation_id: organisationId,
  };

  const typeOptions = [
    { value: "inspection", label: "Inspection" },
    { value: "processing", label: "Processing" },
  ];

  const materialOptions = [
    { value: "plastic", label: "Plastic" },
    { value: "steel", label: "Steel" },
  ];

  const sizeOptions = [
    { value: "20", label: "<20" },
    { value: "20-25", label: "20-25" },
    { value: "26-120", label: "26-120" },
    { value: "121-205", label: "121-205" },
  ];

  const createPrice = (values, setSubmitting) => {
    request(addOrganisationPrice, organisationId, values)
      .then((data) => {
        if (!data) return;
        info("Price has been created!");
        handleClose();
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  const updatePrice = (values, setSubmitting) => {
    request(modifyOrganisationPrice, values, id, organisationId)
      .then((data) => {
        if (!data) return;
        info("Price details have been updated!");
        submitAction === "Save & Exit" && handleClose();
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    modifyEmptyFields(values);
    id ? updatePrice(values, setSubmitting) : createPrice(values, setSubmitting);
  };

  return (
    <div className="border border-secondary bg-white p-10">
      {loading && (
        <Loader isOpen={loading} maxWidth="xs" disableBackdropClick disableEscapeKeyDown />
      )}
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={PriceDetailsSchema}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, values, ...formik }) => (
          <>
            <h3 className="mb-10">
              <strong>{id ? data.name : "Add Price"}</strong>
            </h3>
            <div className="row justify-content-between">
              <div className="col-8">
                <MappedSelect
                  label="Material"
                  options={materialOptions}
                  name="ccd_material"
                  data-testid="ccd_material"
                />
                <MappedSelect
                  label="Size"
                  name="ccd_size"
                  data-testid="ccd_size"
                  options={sizeOptions}
                />
                <MappedSelect label="Type" name="type" data-testid="type" options={typeOptions} />
                <MappedInput label="Price" name="price" data-testid="price" />
              </div>
            </div>
            <SubmitActions
              id={id}
              handleCancel={handleClose}
              disabled={isSubmitting}
              submitAction={submitAction}
              handleSubmit={handleSubmit}
              setSubmitAction={setSubmitAction}
            />
          </>
        )}
      </Formik>
    </div>
  );
};
