import { get } from "lodash";
import { Link } from "react-router-dom";
import {
  formatDate,
  formatPrice,
  isDate,
  isLink,
  isPrice,
  isStatus
} from "../../../ui/helpers";
import { calculatePercentage } from "../Scheduling/helpers";

const getStatusColor = (item = {}) => {
  const capacity = item.capacity_left_percentage;
  if (typeof capacity !== "number") return;
  if (capacity <= 75) {
    return "#7CB342";
  } else if (capacity > 100) return "#F44336";
  else return "#FF9800";
};

const formatCapacity = value => {
  if (typeof value !== "number") return "";
  else return value.toFixed(2) + " %";
};

export const renderClearancesRow = (headings, item) => {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-5 ${
        isStatus(key) ? "text-uppercase" : ""
      } : ""`}
    >
      {isDate(key) && item[key] ? (
        formatDate(item[key])
      ) : isPrice(key) ? (
        formatPrice(item[key])
      ) : key === "indicator" ? (
        <div
          style={{
            background: getStatusColor(item),
            width: 20,
            height: 20,
            borderRadius: "50%",
            margin: "0 auto"
          }}
        ></div>
      ) : key === "capacity_left_percentage" ? (
        formatCapacity(item[key])
      ) : (
        item[key]
      )}
    </td>
  ));
};

export const renderRoutsRow = (headings, item) => {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-5 ${
        isStatus(key) ? "text-uppercase" : ""
      } : ""`}
    >
      {isLink(key) ? (
        <Link className="text-dark" to={`/processing/${item.id}`}>
          {item[key]}
        </Link>
      ) : isDate(key) ? (
        formatDate(item[key])
      ) : (
        item[key]
      )}
    </td>
  ));
};

export const rendeTotalVolumeRow = (data, colSpan = 3) => {
  const sum = data.reduce((acc, value) => acc + Number(value.volume), 0);
  return (
    <tr className="border-bottom">
      <td className="no-line border-0 align-middle px-5 pt-10 pb-1">
        <strong>Totals </strong>
      </td>
      <td colSpan={colSpan}></td>
      <td className="pt-10 pb-1 pl-5">
        <strong> {sum}</strong>
      </td>
    </tr>
  );
};

export const renderTotalAmountRow = data => {
  const totalVolume = data.reduce(
    (acc, value) => acc + Number(value.volume),
    0
  );
  const totalCost = formatPrice(
    data.reduce((acc, value) => acc + Number(value.processing_cost), 0)
  );
  return (
    <tr className="border-bottom">
      <td className="no-line border-0 align-middle px-5 pt-10 pb-1">
        <strong>Totals </strong>
      </td>
      <td colSpan="4"></td>
      <td className="pt-10 pb-1 pl-5">
        <strong> {totalVolume}</strong>
      </td>
      <td colSpan="5"></td>
      <td className="pt-10 pb-1 pl-5">
        <strong> {totalCost}</strong>
      </td>
    </tr>
  );
};

export function modifyCJ(clearance = {}) {
  return {
    ...clearance,
    region: get(clearance, "site.organisation.region.long_name", ""),
    capacity: calculatePercentage(clearance.site),
    site_name: get(clearance, "site.name"),
    claim_payee: get(clearance, "collection_run.processor.business_name", ""),
    state: get(clearance, "site.physical_state"),
    material:
      clearance.material.charAt(0).toUpperCase() + clearance.material.slice(1)
  };
}

export function modifyClearances(list = []) {
  return list.map(item => modifyCJ(item));
}

const getSingleItemCost = (clearance = {}) => {
  const { total_value, volume } = clearance;
  if (!total_value || !volume) return 0;
  return total_value / volume;
};

const calculateVolume = (item = {}) => {
  const ccds = item.ccds;
  if (!ccds) return 0;
  if (!ccds.length) return 0;
  return ccds.reduce((acc, value) => acc + value.quantity, 0);
};

export function modifyCompletedCJ(clearance = {}) {
  return {
    ...clearance,
    region: get(clearance, "site.organisation.region.long_name", ""),
    capacity: calculatePercentage(clearance.site),
    site_name: get(clearance, "site.name"),
    claim_payee: get(clearance, "collection_run.processor.business_name", ""),
    state: get(clearance, "site.physical_state"),
    material:
      clearance.material.charAt(0).toUpperCase() + clearance.material.slice(1),
    per_unit_cost: getSingleItemCost(clearance),
    processing_cost: clearance.total_value || 0,
    collection_run_code: get(clearance, "collection_run.code"),
    volume_pickedup: calculateVolume(clearance)
  };
}

export function modifyCompletedClearances(list = []) {
  return list.map(item => modifyCompletedCJ(item));
}

export function modifyCollection(collection = {}) {
  return {
    ...collection,
    processor_name: get(collection, "processor.business_name", ""),
    planned_pickup_date: get(
      collection,
      "clearance_jobs[0].planned_pickup_date"
    )
  };
}

export function modifyCollections(list = []) {
  return list.map(item => modifyCollection(item));
}
