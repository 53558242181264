import { Skeleton } from "@material-ui/lab";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import KeyboardArrowRightIcon from "@material-ui/icons//KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons//KeyboardArrowLeft";
import pdfjsWorker from "react-pdf/node_modules/pdfjs-dist/build/pdf.worker.entry";
import "./PreviewPDF.css";
import { IconButton } from "@material-ui/core";

export const PreviewPDF = React.memo(({ donwloadURL = "" }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <div>
      <Document
        file={donwloadURL}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<Skeleton variant="rect" width={700} height={990} />}
      >
        <Page
          pageNumber={pageNumber}
          width={700}
          // loading={<Skeleton variant="rect" width={"100%"} height={520} />}
        />
      </Document>
      <div className="d-flex justify-content-center align-items-center mt-3 px-3 w-100">
        <IconButton disabled={pageNumber <= 1} onClick={previousPage}>
          <KeyboardArrowLeftIcon />
        </IconButton>
        <p className="mb-0">
          Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        </p>
        <IconButton disabled={pageNumber >= numPages} onClick={nextPage}>
          <KeyboardArrowRightIcon />
        </IconButton>
      </div>
    </div>
  );
});
