import React, { useState, useMemo, useEffect } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { orgSearchMap, orgHeadings, ORG_SEARCH_HELPER } from "./constants";
import { renderOrganisationsRow } from "../../modules/Organisations/helpers";
import { ListButton } from "../../../ui/components/ListButton";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  getUserOrganisations,
  getUserOrganisationsAmount,
  getUserOrgsAutocomplete,
  removeUserOrgLinks
} from "./_api";
import { handleSearch, objectCheck } from "../../../ui/helpers";
import { uniq } from "lodash";
import { ServerSearchBar } from "../../../ui/structures/ServerSearchBar";
import { info } from "../../../helpers/toasts";
import { Loader } from "../../../ui/components/Loader";

export const UserOrgs = ({ name, id }) => {
  const { request } = useFetch();

  const [selected, setSelected] = useState({});
  const [orgs, setOrgs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [actionLoading, setActionLoading] = useState(false);

  const fetchOrgs = () => {
    setLoading(true);
    request(getUserOrganisations, id, search, page, pageSize)
      .then(data => data && setOrgs(data))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchOrgs();
    // eslint-disable-next-line
  }, [search, page, pageSize]);

  useEffect(() => {
    request(getUserOrganisationsAmount, id, search).then(
      data => data && setTotalRecords(data)
    );
    // eslint-disable-next-line
  }, [search]);

  const fetchOrgsAutocomplete = (
    autocompleteValue,
    autocompleteField,
    searchFields
  ) => {
    if (autocompleteValue === "") return;
    setAutocompleteLoading(true);
    request(
      getUserOrgsAutocomplete,
      id,
      80,
      autocompleteField,
      autocompleteValue,
      searchFields
    ).then(data => {
      if (data && Array.isArray(data) && data.length && objectCheck(data)) {
        setAutocompleteData(uniq(data));
      } else {
        setAutocompleteData(["No option found"]);
      }
      setAutocompleteLoading(false);
    });
  };

  const resetSearch = () => {
    setSearch("");
  };

  const renderButtons = () => (
    <>
      <div className="mr-5">
        <ListButton
          label="Remove Links"
          disabled={removeDisabled}
          onClick={handleRemoveLinks}
        />
      </div>
    </>
  );

  const removeDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length === 0,
    [selected]
  );

  const handleRemoveLinks = () => {
    setActionLoading(true);
    const selectedIds = Object.keys(selected)
      .filter(item => Boolean(selected[item]))
      .map(item => ({ id: item }));
    const idsForFilter = selectedIds.map(({ id }) => id);
    request(removeUserOrgLinks, selectedIds, id)
      .then(res => {
        if (!res) return;
        setOrgs(orgs.filter(({ id }) => !idsForFilter.includes(id)));
        setSelected({});
        info("Links have been removed!");
      })
      .finally(() => setActionLoading(false));
  };

  return (
    <div className="row justify-content-center bg-white py-15 px-10">
      {actionLoading && (
        <Loader
          isOpen={actionLoading}
          maxWidth="xs"
          disableBackdropClick
          disableEscapeKeyDown
        />
      )}
      <div className="col-12">
        <h3 className="mb-15">
          <strong>{name}</strong>
        </h3>
        <div className="mb-10">
          <ServerSearchBar
            className="mb-5"
            onSearch={data =>
              handleSearch(data, orgSearchMap, setSearch, setPage)
            }
            keyMap={ORG_SEARCH_HELPER}
            loading={autocompleteLoading}
            currentSearchList={autocompleteData}
            fetchAutocompleteFunction={fetchOrgsAutocomplete}
            placeholder="Search Orgs..."
            clearSearch={resetSearch}
            searchMap={orgSearchMap}
            searchFields={search}
            contentLoading={loading}
          />
        </div>
        <div>
          <ListHeader title="Organisations" renderButtons={renderButtons} />
          <Datagrid
            loading={loading}
            data={orgs}
            headings={orgHeadings}
            renderRow={renderOrganisationsRow}
            selected={selected}
            setSelected={setSelected}
            selectable
            serverPage={page}
            setServerPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            isServerPagination
            totalRecords={totalRecords}
          />
        </div>
      </div>
    </div>
  );
};
