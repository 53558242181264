import config from "react-global-configuration";

export const getTopOrganisations = (region_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/statistics/top-organisations-by-reimbursements?inspection_only=y${
      region_id ? `region_id=${region_id}` : ""
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getTopReimbursements = () =>
  fetch(`${config.get("ApiUrl").Rest}/statistics/reimbursements`, {
    mode: "cors",
    credentials: "include",
  });

export const getInspectedContainers = () =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/ccds?drop_off.type=inspection&sum=quantity&drop_off.status=active`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getProcessedContainers = () =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/ccds?drop_off.type=processing&sum=quantity&drop_off.status=active`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getCollectionsByYear = () =>
  fetch(`${config.get("ApiUrl").Rest}/statistics/collections_by_year`, {
    mode: "cors",
    credentials: "include",
  });

export const getCollectionsByRegion = () =>
  fetch(`${config.get("ApiUrl").Rest}/statistics/collections_by_region`, {
    mode: "cors",
    credentials: "include",
  });

export const getDailyCollections = () =>
  fetch(`${config.get("ApiUrl").Rest}/statistics/collection_volumes`, {
    mode: "cors",
    credentials: "include",
  });
