import React, { useEffect, useState } from "react";
import { Skeleton } from "@material-ui/lab";
import { useFormikContext } from "formik";
import { Autocomplete } from "../../../../ui/components/Autocomplete";

export const SiteSelect = ({ sitesLoading, siteOptions = [] }) => {
  const [resetKey, setResetKey] = useState(0);
  const { values, setFieldValue, ...formik } = useFormikContext();

  useEffect(() => {
    if (values.organisation_id) {
      setFieldValue("site_id", null);
      setResetKey(Date.now());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.organisation_id]);

  return (
    <div className="mt-5" key={resetKey}>
      {sitesLoading ? (
        <Skeleton variant="rect" width={"100%"} height={55} />
      ) : (
        <>
          <Autocomplete
            name="site_id"
            placeholder="Link Site"
            setValue={setFieldValue}
            defaultValueField="value"
            options={siteOptions}
            defaultValue={values.site_id}
          />
          {formik.touched.site_id && formik.errors.site_id ? (
            <div className="text-danger">{formik.errors.site_id}</div>
          ) : null}
        </>
      )}
    </div>
  );
};
