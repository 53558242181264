import React, { useState } from "react";
import Chart from "react-apexcharts";

export const ColumnChart = ({ options, series, height = "auto", id }) => {
  // eslint-disable-next-line no-unused-vars
  const [initialState, setInitialState] = useState({
    options: {
      chart: {
        id: id,
        type: "bar",
        stacked: false,
        foreColor: "#999",
        toolbar: { show: false },
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 400,
          animateGradually: {
            enabled: true,
            delay: 10,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
        sparkline: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: options.horizontal || false,
          columnWidth: options.type === "minimal" || options.type === "grouped" ? "20%" : "40%",
          endingShape: options.type === "minimal" ? "rounded" : "flat",
        },
      },
      colors: [options.color, options.secondBarColor, options.thirdBarColor, options.forthBarColor],
      grid: {
        xaxis: {
          lines: {
            show: options.type === "grouped" && options.horizontal ? true : false,
          },
        },
        yaxis: {
          lines: {
            show: options.type === "minimal" ? false : options.horizontal ? false : true,
          },
        },
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          show: false,
        },
        labels: {
          show: options.type === "minimal" ? !options.horizontal : true,
          rotate: options.type === "minimal" ? 0 : -45,
          style: {
            fontSize: "14px",
          },
        },
        categories: options.categories,
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        labels: {
          show: options.type === "minimal" ? options.horizontal : true,
          style: {
            fontSize: "14px",
          },
        },
        categories: options.categories,
        crosshairs: {
          show: true,
          position: "back",
          stroke: {
            color: "#b6b6b6",
            width: 1,
            dashArray: 0,
          },
        },
      },
      stroke: {
        show: options.type === "grouped" ? true : false,
        width: 6,
        colors: ["transparent"],
      },
      legend: {
        floating: true,
        position: "top",
        horizontalAlign: "right",
        offsetY: -36,
      },
      title: {
        text: options.title,
        align: options.titlePosition || "center",
        style: { color: options.titleColor, fontWeight: "800" },
      },
      tooltip: {
        shared: false,
        style: {
          fontSize: "12px",
        },
        x: { show: false },
        y: {
          formatter: function(val) {
            return val;
          },
          title: {
            formatter: function(seriesName) {
              return;
            },
          },
        },
        marker: {
          show: false,
        },
      },
    },
    series: series,
  });
  return (
    <div>
      <Chart
        options={initialState.options}
        series={initialState.series}
        type="bar"
        width="100%"
        height={height}
      />
    </div>
  );
};
