/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { getContact } from "./_redux/selectors";
import { ContactDetails } from "./ContactDetails";
import { useFetch } from "../../../hooks/fetch.hook";
import { getContactById } from "./_api";
import { ContactTrainings } from "./ContactTrainings";
import Tabs from "@bit/the-glue.frontendcomponents.tabs";

export function Card({
  history,
  match: {
    params: { id },
  },
}) {
  const { request } = useFetch();

  const data = useSelector(getContact(id));

  const [cardData, setCardData] = useState({});

  useEffect(() => {
    if (!data && id) {
      request(getContactById, id).then((data) => data && setCardData(data));
    } else setCardData(data);
  }, [id]);

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  const tabs = [
    {
      label: "Profile",
      tabPanel: <ContactDetails data={cardData} id={id} />,
    },
    {
      label: "Training",
      tabPanel: <ContactTrainings contact={cardData} />,
    },
  ];

  return (
    <div className="mt-10">
      <Tabs
        defaultActive="0"
        tabs={tabs}
        align="right"
        text="rgba(241, 251, 243, 0.7)"
        selectedTabColor="white"
        selectedColor="#407A28"
      />
    </div>
  );
}
