/* eslint-disable no-unused-vars */
import React from "react";
import { Formik } from "formik";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { useFetch } from "../../../../hooks/fetch.hook";
import { ListButton } from "../../../../ui/components/ListButton";
import { ChemContactDetailsSchema } from "../helpers";
import { inputStype } from "../constants";
import { OrganisationType } from "./OrganisationType";

export const ContactDetails = ({ handleNextStep, contactDetails = {} }) => {
  const { request } = useFetch();

  const {
    first_name,
    last_name,
    email,
    phone,
    company_name,
    mobile,
    qa_program_enabled,
    organisation_type,
    other_organisation,
    ct_number,
    qa_program,
  } = contactDetails;

  const initialValues = {
    first_name,
    last_name,
    email,
    phone,
    company_name,
    mobile,
    qa_program_enabled,
    organisation_type,
    other_organisation,
    qa_program,
    ct_number,
  };

  const handleSubmit = (values) => {
    handleNextStep(values, "contact_details");
  };

  return (
    <div className="border border-secondary bg-white p-10">
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={ChemContactDetailsSchema}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <>
            <h3 className="mb-10">
              <strong>Your Contact Details</strong>
            </h3>
            <div className="d-flex my-5 justify-content-between flex-wrap">
              <div className="mb-5" style={inputStype}>
                <MappedInput label="First Name" name="first_name" data-testid="first_name" />
              </div>
              <div className="mb-5" style={inputStype}>
                <MappedInput label="Last Name" name="last_name" data-testid="last_name" />
              </div>
              <div className="mb-5" style={inputStype}>
                <MappedInput label="Email" name="email" data-testid="email" />
              </div>
              <div className="mb-5" style={inputStype}>
                <MappedInput label="Phone" name="phone" data-testid="phone" />
              </div>
              <div className="mb-5" style={inputStype}>
                <MappedInput
                  label="Company Name (Optional)"
                  name="company_name"
                  data-testid="company_name"
                />
              </div>
              <div className="mb-5" style={inputStype}>
                <MappedInput label="Mobile" name="mobile" data-testid="mobile" />
              </div>
            </div>

            <OrganisationType />

            <div className="mt-10 text-right">
              <ListButton
                label="Next"
                size="medium"
                className="px-12"
                boxShadow={false}
                onClick={handleSubmit}
                disabled={isSubmitting}
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
