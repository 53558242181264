export const HEADINGS = [
  ["code", "ID"],
  ["name", "Product Name"],
  ["manufacturer", "Manufacturer"],
  ["data_source", "Source"],
  ["status", "Status"],
  ["product_group", "Group"],
];

export const searchMap = {
  "Product Name": "name",
  Manufacturer: "manufacturer",
  Source: "data_source",
  Group: "product_group",
  Status: "status",
  "Pack Sizes": "pack_sizes",
};

export const SEARCH_HELPER = [
  ["name", "Product Name"],
  ["manufacturer", "Manufacturer"],
  ["data_source", "Source"],
  ["product_group", "Group"],
  ["status", "Status"],
  ["pack_sizes", "Pack Sizes"],
];

export const typeOptions = [
  { value: "Chemical - Fungicide", label: "Chemical - Fungicide" },
  { value: "Chemical - Herbicide", label: "Chemical - Herbicide" },
  { value: "Dairy Cleaner", label: "Dairy Cleaner" },
  { value: "Chemical", label: "Chemical" },
];

export const groupOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
];

export const IMPORT_HEADINGS = [
  ["legacy_id", "Legacy ID"],
  ["name", "Product Name"],
  ["manufacturer", "Manufacturer"],
  ["product_type", "Type"],
  ["data_source", "Source"],
];
