/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { TableSortLabel } from "@material-ui/core";
import { HEADINGS } from "./constants";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  archiveContact,
  getContactById,
  inviteForTraining,
  modifyContact,
  postSendBulkSms
} from "../../modules/Contacts/_api";
import { ListButton } from "../../../ui/components/ListButton";
import { ListHeader } from "../../../ui/structures/ListHeader";
import {
  modifyContactsArray,
  modifyContact as modifyContactObject
} from "../../modules/Contacts/helpers";
import { Loader } from "../../../ui/components/Loader";
import { Modal } from "../../../ui/components/Modal";
import { ConfirmDelete } from "../../../ui/components/ConfirmDelete";
import { error, info } from "../../../helpers/toasts";
import { ArchiveCheckbox } from "../../../ui/components/ArchiveCheckbox";
import { getSelectedId, sortHelper } from "../../../ui/helpers";
import { UnarchiveButton } from "../../../ui/components/UnarchiveButton";
import {
  getOrganisationContacts,
  getOrganisationContactsAmount,
  getOrganisationContactsWIthTags
} from "../../modules/Organisations/_api";
import { AVAILABLE_ROLES, sortHeaderStyle } from "../../constants";
import { SMSForm } from "../../../ui/structures/SMSForm";
import { TrainingInvite } from "../../modules/Contacts/TrainingInvite";
import { getTrainings } from "../../modules/Settings/Badges/_api";
import { AccessControl } from "../../../ui/structures/AccessControl";
import { uniqBy } from "lodash";
import { Skeleton } from "@material-ui/lab";

export const ContactsList = ({
  data = [],
  name,
  handleNew,
  handleExpand,
  setContactsList,
  organisationId,
  renderContactsRow,
  contactsWithTagsLoading,
  contactsWithTags = []
}) => {
  const { request } = useFetch();

  const [selected, setSelected] = useState({});
  const [actionLoading, setActionLoading] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [unArchiving, setUnarchiving] = useState(false);
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [loading, setLoading] = useState(false);
  const [bulkModalOpen, setBulkModalOpen] = useState(false);
  const [bulkStep, setBulkStep] = useState("1");
  const [confirmedMessgae, setConfirmedMessage] = useState("");
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [trainingModalOpen, setTrainingModalOpen] = useState(false);
  const [trainings, setTrainings] = useState([]);

  const handleBulkOpen = () => setBulkModalOpen(true);

  const handleTrainingOpen = () => {
    const contactID = getSelectedId(selected);
    setSelectedItem(contactID);
    setTrainingModalOpen(true);
  };

  const handleTrainingClose = () => {
    setSelectedItem("");
    setTrainingModalOpen(false);
  };

  useEffect(() => {
    request(getOrganisationContactsWIthTags, organisationId);
  }, []);

  useEffect(() => {
    setLoading(true);
    request(
      getOrganisationContacts,
      organisationId,
      "",
      archived,
      field,
      direction,
      page,
      pageSize
    )
      .then(data => data && setContactsList(modifyContactsArray(data)))
      .finally(() => {
        setArchivedLoading(false);
        setLoading(false);
      });
  }, [archived, field, direction, page, pageSize]);

  useEffect(() => {
    request(getOrganisationContactsAmount, organisationId, "", archived).then(
      data => data && setTotalRecords(data)
    );
  }, [archived]);

  useEffect(() => {
    request(getTrainings).then(
      data =>
        data &&
        setTrainings(
          data
            .map(({ id, name }) => ({
              value: id,
              label: name
            }))
            .sort(sortHelper)
        )
    );
  }, []);

  const handleModalClose = () => setConfirmDeleteOpen(false);

  const handleOpenModal = id => {
    setConfirmDeleteOpen(true);
    setSelectedItem(id);
  };

  const unArchiveDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const bulkDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 0,
    [selected]
  );

  const selectedItems = Object.keys(selected).filter(key =>
    Boolean(selected[key])
  );

  const handleBulkClose = () => {
    setBulkModalOpen(false);
    setBulkStep("1");
    setConfirmedMessage("");
  };

  const handleBulkConfirm = (values, { setSubmitting }) => {
    setBulkStep("2");
    setConfirmedMessage(values);
    setSubmitting(false);
  };

  const handleBulkSubmit = (values, { setSubmitting }) => {
    request(postSendBulkSms, {
      contact_ids: selectedItems,
      ...values
    })
      .then(response => {
        if (response) {
          info("SMS has been sent!");
        }
      })
      .finally(() => {
        setSubmitting(false);
        handleBulkClose();
      });
  };

  const updateCardData = (item = {}) => {
    const index = data.findIndex(
      contact => (contact || {}).id === (item || {}).id
    );
    setContactsList(state => [
      ...state.slice(0, index),
      item,
      ...state.slice(index + 1)
    ]);
  };

  const handleArchive = (id, setSubmitting) => {
    setSubmitting(true);
    setActionLoading(true);
    setConfirmDeleteOpen(false);
    request(archiveContact, id)
      .then(data => {
        if (!data) return;
        if (archived) {
          request(getContactById, id).then(contact => {
            if (!contact) return;
            updateCardData(modifyContactObject(contact));
          });
        } else {
          setContactsList(state => state.filter(item => id !== item.id));
        }
        info("Contact has been archived!");
        setSelected({});
      })
      .finally(() => {
        setSubmitting(false);
        setActionLoading(false);
      });
  };

  const handleUnarchive = () => {
    const contactID = getSelectedId(selected);
    const status = (data.find(({ id }) => id === contactID) || {}).status;
    if (status !== "archived") return;
    setUnarchiving(true);
    setActionLoading(true);
    request(modifyContact, { status: "active" }, contactID)
      .then(data => {
        if (!data) return;
        updateCardData(modifyContactObject(data));
        setSelected({});
        info("Contact has been unarchived!");
      })
      .finally(() => {
        setUnarchiving(false);
        setActionLoading(false);
      });
  };

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  const handleOnsiteInvite = () => {
    setActionLoading(true);
    const contactID = getSelectedId(selected);
    const training = trainings.find(
      ({ label }) => label === "drumMUSTER Inspector Training Onsite"
    );
    if (!contactID || !training) {
      error("Contact or training not found");
      setLoading(false);
      return;
    }
    request(inviteForTraining, contactID, [{ id: training?.value }])
      .then(data => {
        if (!data) return;
        info("Contact has been invited for the training!");
      })
      .finally(() => setActionLoading(false));
  };

  const renderButtons = () => (
    <>
      {archived && (
        <UnarchiveButton
          handleClick={handleUnarchive}
          disabled={unArchiveDisabled}
          loading={unArchiving}
        />
      )}
      <AccessControl
        desiredRoles={[
          AVAILABLE_ROLES.NATIONAL_ADMIN,
          AVAILABLE_ROLES.AGSAFE_ADMIN,
          AVAILABLE_ROLES.AGSAFE_STAFF,
          AVAILABLE_ROLES.RC_ACCESS
        ]}
      >
        <ListButton
          label="Onsite Training"
          className="mr-5"
          disabled={unArchiveDisabled}
          onClick={handleOnsiteInvite}
        />
        <ListButton
          label="Online Training"
          className="mr-5"
          disabled={unArchiveDisabled}
          onClick={handleTrainingOpen}
        />
      </AccessControl>
      <div>
        <ListButton
          label="New"
          onClick={handleNew}
          data-testid="new"
          className="my-2 mr-5"
        />
      </div>

      <AccessControl
        desiredRoles={[
          AVAILABLE_ROLES.NATIONAL_ADMIN,
          AVAILABLE_ROLES.AGSAFE_ADMIN,
          AVAILABLE_ROLES.AGSAFE_STAFF
        ]}
      >
        <ListButton
          label="Bulk SMS"
          className="mr-5"
          disabled={!bulkDisabled}
          onClick={handleBulkOpen}
        />
      </AccessControl>
    </>
  );

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) => (
      <th
        key={key}
        style={sortHeaderStyle}
        className="px-5 text-nowrap"
        onClick={() => {
          setDirection(
            key !== field ? "desc" : direction === "desc" ? "asc" : "desc"
          );
          setField(key);
        }}
      >
        <span style={{ cursor: "pointer" }}>{header}</span>
        {key === field && <TableSortLabel active direction={direction} />}
      </th>
    ));
  }

  const uniqTags = uniqBy(
    contactsWithTags.reduce((acc, value) => [...acc, ...value.tags], []),
    "id"
  );

  return (
    <>
      <Modal
        isOpen={trainingModalOpen}
        submitable
        onClose={handleTrainingClose}
        maxWidth="md"
        modalContent={
          <TrainingInvite
            handleClose={handleTrainingClose}
            contact={data.find(({ id }) => id === selectedItem)}
            trainingOptions={trainings}
          />
        }
      />
      {actionLoading && <Loader isOpen={actionLoading} maxWidth="xs" />}
      {confirmDeleteOpen && (
        <Modal
          isOpen={confirmDeleteOpen}
          submitable
          onClose={handleModalClose}
          maxWidth="sm"
          modalContent={
            <ConfirmDelete
              handleClose={handleModalClose}
              handleSubmit={handleArchive}
              id={selectedItem}
              name={
                (data.find(({ id }) => id === selectedItem) || {}).first_name
              }
            />
          }
        />
      )}
      {bulkModalOpen && (
        <Modal
          isOpen={bulkModalOpen}
          submitable
          onClose={handleBulkClose}
          maxWidth={`${bulkStep === "1" ? "xs" : "md"}`}
          modalContent={
            bulkStep === "1" ? (
              <SMSForm
                handleClose={handleBulkClose}
                handleSubmit={handleBulkConfirm}
                title={`Send SMS to ${selectedItems.length} contacts`}
              />
            ) : (
              <SMSForm
                handleClose={handleBulkClose}
                handleSubmit={handleBulkSubmit}
                title={`Are you sure you want to send SMS to ${selectedItems.length} recipients?`}
                data={confirmedMessgae}
                titleClassName="text-danger"
              />
            )
          }
        />
      )}
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <ListHeader title={name} renderButtons={renderButtons} />
            <ArchiveCheckbox
              archivedLoading={archivedLoading}
              archived={archived}
              showArchived={showArchived}
            />
            <Datagrid
              data={data}
              headings={HEADINGS}
              renderRow={renderContactsRow}
              selected={selected}
              setSelected={setSelected}
              expandable
              handleExpand={handleExpand}
              deletable
              handleDelete={handleOpenModal}
              renderHeaderWithSorting={renderHeaderWithSorting}
              selectable
              loading={loading}
              serverPage={page}
              setServerPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              isServerPagination
              totalRecords={totalRecords}
            />
          </div>

          {contactsWithTagsLoading ? (
            <Skeleton variant="rect" width={"100%"} height={250} />
          ) : (
            <div className="bg-white rounded py-7 px-10 mt-10">
              <h3 className="mb-3">Contacts By Tag</h3>
              {uniqTags.map(tag => (
                <div key={tag.id}>
                  <h5>{tag.name}</h5>
                  {contactsWithTags.map(
                    ({ first_name, last_name, tags, id }) => {
                      if (tags.map(({ id }) => id).includes(tag.id)) {
                        return (
                          <p key={id}>{[first_name, last_name].join(" ")}</p>
                        );
                      } else return null;
                    }
                  )}
                  <hr />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
