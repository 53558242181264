/* eslint-disable no-unused-vars */
import React from "react";
import { Formik } from "formik";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { useFetch } from "../../../../hooks/fetch.hook";
import { ListButton } from "../../../../ui/components/ListButton";
import { DrumAddressDetailsSchema } from "../helpers";
import { stateOptions } from "../constants";
import { FillAddress } from "./FillAddress";

export const AddressDetails = ({ handleNextStep, handlePrevStep, addressDetails }) => {
  const { request } = useFetch();

  const {
    contact_address_1,
    contact_address_2,
    contact_suburb,
    contact_state,
    contact_postcode,
    drum_address_1,
    drum_address_2,
    drum_suburb,
    drum_state,
    drum_postcode,
    as_contact_address,
  } = addressDetails;

  const initialValues = {
    contact_address_1,
    contact_address_2,
    contact_suburb,
    contact_state,
    contact_postcode,
    drum_address_1,
    drum_address_2,
    drum_suburb,
    drum_state,
    drum_postcode,
    as_contact_address,
  };

  const handleSubmit = (values) => {
    handleNextStep(values, "address_details");
  };

  return (
    <div className="border border-secondary bg-white p-10">
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={DrumAddressDetailsSchema}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <>
            <div className="d-flex my-5 justify-content-between">
              <div style={{ width: "48%" }}>
                <h3 className="mb-10">
                  <strong>Contact Address</strong>
                </h3>
                <MappedInput
                  label="Address 1"
                  name="contact_address_1"
                  data-testid="contact_address_1"
                />
                <MappedInput
                  label="Address 2"
                  name="contact_address_2"
                  data-testid="contact_address_2"
                />
                <MappedInput
                  label="Town / Suburb"
                  name="contact_suburb"
                  data-testid="contact_suburb"
                />
                <MappedSelect
                  label="State"
                  name="contact_state"
                  data-testid="contact_state"
                  options={stateOptions}
                />
                <MappedInput
                  label="Postcode"
                  name="contact_postcode"
                  data-testid="contact_postcode"
                />
              </div>
              <div style={{ width: "48%" }}>
                <h3 className="mb-10">
                  <strong>Drum Storage Address</strong>
                </h3>
                <MappedInput label="Address 1" name="drum_address_1" data-testid="drum_address_1" />
                <MappedInput label="Address 2" name="drum_address_2" data-testid="drum_address_2" />
                <MappedInput label="Town / Suburb" name="drum_suburb" data-testid="drum_suburb" />
                <MappedSelect
                  label="State"
                  name="drum_state"
                  data-testid="drum_state"
                  options={stateOptions}
                />
                <MappedInput label="Postcode" name="drum_postcode" data-testid="drum_postcode" />
              </div>
            </div>
            <FillAddress />
            <div className="mt-10 d-flex justify-content-end">
              <ListButton
                label="Back"
                size="medium"
                className="px-12 mr-3"
                boxShadow={false}
                onClick={handlePrevStep}
                variant="outlined"
                text="#64A70B"
              />
              <ListButton
                label="Next"
                size="medium"
                className="px-12"
                boxShadow={false}
                onClick={handleSubmit}
                disabled={isSubmitting}
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
