import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { organisationTypes, isQaProgramOptions, ProgramsList } from "../constants";
import { sortHelper } from "../../../../ui/helpers";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";

const inputStype = {
  minHeight: "65px",
};

export const OrganisationType = () => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!values.qa_program_enabled) {
      setFieldValue("qa_program", "");
      setFieldValue("ct_number", "");
    }
    // eslint-disable-next-line
  }, [values.qa_program_enabled]);

  useEffect(() => {
    if (values.organisation_type !== "farmer") {
      setFieldValue("qa_program_enabled", "");
    }
    // eslint-disable-next-line
  }, [values.organisation_type]);

  return (
    <div className="mt-5 d-flex">
      <div className="w-50 mr-4">
        <div className="mb-5" style={inputStype}>
          <MappedSelect
            label="What type of organisation are you from?"
            name="organisation_type"
            data-testid="organisation_type"
            options={[...organisationTypes.sort(sortHelper), { value: "other", label: "Other" }]}
          />
        </div>
        {values.organisation_type === "other" ? (
          <div className="mb-5" style={inputStype}>
            <MappedInput
              label="Other Organisation"
              name="other_organisation"
              data-testid="other_organisation"
            />
          </div>
        ) : null}
      </div>
      {values.organisation_type === "farmer" ? (
        <div className="w-50 ml-4">
          <div className="mb-5" style={inputStype}>
            <MappedSelect
              label="Do you have a QA Program (Optional)"
              name="qa_program_enabled"
              data-testid="qa_program_enabled"
              options={isQaProgramOptions.sort(sortHelper)}
            />
          </div>
          {values.qa_program_enabled ? (
            <>
              <div className="mb-5" style={inputStype}>
                <MappedSelect
                  label="QA Program"
                  name="qa_program"
                  data-testid="qa_program"
                  options={ProgramsList.sort(sortHelper)}
                />
              </div>
              <div className="mb-5" style={inputStype}>
                <MappedInput
                  label="Program Certification Number"
                  name="ct_number"
                  data-testid="ct_number"
                />
              </div>
            </>
          ) : null}
        </div>
      ) : (
        <div className="w-50 ml-4"></div>
      )}
    </div>
  );
};
