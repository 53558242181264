import { get } from "lodash";
import { formatDate, isDate, isStatus } from "../../../ui/helpers";

const getTotalContainers = (item = {}) => {
  return (item.ccds || []).reduce(
    (acc, value) => acc + (Number(value.quantity) || 0),
    0
  );
};

export const calculatePercentage = (site = {}) => {
  const capacity = site.dm_width * site.dm_length * site.dm_height * 20;
  if (capacity <= 0) return 0;
  const totalContainers = getTotalContainers(site);
  if (!totalContainers) return;
  const capacityLeft = capacity - totalContainers;
  const capacityPrecentage = (capacityLeft * 100) / capacity;
  return (100 - capacityPrecentage).toFixed(2);
};

export function modifySite(site = {}) {
  return {
    ...site,
    region: get(site, "organisation.region.long_name", ""),
    volume: getTotalContainers(site),
    last_stocktake: get(site, "latest_site_audit.completed_at", null),
    capacity: calculatePercentage(site),
  };
}

export function modifySitesArray(sites = []) {
  return sites.map((item) => modifySite(item));
}

export function modifySiteCCD(site = {}) {
  return {
    ...site,
    material: site.material.charAt(0).toUpperCase() + site.material.slice(1),
    capacity: site.capacity ? `${site.capacity} %` : "",
    id: `${site.quantity} + ${site.size} +${site.material} + ${site.site_name}`,
  };
}

export function modifySitesInventory(sites = []) {
  return sites.map((item) => modifySiteCCD(item));
}

export const rendeTotalVolumeRow = (data) => {
  const sum = data.reduce((acc, value) => acc + Number(value.quantity), 0);
  return (
    <tr className="border-bottom">
      <td className="no-line border-0 align-middle px-5 pt-10 pb-1">
        <strong>Totals </strong>
      </td>
      <td colSpan="4"></td>
      <td className="pt-10 pb-1 pl-5">
        <strong> {sum}</strong>
      </td>
    </tr>
  );
};

export function modifyCollectionRun(run = {}) {
  return {
    ...run,
    planned_pickup_date: get(run, "clearance_jobs[0].planned_pickup_date")
      ? formatDate(get(run, "clearance_jobs[0].planned_pickup_date"))
      : "",
  };
}

export function modifyCollectionRuns(runs = []) {
  return runs.map((item) => modifyCollectionRun(item));
}

const getPercentage = (value) => {
  if (typeof value !== "number") return "";
  return value.toFixed(2) + " %";
};

const formatCasing = (value) => {
  if (!value) return "";
  if (typeof value !== "string") return;
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const renderSiteInvetories = (headings, item) => {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-5 ${
        isStatus(key) ? "text-uppercase" : ""
      } : ""`}
    >
      {isDate(key) && item[key]
        ? formatDate(item[key])
        : key === "material"
        ? formatCasing(item[key])
        : key === "capacity_left_percentage"
        ? getPercentage(item[key])
        : item[key]}
    </td>
  ));
};

export function modifyInventoryItem(site = {}) {
  return {
    ...site,
    id: `${site.quantity} + ${site.capacity_left_percentage} + ${site.material} + ${site.site_name}`,
  };
}

export function modifySitesInventorySchedule(sites = []) {
  return sites.map((item) => modifyInventoryItem(item));
}
