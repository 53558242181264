import React from "react";
import { Formik } from "formik";
import ButtonGroup from "@bit/the-glue.frontendcomponents.button-group";
import { MappedDatePicker } from "@bit/the-glue.frontendcomponents.datepicker";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { uniqBy } from "lodash";
import { Skeleton } from "@material-ui/lab";
import { typeOptions, flagsList, statusChangeList } from "./constants";
import { taskDetailsSchema } from "../../../helpers/schemas";
import { sortHelper } from "../../../ui/helpers";
import { AutocompleteMultiple } from "../../../ui/components/AutocompleteMultiple";
import { MappedSwitch } from "../../../ui/components/MappedSwitch";
import { LinkEntity } from "./LinkEntity";

export const TaskForm = ({
  data = {},
  handleClose,
  handleSubmit,
  users = [],
  contacts = [],
  organisations = [],
  sites = [],
  selectedTaskStatusChange = "",
  organisationId,
}) => {
  const initialValues = {
    task_name: data.task_name || "",
    task_type: data.task_type || "",
    detail: data.detail || "",
    due_date: (data.due_date && new Date(data.due_date)) || "",
    completed_date:
      (data.completed_date && new Date(data.completed_date)) || undefined,
    user_id: data?.users?.map(({ id }) => id) || [],
    flag: organisationId ? "organisations" : data.flag || "",
    contact_id: data.contact_id || "",
    organisation_id: organisationId || data.organisation_id || "",
    site_id: data.site_id || "",
    private: data.private || true,
  };

  return (
    <div className="p-5">
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={taskDetailsSchema}
        enableReinitialize
      >
        {({
          handleSubmit,
          isSubmitting,
          setFieldValue,
          values,
          setTouched,
          ...formik
        }) => (
          <>
            <h3 className="mb-10 row align-items-center">
              <div className="col-12 pt-2" style={{ fontWeight: 600 }}>
                {Object.keys(data).length ? data.task_name : "New Task"}
              </div>
              <div className="col-12">
                {selectedTaskStatusChange &&
                selectedTaskStatusChange !== data?.status ? (
                  <div
                    style={{
                      padding: "6px 16px",
                      background: "#e5ffcc",
                      display: "inline-block",
                      fontSize: 10,
                    }}
                  >
                    {
                      statusChangeList[
                        `${data?.status}_to_${selectedTaskStatusChange}`
                      ]
                    }
                  </div>
                ) : (
                  ""
                )}
              </div>
            </h3>
            <div className="row justify-content-center">
              <div className="col-12">
                <MappedInput
                  label="Task Name"
                  name="task_name"
                  data-testid="task_name"
                />
                <MappedInput
                  label="Details"
                  name="detail"
                  data-testid="detail"
                  multiline
                  rows={4}
                />
                <div className="row">
                  <div className="col-6">
                    <MappedSelect
                      label="Link"
                      options={flagsList.sort(sortHelper)}
                      name="flag"
                      data-testid="flag"
                    />
                  </div>
                  <LinkEntity
                    contacts={contacts}
                    organisations={organisations}
                    sites={sites}
                  />
                </div>
                <div className="row">
                  <div className="col-6">
                    <MappedDatePicker label="Due Date" name="due_date" />
                  </div>
                  <div className="col-6">
                    <MappedDatePicker
                      label="Completed Date"
                      name="completed_date"
                    />
                  </div>
                </div>
                <div className="">
                  <MappedSelect
                    label="Type"
                    options={typeOptions}
                    name="task_type"
                    data-testid="task_type"
                  />
                </div>
                {users.length ? (
                  <div className="w-100 mt-3">
                    <AutocompleteMultiple
                      name="user_id"
                      placeholder="Add Task Participants"
                      setValue={setFieldValue}
                      options={uniqBy(users, "label").sort(sortHelper)}
                      defaultValueField="value"
                      defaultValue={
                        (data?.users && data?.users?.map(({ id }) => id)) || []
                      }
                      variant="outlined"
                    />
                    {formik.touched.user_id && formik.errors.user_id ? (
                      <div className="text-danger">{formik.errors.user_id}</div>
                    ) : null}
                  </div>
                ) : (
                  <div className="mt-3 col-12">
                    <Skeleton variant="rect" width={"100%"} height={55} />
                  </div>
                )}

                <div className="d-flex align-items-center mt-5">
                  <div>Internal Task</div>
                  <MappedSwitch name="private" checked={values.private} />
                </div>
              </div>
            </div>
            <div className="mt-10">
              <ButtonGroup
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                textTransofrm="uppercase"
                size="large"
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
