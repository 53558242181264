import config from "react-global-configuration";

export const addBadge = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/badges`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getBadges = (search = "", archived = "", field, direction) =>
  fetch(
    `${config.get("ApiUrl").Rest}/badges?${search && "&" + search}${(archived && "&status=all") ||
      ""}${(field && "&order_by=" + field) || ""}${(direction && "&order_dir=" + direction) || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getTrainings = () =>
  fetch(`${config.get("ApiUrl").Rest}/trainings`, {
    mode: "cors",
    credentials: "include",
  });

export const getBadgeById = (badge_id) =>
  fetch(`${config.get("ApiUrl").Rest}/badges/${badge_id}`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyBadge = (data, badge_id) =>
  fetch(`${config.get("ApiUrl").Rest}/badges/${badge_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

  export const modifyTraining = (data, training_id) =>
  fetch(`${config.get("ApiUrl").Rest}/trainings/${training_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const deleteBadge = (badge_id) =>
  fetch(`${config.get("ApiUrl").Rest}/badges/${badge_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });
