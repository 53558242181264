import React from "react";
import { ReactComponent as ClientsIcon } from "../../../ui/static/Clients.svg";
import { ReactComponent as SamplesIcon } from "../../../ui/static/Samples.svg";
import { ReactComponent as AwaitingIcon } from "../../../ui/static/Awaiting.svg";

export const args = {
  type: "minimal",
  horizontal: false,
  categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  color: "#71BF44",
  titleColor: "#263238",
  title: "Daily Collection Volumes",
  titlePosition: "center",
  height: "250px"
};

export const yearVolumedArgs = {
  type: "grouped",
  horizontal: true,
  categories: [],
  color: "#71BF44",
  secondBarColor: "#FECA0A",
  titleColor: "#263238",
  title: "Collections (This Year vs Last Year)",
  titlePosition: "left"
};

export const stateVolumesArgs = {
  type: "grouped",
  horizontal: true,
  categories: [],
  color: "#FECA0A",
  secondBarColor: "#71BF44",
  thirdBarColor: "#00AEEF",
  forthBarColor: "#df379a",
  fifthBarColor: "#5632a8",
  sixthBarColor: "#eb1313",
  seventhBarColor: "#7d7070",
  titleColor: "#263238",
  title: "Collections By State (since inception)",
  titlePosition: "left"
};

export const tilesMap = [
  {
    title: "Clients",
    icon: <ClientsIcon />,
    path: "/organisations/organisations-list"
  },
  {
    title: "Current Tasks",
    icon: <SamplesIcon />,
    path: "/tasks"
  },
  {
    title: "Overdue Tasks",
    icon: <AwaitingIcon />,
    path: "/tasks"
  }
];

export const endUsertilesMap = [
  {
    title: "Current Tasks",
    icon: <SamplesIcon />,
    path: "/tasks"
  },
  {
    title: "Overdue Tasks",
    icon: <AwaitingIcon />,
    path: "/tasks"
  }
];
