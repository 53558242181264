import config from "react-global-configuration";

export const getGroups = () =>
  fetch(`${config.get("ApiUrl").Rest}/groups`, {
    mode: "cors",
    credentials: "include",
  });

export const addGroup = (groupData) =>
  fetch(`${config.get("ApiUrl").Rest}/groups`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(groupData),
    mode: "cors",
    credentials: "include",
  });

export const getGroupById = (group_id) =>
  fetch(`${config.get("ApiUrl").Rest}/groups/${group_id}`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyGroup = (groupData, group_id) =>
  fetch(`${config.get("ApiUrl").Rest}/groups/${group_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(groupData),
    mode: "cors",
    credentials: "include",
  });

export const removeGroup = (group_id) =>
  fetch(`${config.get("ApiUrl").Rest}/groups/${group_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const getGroupUsers = (group_id) =>
  fetch(`${config.get("ApiUrl").Rest}/groups${group_id}/users`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyGroupUsers = (users, group_id) =>
  fetch(`${config.get("ApiUrl").Rest}/groups/${group_id}/users`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(users),
    mode: "cors",
    credentials: "include",
  });

export const removeGroupUsers = (users, group_id) =>
  fetch(`${config.get("ApiUrl").Rest}/groups/${group_id}/users`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(users),
    mode: "cors",
    credentials: "include",
  });

export const getGroupPolicies = (group_id) =>
  fetch(`${config.get("ApiUrl").Rest}/groups${group_id}/policies`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyGroupPolicies = (policies, group_id) =>
  fetch(`${config.get("ApiUrl").Rest}/groups/${group_id}/policies`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(policies),
    mode: "cors",
    credentials: "include",
  });

export const removeGroupPolicies = (policies, group_id) =>
  fetch(`${config.get("ApiUrl").Rest}/groups/${group_id}/policies`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(policies),
    mode: "cors",
    credentials: "include",
  });

export const getPolicies = () =>
  fetch(`${config.get("ApiUrl").Rest}/policies`, {
    mode: "cors",
    credentials: "include",
  });

export const addPolicy = (policyData) =>
  fetch(`${config.get("ApiUrl").Rest}/policies`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(policyData),
    mode: "cors",
    credentials: "include",
  });

export const getPolicyById = (policy_id) =>
  fetch(`${config.get("ApiUrl").Rest}/policies/${policy_id}`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyPolicy = (policyData, policy_id) =>
  fetch(`${config.get("ApiUrl").Rest}/policies/${policy_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(policyData),
    mode: "cors",
    credentials: "include",
  });

export const removePolicy = (policy_id) =>
  fetch(`${config.get("ApiUrl").Rest}/policies/${policy_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const getPolicyUsers = (policy_id) =>
  fetch(`${config.get("ApiUrl").Rest}/policies${policy_id}/users`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyPolicyUsers = (users, policy_id) =>
  fetch(`${config.get("ApiUrl").Rest}/policies/${policy_id}/users`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(users),
    mode: "cors",
    credentials: "include",
  });

export const removePolicyUsers = (users, policy_id) =>
  fetch(`${config.get("ApiUrl").Rest}/policies/${policy_id}/users`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(users),
    mode: "cors",
    credentials: "include",
  });

export const getPolicyGroups = (policy_id) =>
  fetch(`${config.get("ApiUrl").Rest}/policies${policy_id}/groups`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyPolicyGroups = (groups, policy_id) =>
  fetch(`${config.get("ApiUrl").Rest}/policies/${policy_id}/groups`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(groups),
    mode: "cors",
    credentials: "include",
  });

export const removePolicyGroups = (groups, policy_id) =>
  fetch(`${config.get("ApiUrl").Rest}/policies/${policy_id}/groups`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(groups),
    mode: "cors",
    credentials: "include",
  });
