export const stateOptions = [
  { value: "ACT", label: "ACT" },
  { value: "NSW", label: "NSW" },
  { value: "NT", label: "NT" },
  { value: "QLD", label: "QLD" },
  { value: "SA", label: "SA" },
  { value: "TAS", label: "TAS" },
  { value: "WA", label: "WA" },
  { value: "VIC", label: "VIC" },
];

export const ProgramsList = [
  {
    value: "freshcare",
    label: "Freshcare",
  },
  {
    value: "BRC",
    label: "BRC",
  },
  {
    value: "SQF",
    label: "SQF",
  },
  {
    value: "globalGAP",
    label: "GlobalGAP",
  },
  {
    value: "HACCP",
    label: "HACCP",
  },
];

export const organisationTypes = [
  { value: "golf_course", label: "Golf Course" },
  { value: "farmer", label: "Farmer" },
  { value: "retailer", label: "Retailer" },
  { value: "council", label: "Council" },
  { value: "business", label: "Business" },
];

export const isQaProgramOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const HEADINGS = [
  ["id", "ID"],
  ["product", "Product"],
  ["container_size", "Size"],
  ["container_qty", "Qty"],
  ["integrity", "Integrity"],
  ["status", "Status"],
  ["qty_remaining", "Remaining"],
];

export const inputStype = {
  minHeight: "65px",
  width: "49%",
};
