import config from "react-global-configuration";

export const addPackSize = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/pack-sizes`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getPackSizes = (search = "", field, direction) =>
  fetch(
    `${config.get("ApiUrl").Rest}/pack-sizes?${search && "&" + search}${(field &&
      "&order_by=" + field) ||
      ""}${(direction && "&order_dir=" + direction) || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getPackSizeById = (pack_id) =>
  fetch(`${config.get("ApiUrl").Rest}/pack-sizes/${pack_id}`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyPackSize = (data, pack_id) =>
  fetch(`${config.get("ApiUrl").Rest}/pack-sizes/${pack_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const deletePackSize = (pack_id) =>
  fetch(`${config.get("ApiUrl").Rest}/pack-sizes/${pack_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const geProducttPackSizes = (product_id) =>
  fetch(`${config.get("ApiUrl").Rest}/products/${product_id}/pack-sizes`, {
    mode: "cors",
    credentials: "include",
  });

export const editProductPackSizes = (product_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/products/${product_id}/pack-sizes`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const deleteProductPackSizes = (product_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/products/${product_id}/pack-sizes`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });
