import React from "react";
import { useDrop } from "react-dnd";
import { TaskCard } from "./TaskCard";
import { Skeleton } from "@material-ui/lab";

const columnsMap = {
  "Overdue Tasks": "overdue",
  "Current Tasks": "current",
  "Completed Tasks": "completed",
};

export const TaskColumn = ({
  title,
  data = [],
  setTasks,
  tasks = [],
  handleModalOpen,
  handleStatusChange,
  selectedTaskStatusChange,
  contacts,
  completedLoading,
  currentLoading,
  overdueLoading,
}) => {
  const changeTaskStatus = (element, title) => {
    // let task = tasks.find((item) => item.id === element.id);
    // const index = task && tasks.indexOf(task);
    const newStatus = columnsMap[title];
    // task = { ...task, status: newStatus };
    // setTasks((state) => [
    //   ...state.slice(0, index),
    //   task,
    //   ...state.slice(index + 1),
    // ]);
    handleStatusChange(element.id, newStatus);
  };

  const [, drop] = useDrop(() => ({
    accept: "task",
    drop(item) {
      changeTaskStatus(item, title);
    },
  }));

  const renderContent = () => {
    if (completedLoading && title === "Completed Tasks") {
      return [...Array(3)].map((e, i) => (
        <Skeleton
          key={i}
          className="mb-8"
          variant="rect"
          width={"100%"}
          height={139}
        />
      ));
    } else if (currentLoading && title === "Current Tasks") {
      return [...Array(2)].map((e, i) => (
        <Skeleton
          key={i}
          className="mb-8"
          variant="rect"
          width={"100%"}
          height={139}
        />
      ));
    } else if (overdueLoading && title === "Overdue Tasks") {
      return [...Array(1)].map((e, i) => (
        <Skeleton
          key={i}
          className="mb-8"
          variant="rect"
          width={"100%"}
          height={139}
        />
      ));
    } else
      return data.map((task) => (
        <TaskCard
          data={task}
          selectedTaskStatusChange={selectedTaskStatusChange}
          handleModalOpen={handleModalOpen}
          key={task.id}
          contacts={contacts}
        />
      ));
  };

  return (
    <div
      className="border py px-10"
      style={{
        width: "32%",
        minHeight: "80vh",
        background: "rgba(255,255,255,0.7)",
      }}
      ref={drop}
    >
      <h3 className="my-4">
        <strong>{title} </strong>
      </h3>
      <hr />
      <div>{renderContent()}</div>
    </div>
  );
};
