import config from "react-global-configuration";

export const getActiveClearances = (search = "") =>
  fetch(
    `${config.get("ApiUrl").Rest}/clearance_jobs?${search &&
      "&" + search}&drop_off_id=_null_`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getCompleteClearances = (search = "", page = 0, pageSize = 10) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/clearance_jobs?neq-drop_off_id=_null_&invoice_id=_null_${search &&
      "&" + search}&page=${page}&page_size=${pageSize}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getCompleteClearancesCount = (search = "") =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/clearance_jobs?count_only=y&neq-drop_off_id=_null_&invoice_id=_null_${search &&
      "&" + search}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const getCompleteClearancesAutocomplete = (
  page_size = 80,
  autocompleteField,
  autocompleteText = "",
  search,
  status = ""
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/clearance_jobs?neq-drop_off_id=_null_&invoice_id=_null_&page_size=${page_size}${(autocompleteField &&
      "&distinct=" +
        autocompleteField +
        "&" +
        autocompleteField +
        "=_like(" +
        autocompleteText +
        ")_") ||
      ""}${search ? "&" + search : ""}&status=${status}`,
    {
      mode: "cors",
      credentials: "include"
    }
  );

export const completeClearance = (data, id) =>
  fetch(`${config.get("ApiUrl").Rest}/clearance_jobs/${id}/complete`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const createClearanceInvoice = (id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/collection_runs/${id}/invoices`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include"
  });

export const modifyClearanceInvoice = (data, id, invoice_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/collection_runs/${id}/invoices/${invoice_id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include"
    }
  );

export const modifyClearanceFile = (data, id, invoice_id, file_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/collection_runs/${id}/invoices/${invoice_id}/files/${file_id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include"
    }
  );
