export const HEADINGS = [
  ["code", "ID"],
  ["state", "State"],
  ["region", "Region"],
  ["date_requested", "Date Requested"],
  ["volume", "Volume"],
  ["total_value_gst", "Value (inc GST)"],
  ["type", "Type"],
  ["invoice_number", "Invoice"],
  ["grouped", "Grouped"],
  ["status", "Status"],
  ["organisation_name", "Claim Payee Organisation"]
];

export const searchMap = {
  ID: "code",
  State: "organisation.physical_state",
  Region: "organisation.region.long_name",
  "Claim Payee Organisation": "organisation.business_name",
  Invoice: "invoice.code",
  Grouped: "grouped"
};

export const ADD_DROPS_HEADINGS = [
  ["associated_site", "Site Name"],
  ["drop_off_date", "Date"],
  ["business", "Business"],
  ["name", "Name"],
  ["total_plastic", "Plastic"],
  ["total_steel", "Steel"],
  ["total", "Total"],
  ["rejected", "Rejected"],
  ["inspector", "Inspector"]
];
