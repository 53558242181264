import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import MoodleIcon from "../static/Moodle.png";
import { ListButton } from "../../../../ui/components/ListButton";

const initialValues = {
  apiKey: "",
};

export function AuditorDetails({ onClose }) {
  const handleSubmit = (values) => {};

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object({
        apiKey: Yup.string().required(),
      })}
      onSubmit={handleSubmit}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <div className="px-5 pb-10">
          <div className="d-flex justify-content-between align-items-end">
            <h4>
              <strong>Establish Your Connection to iAuditor</strong>
            </h4>

            <img src={MoodleIcon} alt="logo" />
          </div>
          <div className="px-12 mt-15 mb-10">
            <p className="mb-8">
              drumDRIVE utilises the Illuminance Labs iAuditor accelerator for connecting to and
              interacting with iAuditor.
            </p>
            <p className="mb-8"> This accelerator is used to:</p>
            <ul>
              <li>Create new Site Audits in iAuditor</li>
              <li> Prefil information from Sites in Site Audits</li>
              <li> Retrieve and store copies of completed audits in the site profile</li>
            </ul>
            <p className="mt-8 mb-0">
              Please generate iAuditor API Token and save this below in order for your connection to
              be established.
            </p>
            <p className="mt-0">
              Instructions for creating this token are available at &nbsp;
              <a
                href="https://help.safetyculture.com/en_us/1063650457-rkCBKDK4P"
                target="_blank"
                rel="noreferrer"
              >
                https://help.safetyculture.com/en_us/1063650457-rkCBKDK4P
              </a>
            </p>
            <MappedInput
              name="apiKey"
              wrapperClassName="w-100"
              label="iAuditor API Token"
              placeholder="Paste your API Token here"
            />
          </div>
          <div className="d-flex justify-content-end">
            <ListButton
              label="Cancel"
              className="mr-4 px-16"
              size="small"
              variant="outlined"
              boxShadow={false}
              text="#407A28"
              onClick={onClose}
            />
            <ListButton label="Save" size="small" className="px-20" />
          </div>
        </div>
      )}
    </Formik>
  );
}
