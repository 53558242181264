import React, { useState, useEffect } from "react";
import {
  Chip,
  IconButton,
  LinearProgress,
  makeStyles
} from "@material-ui/core";
import Linkify from "react-linkify";
import Input from "@bit/the-glue.frontendcomponents.input";
import {
  Edit as EditIcon,
  AttachFile as AttachFileIcon
} from "@material-ui/icons";
import { formatDate } from "../../../ui/helpers";
import { ListButton } from "../../../ui/components/ListButton";
import { SortSelect } from "../../../ui/components/SortSelect";
import Options from "../../../ui/components/Options";
import { TaskForm } from "./TaskForm";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  addTaskComment,
  getTaskComments,
  deleteTaskComment,
  editTaskComment,
  createTaskCommentFile,
  modifyTaskCommentFile,
  downloadTaskCommentFile,
  modifyTask,
  archiveTask
} from "./_api";
import { sortOptions, filterOptions } from "./constants";
import { Skeleton } from "@material-ui/lab";
import { startUpload } from "../../../app/modules/AWS-S3/upload";
import Dropzone from "react-dropzone";
import { modifyComments } from "./helpers";
import { get } from "lodash";
import { FilePreview } from "./FilePreview";
import { info } from "../../../helpers/toasts";
import { AccessControl } from "../../../ui/structures/AccessControl";
import { AVAILABLE_ROLES } from "../../constants";

const chipStyle = {
  color: "#00C853",
  backgroundColor: "#ECF9F3",
  minWidth: "90px",
  fontSize: "14px"
};

const setTypeChipStyle = type => {
  if (type === "event") {
    return { ...chipStyle, textTransform: "uppercase" };
  } else
    return {
      color: "#2196F3",
      backgroundColor: "#E3F2FD",
      minWidth: "90px",
      fontSize: "14px",
      textTransform: "uppercase"
    };
};

const useStyles = makeStyles({
  root: {
    display: "flex !important",
    alignItems: "start !important"
  }
});

export const TaskDetails = ({
  data = {},
  users = [],
  contacts = [],
  organisations = [],
  sites = [],
  incomingEditMode = false,
  handleClose,
  setTasksLoading,
  handleEditTask,
  editable = true,
  tasks = [],
  setTasks,
  archived
}) => {
  const { request } = useFetch();
  const classes = useStyles();

  const [comment, setComment] = useState("");
  const [editedComment, setEditedComment] = useState("");
  const [editedCommentId, setEditedComentId] = useState("");
  const [commentEditMode, setCommentEditMode] = useState(false);
  const [editMode, setEditMode] = useState(incomingEditMode);
  const [commentsList, setCommentsList] = useState([]);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [sortValue, setSortValue] = useState("created_at");
  const [filterValue, setFilterValue] = useState("all");
  const [key, setKey] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [actionLoading, setActionLoading] = useState(false);

  const [addCommentFileIsOpen, setAddCommentFileIsOpen] = useState(false);
  const [fileData, setFileData] = useState({});

  const handleCommentChange = e => setComment(e.target.value);

  const handleEditedCommentChange = e => setEditedComment(e.target.value);

  const handleSortValueCHange = event => {
    setSortValue(event.target.value);
  };

  const handleFilterValueCHange = event => {
    setFilterValue(event.target.value);
  };

  const handleEditModeOpen = () => setEditMode(true);

  useEffect(() => {
    if (sortValue === "created_at") {
      setCommentsList(state =>
        state.sort(function(a, b) {
          if (a[sortValue] < b[sortValue]) return 1;
          if (a[sortValue] > b[sortValue]) return -1;
          return 0;
        })
      );
    } else {
      setCommentsList(state =>
        state.sort(function(a, b) {
          if (a[sortValue] > b[sortValue]) return 1;
          if (a[sortValue] < b[sortValue]) return -1;
          return 0;
        })
      );
    }
    setKey(Date.now());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue, commentsList]);

  const handleAddComment = () => {
    if (fileData.length && !comment) {
      setError("A comment must be entered before you can post your attachment");
      return;
    }
    if (!comment) return;
    setSubmitting(true);
    setError("");
    setCommentsLoading(true);
    request(addTaskComment, data?.id, {
      comment: comment
    }).then(comment => {
      if (!comment) return;
      fileData.length &&
        fileData?.map(file =>
          request(createTaskCommentFile, comment?.task_id, comment?.id, {
            file_name: file.name
          }).then(data => {
            const payload = {
              url: data?.aws_s3_presigned_package.url,
              fields: {
                ...data?.aws_s3_presigned_package.fields,
                file: file
              }
            };
            startUpload(file, payload)
              .catch(err => {
                error((err || {}).message);
              })
              .then(() => {
                request(
                  modifyTaskCommentFile,
                  comment?.task_id,
                  comment?.id,
                  data?.id,
                  { file_name: data?.aws_s3_presigned_package?.fields?.key }
                ).then(fetchComments);
              });
          })
        );
    });

    setComment("");
    setSubmitting(false);
  };

  const handleDeleteComment = commentId => {
    // setCommentsList((state) => state.filter(({ id }) => id !== commentId));
    request(deleteTaskComment, data?.id, commentId).then(fetchComments);
  };

  const handleEditComment = commentId => {
    const editedComment = commentsList.find(({ id }) => id === commentId);
    if (!editedComment) return;
    setEditedComentId(commentId);
    setCommentEditMode(true);
    setEditedComment((editedComment || {}).comment);
  };

  const handleCancelEdit = () => {
    setEditedComentId("");
    setCommentEditMode(false);
    setEditedComment("");
  };

  const handleSubmitEdit = () => {
    if (!editedCommentId) return;
    request(editTaskComment, data?.id, editedCommentId, {
      comment: editedComment
    }).then(fetchComments);
    setEditedComentId("");
    setCommentEditMode(false);
    setEditedComment("");
  };

  const handleTaskDetailsClose = () => setEditMode(false);

  const fetchComments = () => {
    setCommentsLoading(true);
    request(getTaskComments, data?.id).then(data => {
      if (!data) return;
      setCommentsList(modifyComments(data));
      setCommentsLoading(false);
    });
  };

  useEffect(() => {
    if (data?.id) fetchComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleDataUpdate = (item = {}, array = [], setData) => {
    const index = array.findIndex(task => (task || {}).id === (item || {}).id);
    setData(state => [
      ...state.slice(0, index),
      item,
      ...state.slice(index + 1)
    ]);
  };

  const handleArchiveTask = () => {
    setActionLoading(true);
    request(archiveTask, data.id)
      .then(res => {
        if (!res) return;
        info("Task has been archived!");
        if (archived) {
          handleDataUpdate(
            { ...data, archive_status: "archived" },
            tasks,
            setTasks
          );
        } else {
          setTasks(state => state.filter(task => task.id !== data.id));
        }
        handleClose();
      })
      .finally(() => setActionLoading(false));
  };

  const handleUnarchiveTask = () => {
    setActionLoading(true);
    request(modifyTask, data.id, { status: "active" })
      .then(res => {
        if (!res) return;
        info("Task has been unarchived!");
        handleDataUpdate(
          { ...data, archive_status: "active" },
          tasks,
          setTasks
        );
        handleClose();
      })
      .finally(() => setActionLoading(false));
  };

  return (
    <>
      {editMode ? (
        <TaskForm
          handleClose={handleTaskDetailsClose}
          handleSubmit={handleEditTask}
          data={data}
          users={users}
          contacts={contacts}
          organisations={organisations}
          sites={sites}
        />
      ) : (
        <Linkify>
          {actionLoading ? (
            <div className="mb-2">
              <LinearProgress color="secondary" />
            </div>
          ) : null}
          <div className="px-7" key={key}>
            <div className="d-flex justify-content-between align-items-top">
              <div>
                <div className="d-flex align-items-top">
                  <strong style={{ fontSize: 18 }}>{data.task_name}</strong>
                  {editable ? (
                    <div
                      className="ml-5 cursor-pointer"
                      onClick={handleEditModeOpen}
                    >
                      <EditIcon />
                    </div>
                  ) : null}
                </div>
                <AccessControl
                  desiredRoles={[
                    AVAILABLE_ROLES.NATIONAL_ADMIN,
                    AVAILABLE_ROLES.AGSAFE_ADMIN
                  ]}
                >
                  <div className="mt-3">
                    {data.archive_status === "archived" ? (
                      <ListButton
                        label="Unarchive"
                        onClick={handleUnarchiveTask}
                        disabled={actionLoading}
                      />
                    ) : (
                      <ListButton
                        label="Archive"
                        onClick={handleArchiveTask}
                        disabled={actionLoading}
                      />
                    )}
                  </div>
                </AccessControl>
              </div>
              <div>
                {data.task_type ? (
                  <div className="mb-2">
                    <Chip
                      label={(
                        data.task_type.charAt(0).toUpperCase() +
                        data.task_type.slice(1)
                      )
                        .split("_")
                        .join(" ")}
                      style={setTypeChipStyle(data.task_type)}
                    />
                  </div>
                ) : null}

                <p>
                  Accountable:{" "}
                  {data.accountable
                    ? data.accountable
                    : data.creator_user?.name}
                </p>
                <p>Due : {data.due_date ? formatDate(data.due_date) : "N/A"}</p>
                <p>Link : {data.linked_entity ? data.linked_entity : null}</p>
                <p>Author: {get(data, "creator_user.name", "")}</p>
              </div>
            </div>
            <div className="mb-5 p-3" style={{ wordWrap: "break-word" }}>
              {data.detail}
            </div>
            <div>
              <Input
                label="Add Comment"
                onChange={handleCommentChange}
                value={comment}
                rows={10}
                multiline
                classes={classes}
                endAdornment={
                  <IconButton
                    aria-label="delete"
                    style={{ marginTop: "-19px" }}
                    onClick={() =>
                      setAddCommentFileIsOpen(!addCommentFileIsOpen)
                    }
                  >
                    <AttachFileIcon />
                  </IconButton>
                }
              />
              {error && <div className="text-danger">{error}</div>}
            </div>

            <div
              className="d-flex justify-content-between align-items-center mb-5"
              style={{ flexDirection: "column" }}
            >
              <div className="d-flex justify-content-between w-100">
                {addCommentFileIsOpen ? (
                  <div className="w-100 pr-5">
                    <Dropzone
                      onDrop={acceptedFiles => setFileData(acceptedFiles)}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div
                            {...getRootProps()}
                            style={{
                              flex: "1",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              padding: 20,
                              borderWidth: 2,
                              borderRadius: 2,
                              borderColor: "#eeeeee",
                              borderStyle: "dashed",
                              backgroundColor: "#fafafa",
                              color: "#bdbdbd",
                              outline: "none",
                              transition: "border .24s ease-in-out",
                              cursor: "pointer"
                            }}
                          >
                            <input {...getInputProps()} />
                            {fileData.length ? (
                              fileData?.map(file => (
                                <div key={file.path}>{file.path}</div>
                              ))
                            ) : (
                              <p>
                                Drag 'n' drop some files here, or click to
                                select files
                              </p>
                            )}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="text-right">
              <ListButton
                label="Post"
                size="large"
                disabled={submitting || commentsLoading || actionLoading}
                onClick={handleAddComment}
              />
            </div>
            {data?.users ? (
              <div className="text-left">
                <div className="text-muted mb-4">Task Participants</div>
                <div className="w-100">
                  {data?.users?.map(({ name }) => (
                    <Chip label={name} style={chipStyle} key={name} />
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="d-flex mt-10">
              <SortSelect
                options={filterOptions}
                value={filterValue}
                onChange={handleFilterValueCHange}
                label="Filter"
                wrapperClassName="mr-3"
              />

              <SortSelect
                options={sortOptions}
                value={sortValue}
                onChange={handleSortValueCHange}
                label="Sort By"
              />
            </div>
            <div className="pb-5">
              {commentsLoading ? (
                <Skeleton variant="rect" width={"100%"} height={139} />
              ) : commentsList.length ? (
                commentsList?.map(
                  ({ comment, created_at, id, user, files = [] }) => (
                    <div className="mb-6" key={id}>
                      <hr />
                      <div className="d-flex justify-content-between align-items-bottom">
                        {commentEditMode && editedCommentId === id ? (
                          <div className="w-100">
                            <Input
                              onChange={handleEditedCommentChange}
                              value={editedComment}
                              multiline
                              rows={4}
                            />
                            <div className="d-flex justify-content-end">
                              <ListButton
                                label="cancel"
                                variant="outlined"
                                text="#407A28"
                                boxShadow={false}
                                onClick={handleCancelEdit}
                              />
                              <ListButton
                                label="save"
                                className="ml-3"
                                onClick={handleSubmitEdit}
                              />
                            </div>
                          </div>
                        ) : (
                          <div>
                            <strong>{comment}</strong>
                          </div>
                        )}
                        <Options
                          id={id}
                          handleDelete={handleDeleteComment}
                          handleEdit={handleEditComment}
                        />
                      </div>
                      <div className="mt-3 mb-1">
                        <div>
                          {user?.name}{" "}
                          <span className="ml-3">{formatDate(created_at)}</span>
                        </div>
                        {user?.position_title ? (
                          <div>{user?.position_title}</div>
                        ) : (
                          ""
                        )}
                      </div>
                      {files.length
                        ? files?.map(file => (
                            <FilePreview
                              key={file.id}
                              fetchFunction={downloadTaskCommentFile}
                              task_id={data.id}
                              comment_id={id}
                              file_id={file.id}
                              file_name={file.file_name}
                              setLoading={setLoading}
                              loading={loading}
                            />
                          ))
                        : ""}
                    </div>
                  )
                )
              ) : (
                <div
                  style={{
                    fontStyle: "italic",
                    margin: "34.75px auto",
                    textAlign: "center"
                  }}
                >
                  No comments
                </div>
              )}
            </div>
          </div>
        </Linkify>
      )}
    </>
  );
};
