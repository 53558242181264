import React from "react";
import { buttonStyle, itemStyle } from "../constants";
import { ReactComponent as BulkIcon } from "../static/Bulk.svg";
import "./BulkStyle.css";

export const BulkSMS = ({ handleConnect }) => {
  return (
    <>
      <div style={itemStyle} className={`${"bg-white"} p-7 mt-10`}>
        <div className="text-center mb-10 bulk">
          <BulkIcon />
        </div>
        <h3 className="mb-2 text-dark">
          <strong>Bulk SMS</strong>
        </h3>
        <div style={{ fontSize: "14px" }}>Integration allows sending SMS to the contacts</div>
        <div className="mt-35">
          <button className={`${buttonStyle} text-primary`} onClick={handleConnect}>
            Open
          </button>
        </div>
      </div>
    </>
  );
};
