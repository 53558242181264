import { get } from "lodash";

export function modifySite(site = {}) {
  return {
    ...site,
    badges: (site.badges || []).map((badge) => (badge || {}).name),
    region: get(site, "organisation.region.long_name", ""),
    consultant: [
      get(site, "organisation.consultant.first_name", ""),
      get(site, "organisation.consultant.last_name", ""),
    ].join(" "),
  };
}

export function modifySitesArray(sites = []) {
  return sites.map((item) => modifySite(item));
}

export function modifyAudit(audit = {}) {
  return {
    ...audit,
    consultant: [
      get(audit, "consultant.first_name", ""),
      get(audit, "consultant.last_name", ""),
    ].join(" "),
  };
}

export function modifyAuditsArray(audits = []) {
  return audits.map((item) => modifyAudit(item));
}
