import React, { useState } from "react";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { Formik } from "formik";
import { SplitInventorySchema } from "../../../../../helpers/schemas";
import { ListButton } from "../../../../../ui/components/ListButton";
import { useFetch } from "../../../../../hooks/fetch.hook";
import { removeEmptyFields } from "../../../../../ui/helpers";
import { createDrops, editDrop } from "../../Inspections/_api";
import { error, info } from "../../../../../helpers/toasts";
import { LinearProgress } from "@material-ui/core";
import { NewDropForm } from "./NewDropForm";
import { setCCDPayload } from "../helpers";

export const SplitInventory = ({
  handleClose,
  data = {},
  nonModifiedData = {},
  setDropSplited,
  dropSplited,
}) => {
  const { request } = useFetch();
  const [loading, setLoading] = useState(false);
  const {
    plastic_0,
    plastic_1,
    plastic_2,
    plastic_3,
    steel_0,
    steel_1,
    steel_2,
    steel_3,
    site_name,
    source,
    consultant,
  } = data;

  const initialValues = {
    plastic_0: plastic_0 || "",
    plastic_1: plastic_1 || "",
    plastic_2: plastic_2 || "",
    plastic_3: plastic_3 || "",
    steel_0: steel_0 || "",
    steel_1: steel_1 || "",
    steel_2: steel_2 || "",
    steel_3: steel_3 || "",
    new_plastic_0: "",
    new_plastic_1: "",
    new_plastic_2: "",
    new_plastic_3: "",
    new_steel_0: "",
    new_steel_1: "",
    new_steel_2: "",
    new_steel_3: "",
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const newTotal = Object.values(values)
      .filter(Boolean)
      .reduce((acc, value) => acc + Number(value), 0);
    if (newTotal !== data.total) {
      error("Total amount of the containers can't be changed!");
    }
    const editCCDs = setCCDPayload(
      values.plastic_0,
      values.plastic_1,
      values.plastic_2,
      values.plastic_3,
      values.steel_0,
      values.steel_1,
      values.steel_2,
      values.steel_3
    );

    const addCCDs = setCCDPayload(
      values.new_plastic_0,
      values.new_plastic_1,
      values.new_plastic_2,
      values.new_plastic_3,
      values.new_steel_0,
      values.new_steel_1,
      values.new_steel_2,
      values.new_steel_3
    );

    const editPayload = {
      ...nonModifiedData,
      ccds: editCCDs,
    };

    const addPyaload = {
      ...nonModifiedData,
      ccds: addCCDs,
    };

    removeEmptyFields(editPayload);
    removeEmptyFields(addPyaload);

    setLoading(true);
    Promise.all([
      request(createDrops, addPyaload),
      request(editDrop, editPayload, data.id),
    ])
      .then(([addRes, editRes]) => {
        addRes && info("New Drop has been created!");
        editRes && info("Drop has been edited!");
        setDropSplited(!dropSplited);
        handleClose();
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };
  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={SplitInventorySchema}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <div data-testid="split-inventory">
          <div>
            <h4 className="mb-3">Details</h4>
            <p>
              <strong>Site Name: </strong> {site_name}
            </p>
            <p>
              <strong>Consultant: </strong> {consultant}
            </p>
            <p>
              <strong>Source: </strong> {source}
            </p>
          </div>
          <hr className="mt-7" />
          <h3 className="mb-7">Edit Drop-off</h3>
          <div className="d-flex justify-content-between">
            <div className="w-50">
              <h4 className="ml-3 mb-5">Plastic</h4>
              <div className="d-flex align-items-center">
                <div className="col-3">Less than 20</div>
                <div className="col-8 ">
                  <MappedInput
                    name="plastic_0"
                    disabled={!Boolean(data.plastic_0)}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="col-3">20-25</div>
                <div className="col-8 ">
                  <MappedInput
                    name="plastic_1"
                    disabled={!Boolean(data.plastic_1)}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="col-3">26-120</div>
                <div className="col-8 ">
                  <MappedInput
                    name="plastic_2"
                    disabled={!Boolean(data.plastic_2)}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="col-3">121-205</div>
                <div className="col-8">
                  <MappedInput
                    name="plastic_3"
                    disabled={!Boolean(data.plastic_3)}
                  />
                </div>
              </div>
            </div>
            <div className="w-50">
              <h4 className="ml-3 mb-5">Steel</h4>
              <div className="d-flex align-items-center">
                <div className="col-3">Less than 20</div>
                <div className="col-8">
                  <MappedInput
                    name="steel_0"
                    disabled={!Boolean(data.steel_0)}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="col-3">20-25</div>
                <div className="col-8">
                  <MappedInput
                    name="steel_1"
                    disabled={!Boolean(data.steel_1)}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="col-3">26-120</div>
                <div className="col-8">
                  <MappedInput
                    name="steel_2"
                    disabled={!Boolean(data.steel_2)}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="col-3">121-205</div>
                <div className="col-8">
                  <MappedInput
                    name="steel_3"
                    disabled={!Boolean(data.steel_3)}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />

          <NewDropForm data={data} />

          <div className="my-6 d-flex justify-content-end mr-4">
            <div className="mr-3">
              <ListButton
                label="Cancel"
                size="large"
                onClick={handleClose}
                variant="outlined"
                text="#407A28"
                boxShadow={false}
              />
            </div>
            <div>
              <ListButton
                label="Submit"
                size="large"
                boxShadow={false}
                onClick={handleSubmit}
                disabled={isSubmitting}
              />
            </div>
          </div>
          <div className="py-2">{loading && <LinearProgress />}</div>
        </div>
      )}
    </Formik>
  );
};
