import React from "react";
import { FormControlLabel, CircularProgress } from "@material-ui/core";
import { ListButton } from "./ListButton";

export const UnarchiveButton = ({
  loading,
  handleClick,
  disabled,
  label = "Unarchive",
}) => {
  return (
    <div style={{ marginTop: "0.5rem", minWidth: "120px", minHeight: "36px" }}>
      <FormControlLabel
        control={
          loading ? (
            <CircularProgress size="1.2rem" />
          ) : (
            <ListButton
              label={label}
              disabled={disabled}
              onClick={handleClick}
              data-testid="unarchive-btn"
            />
          )
        }
      />
    </div>
  );
};
