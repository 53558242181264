import React, { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { get } from "lodash";
import { TextField, Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const TagsAutocomplete = ({
  name,
  placeholder,
  setValue,
  options,
  loading,
  onClose,
  currentValue,
  multiple,
  defaultValue,
  disabled,
  label = "name",
  ...other
}) => {
  const [tags, setTags] = useState([]);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = () => {
    setValue(name, [...currentValue, ...tags.map((tag) => tag.id)]);
    onClose();
  };

  const handleChangeMultiple = (_, value, reason) => {
    setTags(value);
  };

  const handleCHange = (_, value) => {
    setValue(name, get(value, "name", ""));
  };

  return (
    <>
      <div style={{width: "87%"}}>
        <Autocomplete
          options={options}
          id={name}
          multiple={multiple}
          onChange={multiple ? handleChangeMultiple : handleCHange}
          getOptionLabel={(item) => (item.category ? `${item.name} - ${item.category}` : item.name)}
          defaultValue={options.find(({ id }) => id === defaultValue)}
          disabled={disabled}
          disableCloseOnSelect
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          {...other}
          renderInput={(params) => (
            <TextField
              {...params}
              label={placeholder}
              variant="outlined"
              color="secondary"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.stopPropagation();
                  handleClose();
                }
              }}
            />
          )}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option[label]}
            </React.Fragment>
          )}
        />
      </div>
      <div className="d-flex justify-content-end my-3">
        <button onClick={onClose} className="btn btn-white">
          <strong>Cancel</strong>
        </button>
        <button onClick={handleSubmit} className="btn btn-white">
          <strong>OK</strong>
        </button>
      </div>
    </>
  );
};
