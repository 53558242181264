import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { MappedSwitch } from "../../../../ui/components/MappedSwitch";

const addressMap = {
  drum_address_1: "contact_address_1",
  drum_address_2: "contact_address_2",
  drum_suburb: "contact_suburb",
  drum_state: "contact_state",
  drum_postcode: "contact_postcode",
};

export const FillAddress = () => {
  const { values, setFieldValue } = useFormikContext();

  const calculateValue = (fieldName, value) => (value ? values[fieldName] : "");

  useEffect(() => {
    Object.keys(addressMap).forEach((fieldName) => {
      setFieldValue(fieldName, calculateValue(addressMap[fieldName], values.as_contact_address));
    });
    // eslint-disable-next-line
  }, [values.as_contact_address]);

  return (
    <div className="d-flex align-items-center justify-content-end mt-3">
      <p className="mr-2 mt-3">Use Contact Address as Drum Storage Location Information</p>
      <MappedSwitch name="as_contact_address" checked={values.as_contact_address} />
    </div>
  );
};
