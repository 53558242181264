import React from "react";
import Dropzone from "react-dropzone";
import { ReactComponent as UploadIcon } from "../../../../ui/static/UploadIcon.svg";
import { File } from "../../../../ui/structures/FileUploadForm/File";
import { ListButton } from "../../../../ui/components/ListButton";

export const UploadProductsFile = ({
  handleUploadFile,
  uploadedFile,
  setUploadedFile,
  loading,
  handleClose,
  getUploadedData,
}) => {
  return (
    <div>
      <div className="text-center">
        <h3 className="mb-5">Upload Your File</h3>
        {uploadedFile ? <File file={uploadedFile} uploadFile={handleUploadFile} /> : null}
        <div>
          <Dropzone onDrop={(acceptedFiles) => setUploadedFile((acceptedFiles || [])[0])}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div
                  {...getRootProps()}
                  style={{
                    padding: 60,
                    borderWidth: 2,
                    borderRadius: 2,
                    borderColor: "#64A70B",
                    borderStyle: "solid",
                    backgroundColor: "#ffffff",
                    color: "#828282",
                    outline: "none",
                    transition: "border .24s ease-in-out",
                    cursor: "pointer",
                  }}
                >
                  <input {...getInputProps()} />
                  <p className="mb-7">
                    <UploadIcon />
                  </p>
                  <p style={{ fontSize: "16px" }}>
                    Drag File on to this zone or click to browse for the PDF
                  </p>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      </div>

      <div className="d-flex justify-content-end mt-10">
        <ListButton
          label="Cancel"
          boxShadow={false}
          onClick={handleClose}
          size="medium"
          className="mr-3"
          variant="outlined"
          text="#407A28"
          disabled={loading}
        />
        <ListButton
          label="Submit"
          size="medium"
          boxShadow={false}
          onClick={getUploadedData}
          disabled={loading || !uploadedFile}
        />
      </div>
    </div>
  );
};
