import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { Autocomplete } from "../../../../ui/components/Autocomplete";

export const ContactAddress = ({ contactValues = {}, handleContactListen }) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    Object.keys(contactValues).forEach((fieldName) => {
      setFieldValue(fieldName, contactValues[fieldName]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactValues]);
  return (
    <div style={{ width: "48%" }}>
      <h3 className="mb-10">
        <strong>Contact Address</strong>
      </h3>
      <div className="mb-7">
        <Autocomplete
          options={[]}
          name="chem_address"
          placeholder="Search Address"
          hanldeFocus={handleContactListen}
        />
      </div>
      <MappedInput
        label="Address 1"
        name="chem_contact_address_1"
        data-testid="chem_contact_address_1"
      />
      <MappedInput label="Address 2" name="chem_contact_address_2" data-testid="chem_contact_address_2" />
      <MappedInput
        label="Town / Suburb"
        name="chem_contact_suburb"
        data-testid="chem_contact_suburb"
      />
      <MappedInput label="State" name="chem_contact_state" data-testid="chem_contact_state" />
      <MappedInput
        label="Postcode"
        name="chem_contact_postcode"
        data-testid="chem_contact_postcode"
      />
    </div>
  );
};
