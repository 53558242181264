import React, { useState, useEffect, useMemo } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { getPrice } from "./_redux/selectors";
import { useFetch } from "../../../../hooks/fetch.hook";
import { addPrice, getPriceById, modifyPrice } from "./_api";
import { PriceDetailsSchema } from "../../../../helpers/schemas";
import { info } from "../../../../helpers/toasts";
import { Loader } from "../../../../ui/components/Loader";
import { modifyEmptyFields } from "../../../../ui/helpers";
import { getSimpleOrganisations } from "../../Organisations/_api";
import { SubmitActions } from "../../../../ui/structures/SubmitActions";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { getSitesSimple } from "../../Sites/_api";
import { OrgSelect } from "./OrgSelect";
import { SiteSelect } from "./SiteSelect";

export const Card = ({
  match: {
    params: { id },
  },
}) => {
  const { request } = useFetch();
  const data = useSelector(getPrice(id));
  const history = useHistory();

  const [cardData, setCardData] = useState({});
  const [loading, setLoading] = useState(false);
  const [organisations, setOrganisations] = useState([]);
  const [organisationsLoading, setOrganisationsLoading] = useState(false);
  const [submitAction, setSubmitAction] = useState("Save");
  const [sites, setSites] = useState([]);
  const [sitesLoading, setSitesLoading] = useState(false);

  const initialValues = {
    ccd_material: cardData.ccd_material || "",
    ccd_size: cardData.ccd_size || "",
    type: cardData.type || "",
    price: cardData.price || "",
    organisation_id: cardData.organisation_id || "",
    site_id: cardData.site_id || "",
  };

  useEffect(() => {
    if (id && !data) {
      request(getPriceById, id).then((data) => data && setCardData(data));
    } else {
      data && setCardData(data);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setOrganisationsLoading(true);
    request(getSimpleOrganisations)
      .then((data) => data && setOrganisations(data))
      .finally(() => setOrganisationsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSitesLoading(true);
    request(getSitesSimple, "simple=true&fields=name,id")
      .then((data) => data && setSites(data))
      .finally(() => setSitesLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const organiastionOptions = useMemo(
    () =>
      organisations.map((organiastion) => ({
        value: organiastion.id,
        label: organiastion.business_name,
      })),
    [organisations]
  );

  const siteOptions = useMemo(
    () =>
      sites.map((site) => ({
        value: site.id,
        label: site.name,
      })),
    [sites]
  );

  const typeOptions = [
    { value: "inspection", label: "Inspection" },
    { value: "processing", label: "Processing" },
  ];

  const materialOptions = [
    { value: "plastic", label: "Plastic" },
    { value: "steel", label: "Steel" },
  ];

  const sizeOptions = [
    { value: "20", label: "<20" },
    { value: "20-25", label: "20-25" },
    { value: "26-120", label: "26-120" },
    { value: "121-205", label: "121-205" },
  ];

  const handleClose = () => history.push("/settings/prices");

  const createPrice = (values, setSubmitting) => {
    request(addPrice, values)
      .then((data) => {
        if (!data) return;
        info("Price has been created!");
        history.push(`/settings/prices`);
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  const updatePrice = (values, setSubmitting) => {
    request(modifyPrice, values, id)
      .then((data) => {
        if (!data) return;
        info("Price details have been updated!");
        submitAction === "Save & Exit" && history.push("/settings/prices");
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    modifyEmptyFields(values);
    id
      ? updatePrice(values, setSubmitting)
      : createPrice(values, setSubmitting);
  };

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  return (
    <div className="border border-secondary bg-white p-10">
      {loading && (
        <Loader
          isOpen={loading}
          maxWidth="xs"
          disableBackdropClick
          disableEscapeKeyDown
        />
      )}
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={PriceDetailsSchema}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, values, ...formik }) => (
          <>
            <h3 className="mb-10">
              <strong>{id ? cardData.name : "Add Price"}</strong>
            </h3>
            <div className="row justify-content-between">
              <div className="col-8">
                <MappedSelect
                  label="Material"
                  options={materialOptions}
                  name="ccd_material"
                  data-testid="ccd_material"
                />
                <MappedSelect
                  label="Size"
                  name="ccd_size"
                  data-testid="ccd_size"
                  options={sizeOptions}
                />
                <MappedSelect
                  label="Type"
                  name="type"
                  data-testid="type"
                  options={typeOptions}
                />
                <MappedInput label="Price" name="price" data-testid="price" />
                <OrgSelect
                  organisationsLoading={organisationsLoading}
                  organiastionOptions={organiastionOptions}
                />
                <SiteSelect
                  sitesLoading={sitesLoading}
                  siteOptions={siteOptions}
                />
              </div>
            </div>
            <SubmitActions
              id={id}
              handleCancel={handleClose}
              disabled={isSubmitting}
              submitAction={submitAction}
              handleSubmit={handleSubmit}
              setSubmitAction={setSubmitAction}
            />
          </>
        )}
      </Formik>
    </div>
  );
};
