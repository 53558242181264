const type = "regions";

export const setRegionsList = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      regionsList: payload,
    };
  },
  type,
});

export const removeRegion = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      regionsList: state.regionsList.filter(({ id }) => id !== payload),
    };
  },
  type,
});

export const unArchiveRegion = (payload) => ({
  execute: (state) => {
    const index = state.regionsList.findIndex((item) => item.id === payload.id);
    const myState = [...state.regionsList];
    myState.splice(index, 1, payload);
    return {
      ...state,
      regionsList: [...myState],
    };
  },
  type,
});
