import React, { useState, useEffect, useMemo } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";
import { useFetch } from "../../../hooks/fetch.hook";
import { ListButton } from "../../../ui/components/ListButton";
import { renderAuditsRow } from "./helpers";
import { AUDIT_HEADINGS } from "./constants";
import { Modal } from "../../../ui/components/Modal";
import { ConfirmNewAudit } from "../../modules/Sites/ConfirmNewAudit";
import {
  archiveSiteAudit,
  getSiteAudits,
  unarchiveAudit,
} from "../../modules/Sites/_api";
import { modifyAuditsArray } from "../../modules/Sites/helpers";
import { ArchiveCheckbox } from "../../../ui/components/ArchiveCheckbox";
import { ConfirmDelete } from "../../../ui/components/ConfirmDelete";
import { error, info } from "../../../helpers/toasts";
import { Loader } from "../../../ui/components/Loader";
import { AVAILABLE_ROLES } from "../../constants";
import { AccessControl } from "../../../ui/structures/AccessControl";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { UnarchiveButton } from "../../../ui/components/UnarchiveButton";
import { getSelectedId } from "../../../ui/helpers";

export const SiteAudits = ({ handleExpand, id, site = {} }) => {
  const { request } = useFetch();

  const [auditOpen, setAuditOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [actionLoading, setActionLoading] = useState(false);
  const [selected, setSelected] = useState({});

  const handleAuditOpen = () => setAuditOpen(true);
  const handleAuditClose = () => setAuditOpen(false);

  const handleConfirmClose = () => setConfirmDeleteOpen(false);

  useEffect(() => {
    setLoading(true);
    request(getSiteAudits, id, archived)
      .then((data) => data && setData(modifyAuditsArray(data)))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archived]);

  const showArchived = () => {
    setArchived(!archived);
  };

  const handleOpenModal = (id) => {
    const selectedItem = data.find((item) => item.id === id);
    if (!selectedItem) return;
    if (selectedItem?.status === "archived") {
      error("Item already archived!");
      return;
    }
    setConfirmDeleteOpen(true);
    setSelectedItem(id);
  };

  const updateAuditsList = (item = {}, status) => {
    const payload = {
      ...item,
      status,
    };
    const index = data.findIndex(
      (audit) => (audit || {}).id === (item || {}).id
    );
    setData((state) => [
      ...state.slice(0, index),
      payload,
      ...state.slice(index + 1),
    ]);
  };

  const handleArchive = (id, setSubmitting) => {
    const selectedItem = data.find((item) => item.id === id);
    if (!selectedItem) return;
    setSubmitting(true);
    setActionLoading(true);
    setConfirmDeleteOpen(false);
    request(archiveSiteAudit, id)
      .then((data) => {
        if (!data) return;
        if (archived) {
          updateAuditsList(selectedItem, "archived");
        } else {
          setSelected({});
          setData((state) => state.filter((item) => item.id !== data.id));
        }
        info("Audit has been archived!");
      })
      .finally(() => {
        setSubmitting(false);
        setActionLoading(false);
      });
  };

  const handleUnarchive = () => {
    const auditID = Number(getSelectedId(selected));
    const selectedItem = data.find(({ id }) => id === auditID);
    const status = selectedItem?.status;
    if (status !== "archived") return;
    setActionLoading(true);
    request(unarchiveAudit, auditID)
      .then((data) => {
        if (!data) return;
        updateAuditsList(selectedItem, "active");
        setSelected({});
        info("Audit has been unarchived!");
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const unArchiveDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const renderButtons = () => {
    return (
      <>
        {archived && (
          <UnarchiveButton
            handleClick={handleUnarchive}
            disabled={unArchiveDisabled}
            loading={actionLoading}
          />
        )}
        <ListButton
          label="New Audit"
          data-testid="new-audit"
          size="medium"
          variant="outlined"
          text="#407A28"
          boxShadow={false}
          className="mr-5"
          onClick={handleAuditOpen}
          disabled={loading}
        />
      </>
    );
  };

  return (
    <>
      <Loader isOpen={actionLoading} maxWidth="xs" />
      <Modal
        isOpen={confirmDeleteOpen}
        submitable
        onClose={handleConfirmClose}
        maxWidth="sm"
        modalContent={
          <ConfirmDelete
            handleClose={handleConfirmClose}
            handleSubmit={handleArchive}
            id={selectedItem}
          />
        }
      />
      <Modal
        isOpen={auditOpen}
        submitable
        onClose={handleAuditClose}
        maxWidth="xs"
        modalContent={
          <ConfirmNewAudit
            handleClose={handleAuditClose}
            id={id}
            siteAudits={data}
          />
        }
      />
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <div style={{ marginLeft: "-10px" }}>
              <IconButton onClick={handleExpand(id)}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <ListHeader
              title={`${site.name} Audit List`}
              renderButtons={renderButtons}
            />

            <ArchiveCheckbox
              archivedLoading={loading}
              archived={archived}
              showArchived={showArchived}
            />
            <AccessControl
              desiredRoles={[
                AVAILABLE_ROLES.NATIONAL_ADMIN,
                AVAILABLE_ROLES.AGSAFE_ADMIN,
              ]}
              elseContent={
                <Datagrid
                  data={data}
                  headings={AUDIT_HEADINGS}
                  renderRow={renderAuditsRow}
                  loading={loading}
                />
              }
            >
              <Datagrid
                data={data}
                headings={AUDIT_HEADINGS}
                renderRow={renderAuditsRow}
                loading={loading}
                handleDelete={handleOpenModal}
                deletable
                selectable
                selected={selected}
                setSelected={setSelected}
              />
            </AccessControl>
          </div>
        </div>
      </div>
    </>
  );
};
