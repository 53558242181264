import { get } from "lodash";
import { useSelector } from "react-redux";

export const IsGuest = () => {
  const user = useSelector(({ auth: { user } }) => user) || {};

  const isNationalAdmin = get(user, "permissions.is_global_admin");
  const isAgsafeAdmin = get(user, "permissions.is_agsafe_admin");
  const isAgsafeStaff = get(user, "permissions.is_agsafe_staff");
  const isRcAccess = get(user, "permissions.is_rc_access");
  const isCaAccess = get(user, "permissions.is_ca_access");
  const isEndUser = get(user, "permissions.is_end_user");
  const isProcessor = get(user, "permissions.is_processor");

  return (
    !isNationalAdmin &&
    !isAgsafeAdmin &&
    !isAgsafeStaff &&
    !isRcAccess &&
    !isCaAccess &&
    !isEndUser &&
    !isProcessor
  );
};
