import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { MappedDatePicker } from "@bit/the-glue.frontendcomponents.datepicker";
import { groupOptions } from "../../Products/constants";

export const GroupSelect = () => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values.product_group !== "1") {
      setFieldValue("expiry_date", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.product_group]);

  return (
    <>
      <MappedSelect
        label="Group"
        name="product_group"
        data-testid="product_group"
        options={groupOptions}
      />
      {values.product_group === "1" ? (
        <MappedDatePicker name="expiry_date" label="Expiry Date" />
      ) : null}
    </>
  );
};
