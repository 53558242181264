import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { downloadFile } from "../../modules/AWS-S3/download";
import { downloadReport } from "./_api";
import { Loader } from "../../../ui/components/Loader";
import ReportIcon from "../../../ui/static/Report.png";
import { get } from "lodash";
import { info } from "../../../helpers/toasts";

export const ReportDownlad = ({ item = {} }) => {
  const [loading, setLoading] = useState(false);

  const handleDownload = () => {
    const fileId = get(item, "files[0].id");
    if (!fileId) {
      info("FIle not found!");
      return;
    }
    setLoading(true);
    downloadFile(item.id, fileId, setLoading, downloadReport);
  };

  const handleModalClose = () => setLoading(false);
  return (
    <>
      {loading && <Loader title="Downloading PDF..." onClose={handleModalClose} isOpen={loading} />}
      <Button data-testid="download" onClick={handleDownload} disabled={!item.files.length}>
        <span className="cursor-pointer">
          <img src={ReportIcon} alt="Report" style={{ opacity: item.files.length ? 1 : 0.6 }} />
        </span>
      </Button>
    </>
  );
};
