export const organisationTypes = {
  SET_DATA: "ORGANISATIONS/SET_DATA",
  REMOVE_ORGANISATION: "ORGANISATIONS/REMOVE_ORGANISATION",
  UPDATE_DATA: "ORGANISATIONS/UPDATE_DATA",
};

export function setOrganisationsList(data) {
  return {
    type: organisationTypes.SET_DATA,
    payload: data,
  };
}

export function removeOrganisation(data) {
  return {
    type: organisationTypes.REMOVE_ORGANISATION,
    payload: data,
  };
}

export function updateOrganisationsList(data) {
  return {
    type: organisationTypes.UPDATE_DATA,
    payload: data,
  };
}
