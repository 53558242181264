import React, { useState, useMemo } from "react";
import { IconButton, LinearProgress } from "@material-ui/core";
import Input from "@bit/the-glue.frontendcomponents.input";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import SearchIcon from "@material-ui/icons/Search";
import { ListButton } from "../../../../../../ui/components/ListButton";
import { renderRow } from "../../../../../../ui/helpers";
import { error } from "../../../../../../helpers/toasts";
import { useFetch } from "../../../../../../hooks/fetch.hook";
import { abrLookup } from "../../_api";
import { getRandomNumber, setID } from "../../helpers";
import { isArray } from "lodash";
import { DuplicateWarning } from "./DuplicateWarning";
import { Modal } from "../../../../../../ui/components/Modal";

const HEADINGS = [
  ["ABN", "ABN"],
  ["legal_name", "Legal Name"],
  ["trading_names_string", "Business Names"],
];

export const setIDMultiple = (max, data = []) => {
  let random = getRandomNumber(max);
  if (!data.includes(random)) {
    return random;
  } else return setID(max, data);
};

export const ABRLookup = ({
  setSelectedOrg,
  handleClose,
  handleNextStep,
  organisations = [],
}) => {
  const { request } = useFetch();

  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);

  const handleDuplicateOpen = () => setDuplicateModalOpen(true);
  const handleDuplicateClose = () => setDuplicateModalOpen(false);

  const selectedItemID = Object.keys(selected).filter(
    (key) => selected[key]
  )[0];
  const selectedItem = data.find(({ id }) => id === Number(selectedItemID));

  const handleChange = (e) => setSearch(e.target.value);

  const abnList = organisations
    .filter(({ ABN }) => ABN)
    .map(({ ABN }) => ABN.replace(/\s/g, ""));

  const findDuplicate = (value) => {
    return abnList.find((abn) => abn === value && abn !== data.ABN);
  };

  const handleSubmit = () => {
    handleDuplicateClose();
    setLoading(true);
    request(abrLookup, `abn=${selectedItem.ABN}`)
      .then((data) => {
        if (!data || data === "Server error") return;
        setSelectedOrg(data);
        handleNextStep();
      })
      .finally(() => setLoading(false));
  };

  const handleValidatie = () => {
    if (!selectedItem) {
      error("Item not found!");
      return;
    }
    if (findDuplicate(selectedItem.ABN)) handleDuplicateOpen();
    else handleSubmit(selectedItem);
  };

  const isABN = (query = "") => {
    return /(^[0-9+ ]+$)/.test(query) && query.length === 11;
  };

  const nameSearch = (query, usedNumbers) => {
    request(abrLookup, query)
      .then((data) => {
        if (!data || !isArray(data)) return;
        const modifiedData = data.map((item) => {
          const id = setIDMultiple(10000000, usedNumbers);
          usedNumbers.push(id);
          return {
            ...item,
            id,
            trading_names_string: item.name,
          };
        });
        setData(modifiedData);
      })
      .finally(() => setLoading(false));
  };

  const abnSearch = (query, usedNumbers) => {
    request(abrLookup, query)
      .then((data) => {
        if (!data) return;
        const id = setIDMultiple(10000000, usedNumbers);
        const modifiedData = {
          ...data,
          id,
          trading_names_string: data.trading_names.join(", "),
        };
        setData([modifiedData]);
      })
      .finally(() => setLoading(false));
  };

  const handleSearch = () => {
    if (search.length < 5) {
      error("Enter at least 5 characters!");
      return;
    }
    const usedNumbers = [];
    setLoading(true);
    const modifiedSearch = search.replace(/\s/g, "");
    if (isABN(modifiedSearch)) {
      abnSearch(`abn=${modifiedSearch}`, usedNumbers);
    } else nameSearch(`name=${modifiedSearch}`, usedNumbers);
  };

  const submitDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  return (
    <>
      {loading ? (
        <div>
          <LinearProgress color="secondary" />
        </div>
      ) : null}
      <div className="bg-white pb-5 pt-2">
        <Modal
          maxWidth="sm"
          isOpen={duplicateModalOpen}
          onClose={handleDuplicateClose}
          submitable
          modalContent={
            <DuplicateWarning
              handleClose={handleDuplicateClose}
              handleSubmit={handleSubmit}
            />
          }
        />
        <h3 className="mb-5">
          <strong>Search by ABN or Business Name</strong>
        </h3>
        <div className="d-flex align-items-center w-75">
          <Input
            value={search}
            onChange={handleChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.stopPropagation();
                handleSearch();
              }
            }}
          />
          <IconButton onClick={handleSearch} className="ml-10">
            <SearchIcon fontSize="large" color="primary" />
          </IconButton>
        </div>
        <h3 className="my-10">Organisations List</h3>
        <Datagrid
          data={data}
          headings={HEADINGS}
          renderRow={renderRow}
          selectable
          selected={selected}
          setSelected={setSelected}
          paginationDefaultValue={20}
        />
        <div className="my-5 d-flex justify-content-end mr-3">
          <div className="mr-3">
            <ListButton
              label="Cancel"
              size="large"
              onClick={handleClose}
              variant="outlined"
              text="#407A28"
              boxShadow={false}
              disabled={loading}
            />
          </div>
          <div>
            <ListButton
              label="Submit"
              onClick={handleValidatie}
              size="large"
              disabled={submitDisabled || loading}
            />
          </div>
        </div>
      </div>
    </>
  );
};
