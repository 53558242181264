import React, { useRef, useState, useEffect, useCallback } from "react";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Paper, Chip, IconButton } from "@material-ui/core";
import { SearchInput } from "./SearchInput";
import { debounce } from "lodash";
import { Skeleton } from "@material-ui/lab";

const chipStyle = {
  backgroundColor: "#E8F5E1",
  color: "#255915",
  borderRadius: "100px",
  height: "70%",
  paddingTop: "6px",
  paddingBottom: "6px",
  fontSize: "14px",
  margin: "0 0.8rem 0 0",
};

export function ServerSearchBar({
  currentSearchList,
  fetchAutocompleteFunction,
  loading,
  onSearch,
  keyMap,
  className,
  placeholder,
  clearSearch,
  focused,
  setFocused,
  searchMap = {},
  openOnFocus,
  searchFields,
  defaultKey = null,
  defaultValue = [],
  archived,
  copiedSearch = [],
  contentLoading,
}) {
  const [options, setOptions] = useState([""]);
  const [autocomplete, setAutocomplete] = useState([]);
  const [autocompleteText, setAutocompleteText] = useState("");
  const [autocompleteField, setAutocompleteField] = useState("");
  const [open, setOpen] = useState(false);
  const [searchHelper, setSearchHelper] = useState(keyMap);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [search, setSearch] = useState(defaultValue);
  const [keySelected, setKeySelected] = useState("");

  const resetSearch = () => {
    clearSearch();
    setSearch([]);
    setKeySelected(null);
    setOptions([]);
    onSearch([""]);
  };

  const input = useRef(null);

  useEffect(() => {
    if (defaultKey && defaultValue.length) {
      setKeySelected(null);
      let _search = [...search];
      const last = _search.pop();
      _search = [..._search, { key: last.key, value: defaultValue[0].value }];
      setSearch(_search);
    } else if (defaultKey) {
      selectKey(defaultKey);
    }
    // eslint-disable-next-line
  }, [defaultKey]);

  useEffect(() => {
    copiedSearch.length && setSearch(copiedSearch);
    // eslint-disable-next-line
  }, [copiedSearch]);

  useEffect(() => {
    if (focused !== undefined) {
      focused && input.current.focus();
    }
  }, [focused]);

  // eslint-disable-next-line
  const debouncedRequest = useCallback(
    debounce((autocompleteText, autocompleteField, searchFields, archived) => {
      return fetchAutocompleteFunction(
        autocompleteText,
        autocompleteField,
        searchFields,
        archived
      );
    }, 500),
    []
  );

  useEffect(() => {
    if (!keySelected) return;
    debouncedRequest(
      autocompleteText,
      autocompleteField,
      searchFields,
      archived
    );
    // eslint-disable-next-line
  }, [autocompleteText]);

  useEffect(() => {
    if (loading) {
      setOptions(["Loading..."]);
    }
    if (!loading) {
      setOptions([...currentSearchList]);
    }
  }, [currentSearchList, loading]);

  const checkForChip = () => {
    return search.find((item) => item.value !== undefined) && !keySelected;
  };

  const selectKey = (value) => {
    if (keySelected) {
      setKeySelected(null);
      let _search = [...search];
      const last = _search.pop();
      _search = [..._search, { key: last.key, value }];
      setSearch(_search);
      onSearch(
        _search.reduce((acc, { key, value }) => {
          acc[key] = value;
          return acc;
        }, {})
      );
      setAutocomplete([]);
    } else {
      setKeySelected(value);
      setAutocompleteField(searchMap[value]);
      setSearch([...search, { key: value }]);
    }
    if (input.current) {
      input.current.blur();
      requestAnimationFrame(() => input.current && input.current.focus());
    }
  };

  const handleKeyDown = (event) => {
    const backspace = { keyCode: 8 };
    if (event.keyCode === backspace.keyCode && event.target.value === "") {
      if (keySelected === null) {
        const _search = search.slice(0, -1);

        setSearch(_search);
      } else {
        setKeySelected(null);
      }
      if (!search.length) resetSearch();
    }
  };

  const handleChange = (_, [value], reason) => {
    if (
      value === "No option found" ||
      value === "Loading..." ||
      reason !== "select-option"
    )
      return;
    selectKey(value);
  };

  const handleRemove = (value) => () => {
    const _search = search.filter((i) => i.value !== value);

    setSearch(_search);
    if (keySelected) {
      setKeySelected(null);
    } else if (!_search.length) {
      resetSearch();
    } else {
      onSearch(
        _search.reduce((acc, { key, value }) => {
          acc[key] = value;
          return acc;
        }, {})
      );
    }
  };

  const multipleSearchFields = ["Group", "Tag"];

  const getOptions = (search) => {
    const selectedFields = search
      .reduce((acc, value) => [...acc, value.key], [])
      .filter((item) => !multipleSearchFields.includes(item));

    if (
      search.length > 0 &&
      !search[search.length - 1].value &&
      search[search.length - 1].key
    ) {
      return options;
    }
    return searchHelper
      .filter((item) => !selectedFields.includes(item[1]))
      .map((item) => item[1]);
  };

  const handleBlur = () => focused !== undefined && setFocused(false);

  const handleEnterPress = () => {
    if (options[0] === "No option found") return;
    setKeySelected(null);
    let _search = [...search];
    const last = _search.pop();
    _search = [..._search, { key: last.key, value: options[0] }];
    setSearch(_search);
    onSearch(
      _search.reduce((acc, { key, value }) => {
        acc[key] = value;
        return acc;
      }, {})
    );
    setAutocomplete([]);
    handleClose();
  };

  const handleInputChange = (_, value) => {
    setAutocompleteText(value);
    if (!keySelected) {
      setSearchHelper(
        keyMap.filter((item) =>
          item[1].toLowerCase().includes(value.toLowerCase())
        )
      );
    }
  };

  return (
    <>
      {contentLoading ? (
        <div className="mb-5">
          <Skeleton variant="rect" width={"100%"} height={50} />
        </div>
      ) : (
        <Paper
          className={`d-flex align-items-center border border-secondary rounded ${className} shadow`}
          elevation={0}
        >
          <SearchIcon className="m-2" color="secondary" />
          <div className="flex-shrink-0">
            {search.map(({ key, value }, id) => {
              if (!value) {
                return `${key}:`;
              }
              return (
                <Chip
                  className="mr-3"
                  style={chipStyle}
                  key={`${key}-${value}`}
                  size="small"
                  label={value ? `${key}: ${value}` : key}
                  onDelete={handleRemove(value)}
                />
              );
            })}
          </div>
          <Autocomplete
            className="flex-grow-1"
            value={autocomplete}
            onKeyDown={handleKeyDown}
            multiple
            options={getOptions(search)}
            onChange={handleChange}
            onBlur={handleBlur}
            openOnFocus={openOnFocus}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            filterOptions={(x) => x}
            onInputChange={handleInputChange}
            id="1"
            freeSolo
            data-testid="search-bar"
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <SearchInput
                  ref={input}
                  id="1"
                  placeholder={placeholder}
                  labelClassName="mb-0"
                  inputClassName="border-0 bg-white w-100 py-1 h-100"
                  inputGroupClassName="border-0 w-100 py-1 h-100"
                  inputProps={params.inputProps}
                  handleEnterPress={handleEnterPress}
                />
              </div>
            )}
          />
          {checkForChip() && (
            <IconButton onClick={resetSearch} size="small">
              <ClearIcon className="m-2" color="secondary" />
            </IconButton>
          )}
        </Paper>
      )}
    </>
  );
}
