import React, { useState, useEffect } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { COMPLETED_HEADINGS, searchMap, SEARCH_HEADINGS } from "./constants";
import {
  modifyCompletedClearances,
  renderClearancesRow,
  rendeTotalVolumeRow
} from "./helpers";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  createClearanceInvoice,
  getCompleteClearances,
  modifyClearanceInvoice,
  getCompleteClearancesCount,
  getCompleteClearancesAutocomplete,
  modifyClearanceFile
} from "./_api";
import { handleSearch, objectCheck } from "../../../ui/helpers";
import { error, info } from "../../../helpers/toasts";
import { ListButton } from "../../../ui/components/ListButton";
import { FileUploadForm } from "../../../ui/structures/FileUploadForm";
import { ServerSearchBar } from "../../../ui/structures/ServerSearchBar";
import { get, uniq } from "lodash";
import { getOrganisationsListAutocomplete } from "../Organisations/_api";

export const CompletedClearances = () => {
  const { request } = useFetch();

  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const [invoiceId, setInvoiceId] = useState("");

  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  const getTotalValue = (selectedItems = []) => {
    return selectedItems.reduce(
      (acc, value) => acc + value.volume * value.per_unit_cost,
      0
    );
  };

  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = () => {
    const selectedIds = Object.keys(selected).filter(key =>
      Boolean(selected[key])
    );
    const selectedItems = data.filter(item => selectedIds.includes(item.id));
    if (!selectedItems) return;
    if (!selectedItems.length) return;
    const collectionRunId = selectedItems[0].collection_run_id;
    const itemsAreSameRoute = selectedItems.every(
      item => item.collection_run_id === collectionRunId
    );
    if (!itemsAreSameRoute) {
      error("Invoice can be uploaded only for items with same route!");
      return;
    } else {
      setSelectedData({
        site: selectedItems[0].site,
        total_value: getTotalValue(selectedItems),
        invoice_id: selectedItems[0].invoice_id,
        collection_run_id: selectedItems[0].collection_run_id,
        gst_status: get(
          selectedItems[0],
          "collection_run.processor.gst_status"
        ),
        type: "Processing"
      });
      setModalOpen(true);
    }
  };

  const fetchClearances = () => {
    setLoading(true);
    request(getCompleteClearances, search, page, pageSize)
      .then(data => data && setData(modifyCompletedClearances(data)))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchClearances();
    // eslint-disable-next-line
  }, [search, page, pageSize]);

  useEffect(() => {
    request(getCompleteClearancesCount, search).then(
      data => data && setTotalRecords(data)
    );
    // eslint-disable-next-line
  }, [search]);

  const requestOptions = (
    autocompleteValue,
    autocompleteField,
    searchFields
  ) => {
    if (autocompleteField === "collection_run.processor.business_name") {
      return request(
        getOrganisationsListAutocomplete,
        80,
        "business_name",
        autocompleteValue,
        "",
        "",
        searchFields
      );
    } else
      return request(
        getCompleteClearancesAutocomplete,
        80,
        autocompleteField,
        autocompleteValue,
        searchFields,
        "active"
      );
  };

  const fetchRunsAutocomplete = (
    autocompleteValue,
    autocompleteField,
    searchFields
  ) => {
    if (autocompleteValue === "") return;
    setAutocompleteLoading(true);
    requestOptions(autocompleteValue, autocompleteField, searchFields).then(
      data => {
        if (data && Array.isArray(data) && data.length && objectCheck(data)) {
          setAutocompleteData(uniq(data.map(item => String(item))));
        } else {
          setAutocompleteData(["No option found"]);
        }
        setAutocompleteLoading(false);
      }
    );
  };

  const resetSearch = () => {
    setSearch("");
  };

  const handleUpdateFile = (
    values,
    setSubmitting,
    uploadedFile,
    fileData = {},
    setLoading
  ) => {
    const selectedItems = Object.keys(selected).filter(key =>
      Boolean(selected[key])
    );
    if (!uploadedFile) return;
    setLoading(true);
    if (!invoiceId) {
      error("Invoice ID is missing!");
      return;
    }
    // eslint-disable-next-line eqeqeq
    if (values.invoice_total == 0) {
      setSubmitting(false);
      setLoading(false);
      error("Invoice total can't be 0!");
      return;
    }
    const payload = {
      code: values.code,
      date: values.date,
      processing_misc_charge: values.processing_misc_charge
        ? Number(values.processing_misc_charge)
        : 0,
      processing_fees: values.processing_fees
        ? Number(values.processing_fees)
        : 0,
      gst: values.gst ? Number(values.gst) : null
    };
    request(
      modifyClearanceFile,
      {},
      selectedData.collection_run_id,
      invoiceId,
      fileData.id
    );
    request(
      modifyClearanceInvoice,
      { ...payload, clearance_job_ids: selectedItems },
      selectedData.collection_run_id,
      invoiceId,
      fileData.id
    )
      .then(data => {
        if (!data) return;
        info("Invoice has been uploaded!");
        setData(state =>
          state.filter(item => !selectedItems.includes(item.id))
        );
        handleModalClose();
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const gstStatus = get(selectedData, "gst_status");

  return (
    <>
      <FileUploadForm
        handleClose={handleModalClose}
        isOpen={modalOpen}
        id={selectedData.collection_run_id}
        apiCall={createClearanceInvoice}
        name="invoice"
        handleSubmit={handleUpdateFile}
        data={selectedData}
        setInvoiceId={setInvoiceId}
        extraPayload={{
          clearance_job_ids: Object.keys(selected).filter(key =>
            Boolean(selected[key])
          )
        }}
        isClearance
        gstStatus={gstStatus}
      />
      <div className="row justify-content-center">
        <div className="col-12">
          <ServerSearchBar
            className="mb-5"
            onSearch={data => handleSearch(data, searchMap, setSearch, setPage)}
            keyMap={SEARCH_HEADINGS}
            loading={autocompleteLoading}
            currentSearchList={autocompleteData}
            fetchAutocompleteFunction={fetchRunsAutocomplete}
            placeholder="Search Clearances..."
            clearSearch={resetSearch}
            searchMap={searchMap}
            searchFields={search}
            contentLoading={loading}
          />
          <div className="bg-white rounded py-7 px-10">
            <ListHeader title="Clearance Jobs" />
            <Datagrid
              data={data}
              headings={COMPLETED_HEADINGS}
              renderRow={renderClearancesRow}
              footerRow={data => rendeTotalVolumeRow(data, 5)}
              selectable
              selected={selected}
              setSelected={setSelected}
              loading={loading}
              paginationOptions={[50, 100, 200]}
              paginationDefaultValue={50}
              serverPage={page}
              setServerPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              isServerPagination
              totalRecords={totalRecords}
            />
          </div>
          <div className="text-right mt-5">
            <ListButton
              label="Invoice"
              data-testid="invoice"
              size="large"
              disabled={!Object.values(selected).filter(Boolean).length}
              onClick={handleModalOpen}
            />
          </div>
        </div>
      </div>
    </>
  );
};
