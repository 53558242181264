export const HEADINGS = [
  ["code", "Org ID"],
  ["business_name", "Business Name"],
  ["phone", "Phone Number"],
  ["email", "Email Address"],
  ["status", "Status"],
  ["region", "Region"],
  ["consultant_name", "Consultant"],
];

export const searchMap = {
  "Org ID": "code",
  "Business Name": "business_name",
  "Phone Number": "phone",
  "Email Address": "email",
  Region: "region.long_name",
  "Consultant First Name": "consultant.first_name",
  "Consultant Last Name": "consultant.last_name",
  Tag: "tag",
  "ABN Status": "abn_verified",
  Status: "status",
};

export const SEARCH_HELPER = [
  ["code", "Org ID"],
  ["business_name", "Business Name"],
  ["phone", "Phone Number"],
  ["email", "Email Address"],
  ["tag", "Tag"],
  ["region.long_name", "Region"],
  ["consultant.first_name", "Consultant First Name"],
  ["consultant.last_name", "Consultant Last Name"],
  ["abn_verified", "ABN Status"],
  ["status", "Status"],
];

export const COPY_HELPER = [
  ["code", "Org ID"],
  ["business_name", "Business Name"],
  ["phone", "Phone Number"],
  ["email", "Email Address"],
  ["tags.name", "Tag"],
  ["region.long_name", "Region"],
  ["consultant.first_name", "Consultant First Name"],
  ["consultant.last_name", "Consultant Last Name"],
  ["abn_verified", "ABN Status"],
  ["status", "Status"],
];

export const statusOptions = [
  { value: "new", label: "New" },
  { value: "active", label: "Active" },
  { value: "archived", label: "Archived" },
  { value: "imported", label: "Imported" },
];

export const newOrgStatusOptions = [
  { value: "new", label: "New" },
  { value: "active", label: "Active" },
];

export const consultantOptions = [
  { value: "Jake Blake", label: "Jake Blake" },
  { value: "John Doe", label: "John Doe" },
  { value: "Kate Blake", label: "Kate Blake" },
];

export const stateOptions = [
  { value: "ACT", label: "ACT" },
  { value: "NSW", label: "NSW" },
  { value: "NT", label: "NT" },
  { value: "QLD", label: "QLD" },
  { value: "SA", label: "SA" },
  { value: "TAS", label: "TAS" },
  { value: "WA", label: "WA" },
  { value: "VIC", label: "VIC" },
];
