import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { getBadge } from "./_redux/selectors";
import { useFetch } from "../../../../hooks/fetch.hook";
import { addBadge, getBadgeById, modifyBadge } from "./_api";
import { BadgesDetailsSchema } from "../../../../helpers/schemas";
import { info } from "../../../../helpers/toasts";
import { Loader } from "../../../../ui/components/Loader";
import { modifyEmptyFields } from "../../../../ui/helpers";
import { ListButton } from "../../../../ui/components/ListButton";

export const Card = ({
  match: {
    params: { id }
  }
}) => {
  const { request } = useFetch();
  const data = useSelector(getBadge(id));

  const [cardData, setCardData] = useState({});
  const [loading, setLoading] = useState(false);

  const initialValues = {
    name: cardData.name || "",
    description: cardData.description || ""
  };

  useEffect(() => {
    if (id && !data) {
      request(getBadgeById, id).then(data => data && setCardData(data));
    } else {
      data && setCardData(data);
    }
    // eslint-disable-next-line
  }, []);

  const history = useHistory();
  const handleClose = () => history.push("/settings/badges");

  const createBadge = (values, setSubmitting) => {
    request(addBadge, values)
      .then(data => data && history.push(`/badges/${data.id}`))
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  const updteBadge = (values, setSubmitting) => {
    request(modifyBadge, values, id)
      .then(data => {
        if (!data) return;
        setCardData(data);
        info("Badge details have been updated!");
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    modifyEmptyFields(values);
    id ? updteBadge(values, setSubmitting) : createBadge(values, setSubmitting);
  };

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  return (
    <div className="border border-secondary bg-white p-10">
      {loading && (
        <Loader
          isOpen={loading}
          maxWidth="xs"
          disableBackdropClick
          disableEscapeKeyDown
        />
      )}
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={BadgesDetailsSchema}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
          <div className="d-flex justify-content-between">
            <div className="w-50">
              <h3 className="mb-10">
                <strong>{id ? cardData.name : "Add Badge"}</strong>
              </h3>
              <div className="row justify-content-between">
                <div className="col-11">
                  <MappedInput
                    label="Badge Name"
                    name="name"
                    data-testid="name"
                  />
                  <MappedInput
                    label="Badge Description"
                    name="description"
                    multiline
                    rows={6}
                    data-testid="description"
                  />
                </div>
              </div>

              <div className="mt-10 d-flex justify-content-end col-11 mr-0 pr-0">
                <ListButton
                  label="Cancel"
                  size="large"
                  onClick={handleClose}
                  data-testid="cancel"
                  variant="outlined"
                  boxShadow={false}
                  text="#407A28"
                  className="mr-3"
                />
                <ListButton
                  label="Save"
                  size="large"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  data-testid="submit"
                />
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};
