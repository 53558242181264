import React, { useCallback } from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio as MuiRadio,
} from "@material-ui/core";
import { useField } from "formik";
import { getTip } from "../helpers";

export function Radio({
  className,
  label,
  options = [],
  labelName = "name",
  withDevider,
  tip,
  labelStyle = {},
  disabledOptions = [],
  ...other
}) {
  return (
    <>
      <label className="d-block mb-0">
        {label && <div className="mb-2">{label}</div>}
        <RadioGroup {...other}>
          {options.length ? (
            options.map((item) => (
              <div key={(item || {}).id}>
                <FormControlLabel
                  value={(item || {}).id}
                  control={<MuiRadio />}
                  label={(item || {})[labelName]}
                  key={(item || {}).id}
                  className="ml-2"
                  classes={labelStyle}
                  disabled={disabledOptions.includes(item?.id)}
                />
                {withDevider && <hr />}
              </div>
            ))
          ) : (
            <p>No Results Found</p>
          )}
        </RadioGroup>
        {tip && <div>{tip}</div>}
      </label>
    </>
  );
}

export function MappedRadio(props) {
  const [field, meta] = useField(props.name);

  const getInputClasses = useCallback(() => {
    if (meta.touched && meta.error) {
      return "is-invalid";
    }

    if (meta.touched && !meta.error) {
      return "is-valid";
    }

    return "";
  }, [meta]);

  return (
    <div className="form-group">
      <Radio
        {...field}
        {...props}
        inputClassName={getInputClasses()}
        tip={getTip(meta, props.tip)}
      />
    </div>
  );
}
