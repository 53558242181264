/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { stateOptions } from "./constants";
import { useFormikContext } from "formik";
import { Autocomplete } from "../../../ui/components/Autocomplete";

export const PhysicalAddress = ({
  disabledField,
  handleOpen,
  initialValues = {},
  orgValues,
  handleOrgListen,
  fetchLgaRegions
}) => {
  const { values, setFieldValue } = useFormikContext();
  const {
    physical_address,
    physical_city,
    physical_state,
    physical_postcode
  } = values;

  const handleAddressBlur = name => {
    if (
      !physical_address ||
      !physical_city ||
      !physical_state ||
      !physical_postcode
    )
      return;
    if (initialValues[name] === values[name]) return;
    handleOpen();
  };

  useEffect(() => {
    Object.keys(orgValues).forEach(fieldName => {
      setFieldValue(fieldName, orgValues[fieldName]);
    });
  }, [orgValues]);

  useEffect(() => {
    if (!values.physical_postcode) return;
    if (
      values.physical_postcode.length > 3 &&
      /^[0-9]+$/.test(values.physical_postcode)
    ) {
      fetchLgaRegions(values, setFieldValue);
    }
  }, [values.physical_postcode]);

  return (
    <div className="col-6">
      <h5 className="mb-4">
        <strong>Physical Address</strong>
      </h5>
      <div className="mb-7">
        <Autocomplete
          options={[]}
          name="org_address"
          placeholder="Search Address"
          hanldeFocus={handleOrgListen}
        />
      </div>
      <MappedInput
        label="Address 1"
        name="physical_address"
        data-testid="physical_address"
        disabled={disabledField}
        onBlur={() => handleAddressBlur("physical_address")}
      />
      <MappedInput
        label="Address 2"
        name="physical_address2"
        data-testid="physical_address2"
        disabled={disabledField}
      />
      <MappedInput
        label="City"
        name="physical_city"
        data-testid="physical_city"
        disabled={disabledField}
        onBlur={() => handleAddressBlur("physical_city")}
      />
      <div className="row">
        <div className="col-6">
          <MappedSelect
            label="State"
            name="physical_state"
            data-testid="physical_state"
            options={stateOptions}
            disabled={disabledField}
            onBlur={() => handleAddressBlur("physical_state")}
          />
        </div>
        <div className="col-6">
          <MappedInput
            label="Postal Code"
            name="physical_postcode"
            data-testid="physical_postcode"
            disabled={disabledField}
            onBlur={() => handleAddressBlur("physical_postcode")}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <MappedInput
            label="Latitude"
            name="physical_latitude"
            data-testid="latitude"
            disabled
          />
        </div>
        <div className="col-6">
          <MappedInput
            label="Longitude"
            name="physical_longitude"
            data-testid="longitude"
            disabled
          />
        </div>
      </div>
    </div>
  );
};
