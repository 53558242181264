import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import { TableSortLabel } from "@material-ui/core";
import { HEADINGS, searchMap } from "./constants";
import { removeConsultant, setConsultantsist, unArchiveConsultant } from "./_redux/actions";
import { renderConsultantsRow } from "./helpers";
import { ListButton } from "../../../../ui/components/ListButton";
import { ListHeader } from "../../../../ui/structures/ListHeader";
import { useFetch } from "../../../../hooks/fetch.hook";
import { deleteConsultant, getConsultantById, getConsultants, modifyConsultant } from "./_api";
import { Modal } from "../../../../ui/components/Modal";
import { ConfirmDelete } from "../../../../ui/components/ConfirmDelete";
import { ArchiveCheckbox } from "../../../../ui/components/ArchiveCheckbox";
import { getSelectedId, handleSearch } from "../../../../ui/helpers";
import { UnarchiveButton } from "../../../../ui/components/UnarchiveButton";
import { sortHeaderStyle } from "../../../constants";
import { Loader } from "../../../../ui/components/Loader";
import { info } from "../../../../helpers/toasts";

export const List = () => {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState({});
  const [unArchiving, setUnarchiving] = useState(false);
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [actionLoading, setActionLoading] = useState(false);

  const handleModalClose = () => setConfirmDeleteOpen(false);

  const handleOpenModal = (id) => {
    setConfirmDeleteOpen(true);
    setSelectedItem(id);
  };

  const data = useSelector(({ consultants: { consultantsList } }) => consultantsList);

  const fetchConsultants = () => {
    setLoading(true);
    request(getConsultants, search, archived, field, field && direction)
      .then((data) => {
        dispatch(setConsultantsist(data));
        setLoading(false);
      })
      .finally(() => setArchivedLoading(false));
  };

  useEffect(() => {
    fetchConsultants();
    // eslint-disable-next-line
  }, [search, archived, field, direction]);

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  const unArchiveDisabled = useMemo(() => Object.values(selected).filter(Boolean).length !== 1, [
    selected,
  ]);

  const handleUnarchive = () => {
    const ConsultantID = getSelectedId(selected);
    const status = (data.find(({ id }) => id === ConsultantID) || {}).status;
    if (status !== "archived") return;
    setUnarchiving(true);
    setActionLoading(true);
    request(modifyConsultant, { status: "active" }, ConsultantID)
      .then((data) => {
        if (!data) return;
        dispatch(unArchiveConsultant(data));
        setSelected({});
        info("Consultant has been unarchived!");
      })
      .finally(() => {
        setUnarchiving(false);
        setActionLoading(false);
      });
  };

  const handleDelete = (id, setSubmitting) => {
    setActionLoading(true);
    setConfirmDeleteOpen(false);
    request(deleteConsultant, id)
      .then((data) => {
        if (!data) return;
        if (archived) {
          request(getConsultantById, id).then((data) => data && dispatch(unArchiveConsultant(data)));
        } else dispatch(removeConsultant(id));
        info("Consultant has been archived!");
        setSelected({});
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const KEY_MAP = {
    "First Name": data.map((item) => item.first_name),
    "Last Name": data.map((item) => item.last_name)
  };

  const resetSearch = () => {
    setSearch("");
  };

  const renderButtons = () => (
    <>
      <div className="mr-10">
        <Link
          to={{
            pathname: `/add-consultant`,
          }}
          data-testid="new"
        >
          <ListButton label="New" />
        </Link>
      </div>
      {archived && (
        <UnarchiveButton
          handleClick={handleUnarchive}
          disabled={unArchiveDisabled}
          loading={unArchiving}
        />
      )}
    </>
  );

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) => (
      <th
        key={key}
        style={sortHeaderStyle}
        className="px-5 text-nowrap"
        onClick={() => {
          setDirection(key !== field ? "desc" : direction === "desc" ? "asc" : "desc");
          setField(key);
        }}
      >
        <span style={{ cursor: "pointer" }}>{header}</span>
        {key === field && <TableSortLabel active direction={direction} />}
      </th>
    ));
  }

  return (
    <div className="row justify-content-center mt-10">
      <div className="col-12">
        {actionLoading && <Loader isOpen={actionLoading} maxWidth="xs" />}
        {confirmDeleteOpen && (
          <Modal
            isOpen={confirmDeleteOpen}
            submitable
            onClose={handleModalClose}
            maxWidth="sm"
            modalContent={
              <ConfirmDelete
                handleClose={handleModalClose}
                handleSubmit={handleDelete}
                id={selectedItem}
                name={(data.find(({ id }) => id === selectedItem) || {}).first_name}
              />
            }
          />
        )}
        <div>
          <SearchBar
            onSearch={(data) => handleSearch(data, searchMap, setSearch)}
            clearSearch={resetSearch}
            keyMap={KEY_MAP}
            placeholder="Filter Consultants..."
            elevation={2}
            chipBackgroundColor="#E8F5E1"
            chipColor="#255915"
          />
        </div>
        <div className="bg-white rounded py-7 px-10">
          <ListHeader
            title="Consultants"
            renderButtons={renderButtons}
          />
          <ArchiveCheckbox
            archivedLoading={archivedLoading}
            archived={archived}
            showArchived={showArchived}
          />
          <Datagrid
            data={data}
            headings={HEADINGS}
            renderRow={renderConsultantsRow}
            renderHeaderWithSorting={renderHeaderWithSorting}
            selectable
            selected={selected}
            setSelected={setSelected}
            editable
            isNewTabLink
            deletable
            handleDelete={handleOpenModal}
            loading={loading}
            link="consultants"
            paginationOptions={[50, 100, 200]}
            paginationDefaultValue={50}
          />
        </div>
      </div>
    </div>
  );
};
