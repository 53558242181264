import React, { useState, useEffect } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { useFetch } from "../../../hooks/fetch.hook";
import { getReimbursements } from "../DrumMuster/Inspections/_api";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { HEADINGS, searchMap } from "./constants";
import { renderReimbursementsRow, modifyReimbursementsArray } from "./helpers";
import { ListButton } from "../../../ui/components/ListButton";
import { Line } from "./Line";
import { handleSearch } from "../../../ui/helpers";
import { exportReimbursements, printReimbursements } from "./_api";
import { Loader } from "../../../ui/components/Loader";
import { downloadFile } from "../AWS-S3/download";

export const List = () => {
  const { request } = useFetch();

  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [focused, setFocused] = useState(false);
  const [reviewedComponent, setReviewedComponent] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [data, setData] = useState([]);
  const [actionLoading, setActionLoading] = useState(false);

  const fetchReimbursements = () => {
    setLoading(true);
    return request(getReimbursements, "active", search)
      .then(data => data && setData(modifyReimbursementsArray(data)))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchReimbursements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const KEY_MAP = {
    ID: data.map(item => item.code),
    State: data.map(item => item.state),
    Region: data.map(item => item.region),
    "Claim Payee Organisation": data.map(
      item => item?.organisation?.business_name
    ),
    Grouped: data.map(item => item.grouped_search),
    Invoice: data.map(item => item?.invoice?.code)
  };

  const renderButtons = () => (
    <>
      <ListButton
        label="Print Report"
        size="medium"
        className="mr-3"
        onClick={handlePrint}
      />
      <ListButton label="Export" size="medium" onClick={handleExport} />
    </>
  );

  const resetSearch = () => setSearch("");

  const handleExpand = id => () => {
    setReviewedComponent(id);
    setExpanded(true);
  };

  const handlePrint = () => {
    setActionLoading(true);
    downloadFile(null, null, setActionLoading, printReimbursements);
  };

  const handleExport = () => {
    setActionLoading(true);
    downloadFile(null, null, setActionLoading, exportReimbursements);
  };

  return (
    <>
      <Loader
        isOpen={actionLoading}
        maxWidth="xs"
        disableBackdropClick
        disableEscapeKeyDown
      />
      <div style={{ display: expanded ? "none" : "block" }}>
        <SearchBar
          onSearch={data => handleSearch(data, searchMap, setSearch)}
          clearSearch={resetSearch}
          keyMap={KEY_MAP}
          placeholder="Filter Reimbursements..."
          elevation={2}
          chipBackgroundColor="#E8F5E1"
          chipColor="#255915"
          focused={focused}
          setFocused={setFocused}
        />
      </div>
      {expanded ? (
        <Line
          item={data.find(({ id }) => id === reviewedComponent)}
          setExpanded={setExpanded}
          setReimbursementsData={setData}
          reimbursemenets={data}
          fetchReimbursements={fetchReimbursements}
        />
      ) : (
        <div className="row justify-content-center mt-10">
          <div className="col-12">
            <div className="bg-white rounded py-7 px-10">
              <ListHeader
                title="Reimbursements"
                renderButtons={renderButtons}
              />
              <Datagrid
                data={data}
                headings={HEADINGS}
                renderRow={renderReimbursementsRow}
                selected={selected}
                setSelected={setSelected}
                loading={loading}
                expandable
                handleExpand={handleExpand}
                expandHeader="More"
                expandIcon={
                  <ArrowRightAltIcon className="ml-5" fontSize="large" />
                }
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
