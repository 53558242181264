import React from "react";
import { Formik } from "formik";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { MappedDatePicker } from "@bit/the-glue.frontendcomponents.datepicker";
import { fileFormSchema } from "../../../helpers/schemas";
import { ListButton } from "../../components/ListButton";
import FileUploadIcon from "../../static/FileUpload.png";
import { File } from "./File";

const categoryOptions = [
  {
    value: "contracts",
    label: "Contracts",
  },
  {
    value: "insurance",
    label: "Insurance",
  },
  {
    value: "incidents",
    label: "Incidents",
  },
  {
    value: "certificates",
    label: "Certificates",
  },
  {
    value: "photos",
    label: "Photos",
  },
  {
    value: "other",
    label: "Other",
  },
];

export const FileDetails = ({
  handleClose,
  handleSubmit,
  uploadedFile,
  uploadFile,
  fileData,
  setLoading,
}) => {
  const initialValues = {
    category: "",
    expiry_date: "",
    description: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) =>
        handleSubmit(values, setSubmitting, uploadFile, fileData, setLoading)
      }
      validationSchema={fileFormSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <div>
          <div className="text-center mb-10">
            <img src={FileUploadIcon} alt="logo" width="20%" />
            <h4 className="mt-3">Please label your file</h4>
          </div>
          <MappedSelect name="category" label="Category" options={categoryOptions} />
          <MappedDatePicker name="expiry_date" label="Expiry Date" />
          <MappedInput name="description" label="Title" multiline rows={6} />

          {uploadedFile ? (
            <div className="mt-5">
              <File file={uploadedFile} uploadFile={uploadFile} />
            </div>
          ) : null}

          <div className="d-flex justify-content-end mt-10">
            <ListButton
              label="Cancel"
              boxShadow={false}
              onClick={handleClose}
              size="medium"
              className="mr-5"
              variant="outlined"
              text="#407A28"
              disabled={isSubmitting}
            />
            <ListButton
              label="Submit"
              size="medium"
              boxShadow={false}
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </div>
        </div>
      )}
    </Formik>
  );
};
