import React from "react";
import { Link } from "react-router-dom";
import {
  formatDate,
  getStatusRowStyle,
  isDate,
  isLink,
  isStatus,
} from "../../../ui/helpers";
import LinkIcon from "../../../ui/static/Link.png";
import { renderBadges } from "../../modules/Contacts/helpers";
import { ReportDownlad } from "./ReportDownload";

const renderLinkIcon = (item) => {
  return (
    <a
      href={item.link_to_audit}
      target="_blank"
      rel="noreferrer"
      data-testid="audit-link"
    >
      <img src={LinkIcon} alt="Link" />
    </a>
  );
};

export function renderSitesRow(headings, item) {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-5 ${
        isStatus(key) && getStatusRowStyle(item)
      }`}
    >
      {key === "badges" ? (
        renderBadges(item)
      ) : isLink(key) ? (
        <Link
          className="text-dark"
          to={`/sites/${item.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item[key]}
        </Link>
      ) : isStatus(key) ? (
        item[key].split("_").join(" ")
      ) : (
        item[key]
      )}
    </td>
  ));
}

export function renderAuditsRow(headings, item) {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-5 "text-dark" ${
        isStatus(key) && getStatusRowStyle(item)
      }`}
    >
      {isDate(key) ? (
        formatDate(item[key])
      ) : key === "report" ? (
        <ReportDownlad item={item} />
      ) : key === "link" ? (
        renderLinkIcon(item)
      ) : (
        item[key]
      )}
    </td>
  ));
}
