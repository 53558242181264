export const drumActions = {
  SET_DATA: "DRUM/SET_DATA",
  RESET: "DRUM/RESET_DATA",
};

export function setData(data) {
  return {
    type: drumActions.SET_DATA,
    payload: data,
  };
}

export const resetState = () => {
  return {
    type: drumActions.RESET,
  };
};
