import React from "react";
import { Formik } from "formik";
import Button from "@bit/the-glue.frontendcomponents.button";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { useFetch } from "../../../hooks/fetch.hook";
import { modifyCurrentUser } from "../Auth/_api";
import { userProfileSchema } from "../../../helpers/schemas";
import { info } from "../../../helpers/toasts";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";

export const AccountInfo = ({ user }) => {
  const { request } = useFetch();

  const { first_name, last_name, phone_number, email, user_info } = user || {};

  const initialValues = {
    first_name: first_name || "",
    last_name: last_name || "",
    phone_number: phone_number || "",
    email: email || "",
    preferred_mfa: user_info?.preferred_mfa || "SOFTWARE_TOKEN_MFA",
  };

  const handleSubmit = (values, { setSubmitting }) => {
    if (values.preferred_mfa === "SOFTWARE_TOKEN_MFA")
      delete values["phone_number"];
    request(modifyCurrentUser, {
      ...values,
      name: [values.first_name, values.last_name].join(" "),
    })
      .then((data) => data && info("User information has been updated"))
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={userProfileSchema}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <>
          <div className="bg-white p-8 rounded">
            <div className="row">
              <div className="col col-lg-12">
                <form>
                  <h3 className="mb-10">
                    <strong>Account Information</strong>
                  </h3>
                  <div className="row mb-5">
                    <div className="col-6">
                      <MappedInput name="first_name" label="First Name" />
                    </div>
                    <div className="col-6">
                      <MappedInput name="last_name" label="Last Name" />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <MappedInput name="email" label="Email" disabled />
                    </div>
                    <div className="col-6">
                      <MappedSelect
                        label="Multi-factor authentication (MFA)"
                        name="preferred_mfa"
                        options={[
                          {
                            value: "SMS_MFA",
                            label: "SMS",
                          },
                          {
                            value: "SOFTWARE_TOKEN_MFA",
                            label: "Authenticator",
                          },
                        ]}
                      />
                      {values.preferred_mfa === "SMS_MFA" ||
                      values.phone_number ? (
                        <MappedInput label="Phone Number" name="phone_number" />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="d-flex justify-content-end mt-5">
                    <Button
                      label="Save"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                      background="#407A28"
                      text="#E8F5E1"
                      textTransform="uppercase"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};
