import React, { useState, useEffect, useMemo } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { get } from "lodash";
import {
  getInvoicePayload,
  modifyClaimsArray,
  renderClaimsRow,
} from "./helpers";
import { INVOICE_HEADINGS } from "./constants";
import { ListButton } from "../../../../ui/components/ListButton";
import { useFetch } from "../../../../hooks/fetch.hook";
import {
  deleteReimbursement,
  getReimbursements,
  groupReimbursements,
  modifyInvoice,
  modifyInvoiceFile,
} from "../Inspections/_api";
import { FileUploadForm } from "../../../../ui/structures/FileUploadForm";
import {
  createReimbursementFile,
  deleteReimbursementFile,
} from "../../../submodules/Files/_api";
import { error, info } from "../../../../helpers/toasts";
import { Modal } from "../../../../ui/components/Modal";
import { ConfirmDelete } from "../../../../ui/components/ConfirmDelete";
import { Loader } from "../../../../ui/components/Loader";
import { useSelector } from "react-redux";
import { accessControlFunction } from "../../../../ui/structures/AccessControl/AccessControlFunction";
import { AVAILABLE_ROLES } from "../../../constants";

const sortHelper = function (a, b) {
  if (a.created_at > b.created_at) return -1;
  if (a.created_at < b.created_at) return 1;
  return 0;
};

export const SubmitInvoice = ({
  handlePrevStep,
  handleNextStep,
  ORGANISATION_ID,
  loading,
  setLoading,
}) => {
  const { request } = useFetch();
  const user = useSelector(({ auth: { user } }) => user) || {};
  const isCAUser = accessControlFunction([AVAILABLE_ROLES.CA_ACCESS], user);

  const [data, setData] = useState([]);
  const [selectedClaim, setSelectedClaim] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [selected, setSelected] = useState({});

  const handleModalClose = () => {
    setModalOpen(false);
    handlePrevStep();
    setSelectedClaim({});
    setSelected({});
  };

  const handleConfirmClose = () => {
    setConfirmDeleteOpen(false);
    setSelectedClaim({});
    setSelected({});
  };

  const fetchReimbursements = () => {
    return isCAUser
      ? request(
          getReimbursements,
          "waiting_for_invoice&status=active&status=admin_review",
          `${ORGANISATION_ID ? `organisation_id=${ORGANISATION_ID}` : ""}`
        )
      : request(
          getReimbursements,
          "waiting_for_invoice&status=active",
          `${ORGANISATION_ID ? `organisation_id=${ORGANISATION_ID}` : ""}`
        );
  };

  useEffect(() => {
    setLoading(true);
    fetchReimbursements()
      .then((data) => data && setData(modifyClaimsArray(data.sort(sortHelper))))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ORGANISATION_ID]);

  const handleExpand = (id) => {
    const item = data.find((claim) => claim.id === id);
    if (!item) return;
    if (item.status === "active") {
      error("Can't upload invoice for submitted or rejected record!");
      return;
    }
    setSelectedClaim(item);
    setModalOpen(true);
    handleNextStep();
  };

  const handleUpdateFile = (
    values,
    setSubmitting,
    uploadedFile,
    fileData = {},
    setLoading,
    handleClose,
    type = ""
  ) => {
    if (!uploadedFile) return;
    // eslint-disable-next-line eqeqeq
    if (values.invoice_total == 0) {
      setSubmitting(false);
      setActionLoading(false);
      error("Invoice total can't be 0!");
      return;
    }
    setLoading(true);
    request(
      modifyInvoiceFile,
      {},
      selectedClaim?.id,
      selectedClaim?.invoice_id,
      fileData.id
    );
    request(
      modifyInvoice,
      getInvoicePayload(type, values),
      selectedClaim?.id,
      selectedClaim?.invoice_id
    )
      .then((res) => {
        if (!res) return;
        info("File has been uploaded!");
        const updatedData = data
          .filter(({ invoice_id }) => invoice_id === selectedClaim.invoice_id)
          .map(({ id }) => id);
        setData((state) => state.filter(({ id }) => !updatedData.includes(id)));
        setSubmitting(false);
        setLoading(false);
        handleClose();
        handleModalClose();
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const handleOpenModal = (id) => {
    const item = data.find((claim) => claim.id === id);
    setConfirmDeleteOpen(true);
    setSelectedClaim(item);
  };

  const removeReimbursement = (id, setSubmitting, isCustomArchive = false) => {
    setSubmitting(true);
    setActionLoading(true);
    setConfirmDeleteOpen(false);
    request(
      deleteReimbursement,
      id,
      isCustomArchive ? { unprocess: true } : { unprocess: false }
    )
      .then((res) => {
        if (!res) return;
        const updatedData = data
          .filter(({ invoice_id }) => invoice_id === selectedClaim.invoice_id)
          .map(({ id }) => id);
        setData((state) =>
          state.filter((item) => !updatedData.includes(item?.id))
        );
        info("Reimbursement has been deleted!");
        setSelected({});
        setSelectedClaim({});
      })
      .finally(() => {
        setSubmitting(false);
        setActionLoading(false);
      });
  };

  const handleRejectedItem = (item = {}) => {
    const { id, invoice } = item;
    const fileID = get(invoice, "files[0].id");
    const invoiceID = invoice?.id;
    if (!id || !invoiceID) {
      error("Something is wrong with invoice!");
      return;
    }
    if (fileID) {
      setActionLoading(true);
      request(deleteReimbursementFile, id, invoiceID, fileID)
        .then((deleteRes) => {
          if (!deleteRes) {
            setActionLoading(false);
            return;
          }
          handleExpand(id);
        })
        .finally(() => setActionLoading(false));
    } else handleExpand(id);
  };

  const updateReimbursements = (selectedIDs = [], selectedItemID) => {
    const selectedItem = data.find(({ id }) => id === selectedItemID);
    if (!selectedItem) {
      error("Update failed. Please reload page!");
      return;
    }
    setData((state) => {
      return state.map((reimbursement) => {
        const isSelectedItem = selectedIDs.includes(reimbursement?.id);
        if (isSelectedItem)
          return {
            ...reimbursement,
            grouped: true,
            invoice_id: selectedItem.invoice_id,
          };
        else return reimbursement;
      });
    });
  };

  const handleGroup = () => {
    const selectedIDs = Object.keys(selected).filter((key) =>
      Boolean(selected[key])
    );
    const selectedItems =
      data.filter((item) => selectedIDs.includes(item.id)) || [];
    const orgID = selectedItems[0]?.organisation_id;
    const sameOrgBelongning = selectedItems.every(
      (item) => item?.organisation_id === orgID
    );
    const properStatus = selectedItems.every(
      (item) => item?.status === "waiting for invoice"
    );
    if (!sameOrgBelongning) {
      error("Claims must belong to the same org!");
    } else if (!properStatus) {
      error("Claims must be waiting for invoice!");
    } else {
      setActionLoading(true);
      request(groupReimbursements, selectedIDs[0], selectedIDs.slice(1))
        .then((data) => {
          if (!data) return;
          info("Claims have been grouped!");
          updateReimbursements(selectedIDs, selectedIDs[0]);
        })
        .finally(() => setActionLoading(false));
    }
  };

  const groupDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length < 2,
    [selected]
  );

  const getItemType = (groupedItems = []) => {
    const isProcessingType = groupedItems.find(
      ({ type }) => type === "Processing"
    );
    const isInspectionType = groupedItems.find(
      ({ type }) => type === "Inspection"
    );
    if (isInspectionType && isProcessingType) {
      return "Combined";
    } else if (isProcessingType) return "Processing";
    else if (isInspectionType) return "Inspection";
  };

  const getGroupedData = () => {
    const groupedItems = data.filter(
      ({ invoice_id }) => invoice_id === selectedClaim?.invoice_id
    );
    const inspectionItems = groupedItems.filter(
      ({ type }) => type === "Inspection"
    );
    const processingItems = groupedItems.filter(
      ({ type }) => type === "Processing"
    );
    return {
      ...selectedClaim,
      total_value: groupedItems.reduce(
        (acc, value) => acc + value.total_value,
        0
      ),
      inspection_fees: inspectionItems.reduce(
        (acc, value) => acc + value.total_value,
        0
      ),
      processing_fees: processingItems.reduce(
        (acc, value) => acc + value.total_value,
        0
      ),
      type: getItemType(groupedItems),
    };
  };

  const getGroupedItemsAmount = () => {
    const groupedItems = data.filter(
      ({ invoice_id }) => invoice_id === selectedClaim?.invoice_id
    );
    return groupedItems.length;
  };

  return (
    <>
      <Loader
        isOpen={actionLoading}
        maxWidth="xs"
        disableBackdropClick
        disableEscapeKeyDown
      />
      <Modal
        isOpen={confirmDeleteOpen}
        submitable
        onClose={handleModalClose}
        maxWidth="sm"
        modalContent={
          <ConfirmDelete
            handleClose={handleConfirmClose}
            handleSubmit={removeReimbursement}
            id={selectedClaim?.id}
            isGroupedItem={selectedClaim?.grouped}
            getGroupedItemsAmount={getGroupedItemsAmount()}
            allowCustomArchive={true}
            customArchiveLabel={"Archive & Un-Process Container"}
            allowCustomArchiveCondition={true}
            customArchiveCondition={
              selectedClaim.type === "Processing" ||
              (selectedClaim.grouped &&
                selectedClaim.type === "Inspection" &&
                data
                  .filter(
                    (item) => item.invoice_id === selectedClaim.invoice_id
                  )
                  .filter((item) => item.type === "Processing").length > 0)
            }
          />
        }
      />
      <FileUploadForm
        handleClose={handleModalClose}
        isOpen={modalOpen}
        id={selectedClaim?.id}
        invoiceID={selectedClaim?.invoice_id}
        apiCall={createReimbursementFile}
        name="invoice"
        handleSubmit={handleUpdateFile}
        isGroupedItem={selectedClaim?.grouped}
        data={
          selectedClaim?.grouped
            ? getGroupedData()
            : data.find(({ id }) => id === selectedClaim?.id)
        }
        gstStatus={get(
          data.find(({ id }) => id === selectedClaim?.id),
          "organisation.gst_status"
        )}
      />
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <div className="text-right mb-5">
              <ListButton
                label="Group"
                disabled={groupDisabled}
                onClick={handleGroup}
              />
            </div>
            <Datagrid
              data={data}
              headings={INVOICE_HEADINGS}
              renderRow={(headings, item) =>
                renderClaimsRow(
                  headings,
                  item,
                  handleExpand,
                  handleRejectedItem
                )
              }
              loading={loading}
              deletable
              handleDelete={handleOpenModal}
              selectable
              selected={selected}
              setSelected={setSelected}
            />
            <div className="d-flex justify-content-end mt-10">
              <ListButton
                label="Drop Offs"
                size="large"
                onClick={handlePrevStep}
                className="mr-3"
                variant="outlined"
                text="#407A28"
                data-testid="prev-step"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
