import React, { useState } from "react";
import { Dialog, DialogContent, LinearProgress } from "@material-ui/core";
import { FileNameForm } from "./FileNameForm";
import { FileDetails } from "./FileDetails";
import { useFetch } from "../../../hooks/fetch.hook";
import { error, info } from "../../../helpers/toasts";
import { startUpload } from "../../../app/modules/AWS-S3/upload";
import { UploadInvoice } from "../../../app/modules/DrumMuster/Claims/UploadInvoice";
import { deleteReimbursementFile } from "../../../app/submodules/Files/_api";

export const FileUploadForm = ({
  handleClose,
  isOpen,
  id,
  handleSubmit,
  apiCall,
  name = "default",
  invoiceID = "",
  data = {},
  extraPayload = {},
  isClearance,
  setInvoiceId,
  gstStatus,
  fileID,
  updateFileForm,
  isGroupedItem
}) => {
  const { request } = useFetch();

  const [step, setStep] = useState(0);
  const [fileData, setFileData] = useState({});
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState("");

  const handleNextStep = () => setStep(step + 1);

  const onClose = () => {
    setFileData({});
    setUploadedFile("");
    handleClose();
    setStep(0);
  };

  const invoiceSumbit = setSubmitting => {
    return request(
      apiCall,
      id,
      { file_name: uploadedFile.name, ...extraPayload },
      invoiceID
    )
      .then(data => {
        if (!data) return;
        setFileData(isClearance ? data.file_data : data);
        isClearance && setInvoiceId(data.id);
        handleNextStep();
        info("File name has been submitted!");
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const submitNewFileName = (values, { setSubmitting }) => {
    setLoading(true);
    if (!id || !invoiceID) {
      setLoading(false);
      setSubmitting(false);
      error("Something is wrong with invoice file!");
      return;
    } else if (id && invoiceID && !fileID) {
      invoiceSumbit(setSubmitting);
      return;
    }
    request(deleteReimbursementFile, id, invoiceID, fileID).then(data => {
      if (!data) {
        setLoading(false);
        setSubmitting(false);
        return;
      }
      invoiceSumbit(setSubmitting);
    });
  };

  const handleSubmitName = (values, { setSubmitting }) => {
    setLoading(true);
    invoiceSumbit(setSubmitting);
  };

  const handleUploadFile = () => {
    const payload = {
      url: fileData.aws_s3_presigned_package.url,
      fields: {
        ...fileData.aws_s3_presigned_package.fields,
        file: uploadedFile
      }
    };
    return startUpload(fileData.file_name, payload).catch(err => {
      error((err || {}).message);
    });
  };

  const optionsMap = {
    default: (
      <FileDetails
        handleSubmit={handleSubmit}
        handleClose={onClose}
        uploadedFile={uploadedFile}
        uploadFile={handleUploadFile}
        fileData={fileData}
        setLoading={setLoading}
      />
    ),
    invoice: (
      <UploadInvoice
        handleSubmit={handleSubmit}
        handleClose={onClose}
        uploadedFile={uploadedFile}
        uploadFile={handleUploadFile}
        fileData={fileData}
        setLoading={setLoading}
        data={data}
        gstStatus={gstStatus}
        isGroupedItem={isGroupedItem}
      />
    )
  };

  const stepsContent = [
    <FileNameForm
      handleSubmit={updateFileForm ? submitNewFileName : handleSubmitName}
      handleClose={onClose}
      setUploadedFile={setUploadedFile}
      uploadedFile={uploadedFile}
      loading={loading}
    />,
    optionsMap[name]
  ];

  return (
    <>
      <Dialog
        onClose={onClose}
        open={isOpen}
        fullWidth={true}
        maxWidth={`${name === "default" || step === 0 ? "xs" : "md"}`}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogContent className="p-10">
          <div className="mt-5">
            {stepsContent.find((item, index) => index === step)}
          </div>
          <div className="py-2">{loading && <LinearProgress />}</div>
        </DialogContent>
      </Dialog>
    </>
  );
};
