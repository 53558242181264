/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  getContactOrganisations,
  getContactTags
} from "../../modules/Contacts/_api";
import { ContactDetails } from "./ContactDetails";
import { ContactsList } from "./ContactsList";
import { getTags } from "../../modules/Settings/Tags/_api";
import {
  getStatusRowStyle,
  isLink,
  isStatus,
  isValidMobile
} from "../../../ui/helpers";
import { renderBadges } from "../../modules/Contacts/helpers";
import { ContactCard } from "./ContactCard";

export const Contacts = ({
  name,
  organisationId,
  simpleOrganisations = [],
  contactsWithTagsLoading,
  contactsWithTags
}) => {
  const { request } = useFetch();

  const [component, setComponent] = useState("list");
  const [componentId, setComponentId] = useState("");
  const [linkedOrgsLoading, setLinkedOrgsLoading] = useState(false);
  const [linkedOrganisations, setLinkedOrganisations] = useState([]);
  const [linkedTags, setLinkedTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [helpersLoading, setHelpersLoading] = useState(false);
  const [contactsList, setContactsList] = useState([]);

  useEffect(() => {
    setHelpersLoading(true);
    request(getTags, "category=_like(Contact)_")
      .then(data => data && setTags(data))
      .finally(() => setHelpersLoading(false));
  }, []);

  useEffect(() => {
    if (componentId) {
      setLinkedOrgsLoading(true);
      Promise.all([
        request(getContactTags, componentId),
        request(getContactOrganisations, componentId)
      ])
        .then(([linkedTags, organisations]) => {
          linkedTags && setLinkedTags(linkedTags);
          organisations &&
            setLinkedOrganisations((organisations || []).map(({ id }) => id));
        })
        .finally(() => setLinkedOrgsLoading(false));
    }
  }, [componentId]);

  const organiastionOptions = useMemo(
    () =>
      simpleOrganisations.map(organiastion => ({
        value: organiastion.id,
        label: organiastion.business_name
      })),
    [simpleOrganisations]
  );

  if (componentId && linkedOrgsLoading) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  if (helpersLoading) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  const getContactById = () =>
    contactsList && contactsList.find(({ id }) => id === componentId);

  const handleExpand = id => () => {
    setComponentId(id);
    setComponent("edit");
  };

  const handleNew = () => {
    setComponent("new");
  };

  const handleCancel = () => {
    setComponent("list");
  };

  function renderContactsRow(headings, item) {
    return headings.map(([key]) => (
      <td
        key={key}
        className={`align-middle no-line border-0 px-5 py-5 ${isStatus(key) &&
          getStatusRowStyle(item)}`}
      >
        {isLink(key) ? (
          <span
            className="text-dark cursor-pointer"
            onClick={handleExpand(item.id)}
          >
            {item[key]}
          </span>
        ) : key === "badges" ? (
          renderBadges(item)
        ) : key === "mobile" && item["mobile"] ? (
          isValidMobile(item)
        ) : (
          item[key]
        )}
      </td>
    ));
  }

  const componentsMap = {
    list: (
      <ContactsList
        setContactsList={setContactsList}
        data={contactsList}
        name={name}
        handleExpand={handleExpand}
        handleNew={handleNew}
        organisationId={organisationId}
        renderContactsRow={renderContactsRow}
        contactsWithTags={contactsWithTags}
        contactsWithTagsLoading={contactsWithTagsLoading}
      />
    ),
    new: (
      <ContactDetails
        handleCancel={handleCancel}
        organisationId={organisationId}
        organiastionOptions={organiastionOptions}
        linkedOrganisations={[organisationId]}
      />
    ),
    edit: (
      <ContactCard
        data={getContactById()}
        id={componentId}
        handleCancel={handleCancel}
        organisationId={organisationId}
        organiastionOptions={organiastionOptions}
        linkedOrganisations={linkedOrganisations}
        tags={tags}
        linkedTags={linkedTags}
        setLinkedTags={setLinkedTags}
      />
    )
  };

  return <>{componentsMap[component]}</>;
};
