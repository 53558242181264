export const HEADINGS = [
  ["site_name", "Site Name"],
  ["drop_off_date", "Date"],
  ["material", "Material"],
  ["total", "Quantity"],
  ["consultant", "Consultant"],
  ["source", "Source"]
];

export const SORT_HEADINGS = [
  ["site.name", "Site Name"],
  ["drop_off_date", "Date"],
  ["material", "Material"],
  ["total", "Quantity"],
  ["consultant", "Consultant"],
  ["source", "Source"]
];

export const INVOICE_HEADINGS = [
  ["indicator", ""],
  ["code", "Claim ID"],
  ["organisation_name", "Claim Payee Organisation"],
  ["created_at", "Date"],
  ["volume", "Volume"],
  ["total_value_gst", "Value (inc GST)"],
  ["type", "Type"],
  ["status", "Status"],
  ["grouped", "Grouped"],
  ["invoice_upload", "Invoice"]
];

export const totalStyle = {
  fontWeight: "700",
  fontSize: "12px",
  color: "#828282"
};
