/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { injectIntl } from "react-intl";
import { useFetch } from "../../../../hooks/fetch.hook";
import * as auth from "../_redux/authRedux";

import {
  Authenticator,
  SignIn,
  ConfirmSignUp,
  ConfirmSignIn,
  RequireNewPassword,
  TOTPSetup,
} from "aws-amplify-react";
import { ForgotPassword } from "./ForgotPassword";
import { MyGreetings } from "./MyGreetings";
import { Auth } from "@aws-amplify/auth";
import { I18n } from "@aws-amplify/core";
import "./TotpQrCodeFix.css";

import config from "react-global-configuration";
import configuration from "../../../../config";
import { DBWakeUp, getUser } from "../_api";
import { SignUp } from "./SignUp";
import { Modal } from "../../../../ui/components/Modal";
import { RequestCall } from "../RequestCall";
import {
  DevAlertBar,
  WarningAlertBar,
} from "../../../../ui/components/AlertBar";
import { NewPassword } from "./NewPassword";
import { get } from "lodash";
import { WelcomeOverlay } from "../WelcomeOverlay";

const dict = {
  en: {
    "Forget your password? ": " ", // Blank out hint in default theme
    "Confirm TOTP Code": "2FA Login Code",
    "Enter your username": "Enter your email",
    AWSCognito: configuration.CognitoInformation.IssuerName,
  },
};

I18n.setLanguage("en");
I18n.putVocabularies(dict);

const errorMap = (message) => {
  if (/^Custom auth.*/i.test(message)) {
    return "Incorrect username or password.";
  }

  return message;
};

const MyTheme = {
  toast: {
    backgroundColor: "#e8cccc",
    color: "#8b0000",
    paddingTop: "1.25rem",
    paddingBottom: "1.25rem",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    border: "1px solid",
    borderRadius: ".42rem",
    borderColor: "#e8cccc",
  },
  sectionFooter: {
    textAlign: "center",
  },
  sectionBody: {
    textAlign: "center",
  },
  sectionHeader: {
    fontWeight: "600",
    fontSize: "1.25rem",
    textAlign: "center",
    width: "100%",
    paddingTop: "1.25rem",
    paddingBottom: "1.25rem",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    color: "#62BA09",
    textTransform: "uppercase",
  },
  sectionFooterSecondaryContent: {
    textTransform: "uppercase",
    marginTop: "1rem",
    display: "block",
  },
  container: {
    width: "100%",
    // paddingTop: "1rem",
    //'paddingBottom': '1rem',
  },
  formField: {
    width: "100%",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  hint: {
    width: "100%",
    paddingTop: "1.25rem",
    paddingBottom: "1.25rem",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    color: "#64A70B",
    textAlign: "center",
    textTransform: "uppercase",
  },
  input: {
    width: "100%",
    paddingTop: "1.25rem",
    paddingBottom: "1.25rem",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    borderRadius: ".42rem",
    border: "1px solid",
    borderColor: "#f3f6f9",
    backgroundColor: "#f3f6f9",
  },
  inputLabel: { display: "none" },
  button: {
    marginTop: "0.75rem",
    marginBottom: "0.75rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    fontSize: "1rem",
    lineHeight: "1.5",
    borderRadius: ".42rem",
    border: "1px solid",
    borderColor: "#64A70B",
    backgroundColor: "#64A70B",
    color: "#FFFFFF",
    width: "100%",
    textTransform: "uppercase",
  },
};

function Login(props) {
  const [status, setStatus] = useState("");
  const [step, setStep] = useState("signIn");
  const [code, setCode] = useState("");
  const [expiredMessage, setExpiredMessage] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [requestCallAlertOpen, setRequestCallAlertOpen] = useState(false);
  const [welcomeOpen, setWelcomeOpen] = useState(true);

  const handleWelcomeClose = () => setWelcomeOpen(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const { request } = useFetch();

  const getUrlParams = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");
    return code;
  };

  useEffect(() => {
    const code = getUrlParams();
    if (code) {
      setCode(code);
      setStep("newPassword");
    }
  }, []);

  useEffect(() => {
    DBWakeUp().catch((err) => console.log(err));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setExpiredMessage(Boolean(localStorage.getItem("sessionExpired")));
  }, []);

  function handleAuthStateChange(state) {
    // console.log(state);
    setStatus(state);
    if (state === "signedIn") {
      Auth.currentUserInfo().then((userInfo) => {
        if (userInfo && userInfo.attributes) {
          // Require check as this bounces on logout
          request(getUser).then((userData) => {
            if (userData) {
              const {
                attributes: { email, name },
              } = userInfo;

              const { id, roles, policies, permissions, region_id, region } =
                userData;

              const user = {
                id,
                username: email,
                email: email,
                fullname: name,
                roles,
                region_id,
                region_name: get(region, "long_name", ""),
                permissions,
                policies,
                sessionStart: new Date(),
              };

              localStorage.setItem("sessionExpired", "");
              setExpiredMessage(false);
              props.fulfillUser(user);
            }
          });
        }
      });
    }
  }

  // const handleSignUp = () => setStep("signUp");

  const handleSignIn = () => setStep("signIn");

  const handleResetPassword = () => setStep("resetPassword");

  const stepsMap = {
    signIn: (
      <>
        {requestCallAlertOpen && (
          <div>
            <DevAlertBar severity="info" message="Your message has been sent" />
          </div>
        )}
        <div
          className="d-flex justify-content-center align-items-center mt-10"
          style={{ minHeight: "50%" }}
        >
          <div
            className={`bg-white border border-secondary py-10 px-10 rounded col-md-6 col-lg-4`}
          >
            <div
              className="text-center text-uppercase"
              style={{
                color: "rgb(98, 186, 9)",
                fontSize: "1.25rem",
                fontWeight: 600,
              }}
            >
              {status === "TOTPSetup"
                ? "QR code needs to be scanned with an authenticator app!"
                : null}
            </div>
            <div className="d-flex justify-content-center login-form login-signin">
              <Authenticator
                theme={MyTheme}
                onStateChange={handleAuthStateChange}
                amplifyConfig={config.get("Auth")}
                errorMessage={errorMap}
                hideDefault={true}
              >
                <SignIn />
                <ConfirmSignIn />
                <RequireNewPassword />
                <ConfirmSignUp />
                <TOTPSetup />
                <MyGreetings props={props} />
              </Authenticator>
            </div>
            {/*end::Form*/}

            {status === "signIn" && (
              <>
                <div
                  style={MyTheme.hint}
                  className="mt-5 cursor-pointer"
                  onClick={handleResetPassword}
                >
                  Forgot Password? Reset Password
                </div>
                {/* <div
                  style={MyTheme.hint}
                  className="mt-1 cursor-pointer"
                  onClick={handleSignUp}
                >
                  Not registered yet? Create Account
                </div> */}
              </>
            )}
          </div>
        </div>

        {status === "signIn" && (
          <div className="d-flex justify-content-center align-items-center mt-15">
            <div
              className={`bg-white border border-secondary py-10 px-10 rounded col-md-12 col-lg-4`}
            >
              <div className="text-center text-uppercase">
                <div
                  style={{
                    color: "#62BA09",
                    fontSize: "1.1rem",
                  }}
                >
                  <strong>NEED ASSISTANCE?</strong>
                </div>
                <button
                  style={{ ...MyTheme.button, width: "50%" }}
                  onClick={handleModalOpen}
                >
                  Get In Touch
                </button>
              </div>
              <div className="text-center mt-10">
                <img
                  src={toAbsoluteUrl("/assets/icons/illuminance-labs.png")}
                  alt="logo"
                  className="img-fluid w-25"
                />
              </div>
            </div>
          </div>
        )}
      </>
    ),
    signUp: <SignUp handleSignIn={handleSignIn} MyTheme={MyTheme} />,
    resetPassword: <ForgotPassword MyTheme={MyTheme} />,
    newPassword: <NewPassword MyTheme={MyTheme} code={code} />,
  };

  return (
    <>
      {modalOpen && (
        <Modal
          maxWidth="xs"
          isOpen={modalOpen}
          submitable
          onClose={handleModalClose}
          modalContent={
            <RequestCall
              handleClose={handleModalClose}
              setAlertOpen={setRequestCallAlertOpen}
            />
          }
        />
      )}
      <WelcomeOverlay isOpen={welcomeOpen} onClose={handleWelcomeClose} />
      <div className="containter w-100 h-100">
        <div className="p-5 bg-white">
          <span className="mr-5">
            <img
              className="img-fluid"
              src={toAbsoluteUrl("/assets/icons/DDLogo.png")}
              alt="Logo"
            />
          </span>
        </div>
        {expiredMessage && (
          <WarningAlertBar message="Your session has expired. Please, reauthenticate." />
        )}
        {!welcomeOpen && stepsMap[step]}
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
