import React, { useState, useEffect } from "react";
import Input from "@bit/the-glue.frontendcomponents.input";
import { Skeleton } from "@material-ui/lab";
import { CustomDatePicker } from "../../../../../ui/components/DatePicker";
import { Autocomplete } from "../../../../../ui/components/Autocomplete";
import { InputsRow } from "./InputsRow";
import { InspectorSelect } from "./InspectorSelect";
import { AddBusiness } from "./AddBusiness";
import { Modal } from "../../../../../ui/components/Modal";
import { totalStyle } from "./constants";
import { makeStyles } from "@material-ui/core";

const dataMap = [
  {
    header: "Less than 20L",
    plasticName: "plastic_0",
    steelName: "steel_0",
  },
  {
    header: "20L-25L",
    plasticName: "plastic_1",
    steelName: "steel_1",
  },
  {
    header: "26L-120L",
    plasticName: "plastic_2",
    steelName: "steel_2",
  },
  {
    header: "121L-205L",
    plasticName: "plastic_3",
    steelName: "steel_3",
  },
];

const useStyles = makeStyles({
  root: {
    backgroundColor: "rgba(0,0,0,0.04)",
  },
});

export const getTotalPlastic = (values = {}) => {
  return [
    values.plastic_0,
    values.plastic_1,
    values.plastic_2,
    values.plastic_3,
  ].reduce((acc, value) => acc + Number(value), 0);
};

export const getTotalSteel = (values = {}) => {
  return [
    values.steel_0,
    values.steel_1,
    values.steel_2,
    values.steel_3,
  ].reduce((acc, value) => acc + Number(value), 0);
};

export const getTotalContainers = (values = {}) => {
  return [
    values.plastic_0,
    values.plastic_1,
    values.plastic_2,
    values.plastic_3,
    values.steel_0,
    values.steel_1,
    values.steel_2,
  ].reduce((acc, value) => acc + Number(value), 0);
};

export const GridItem = ({
  data = {},
  siteOptions = [],
  contactsOptions = [],
  organisations = [],
  inspectorsOptions = [],
  setContacts,
  setInspectors,
  handleUpdateField,
  setOrganisations,
  sites,
  orgsLoading,
  sitesLoading,
  dropId,
}) => {
  const rootStyle = useStyles();

  const [rejected, setRejected] = useState(data.rejected);
  const [date, setDate] = useState(data.drop_off_date);
  const [modalOpen, setModalOpen] = useState(false);
  // const [resetKey, setResetKey] = useState(0);

  // const organisationsOptions = useMemo(
  //   () => [
  //     ...(organisations || [])
  //       .map((organiastion) => ({
  //         value: organiastion.id,
  //         label: organiastion.business_name,
  //       }))
  //       .sort(sortHelper),
  //     { value: "add_organisation", label: "Add New Organisation" },
  //   ],
  //   [organisations]
  // );

  const handleModalOpen = () => {
    setModalOpen(true);
    // setResetKey(Date.now());
  };
  const handleModalClose = () => setModalOpen(false);

  const handleRejectedChange = (e) => {
    setRejected(e.target.value);
    handleUpdateField("rejected", e.target.value);
  };

  useEffect(() => {
    if (data.organisation_id === "add_organisation") {
      handleModalOpen();
      handleUpdateField("organisation_id", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // const _filterOptions = createFilterOptions({
  //   limit: 70,
  // });
  // const filterOptions = (options, state) => {
  //   const results = _filterOptions(options, state);

  //   if (!results.find(({ value }) => value === "add_organisation")) {
  //     results.push({
  //       value: "add_organisation",
  //       label: "Add New Organisation",
  //     });
  //   }

  //   return results;
  // };

  return (
    <>
      <Modal
        isOpen={modalOpen}
        submitable
        onClose={handleModalClose}
        maxWidth="lg"
        disableBackdropClick
        disableEscapeKeyDown
        modalContent={
          <AddBusiness
            handleClose={handleModalClose}
            organisations={organisations}
            setOrganisations={setOrganisations}
          />
        }
      />
      <tr>
        <td
          rowSpan="6"
          style={{ minWidth: "150px", verticalAlign: "top" }}
          className="pt-5 px-2"
        >
          {sitesLoading ? (
            <Skeleton variant="rect" width={"100%"} height={40} />
          ) : (
            <Autocomplete
              name="site_id"
              placeholder="Select Site"
              setValue={handleUpdateField}
              defaultValue={data.site_id || (sites.length === 1 && sites[0].id)}
              size="small"
              defaultValueField="value"
              options={siteOptions}
              inputStyle={{}}
              rootStyle={rootStyle}
            />
          )}
          <span className="text-danger">{data.siteError}</span>
        </td>
        <td
          rowSpan="6"
          style={{ maxWidth: "100px", minWidth: "50px", verticalAlign: "top" }}
          className="pt-5 px-2"
        >
          <CustomDatePicker
            name="drop_off_date"
            shouldCloseOnSelect
            maxDate={new Date()}
            selected={date ? new Date(date) : new Date(Date.now())}
            onChange={(data) => {
              setDate(data);
              handleUpdateField("drop_off_date", new Date(data));
            }}
            disabled={Boolean(dropId)}
          />
          <span className="text-danger">{data.dateError}</span>
        </td>
        {/* <td
          rowSpan="6"
          style={{ minWidth: "180px", verticalAlign: "top" }}
          className="pt-5 px-2"
        >
          {orgsLoading ? (
            <Skeleton variant="rect" width={"100%"} height={40} />
          ) : (
            <Autocomplete
              name="organisation_id"
              placeholder="Select Organisation"
              setValue={handleUpdateField}
              defaultValue={data.organisation_id}
              size="small"
              defaultValueField="value"
              options={organisationsOptions}
              sortable={false}
              filterOptions={filterOptions}
              key={resetKey}
              inputStyle={{}}
              rootStyle={rootStyle}
            />
          )}
        </td>
        <td
          rowSpan="6"
          style={{ minWidth: "140px", verticalAlign: "top" }}
          className="pt-5 px-2"
        >
          <ContactSelect
            defaultValue={data.contact_id}
            contactsOptions={contactsOptions}
            setContacts={setContacts}
            handleUpdateField={handleUpdateField}
            data={data}
            rootStyle={rootStyle}
          />
        </td> */}
        <td colSpan="4"></td>
        <td
          rowSpan="6"
          style={{ maxWidth: "40px", verticalAlign: "top" }}
          className="pt-5 px-2"
        >
          <Input value={rejected} onChange={handleRejectedChange} />
        </td>
        <td
          rowSpan="6"
          style={{ minWidth: "140px", verticalAlign: "top" }}
          className="pt-5 px-2"
        >
          <InspectorSelect
            inspectorsOptions={inspectorsOptions}
            setInspectors={setInspectors}
            handleUpdateField={handleUpdateField}
            defaultValue={data.inspector_id}
            data={data}
            sites={sites}
            rootStyle={rootStyle}
          />
        </td>
        <td></td>
      </tr>
      {dataMap.map(({ header, plasticName, steelName }) => (
        <InputsRow
          key={header}
          header={header}
          plasticName={plasticName}
          steelName={steelName}
          data={data}
          handleUpdateField={handleUpdateField}
        />
      ))}

      <tr>
        <td className="pt-6 text-center" style={totalStyle}>
          <strong>Plastic Total</strong>
        </td>
        <td className="px-6 pt-6" style={totalStyle}>
          <strong> {getTotalPlastic(data)}</strong>
        </td>
        <td className="pt-6 text-center" style={totalStyle}>
          <strong>Steel Total</strong>
        </td>
        <td className="px-6 pt-6" style={totalStyle}>
          <strong> {getTotalSteel(data)}</strong>
        </td>
      </tr>
    </>
  );
};
