import { productTypes } from "./actions";

const initialState = {
  productsList: [],
};

export function productsReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case productTypes.SET_DATA:
      return {
        ...state,
        productsList: payload,
      };

    case productTypes.REMOVE_DATA:
      return {
        ...state,
        productsList: [...state.productsList.filter(({ id }) => id !== payload)],
      };

    case productTypes.UPDATE_DATA:
      const index = state.productsList.findIndex((item) => (item || {}).id === (payload || {}).id);
      return {
        ...state,
        productsList: [
          ...state.productsList.slice(0, index),
          payload,
          ...state.productsList.slice(index + 1),
        ],
      };

    default:
      return state;
  }
}
