const type = "pack-sizes";

export const setPacksList = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      packsList: payload,
    };
  },
  type,
});

export const removePack = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      packsList: state.packsList.filter(({ id }) => id !== payload),
    };
  },
  type,
});

export const unArchivePack = (payload) => ({
  execute: (state) => {
    const index = state.packsList.findIndex((item) => item.id === payload.id);
    const myState = [...state.packsList];
    myState.splice(index, 1, payload);
    return {
      ...state,
      packsList: [...myState],
    };
  },
  type,
});
