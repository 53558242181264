export const CLEARANCES_HEADINGS = [
  ["code", "ID"],
  ["state", "State"],
  ["region", "Region"],
  ["planned_pickup_date", "Planned Date"],
  ["volume", "Volume"],
  ["material", "Material"],
  ["claim_payee", "Claim Payee"],
  ["site_name", "Site Name"],
  ["status", "Status"]
];

export const COMPLETED_HEADINGS = [
  ["code", "Site ID"],
  ["collection_run_code", "Run ID"],
  ["state", "State"],
  ["region", "Region"],
  ["pickup_date", "Pickup Date"],
  ["volume_pickedup", "Volume"],
  ["material", "Material"],
  ["per_unit_cost", "Per Unit Cost"],
  ["claim_payee", "Claim Payee"],
  ["site_name", "Site Name"],
  ["processing_cost", "Processing Cost"],
  ["status", "Status"]
];

export const ROUTE_HEADINGS = [
  ["code", "Code"],
  ["processor_name", "Processor"],
  ["planned_pickup_date", "Planned Pickup Date"],
  ["status", "Status"]
];

export const SEARCH_HEADINGS = [
  ["code", "ID"],
  ["collection_run.code", "Run ID"],
  ["collection_run.processor.business_name", "Claim Payee"]
];

export const searchMap = {
  ID: "code",
  "Run ID": "collection_run.code",
  "Claim Payee": "collection_run.processor.business_name"
};

export const collectionRunsSearchMap = {
  Code: "code",
  Processor: "processor.business_name"
};

export const collectionRunSearchHelper = [
  ["code", "Code"],
  ["processor.business_name", "Processor"]
];
