import React from "react";
import { Formik } from "formik";
import ButtonGroup from "@bit/the-glue.frontendcomponents.button-group";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { useFetch } from "../../../hooks/fetch.hook";
import { modifyFile } from "./_api";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { MappedDatePicker } from "@bit/the-glue.frontendcomponents.datepicker";
import { fileFormSchema } from "../../../helpers/schemas";
import { removeEmptyFields } from "../../../ui/helpers";

const categoryOptions = [
  {
    value: "contracts",
    label: "Contracts",
  },
  {
    value: "insurance",
    label: "Insurance",
  },
  {
    value: "incidents",
    label: "Incidents",
  },
  {
    value: "certificates",
    label: "Certificates",
  },
  {
    value: "photos",
    label: "Photos",
  },
  {
    value: "other",
    label: "Other",
  },
];

export const FileDetails = ({ file = {}, handleClose, setFiles, files = [] }) => {
  const { request } = useFetch();

  const initialValues = {
    category: file.category || "",
    expiry_date: file.expiry_date ? new Date(file.expiry_date) : "",
    description: file.description || "",
  };

  const updateCardData = (item = {}) => {
    const index = files.findIndex((file) => (file || {}).id === (item || {}).id);
    setFiles((state) => [...state.slice(0, index), item, ...state.slice(index + 1)]);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    removeEmptyFields(values)
    request(modifyFile, values, file.id)
      .then((data) => {
        if (!data) return;
        updateCardData(data);
        handleClose();
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div className="bg-white p-10">
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={fileFormSchema}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <>
            <h3 className="mb-10">
              <strong>Edit File</strong>
            </h3>
            <div className="row justify-content-between">
              <div className="col-8">
                <MappedSelect name="category" label="Category" options={categoryOptions} />
                <MappedDatePicker name="expiry_date" label="Expiry Date" />
                <MappedInput name="description" label="Title" multiline rows={6} />
              </div>
            </div>
            <div className="mt-10">
              <ButtonGroup
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                textTransofrm="uppercase"
                size="large"
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
