import React, { useState, useEffect, useMemo } from "react";
import { ErrorOutline as ErrorOutlineIcon } from "@material-ui/icons";
import Input from "@bit/the-glue.frontendcomponents.input";
import Skeleton from "@material-ui/lab/Skeleton";
import ClearIcon from "@material-ui/icons/Clear";
import { useHistory } from "react-router-dom";
import { Checkbox } from "../../../../../ui/components/Checkbox";
import { GridItem } from "./GridItem";
import { useFetch } from "../../../../../hooks/fetch.hook";
import { getSitesSimple } from "../../../Sites/_api";
import { ListButton } from "../../../../../ui/components/ListButton";
import { headerStyle } from "./constants";
import { editDrop } from "../_api";
import { info } from "../../../../../helpers/toasts";

export const EditableGrid = ({
  data = [],
  handleNewItem,
  handleRemoveItem,
  setData,
  failedItems = [],
  id,
}) => {
  const { request } = useFetch();
  const history = useHistory();

  // const [organisations, setOrganisations] = useState([]);
  // const [contacts, setContacts] = useState([]);
  const [sites, setSites] = useState([]);
  const [inspectors, setInspectors] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [orgsLoading, setOrgsLoading] = useState(false);
  const [sitesLoading, setSitesLoading] = useState(false);

  const handleCancelDrop = () => {
    setLoading(true);
    request(editDrop, { status: "cancelled" }, id)
      .then((data) => {
        if (!data) return;
        history.push("/drummuster/claims");
        info("Drop-off has been cancelled!");
      })
      .finally(() => setLoading(false));
  };

  // useEffect(() => {
  //   setOrgsLoading(true);
  //   request(getSimpleOrganisations)
  //     .then((data) => data && setOrganisations(data))
  //     .finally(() => setOrgsLoading(false));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    setSitesLoading(true);
    request(
      getSitesSimple,
      "dm_program_participation=true&simple=true&fields=name,id"
    )
      .then((data) => data && setSites(data))
      .finally(() => setSitesLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const siteOptions = useMemo(
    () =>
      sites.map((site) => ({
        value: site.id,
        label: site.name,
      })),
    [sites]
  );

  // const contactsOptions = useMemo(
  //   () =>
  //     contacts.map((contact) => ({
  //       value: contact.id,
  //       label: [contact.first_name, contact.last_name].join(" "),
  //     })),
  //   [contacts]
  // );

  const inspectorsOptions = useMemo(
    () =>
      inspectors.map((inspector) => ({
        value: inspector.id,
        label: [inspector.first_name, inspector.last_name].join(" "),
      })),
    [inspectors]
  );

  const handleUpdateField = (column, value, selectedItem = {}) => {
    let payload = { ...selectedItem, [column]: value };
    const index = data.findIndex((item) => (item || {}).id === selectedItem.id);
    if (index === -1) return;
    setData((state) => [
      ...state.slice(0, index),
      payload,
      ...state.slice(index + 1),
    ]);
  };

  const handleDeliveriesChange = (e, item) => {
    handleUpdateField("number_of_deliveries", e.target.value, item);
  };

  const handleBulkChange = (e, item) => {
    const payload = {
      ...item,
      bulk_entry: e.target.checked,
      number_of_deliveries: e.target.checked ? item.number_of_deliveries : "",
    };
    const index = data.findIndex((entry) => (entry || {}).id === item.id);
    if (index === -1) return;
    setData((state) => [
      ...state.slice(0, index),
      payload,
      ...state.slice(index + 1),
    ]);
  };

  return (
    <div>
      <div className="overflow-auto flex-grow-1 w-100">
        {loading ? (
          <Skeleton variant="rect" width={"100%"} height={420} />
        ) : (
          data.map((item) => (
            <div
              key={item.id}
              className="w-100 bg-white rounded mb-10 py-7 px-10"
            >
              {data.length > 1 ? (
                <div className="text-right cursor-pointer mb-2">
                  {
                    <ClearIcon
                      size="large"
                      color="secondary"
                      onClick={() => handleRemoveItem(item.id)}
                    />
                  }
                </div>
              ) : null}
              <table style={{ minWidth: "700px" }} className="w-100">
                <thead>
                  <tr className="border-bottom">
                    <th className="px-2" style={headerStyle}>
                      Site Name
                    </th>
                    <th className="px-2" style={headerStyle}>
                      Date
                    </th>
                    {/* <th className="px-2" style={headerStyle}>
                      Business
                    </th>
                    <th className="px-2" style={headerStyle}>
                      Name
                    </th> */}
                    <th className="px-2 text-center" style={headerStyle}>
                      Size
                    </th>
                    <th className="px-2 text-center" style={headerStyle}>
                      Plastic
                    </th>
                    <th className="px-2 text-center" style={headerStyle}>
                      Size
                    </th>
                    <th className="px-2 text-center" style={headerStyle}>
                      Steel
                    </th>
                    <th className="px-2" style={headerStyle}>
                      Rejected
                    </th>
                    <th className="px-2" style={headerStyle}>
                      Inspector
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <GridItem
                    dropId={id}
                    data={item}
                    // organisations={organisations}
                    // contactsOptions={contactsOptions}
                    siteOptions={siteOptions}
                    inspectorsOptions={inspectorsOptions}
                    // setContacts={setContacts}
                    setInspectors={setInspectors}
                    // setOrganisations={setOrganisations}
                    handleUpdateField={(column, value) =>
                      handleUpdateField(column, value, item)
                    }
                    sites={sites}
                    // orgsLoading={orgsLoading}
                    sitesLoading={sitesLoading}
                  />
                </tbody>
              </table>
              <div className="d-flex justify-content-between align-items-center px-5 mt-5 pt-5 border-top">
                <div>
                  <Checkbox
                    name="bulk_entry"
                    checked={item.bulk_entry}
                    label="Bulk Entry"
                    onChange={(e) => handleBulkChange(e, item)}
                  />
                  <Input
                    value={item.number_of_deliveries}
                    onChange={(e) => handleDeliveriesChange(e, item)}
                    label="Number of Deliveries"
                    inputWrapperStyle={{
                      padding: "0px 15px",
                    }}
                    disabled={!item.bulk_entry}
                  />
                </div>
                {id ? (
                  <ListButton
                    color="secondary"
                    className="px-9"
                    size="large"
                    label="Cancel Drop-off"
                    onClick={handleCancelDrop}
                  />
                ) : (
                  <ListButton
                    color="secondary"
                    className="px-9"
                    size="large"
                    onClick={handleNewItem}
                    label="Add Row"
                    variant="outlined"
                    text="#407A28"
                    boxShadow={false}
                  />
                )}
              </div>
              {failedItems.includes(item.id) ? (
                <div className="text-danger mt-5">
                  <ErrorOutlineIcon
                    className="text-danger mr-3"
                    fontSize="large"
                  />
                  Failed
                </div>
              ) : (
                <span></span>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};
