export const siteTypes = {
  SET_DATA: "SITES/SET_DATA",
  REMOVE_DATA: "SITES/REMOVE_DATA",
  UPDATE_DATA: "SITES/UPDATE_DATA",
};

export function setSitesList(data) {
  return {
    type: siteTypes.SET_DATA,
    payload: data,
  };
}

export function removeSite(data) {
  return {
    type: siteTypes.REMOVE_DATA,
    payload: data,
  };
}

export function updateSitesList(data) {
  return {
    type: siteTypes.UPDATE_DATA,
    payload: data,
  };
}
