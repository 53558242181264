import React, { useState, useEffect } from "react";
import { useFetch } from "../../../hooks/fetch.hook";
import { downloadFile } from "../../../app/modules/AWS-S3/download";
import PDFIcon from "./assets/pdf_icon.png";
import FileIcon from "./assets/file.png";
import ImageIcon from "./assets/image.png";

export const FilePreview = ({
  fetchFunction,
  task_id,
  comment_id,
  file_id,
  file_name,
  loading,
  setLoading,
}) => {
  const { request } = useFetch();
  const [url, setUrl] = useState("");
  const [fileType, setFileType] = useState("");

  useEffect(() => {
    const currentFileType = file_name
      .split(".")
      .pop()
      .toLowerCase();
    setFileType(currentFileType);
    if (file_id && ["png", "jpg"].includes(currentFileType)) setUrl(ImageIcon);
    request(fetchFunction, task_id, comment_id, [file_id]).then((data) => {
      if (!data) return;
      setUrl(data.download_url);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file_id]);

  return (
    <div className="d-flex align-items-center">
      <div style={{ width: 200, textAlign: "center" }}>
        <img
          className="my-1 rounded"
          alt={file_name}
          style={{ height: 80, maxWidth: 200, objectFit: "contain" }}
          src={
            ["png", "jpg"].includes(fileType)
              ? url
              : ["pdf"].includes(fileType)
              ? PDFIcon
              : FileIcon
          }
        />
      </div>

      <p
        style={{ color: "#76ad1c", cursor: "pointer", paddingLeft: 12 }}
        key={file_id}
        onClick={() =>
          loading
            ? console.error("Already downloading")
            : downloadFile(
                task_id,
                comment_id,
                setLoading,
                fetchFunction,
                file_id
              )
        }
      >
        {file_name}
      </p>
    </div>
  );
};
