import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import { HEADINGS, searchMap } from "./constants";
import { removePack, setPacksList } from "./_redux/actions";
import { renderPacksRow } from "./helpers";
import { ListButton } from "../../../../ui/components/ListButton";
import { ListHeader } from "../../../../ui/structures/ListHeader";
import { useFetch } from "../../../../hooks/fetch.hook";
import { deletePackSize, getPackSizes } from "./_api";
import { Modal } from "../../../../ui/components/Modal";
import { ConfirmDelete } from "../../../../ui/components/ConfirmDelete";
import { handleSearch } from "../../../../ui/helpers";
import { sortHeaderStyle } from "../../../constants";
import { TableSortLabel } from "@material-ui/core";
import { Loader } from "../../../../ui/components/Loader";
import { info } from "../../../../helpers/toasts";

export const List = () => {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState({});
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [actionLoading, setActionLoading] = useState(false);

  const handleModalClose = () => setConfirmDeleteOpen(false);

  const handleOpenModal = (id) => {
    setConfirmDeleteOpen(true);
    setSelectedItem(id);
  };

  const data = useSelector(({ packSizes: { packsList } }) => packsList);

  const fetchPacks = () => {
    setLoading(true);
    request(getPackSizes, search, field, field && direction)
      .then((data) => {
        dispatch(setPacksList(data));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchPacks();
    // eslint-disable-next-line
  }, [search, field, direction]);

  const handleDelete = (id, setSubmitting) => {
    setActionLoading(true);
    setConfirmDeleteOpen(false);
    request(deletePackSize, id)
      .then((data) => {
        if (!data) return;
        dispatch(removePack(id));
        info("Pack Size has been archived!");
        setSelected({});
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const KEY_MAP = {
    Name: data.map((item) => item.name),
  };

  const resetSearch = () => {
    setSearch("");
  };

  const renderButtons = () => (
    <>
      <div className="mr-10">
        <Link
          to={{
            pathname: `/add-pack-size`,
          }}
          data-testid="new"
        >
          <ListButton label="New" />
        </Link>
      </div>
    </>
  );

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) => (
      <th
        key={key}
        style={sortHeaderStyle}
        className="px-5 text-nowrap"
        onClick={() => {
          setDirection(key !== field ? "desc" : direction === "desc" ? "asc" : "desc");
          setField(key);
        }}
      >
        <span style={{ cursor: "pointer" }}>{header}</span>
        {key === field && <TableSortLabel active direction={direction} />}
      </th>
    ));
  }

  return (
    <div className="row justify-content-center mt-10">
      <div className="col-12">
        {actionLoading && <Loader isOpen={actionLoading} maxWidth="xs" />}
        {confirmDeleteOpen && (
          <Modal
            isOpen={confirmDeleteOpen}
            submitable
            onClose={handleModalClose}
            maxWidth="sm"
            modalContent={
              <ConfirmDelete
                handleClose={handleModalClose}
                handleSubmit={handleDelete}
                id={selectedItem}
                name={(data.find(({ id }) => id === selectedItem) || {}).name}
              />
            }
          />
        )}
        <div>
          <SearchBar
            onSearch={(data) => handleSearch(data, searchMap, setSearch)}
            clearSearch={resetSearch}
            keyMap={KEY_MAP}
            placeholder="Filter..."
            elevation={2}
            chipBackgroundColor="#E8F5E1"
            chipColor="#255915"
          />
        </div>
        <div className="bg-white rounded py-7 px-10">
          <ListHeader title="Pack Sizes" renderButtons={renderButtons} />
          <Datagrid
            data={data}
            headings={HEADINGS}
            renderRow={renderPacksRow}
            renderHeaderWithSorting={renderHeaderWithSorting}
            selectable
            selected={selected}
            setSelected={setSelected}
            editable
            isNewTabLink
            deletable
            handleDelete={handleOpenModal}
            loading={loading}
            link="pack-sizes"
            paginationOptions={[50, 100, 200]}
            paginationDefaultValue={50}
          />
        </div>
      </div>
    </div>
  );
};
