/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ListButton } from "../../../../ui/components/ListButton";
import { Modal } from "../../../../ui/components/Modal";
import { Mapping } from "../../../../ui/structures/Mapping";
import { buttonStyle, itemStyle, callBackUrlMap } from "../constants";
import PowerIcon from "../static/PowerIcon.png";
import { SuccessModal } from "../SuccessModal";
import { useFetch } from "../../../../hooks/fetch.hook";

export const Power = ({
  handleConnect,
  handleDisconnect,
  tokenExpired = false,
  connectedComponent,
  setConnectedComponent,
  integrations,
  fulfillUser,
  user,
}) => {
  const connected = false;

  return (
    <>
      <div style={itemStyle} className={`${"bg-white"} p-7 mt-10`}>
        <div className="text-center mb-15">
          <img src={PowerIcon} alt="logo" />
        </div>
        <h3 className="mb-2 text-dark">
          <strong>PowerBI</strong>
        </h3>
        <div style={{fontSize:"14px"}}>Create meaningful data with Dashboards</div>
        <div className="mt-25">
          {tokenExpired ? (
            <button className={`${buttonStyle} text-primary`}>Reconnect PowerBI</button>
          ) : connected ? (
            <div className="d-flex justify-content-between align-items-center">
              <ListButton label="Configure PowerBI" size="small" />
              <button className={`${buttonStyle} text-danger`}>Disconnect</button>
            </div>
          ) : (
            <button className={`${buttonStyle} text-secondary`} disabled>Connect</button>
          )}
        </div>
      </div>
    </>
  );
};
