import React, { useState, useEffect } from "react";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import { Avatar, ListItemAvatar } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  formatDate,
  formatLongRow,
  modifyEmptyFields
} from "../../../ui/helpers";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import { FileUploadForm } from "../../../ui/structures/FileUploadForm/index";
import { SortSelect } from "../../../ui/components/SortSelect";
import { ListButton } from "../../../ui/components/ListButton";
import { downloadFile } from "../../modules/AWS-S3/download";
import { Loader } from "../../../ui/components/Loader";
import { Modal } from "../../../ui/components/Modal";
import { FileDetails } from "./FileDetails";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  createOrganisationFile,
  deleteFile,
  getFileById,
  getOrganisationFiles,
  modifyFile
} from "./_api";
import { info } from "../../../helpers/toasts";
import { ConfirmDelete } from "../../../ui/components/ConfirmDelete";
import { ArchiveCheckbox } from "../../../ui/components/ArchiveCheckbox";

const HEADINGS = [
  ["file_name", "Name"],
  ["category", "Category"]
];

const filterOptions = [
  { label: "Category", value: "category" },
  { label: "Expiry Date", value: "expiry_date" },
  { label: "File Format", value: "file_format" },
  { label: "User", value: "user" }
];

export function Files({ organisationName, organisationId, users = [] }) {
  const { request } = useFetch();

  const [initialData, setInitialData] = useState([]);
  const [files, setFiles] = useState([]);
  const perPage = 10;
  const [page, setPage] = useState(1);
  const [fileFormOpen, setFileFormOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("category");
  const [loading, setLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [componendId, setComponentId] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [archived, setArchived] = useState(false);
  const [archivedLoading, setArchivedLoading] = useState(false);

  const handleConfirmOpen = () => setConfirmDeleteOpen(true);
  const handleConfirmClose = () => setConfirmDeleteOpen(false);

  const handleSort = (data = []) => {
    return data.sort(function(a, b) {
      if (a[selectedFilter] > b[selectedFilter]) return 1;
      if (a[selectedFilter] < b[selectedFilter]) return -1;
      return 0;
    });
  };

  useEffect(() => {
    setLoading(true);
    request(getOrganisationFiles, organisationId, archived)
      .then(data => {
        if (!data) return;
        setFiles(handleSort(data));
        setInitialData(data);
      })
      .finally(() => {
        setLoading(false);
        setArchivedLoading(false);
      });
    // eslint-disable-next-line
  }, [archived]);

  const handleEditModalClose = () => setEditModal(false);
  const handleEditModalOpen = id => () => {
    setEditModal(true);
    setComponentId(id);
  };

  const handleOpenForm = () => setFileFormOpen(true);

  useEffect(() => {
    setFiles([...handleSort(files)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter]);

  const show = files.slice((page - 1) * perPage, page * perPage);

  const handleFileFormClose = () => setFileFormOpen(false);

  const handleSearch = (search, isRemoveKey) => {
    setFiles([
      ...(isRemoveKey ? initialData : files).filter(item => {
        const _search = Object.entries(search).map(item => [
          HEADINGS.find(i => i[1] === item[0])[0],
          item[1]
        ]);
        return (
          _search.length === 0 ||
          _search.every(([k, v]) => {
            if (Array.isArray(item[k])) {
              return item[k].includes(v);
            }
            return item[k] === v;
          })
        );
      })
    ]);
  };

  const resetSearch = () => {
    setLoading(true);
    request(getOrganisationFiles, organisationId)
      .then(data => {
        if (!data) return;
        setFiles(handleSort(data));
      })
      .finally(() => setLoading(false));
  };

  const KEY_MAP = {
    Name: files.map(item => item.file_name),
    Category: files.map(item => item.category)
  };

  const isExpired = expiryDate => {
    if (!expiryDate) return "";
    if (new Date(expiryDate) < new Date(Date.now())) {
      return "Expired";
    } else return "";
  };

  const renderStatus = status => {
    if (status === "archived")
      return <span className="text-uppercase text-danger">{status}</span>;
    else return <span className="text-uppercase ">{status}</span>;
  };

  const handleChangePage = (_, page) => {
    setPage(page);
  };

  const handleFilterChange = event => {
    setSelectedFilter(event.target.value);
  };

  const handleDownloadFile = id => () => {
    setLoading(true);
    downloadFile(organisationId, id, setLoading);
  };

  const updateCardData = (item = {}) => {
    setFiles && setFiles(state => [...state, item]);
  };

  const handleUpdateFile = (
    values,
    setSubmitting,
    uploadedFile,
    fileData,
    setLoading
  ) => {
    if (!uploadedFile) return;
    setLoading(true);
    modifyEmptyFields(values);
    request(modifyFile, values, (fileData || {}).id)
      .then(data => {
        if (!data) return;
        updateCardData(data);
        info("File has been uploaded!");
        handleFileFormClose();
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const openArchiveModal = id => {
    handleConfirmOpen();
    setSelectedItem(id);
  };

  const handleArchive = (id, setSubmitting) => {
    setSubmitting(true);
    setLoading(true);
    setConfirmDeleteOpen(false);
    request(deleteFile, id)
      .then(data => {
        if (!data) return;
        if (archived) {
          request(getFileById, id).then(data => {
            if (!data) return;
            const index = files.findIndex(file => (file || {}).id === id);
            if (index === -1) return;
            setFiles(state => [
              ...state.slice(0, index),
              data,
              ...state.slice(index + 1)
            ]);
          });
        } else {
          setFiles(state => state.filter(file => file.id !== id));
        }
        info("File has been archived!");
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  const getUserName = userId => {
    const user = users.find(({ id }) => id === userId);
    if (user) {
      return `by ${user?.name}`;
    } else return "";
  };

  return (
    <>
      <Loader
        isOpen={loading}
        maxWidth="xs"
        disableBackdropClick
        disableEscapeKeyDown
      />
      {confirmDeleteOpen && (
        <Modal
          isOpen={confirmDeleteOpen}
          submitable
          onClose={handleConfirmClose}
          maxWidth="sm"
          modalContent={
            <ConfirmDelete
              handleClose={handleConfirmClose}
              handleSubmit={handleArchive}
              id={selectedItem}
              name={
                (files.find(({ id }) => id === selectedItem) || {}).file_name
              }
            />
          }
        />
      )}
      {fileFormOpen && (
        <FileUploadForm
          handleClose={handleFileFormClose}
          handleSubmit={handleUpdateFile}
          isOpen={fileFormOpen}
          id={organisationId}
          setFiles={setFiles}
          files={files}
          apiCall={createOrganisationFile}
        />
      )}
      <Modal
        maxWidth={"md"}
        isOpen={editModal}
        submitable
        onClose={handleEditModalClose}
        modalContent={
          <FileDetails
            handleClose={handleEditModalClose}
            file={files.find(({ id }) => id === componendId)}
            setFiles={setFiles}
            files={files}
          />
        }
      />
      <div
        className={`bg-white position-relative w-100 rounded py-7 px-10 mb-5`}
      >
        <div className="mb-3 d-flex justify-content-between align-items-center">
          <h3>{`${organisationName} Files`}</h3>
          <ArchiveCheckbox
            archivedLoading={archivedLoading}
            archived={archived}
            showArchived={showArchived}
          />
        </div>
        <div className="d-flex justify-content-end mb-3">
          <SortSelect
            options={filterOptions}
            value={selectedFilter}
            onChange={handleFilterChange}
            label="Group By"
          />
        </div>
        <div className="mb-10">
          <SearchBar
            onSearch={handleSearch}
            clearSearch={resetSearch}
            keyMap={KEY_MAP}
            placeholder="Filter Files..."
            elevation={2}
            chipBackgroundColor="#E8F5E1"
            chipColor="#255915"
          />
        </div>
        {show.length ? (
          <table style={{ minWidth: "700px" }} className="w-100">
            <thead>
              <tr className="border-bottom text-muted">
                <th></th>
                <th>Title</th>
                <th>Filename</th>
                <th>Date</th>
                <th>Type</th>
                <th>Expiry Status</th>
                <th>Status</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {show.map(
                ({
                  file_name,
                  last_modified,
                  user_id,
                  category,
                  description,
                  expiry_date,
                  id,
                  status
                }) => (
                  <tr key={id} className="border-bottom">
                    <td className="py-2">
                      <ListItemAvatar>
                        <Avatar className="bg-white border-secondary border-x border-y text-success">
                          <DescriptionIcon />
                        </Avatar>
                      </ListItemAvatar>
                    </td>
                    <td>
                      {formatLongRow(description, 35)}{" "}
                      Uploaded {getUserName(user_id)} on{" "}
                      {formatDate(last_modified)}
                    </td>
                    <td>
                      <span
                        onClick={handleDownloadFile(id)}
                        className="text-primary font-weight-bold"
                        style={{ cursor: "pointer" }}
                      >
                        {file_name}
                      </span>
                    </td>
                    <td>{expiry_date ? formatDate(expiry_date) : ""}</td>
                    <td>{(category || "").toUpperCase()}</td>
                    <td>{isExpired(expiry_date)}</td>
                    <td>{renderStatus(status)}</td>
                    <td>
                      <span className="cursor-pointer">
                        <EditIcon onClick={handleEditModalOpen(id)} />
                      </span>
                    </td>
                    <td>
                      <span className="cursor-pointer ml-3">
                        <DeleteIcon onClick={() => openArchiveModal(id)} />
                      </span>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        ) : (
          <div className="text-center mt-16 mb-16">
            <h6 className="mb-5">No uploaded Files</h6>
          </div>
        )}
        <div className="d-flex justify-content-end mt-5">
          {Math.ceil(files.length / perPage) > 1 ? (
            <Pagination
              count={Math.ceil(files.length / perPage)}
              page={page}
              showFirstButton
              showLastButton
              classes={{ root: "text-primary" }}
              shape="rounded"
              onChange={handleChangePage}
            />
          ) : (
            <div />
          )}
        </div>
        <div className="text-center mt-10">
          <ListButton
            label="Upload File"
            size="large"
            className="px-10"
            onClick={handleOpenForm}
          />
        </div>
      </div>
    </>
  );
}
