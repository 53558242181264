import React from "react";
import { useHistory } from "react-router-dom";
import { ListButton } from "../../../ui/components/ListButton";
import { formatDate } from "../../../ui/helpers";

export const SelectTask = ({ tasks = [], handleClose }) => {
  const history = useHistory();
  const handleConfirm = (id) => {
    handleClose();
    history.push({
      pathname: "/tasks",
      taskID: id,
    });
  };
  return (
    <div className="px-4 pb-4">
      <h3 className="text-center mb-15">
        Multiple tasks are due to the selected date. Please select one from the list
      </h3>
      {tasks.map(({ id, task_name, date, task_type }) => (
        <div className="d-flex justify-content-between">
          <p className="w-25">{task_name}</p>
          <p className="w-25">{formatDate(new Date(date))}</p>
          <p className="w-25">
            {task_type ? task_type.charAt(0).toUpperCase() + task_type.slice(1) : ""}
          </p>
          <p>
            <ListButton label="Confirm" onClick={() => handleConfirm(id)} />
          </p>
        </div>
      ))}
    </div>
  );
};
