import React from "react";

export const EditableRow = ({ data = {}, colSpanValue = 5 }) => {
  const getTotalRowValue = (plastic, steel) => {
    return (Number(plastic) || 0) + (Number(steel) || 0);
  };

  const sizeStyle = { color: "#9e9e9e" };
  return (
    <>
      <tr style={{ borderBottom: "1px solid #c6ccc8" }}>
        <td colSpan={colSpanValue}></td>
        <td>
          <div className="d-flex justify-content-between">
            <div className="pl-5" style={sizeStyle}>
              &#60;20
            </div>
            <div className="pr-10"> {data["plastic_0"]}</div>
          </div>
        </td>
        <td>
          <div className="d-flex justify-content-between">
            <div className="pl-5" style={sizeStyle}>
              &#60;20
            </div>
            <div className="pr-10"> {data["steel_0"]}</div>
          </div>
        </td>
        <td className="pl-5">{getTotalRowValue(data["plastic_0"], data["steel_0"])}</td>
      </tr>
      <tr style={{ borderBottom: "1px solid #c6ccc8" }}>
        <td colSpan={colSpanValue}></td>
        <td>
          <div className="d-flex justify-content-between">
            <div className="pl-5" style={sizeStyle}>
              20-25
            </div>
            <div className="pr-10"> {data["plastic_1"]}</div>
          </div>
        </td>
        <td>
          <div className="d-flex justify-content-between">
            <div className="pl-5" style={sizeStyle}>
              20-25
            </div>
            <div className="pr-10"> {data["steel_1"]}</div>
          </div>
        </td>
        <td className="pl-5">{getTotalRowValue(data["plastic_1"], data["steel_1"])}</td>
      </tr>
      <tr style={{ borderBottom: "1px solid #c6ccc8" }}>
        <td colSpan={colSpanValue}></td>
        <td>
          <div className="d-flex justify-content-between">
            <div className="pl-5" style={sizeStyle}>
              26-120
            </div>
            <div className="pr-10"> {data["plastic_2"]}</div>
          </div>
        </td>
        <td>
          <div className="d-flex justify-content-between">
            <div className="pl-5" style={sizeStyle}>
              26-120
            </div>
            <div className="pr-10"> {data["steel_2"]}</div>
          </div>
        </td>
        <td className="pl-5">{getTotalRowValue(data["plastic_2"], data["steel_2"])}</td>
      </tr>
      <tr style={{ borderBottom: "1px solid #c6ccc8" }}>
        <td colSpan={colSpanValue}></td>
        <td>
          <div className="d-flex justify-content-between">
            <div className="pl-5" style={sizeStyle}>
              121-205
            </div>
            <div className="pr-10"> {data["plastic_3"]}</div>
          </div>
        </td>
        <td>
          <div className="d-flex justify-content-between">
            <div className="pl-5" style={sizeStyle}>
              121-205
            </div>
            <div className="pr-10"> {data["steel_3"]}</div>
          </div>
        </td>
        <td className="pl-5">{getTotalRowValue(data["plastic_3"], data["steel_3"])}</td>
      </tr>
    </>
  );
};
