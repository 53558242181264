import React from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

export const TradingNameSelect = ({
  onClose,
  handleSubmit,
  setTradingNameValue,
  tradingNamesList = [],
}) => {
  const handleChange = (_, value) => {
    setTradingNameValue(value);
  };

  return (
    <>
      <h4 className="mb-10 text-center">
        <strong>Select Business Name</strong>
      </h4>
      <div className="d-flex">
        <div className="w-100">
          <Autocomplete
            options={tradingNamesList}
            id="2"
            getOptionLabel={({ label }) => label}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Business Name..."
                variant="outlined"
                color="secondary"
                data-testid="edit-name"
              />
            )}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end mt-15 mb-5">
        <button onClick={onClose} className="btn btn-white mr-3">
          <strong>Cancel</strong>
        </button>
        <button
          onClick={handleSubmit}
          className=" py-3 px-5 text-uppercase text-primary bg-primary text-white border border-primary rounded"
        >
          <strong>Submit</strong>
        </button>
      </div>
    </>
  );
};
