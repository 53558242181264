import React, { useEffect, useRef } from "react";
import { useFormikContext } from "formik";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { MappedSwitch } from "../../../ui/components/MappedSwitch";
import { stateOptions } from "./constants";

const addressMap = {
  postal_address: "physical_address",
  postal_address2: "physical_address2",
  postal_city: "physical_city",
  postal_state: "physical_state",
  postal_postcode: "physical_postcode"
};

export const PostalAddress = ({ id, disabledField }) => {
  const firstUpdate = useRef(true);
  const { values, setFieldValue } = useFormikContext();

  const calculateValue = (fieldName, value) => (value ? values[fieldName] : "");

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    Object.keys(addressMap).forEach(fieldName => {
      setFieldValue(
        fieldName,
        calculateValue(addressMap[fieldName], values.as_physical)
      );
    });
    // eslint-disable-next-line
  }, [values.as_physical]);

  return (
    <div className="col-6">
      <div>
        <h5 className="mb-4">
          <strong>Postal Address</strong>
        </h5>
      </div>
      <MappedInput
        label="Address 1"
        name="postal_address"
        data-testid="postal_address"
        disabled={disabledField}
      />
      <MappedInput
        label="Address 2"
        name="postal_address2"
        data-testid="postal_address2"
        disabled={disabledField}
      />
      <MappedInput
        label="City"
        name="postal_city"
        data-testid="postal_city"
        disabled={disabledField}
      />
      <div className="row">
        <div className="col-6">
          <MappedSelect
            label="State"
            name="postal_state"
            data-testid="postal_state"
            options={stateOptions}
            disabled={disabledField}
          />
        </div>
        <div className="col-6">
          <MappedInput
            label="Postal Code"
            name="postal_postcode"
            data-testid="postal_postcode"
            disabled={disabledField}
          />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end mt-3">
        <p className="mr-2 mt-3">Same as physical</p>
        <MappedSwitch
          name="as_physical"
          checked={values.as_physical}
          disabled={disabledField}
        />
      </div>
    </div>
  );
};
