export const AVAILABLE_ROLES = {
  NATIONAL_ADMIN: "NATIONAL_ADMIN",
  AGSAFE_ADMIN: "AGSAFE_ADMIN",
  AGSAFE_STAFF: "AGSAFE_STAFF",
  RC_ACCESS: "RC_ACCESS",
  CA_ACCESS: "CA_ACCESS",
  END_USER: "END_USER",
  PROCESSOR: "PROCESSOR"
};

export const sortHeaderStyle = {
  color: "#9e9e9e",
  fontWeight: 500,
  fontSize: "14px",
  padding: "0 1.25rem 1rem",
  whiteSpace: "nowrap",
  textAlign: "left",
};
