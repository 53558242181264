import config from "react-global-configuration";

export const addConsultant = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/consultants`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getConsultants = (search = "", archived = "", field, direction) =>
  fetch(
    `${config.get("ApiUrl").Rest}/consultants?${search &&
      "&" + search}${(archived && "&status=all") || ""}${(field &&
      "&order_by=" + field) ||
      ""}${(direction && "&order_dir=" + direction) || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getConsultantById = (consultant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/consultants/${consultant_id}`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyConsultant = (data, consultant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/consultants/${consultant_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const deleteConsultant = (consultant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/consultants/${consultant_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const getConsultantsAutocomplete = (
  page_size = 80,
  autocompleteField,
  autocompleteText = "",
  search
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/consultants?&page_size=${page_size}${(autocompleteField &&
      "&distinct=" +
        autocompleteField +
        "&" +
        autocompleteField +
        "=_like(" +
        autocompleteText +
        ")_") ||
      ""}${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getConstultantRegions = (consultant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/consultants/${consultant_id}/regions`, {
    mode: "cors",
    credentials: "include",
  });

export const linkRegionToContact = (consultant_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/consultants/${consultant_id}/regions`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });
