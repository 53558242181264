import React, { useState } from "react";
import { useFormikContext } from "formik";
import { CircularProgressbar } from "react-circular-progressbar";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { Capacity } from "./Capacity";
import { booleanOptions, surfaceTypeOptions } from "./constants";
import { ListButton } from "../../../ui/components/ListButton";
import { WebPreview } from "./WebPreview";
import { Modal } from "../../../ui/components/Modal";
import "react-circular-progressbar/dist/styles.css";

export const DrumMuster = ({
  name = "",
  containersAmount = 0,
  site,
  siteStatistic,
  entryStatistic = 0
}) => {
  const { values } = useFormikContext();
  const [modalOpen, setModalOpen] = useState(false);

  const hanldeModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const calculatePercentage = () => {
    const capacity = values.dm_width * values.dm_length * values.dm_height * 20;
    if (capacity <= 0) return 0;
    return ((containersAmount * 100) / capacity).toFixed(2);
  };

  return (
    <div className="border p-5">
      {
        <Modal
          isOpen={modalOpen}
          submitable
          onClose={handleModalClose}
          maxWidth="md"
          modalContent={
            <WebPreview
              onClose={handleModalClose}
              site={site}
              siteStatistic={siteStatistic}
            />
          }
        />
      }
      <div className="mb-5 w-75 d-flex justify-content-between">
        <h4>{name} Details</h4>
        <h4 className="mb-5">Site Statistics</h4>
      </div>
      <div className="row justify-content-between">
        <div className="col-6">
          <MappedInput
            label="Public Website General Description"
            name="public_website_general_description"
            multiline
            rows={19}
          />
          <div className="d-flex justify-content-between align-items-center">
            <div style={{ color: "#64A70B", fontWeight: 500 }}>
              Annual Deliveries (Last 12 Month) :{" "}
              <span style={{ fontWeight: 600 }}>{entryStatistic}</span>
            </div>
            <ListButton
              label="Web Preview"
              size="large"
              onClick={hanldeModalOpen}
            />
          </div>
        </div>
        <div className="col-2">
          <MappedInput label="Width" name="dm_width" />
          <MappedInput label="Length" name="dm_length" />
          <MappedInput label="Height" name="dm_height" />
          <Capacity />
          <MappedSelect
            label="DM Surface Type"
            name="dm_type"
            options={surfaceTypeOptions}
          />
          <MappedSelect
            label="Dry Weather Access"
            name="dm_dry_weather_access"
            options={booleanOptions}
          />
        </div>
        <div className="col-4 border d-flex flex-column">
          <h4 className="mt-7 ml-7">
            <strong>Capacity</strong>
          </h4>
          <div style={{ width: 250 }} className="align-self-center">
            <CircularProgressbar
              value={calculatePercentage()}
              text={`${calculatePercentage()} %`}
              styles={{
                path: {
                  stroke: "#62BA09"
                },
                text: {
                  fill: "#62BA09",
                  fontSize: "16px"
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
