import config from "react-global-configuration";

export const getUser = () =>
  fetch(`${config.get("ApiUrl").Rest}/users/me`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyCurrentUser = (userData) =>
  fetch(`${config.get("ApiUrl").Rest}/users/me`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userData),
    mode: "cors",
    credentials: "include",
  });

export const requestCall = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/request-call`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const DBWakeUp = () =>
  fetch(`${config.get("ApiUrl").Rest}/wake-up`, {
    mode: "cors",
    credentials: "include",
  });

export const getBackendVersion = () =>
  fetch(`${config.get("ApiUrl").Rest}/version`, {
    mode: "cors",
    credentials: "include",
  });
