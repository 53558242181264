import React from "react";
import { Formik } from "formik";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { Auth } from "aws-amplify";
import ButtonGroup from "@bit/the-glue.frontendcomponents.button-group";
import { error, info } from "../../../helpers/toasts";
import { confirmPasswordSchema } from "../../../helpers/schemas";

const initialValues = {
  code: "",
  new_password: "",
};

export const ConfirmPassword = ({ handleClose, username, setStep }) => {
  const handleSubmit = (values, { setSubmitting }) => {
    Auth.forgotPasswordSubmit(username, values.code, values.new_password)
      .then((data) => {
        setStep("reset");
        handleClose();
        info("User information has been updated!");
      })
      .catch((err) => error(err.message))
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={confirmPasswordSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <div className="px-5 py-10">
          <h3 className="mb-5">Reset password</h3>
          <div>
            <MappedInput name="code" label="Code" />
          </div>

          <div>
            <MappedInput name="new_password" label="New Password" />
          </div>

          <div className="mt-10">
            <ButtonGroup
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              textTransofrm="uppercase"
              size="small"
            />
          </div>
        </div>
      )}
    </Formik>
  );
};
