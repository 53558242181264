/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Tabs from "@bit/the-glue.frontendcomponents.tabs";
import Skeleton from "@material-ui/lab/Skeleton";
import { getProduct } from "./_redux/selectors";
import { useFetch } from "../../../hooks/fetch.hook";
import { ProductDetails } from "./ProductDetails";
import { PackSizes } from "./PackSizes";
import { getProductById } from "./_api";
import { geProducttPackSizes, getPackSizes } from "../Settings/PackSizes/_api";

export const Card = ({
  match: {
    params: { id },
  },
}) => {
  const { request } = useFetch();

  const data = useSelector(getProduct(id));

  const [cardData, setCardData] = useState({});
  const [packSizes, setPackSizes] = useState([]);
  const [productPackSizes, setProductPackSizes] = useState([]);

  const { name } = cardData || "";

  useEffect(() => {
    if (!data && id) {
      request(getProductById, id).then((data) => data && setCardData(data));
    } else setCardData(data);
  }, [id]);

  useEffect(() => {
    request(getPackSizes).then((data) => data && setPackSizes(data));
    request(geProducttPackSizes, id).then(
      (data) => data && setProductPackSizes(data)
    );
  }, []);

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  const tabs = [
    {
      label: "Profile",
      tabPanel: <ProductDetails productName={name} data={cardData} id={id} />,
    },
    {
      label: "Pack Sizes",
      tabPanel: (
        <PackSizes
          name={name}
          data={cardData}
          id={id}
          packSizes={packSizes}
          productPackSizes={productPackSizes}
          setProductPackSizes={setProductPackSizes}
        />
      ),
    },
  ];

  return (
    <div className="mt-10">
      <Tabs
        defaultActive="0"
        tabs={tabs}
        align="right"
        text="rgba(241, 251, 243, 0.7)"
        selectedTabColor="white"
        selectedColor="#407A28"
      />
    </div>
  );
};
