/* eslint-disable no-unused-vars */
import React from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { ListButton } from "../../../ui/components/ListButton";
import { productDetailsSchema } from "../../../helpers/schemas";
import { useFetch } from "../../../hooks/fetch.hook";
import { AutocompleteMultiple } from "../../../ui/components/AutocompleteMultiple";
import { stateOptions } from "../../modules/Organisations/constants";

export const Scheduling = () => {
  const history = useHistory();
  const { request } = useFetch();

  const initialValues = {
    regions: [],
    collections: "",
    total_volume: "",
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
  };

  return (
    <div className="border border-secondary bg-white p-10">
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={productDetailsSchema}
      >
        {({ handleSubmit, isSubmitting, setFieldValue }) => (
          <>
            <h3 className="mb-5">
              <strong>{"Scheduling"}</strong>
            </h3>
            <div className="row justify-content-between">
              <div className="col-8">
                <div className="my-5">
                  <AutocompleteMultiple
                    name="organisation_id"
                    placeholder="Regions"
                    setValue={setFieldValue}
                    options={stateOptions}
                    defaultValueField="value"
                  />
                </div>
                <MappedInput
                  label="Number of Collections"
                  name="collections"
                  data-testid="collections"
                />
                <MappedInput label="Total Volume" name="total_volume" data-testid="total_volume" />
              </div>
            </div>

            <div className="mt-10 text-right">
              <ListButton
                label="Plan Route"
                size="medium"
                className="px-12"
                boxShadow={false}
                onClick={handleSubmit}
                disabled={isSubmitting}
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
