import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { Autocomplete } from "../../../ui/components/Autocomplete";
import { useState } from "react";

export const LinkEntity = ({ organisations = [], sites = [], contacts = [] }) => {
  const { values, setFieldValue } = useFormikContext();
  const [resetKey, setResetKey] = useState(0);

  useEffect(() => {
    if (values.flag === "sites") {
      setFieldValue("organisation_id", "");
      setFieldValue("contact_id", "");
    } else if (values.flag === "organisations") {
      setFieldValue("site_id", "");
      setFieldValue("contact_id", "");
    } else if (values.flag === "contacts") {
      setFieldValue("organisation_id", "");
      setFieldValue("site_id", "");
    }
    setResetKey(Date.now());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.flag]);
  
  return (
    <div className="col-6" key={resetKey}>
      {values.flag === "organisations" ? (
        <Autocomplete
          id="grouped-demo"
          className="w-100"
          size="medium"
          setValue={setFieldValue}
          name="organisation_id"
          defaultValueField="value"
          options={organisations}
          defaultValue={values.organisation_id || null}
        />
      ) : values.flag === "sites" ? (
        <Autocomplete
          id="grouped-demo"
          className="w-100"
          size="medium"
          options={sites}
          setValue={setFieldValue}
          name="site_id"
          defaultValueField="value"
          defaultValue={values.site_id || null}
        />
      ) : values.flag === "contacts" ? (
        <Autocomplete
          id="grouped-demo"
          className="w-100"
          size="medium"
          options={contacts}
          name="contact_id"
          defaultValueField="value"
          defaultValue={values.contact_id || null}
          setValue={setFieldValue}
        />
      ) : (
        ""
      )}
    </div>
  );
};
