/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { buttonStyle, itemStyle } from "../constants";
import QuickbooksIcon from "../static/QuickbooksIcon.png";
import { useFetch } from "../../../../hooks/fetch.hook";
import {
  createIntegration,
  deleteIntegration,
  getIntegration,
  getIntegrationByID,
  modifyIntegration,
  navigateQiuckBooksIntegration
} from "../_api";
import { Loader } from "../../../../ui/components/Loader";
import { QuickbooksConfig } from "./QuickbooksConfig";
import { Modal } from "../../../../ui/components/Modal";
import { info } from "../../../../helpers/toasts";
import { Skeleton } from "@material-ui/lab";
import { useHistory } from "react-router-dom";

export const QuickBooks = props => {
  const { request } = useFetch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [tileLoading, setTileLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentIntegration, setCurrentIntegration] = useState({});
  const [status, setStatus] = useState("inactive");
  const [resetKey, setResetKey] = useState(1);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");
    const realmId = urlParams.get("realmId");
    if (!code || !realmId) return;
    setLoading(true);
    request(createIntegration, {
      integration_type: "quickbooks",
      code: code,
      realm_id: realmId
    }).then(data => {
      if (!data) return;
      setLoading(false);
      setResetKey(Date.now());
      history.push("/integrations");
    });
  }, []);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");
    if (code) return;
    setTileLoading(true);
    request(
      getIntegration,
      `integration_type=quickbooks&status=active&status=need_config`
    ).then(data => {
      if (!data) {
        setTileLoading(false);
        return;
      }
      if (!data.length) {
        setTileLoading(false);
        return;
      }
      const currentIntegration = (data || [])[0] || {};
      setStatus(currentIntegration.status);
      request(getIntegrationByID, currentIntegration.id).then(data => {
        if (!data) {
          setTileLoading(false);
          return;
        }
        setCurrentIntegration(data);
        setTileLoading(false);
      });
    });
  }, [resetKey]);

  const handleNavigate = () => {
    setLoading(true);
    request(navigateQiuckBooksIntegration).then(data => {
      if (!data) {
        setLoading(false);
        return;
      }
      const link = document.createElement("a");
      link.href = `https://appcenter.intuit.com/connect/oauth2?response_type=code&scope=com.intuit.quickbooks.accounting&state=STATE&client_id=${
        data.client_id
      }&redirect_uri=${encodeURIComponent(data.redirect_uri)}`;
      link.setAttribute("rel", "noopener noreferrer");
      link.click();
      setLoading(false);
    });
  };

  const handleConfigSubmit = (values, { setSubmitting }) => {
    const payload = {
      user_config: values,
      status: "active"
    };
    handleModalClose();
    setLoading(true);
    request(modifyIntegration, currentIntegration.id, payload)
      .then(data => {
        if (!data) return;
        setCurrentIntegration(state => ({
          ...state,
          user_config: data.user_config
        }));
        setStatus("active");
        info("Integration has been configured!");
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  const handleDisconnect = () => {
    setLoading(true);
    request(deleteIntegration, currentIntegration.id)
      .then(data => {
        if (!data) return;
        info("Integration has been removed!");
        setStatus("inactive");
      })
      .finally(() => setLoading(false));
  };

  const getStatusColor = () => {
    if (status === "active") {
      return "#7CB342";
    } else if (status === "inactive") return "#F44336";
    else return "#FF9800";
  };

  return (
    <>
      <Modal
        isOpen={modalOpen}
        submitable
        onClose={handleModalClose}
        maxWidth="md"
        className="ml-2"
        modalContent={
          <QuickbooksConfig
            handleClose={handleModalClose}
            handleSubmit={handleConfigSubmit}
            configData={currentIntegration}
          />
        }
      />
      <Loader isOpen={loading} maxWidth="xs" />
      {tileLoading ? (
        <div style={itemStyle} className=" p-7 mt-10">
          <Skeleton variant="rect" height={270} />
        </div>
      ) : (
        <div style={itemStyle} className="bg-white p-7 mt-10">
          <div className="text-center mb-20">
            <img src={QuickbooksIcon} alt="logo" />
          </div>
          <h3 className="mb-2 text-dark">
            <strong>Quickbooks</strong>
          </h3>
          <div style={{ fontSize: "14px" }}>
            Add you Finance and Accounting Platform for even greater visibility
            of your operations.
          </div>
          <div className="mt-25 d-flex justify-content-between align-items-center">
            {status === "inactive" ? (
              <button
                className={`${buttonStyle} text-primary`}
                onClick={handleNavigate}
              >
                Connect
              </button>
            ) : null}
            {status === "need_config" || status === "active" ? (
              <button
                className={`${buttonStyle} text-primary`}
                onClick={handleModalOpen}
              >
                Configure
              </button>
            ) : null}
            {status === "active" ? (
              <button
                className={`${buttonStyle} text-danger`}
                onClick={handleDisconnect}
              >
                Disconnect
              </button>
            ) : null}
            <div className="text-uppercase d-flex align-items-center">
              <div
                style={{
                  background: getStatusColor(),
                  width: 20,
                  height: 20,
                  borderRadius: "50%",
                  margin: "0 auto"
                }}
              ></div>
              <div className="ml-3"> {status.split("_").join(" ")}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
