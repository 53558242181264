/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { HEADINGS } from "./constants";
import { modifyUsers } from "../../modules/Users/helpers";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { ListButton } from "../../../ui/components/ListButton";
import { Modal } from "../../../ui/components/Modal";
import { UsersList } from "./UsersList";
import { useFetch } from "../../../hooks/fetch.hook";
import { getUsers } from "../../modules/Users/_api";
import { getOrganisationUsers, unlinkUsers } from "../../modules/Organisations/_api";
import { renderUsersRow } from "./helpers";
import { Loader } from "../../../ui/components/Loader";
import { AccessControl } from "../../../ui/structures/AccessControl";
import { AVAILABLE_ROLES } from "../../constants";

export const OrganisationUsers = ({ organisationId }) => {
  const { request } = useFetch();

  const [usersList, setUsersList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const [linkedUsers, setLinkedUsers] = useState([]);

  const handleModalOpen = () => setModalOpen(true);
  const handleClose = () => {
    setSelected({});
    setModalOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    request(getOrganisationUsers, organisationId)
      .then((users) => {
        if (!users) return;
        setLinkedUsers(modifyUsers(users));
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    request(getUsers).then((data) => data && setUsersList(modifyUsers(data)));
    // eslint-disable-next-line
  }, []);

  const getNonLinkedUsers = () => {
    const arrayOfIds = linkedUsers.map((item) => item.id);
    return usersList.filter((item) => !arrayOfIds.includes(item.id));
  };

  const renderButtons = () => (
    <>
      <div className="mr-5">
        <ListButton label="Add" onClick={handleModalOpen} data-testid="add" />
      </div>
      <AccessControl
        desiredRoles={[
          AVAILABLE_ROLES.NATIONAL_ADMIN,
          AVAILABLE_ROLES.AGSAFE_ADMIN,
          AVAILABLE_ROLES.AGSAFE_STAFF,
        ]}
      >
        <ListButton
          label="Remove Users"
          onClick={removeUser}
          disabled={unlinkDisabled}
          data-testid="remove"
        />
      </AccessControl>
    </>
  );

  const updateUsersList = () => {
    setLoading(true);
    return request(getOrganisationUsers, organisationId)
      .then((data) => {
        if (!data) return;
        setLinkedUsers(modifyUsers(data));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeUser = () => {
    setLoading(true);
    const payload = Object.keys(selected)
      .filter((item) => Boolean(selected[item]))
      .map((item) => ({
        id: item,
      }));
    request(unlinkUsers, payload, organisationId).then((data) => {
      if (!data) {
        setLoading(false);
        return;
      }
      updateUsersList();
      setSelected({});
    });
  };

  const unlinkDisabled = useMemo(() => Object.values(selected).filter(Boolean).length < 1, [
    selected,
  ]);

  return (
    <>
      {loading && <Loader isOpen={loading} maxWidth="xs" />}
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          submitable
          onClose={handleClose}
          modalContent={
            <UsersList
              handleClose={handleClose}
              data={getNonLinkedUsers()}
              organisationId={organisationId}
              setUsersList={setUsersList}
              updateUsersList={updateUsersList}
              setLoading={setLoading}
              loading={loading}
            />
          }
        />
      )}
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <ListHeader title="Users" renderButtons={renderButtons} />
            <Datagrid
              data={linkedUsers}
              headings={HEADINGS}
              renderRow={renderUsersRow}
              selected={selected}
              setSelected={setSelected}
              selectable
            />
          </div>
        </div>
      </div>
    </>
  );
};
