const type = "users";

export const setUsersList = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      usersList: payload,
    };
  },
  type,
});

export const removeUser = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      usersList: state.usersList.filter(({ id }) => id !== payload),
    };
  },
  type,
});

export const updateUserList = (payload) => ({
  execute: (state) => {
    const index = state.usersList.findIndex((item) => item.id === payload.id);
    const myState = [...state.usersList];
    myState.splice(index, 1, payload);
    return {
      ...state,
      usersList: [...myState],
    };
  },
  type,
});
