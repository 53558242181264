export const reimbursementsTypes = {
  SET_DATA: "REIMBURSEMENTS/SET_DATA",
  REMOVE_DATA: "REIMBURSEMENTS/REMOVE_DATA",
  UPDATE_DATA: "REIMBURSEMENTS/UPDATE_DATA",
};

export function setReimbursementsList(data) {
  return {
    type: reimbursementsTypes.SET_DATA,
    payload: data,
  };
}

export function removeReimbursement(data) {
  return {
    type: reimbursementsTypes.REMOVE_DATA,
    payload: data,
  };
}

export function updateReimbursementsList(data) {
  return {
    type: reimbursementsTypes.UPDATE_DATA,
    payload: data,
  };
}
