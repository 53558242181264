import React, { useState, useEffect, useMemo } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { useFetch } from "../../../hooks/fetch.hook";
import { getReviewReimbursements } from "../DrumMuster/Inspections/_api";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { renderReimbursementsRow } from "./helpers";
import { ListButton } from "../../../ui/components/ListButton";
import {
  calculateInvoiceTotalGST,
  modifyReimbursementsArray,
} from "../ReimbursementsApprovals/helpers";
import { getSelectedId, handleSearch } from "../../../ui/helpers";
import {
  exportReimbursements,
  printReimbursements,
} from "../ReimbursementsApprovals/_api";
import { Loader } from "../../../ui/components/Loader";
import { downloadFile } from "../AWS-S3/download";
import { HEADINGS, searchMap } from "./constants";
import { useHistory } from "react-router-dom";
import { recalculateReimbursement } from "./_api";
import { info } from "../../../helpers/toasts";

export const List = () => {
  const { request } = useFetch();
  const history = useHistory();

  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [focused, setFocused] = useState(false);
  const [data, setData] = useState([]);
  const [actionLoading, setActionLoading] = useState(false);

  const getStatusValue = (search = "") => {
    if (search.includes("status=waiting_for_invoice")) {
      return "&status=waiting_for_invoice";
    } else if (search.includes("status=admin_review")) {
      return "&status=admin_review";
    } else return "&status=waiting_for_invoice&status=admin_review";
  };

  const fetchReimbursements = () => {
    setLoading(true);
    const status = getStatusValue(search);
    return request(getReviewReimbursements, status, search)
      .then((data) => data && setData(modifyReimbursementsArray(data)))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchReimbursements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const KEY_MAP = {
    "Claim Payee Organisation": data.map((item) => item.organisation_name),
    Type: data.map((item) => item.type),
    Status: data.map((item) => (item.status || "").split(" ").join("_")),
    Grouped: data.map((item) => item.grouped_search),
    Invoice: data.map((item) => item?.invoice?.code),
  };

  const resetSearch = () => setSearch("");

  const handleExpand = (id) => () => {
    history.push(`/reconciliation/${id}`);
  };

  const handlePrint = () => {
    setActionLoading(true);
    downloadFile(null, null, setActionLoading, printReimbursements);
  };

  const handleExport = () => {
    setActionLoading(true);
    downloadFile(null, null, setActionLoading, exportReimbursements);
  };

  const handleDataUpdate = (record = {}) => {
    const index = data.findIndex(
      (item) => (item || {}).id === (record || {}).id
    );
    const item = data.find((item) => item?.id === record?.id) || {};
    if (index === -1) return;
    const payload = {
      ...item,
      total_value: record.total_value,
      total_value_gst: calculateInvoiceTotalGST(record),
    };
    setData((state) => [
      ...state.slice(0, index),
      payload,
      ...state.slice(index + 1),
    ]);
  };

  const handleCalculate = () => {
    const itemID = getSelectedId(selected);
    if (!itemID) return;
    setActionLoading(true);
    request(recalculateReimbursement, itemID)
      .then((data) => {
        if (!data) return;
        handleDataUpdate(data);
        info("Value has been recalculated!");
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const recalculateDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const renderButtons = () => {
    return (
      <>
        <ListButton
          label="Recalculate Value"
          size="medium"
          className="mr-3"
          onClick={handleCalculate}
          disabled={recalculateDisabled}
          data-testid="recalculate"
        />
        <ListButton
          label="Print Report"
          size="medium"
          className="mr-3"
          onClick={handlePrint}
          data-testid="print-report"
        />
        <ListButton
          label="Export"
          size="medium"
          onClick={handleExport}
          data-testid="export"
        />
      </>
    );
  };

  return (
    <>
      <Loader
        isOpen={actionLoading}
        maxWidth="xs"
        disableBackdropClick
        disableEscapeKeyDown
      />
      <div className="row justify-content-center mt-10">
        <div className="col-12">
          <div>
            <SearchBar
              onSearch={(data) => handleSearch(data, searchMap, setSearch)}
              clearSearch={resetSearch}
              keyMap={KEY_MAP}
              placeholder="Filter..."
              elevation={2}
              chipBackgroundColor="#E8F5E1"
              chipColor="#255915"
              focused={focused}
              setFocused={setFocused}
            />
          </div>
          <div className="bg-white rounded py-7 px-10">
            <ListHeader title="Review List" renderButtons={renderButtons} />
            <Datagrid
              data={data}
              headings={HEADINGS}
              renderRow={renderReimbursementsRow}
              selectable
              selected={selected}
              setSelected={setSelected}
              loading={loading}
              expandable
              handleExpand={handleExpand}
              expandHeader="More"
              expandIcon={
                <ArrowRightAltIcon className="ml-5" fontSize="large" />
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};
