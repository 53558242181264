import React from "react";
import WarningIcon from "@material-ui/icons/Warning";
import { ListButton } from "../../../ui/components/ListButton";

export const DublicateEmail = ({ onClose, handleSubmit }) => {
  return (
    <div className="text-center">
      <div className="mb-5">
        <WarningIcon fontSize="large" color="action" />
      </div>
      <h3 className="text-danger mb-2">Contact with entered email already exists!</h3>
      <div>You can edit existing contact or continue</div>
      <div className="mt-10 d-flex justify-content-end mr-4 pb-5">
        <div className="mr-3">
          <ListButton
            label="Continue"
            size="large"
            onClick={onClose}
            data-testid="cancel"
            variant="outlined"
            boxShadow={false}
            text="#407A28"
          />
        </div>
        <div>
          <ListButton label="Edit" size="large" onClick={handleSubmit} boxShadow={false} />
        </div>
      </div>
    </div>
  );
};
