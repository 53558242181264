/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Tabs from "@bit/the-glue.frontendcomponents.tabs";
import { Clearances } from "./Clearances";
import { CompletedClearances } from "./CompletedClearances";
import { ProcessorRoutes } from "./ProcessorRoutes";
import { useFetch } from "../../../hooks/fetch.hook";
import { getProcessors } from "../Organisations/_api";
import { getSitesSimple } from "../Sites/_api";
export const Processing = () => {
  const { request } = useFetch();

  const [processors, setProcessors] = useState([]);
  const [processorsLoading, setProcessorsLoading] = useState(false);
  const [sites, setSites] = useState([]);
  const [sitesLoading, setSitesLoading] = useState(false);

  useEffect(() => {
    setProcessorsLoading(true);
    request(getProcessors)
      .then(data => data && setProcessors(data))
      .finally(() => setProcessorsLoading(false));
  }, []);

  useEffect(() => {
    setSitesLoading(true);
    request(
      getSitesSimple,
      `dm_program_participation=true&simple=true&fields=name,id`
    )
      .then(data => {
        data && setSites(data);
      })
      .finally(() => setSitesLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs = [
    {
      label: "Route Lists",
      tabPanel: <ProcessorRoutes />
    },
    {
      label: "Clearances",
      tabPanel: (
        <Clearances
          processors={processors}
          processorsLoading={processorsLoading}
          sites={sites}
          sitesLoading={sitesLoading}
        />
      )
    },
    {
      label: "Completed",
      tabPanel: <CompletedClearances />
    }
  ];
  return (
    <div className="mt-10">
      <Tabs
        defaultActive="0"
        tabs={tabs}
        align="right"
        text="rgba(241, 251, 243, 0.7)"
        selectedTabColor="white"
        selectedColor="#407A28"
      />
    </div>
  );
};
