const type = "prices";

export const setPricesList = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      pricesList: payload,
    };
  },
  type,
});

export const removePrice = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      pricesList: state.pricesList.filter(({ id }) => id !== payload),
    };
  },
  type,
});

export const unArchivePrice = (payload) => ({
  execute: (state) => {
    const index = state.pricesList.findIndex((item) => item.id === payload.id);
    const myState = [...state.pricesList];
    myState.splice(index, 1, payload);
    return {
      ...state,
      pricesList: [...myState],
    };
  },
  type,
});
