export const itemStyle = {
  boxShadow:
    "0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)",
  width: "32%",
};

export const buttonStyle = "text-uppercase border-0 bg-transparent font-weight-bold";

export const mockedMapData = [
  {
    title: "Sales Revenue",
    options: [
      { value: "sales", label: "400 - Sales" },
      { value: "postage", label: "624 - Postage & Delivery" },
      { value: "discounst", label: "411 - Coupon Discounts" },
    ],
  },
  {
    title: "GST",
    options: [
      { value: "sales", label: "400 - Sales" },
      { value: "postage", label: "624 - Postage & Delivery" },
      { value: "discounst", label: "411 - Coupon Discounts" },
    ],
  },
  {
    title: "Payments",
    options: [
      { value: "sales", label: "400 - Sales" },
      { value: "postage", label: "624 - Postage & Delivery" },
      { value: "discounst", label: "411 - Coupon Discounts" },
    ],
  },
  {
    title: "Merchant Fees",
    options: [
      { value: "sales", label: "400 - Sales" },
      { value: "postage", label: "624 - Postage & Delivery" },
      { value: "discounst", label: "411 - Coupon Discounts" },
    ],
  },
];

export const mockedDefaultValues = {
  "Sales Revenue": "sales",
  GST: "postage",
  Payments: "discounst",
  "Merchant Fees": "sales",
};

export const mockedAccounts = [
  {
    account_type: "Revenue",
    code: 410,
    name: '"On sale" Discounts',
    description: "Discounts taken from placing products on Sale in Squarespace Commmerce",
    is_missing: true,
  },
  {
    account_type: "Revenue",
    code: 411,
    name: "Coupon Discounts",
    description: "Discounts taken from applying coupons to orders in  Squarespace Commmerce",
    is_missing: true,
  },
  {
    account_type: "Current Asset",
    code: 410,
    name: '"On sale" Discounts',
    description: "Assets from placing products on Sale in Squarespace Commmerce",
    is_missing: true,
  },
];

export const callBackUrlMap = {
  "localhost.dev.agsafe.org.au": "https://localhost.dev.agsafe.org.au:3000/integrations",
  "dev.agsafe.org.au": "https://dev.agsafe.org.au/integrations",
  "beta.agsafe.org.au": "https://beta.agsafe.org.au/integrations",
};

export const getXeroAuthUrl = (userId, clientId, callback) =>
  `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${clientId}&redirect_uri=${callback}&scope=openid profile email accounting.transactions offline_access accounting.contacts.read accounting.settings.read&state=${userId}`;

export const mockedTokenResponse = {
  access_token: "eyJhbGciOXXXXAyOEQ2NzI2RkQwMjYxNTgxNTcwRUZDMTkiLCJ0",
  expires_in: 1800,
  id_token: "eyJhbGciOXXXXDZEQzAyOEQ2NzI2RkQwMjYxNTgxNTcwRUZDMTkiLCJ",
  refresh_token: "00a2de822d08b29d0AAAAAAAAAe6d20c71333880",
  token_type: "Bearer",
};
