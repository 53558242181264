/* eslint-disable react-hooks/exhaustive-deps */
import { Skeleton } from "@material-ui/lab";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
} from "react-google-maps";
import { error } from "../../../helpers/toasts";

export const SuggestedRoute = withScriptjs(
  withGoogleMap(
    ({
      processorAddress,
      sites = [],
      setLegs,
      selectedRun,
      setSitesWithCoordinates,
    }) => {
      const google = window.google;
      const geocoder = new google.maps.Geocoder();
      const directionsService = new google.maps.DirectionsService();

      const [processorCoordinates, setProcessorCoorditanes] = useState({});
      const [directions, setDirections] = useState(null);
      const [locations, setLocations] = useState([]);

      const setWaypoints = (sites = []) => {
        return sites.map(({ address, ...site }) => {
          return geocoder.geocode({ address }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              const latitude = results[0].geometry.location.lat();
              const longitude = results[0].geometry.location.lng();
              if (!latitude || !longitude) return;
              setLocations((state) => {
                const existingLocation = state.find(
                  ({ location }) =>
                    location.lat === latitude && location.lng === longitude
                );
                if (existingLocation) return state;
                return [
                  ...state,
                  {
                    location: { lat: latitude, lng: longitude },
                    stopover: true,
                  },
                ];
              });
              setSitesWithCoordinates((state) => [
                ...state,
                {
                  ...site,
                  address,
                  lat: latitude,
                  lng: longitude,
                },
              ]);
            }
          });
        });
      };

      useEffect(() => {
        setWaypoints(sites);
      }, [selectedRun]);

      useEffect(() => {
        if (!processorAddress || !locations.length) return;
        geocoder.geocode({ address: processorAddress }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            const latitude = results[0].geometry.location.lat();
            const longitude = results[0].geometry.location.lng();
            if (!latitude || !longitude) return;
            setProcessorCoorditanes({ lat: latitude, lng: longitude });
            directionsService.route(
              {
                origin: { lat: latitude, lng: longitude },
                destination: { lat: latitude, lng: longitude },
                travelMode: google.maps.TravelMode.DRIVING,
                waypoints: locations,
                optimizeWaypoints: true,
              },
              (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                  setLegs(get(result, "routes[0].legs", []));
                  setDirections(result);
                } else {
                  error(
                    `error fetching directions ${get(result, "status", "")}`
                  );
                }
              }
            );
          } else if (status === "ZERO_RESULTS") {
            error("Processor Address not found!");
          }
        });
      }, [locations]);

      if (!Object.keys(processorCoordinates).length || !directions) {
        return <Skeleton variant="rect" width={"100%"} height={1} />;
      }

      return (
        <GoogleMap defaultZoom={12} defaultCenter={processorCoordinates}>
          <DirectionsRenderer directions={directions} />
        </GoogleMap>
      );
    }
  )
);
