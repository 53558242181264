/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { Pageview } from "@material-ui/icons";
import { TableSortLabel } from "@material-ui/core";
import { ListHeader } from "../../../ui/structures/ListHeader";
import {
  collectionRunSearchHelper,
  collectionRunsSearchMap,
  ROUTE_HEADINGS,
} from "./constants";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  getCollectionRunsPaginated,
  getRunsAmount,
  getRunsAutocomplete,
} from "../Scheduling/_api";
import { renderRoutsRow } from "./helpers";
import { modifyCollections } from "./helpers";
import { useHistory } from "react-router-dom";
import { sortHeaderStyle } from "../../constants";
import { ServerSearchBar } from "../../../ui/structures/ServerSearchBar";
import { handleSearch, objectCheck } from "../../../ui/helpers";
import { uniq } from "lodash";

export const ProcessorRoutes = () => {
  const { request } = useFetch();
  const history = useHistory();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    request(
      getCollectionRunsPaginated,
      search,
      field,
      field && direction,
      page,
      pageSize,
      "active"
    )
      .then((data) => data && setData(modifyCollections(data)))
      .finally(() => setLoading(false));
  }, [search, field, direction, page, pageSize]);

  useEffect(() => {
    request(getRunsAmount, search).then(
      (data) => data && setTotalRecords(data)
    );
  }, [search]);

  const fetchRunsAutocomplete = (
    autocompleteValue,
    autocompleteField,
    searchFields
  ) => {
    if (autocompleteValue === "") return;
    setAutocompleteLoading(true);

    request(
      getRunsAutocomplete,
      80,
      autocompleteField,
      autocompleteValue,
      field,
      field && direction,
      searchFields,
      "active"
    ).then((data) => {
      if (data && Array.isArray(data) && data.length && objectCheck(data)) {
        setAutocompleteData(uniq(data.map((item) => String(item))));
      } else {
        setAutocompleteData(["No option found"]);
      }
      setAutocompleteLoading(false);
    });
  };

  const nonSortHeaders = ["planned_pickup_date", "status"];

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) =>
      nonSortHeaders.includes(key) ? (
        <th key={key} style={sortHeaderStyle} className="px-5 text-nowrap">
          <span>{header}</span>
        </th>
      ) : (
        <th
          key={key}
          style={sortHeaderStyle}
          className="px-5 text-nowrap"
          onClick={() => {
            setDirection(
              key !== field ? "desc" : direction === "desc" ? "asc" : "desc"
            );
            setField(key);
          }}
        >
          <span style={{ cursor: "pointer" }}>{header}</span>
          {key === field && <TableSortLabel active direction={direction} />}
        </th>
      )
    );
  }

  const handleExpand = (id) => () => history.push(`/processing/${id}`);

  const resetSearch = () => setSearch("");

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12">
          <ServerSearchBar
            className="mb-5"
            onSearch={(data) =>
              handleSearch(data, collectionRunsSearchMap, setSearch, setPage)
            }
            keyMap={collectionRunSearchHelper}
            loading={autocompleteLoading}
            currentSearchList={autocompleteData}
            fetchAutocompleteFunction={fetchRunsAutocomplete}
            placeholder="Search..."
            clearSearch={resetSearch}
            searchMap={collectionRunsSearchMap}
            searchFields={search}
            contentLoading={loading}
          />
          <div className="bg-white rounded py-7 px-10">
            <ListHeader title="Route Lists" />
            <Datagrid
              data={data}
              loading={loading}
              headings={ROUTE_HEADINGS}
              renderRow={renderRoutsRow}
              link="processing"
              expandable
              handleExpand={handleExpand}
              expandHeader="Review"
              expandIcon={<Pageview fontSize="large" color="primary" />}
              expandHeaderClassName="text-left ml-3 grid-header"
              renderHeaderWithSorting={renderHeaderWithSorting}
              serverPage={page}
              setServerPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              isServerPagination
              totalRecords={totalRecords}
            />
          </div>
        </div>
      </div>
    </>
  );
};
