import React, { useState } from "react";
import { Auth } from "aws-amplify";
import Button from "@bit/the-glue.frontendcomponents.button";
import { error } from "../../../helpers/toasts";

export const ResetPassword = ({ handleClose, setStep, username }) => {
  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = () => {
    setSubmitting(true);
    Auth.forgotPassword(username)
      .then((data) => data && setStep("confirm"))
      .catch((err) => error(err.message))
      .finally(() => setSubmitting(false));
  };
  return (
    <div className="px-5 py-10">
      <div className="mb-5">Password reset</div>
      <h4>Are you sure you want to reset your password?</h4>

      <div className="mt-15 d-flex justify-content-end align-items-center">
        <Button
          onClick={handleClose}
          textTransform="uppercase"
          size="small"
          background="#407A28"
          variant="outlined"
          label="Cancel"
        />
        <Button
          onClick={handleSubmit}
          textTransform="uppercase"
          size="small"
          label="Reset"
          className="ml-5"
          background="#407A28"
          text="#E8F5E1"
          disabled={submitting}
        />
      </div>
    </div>
  );
};
