/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { TableSortLabel } from "@material-ui/core";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  reimbursementsSearchMap,
  REIMBURSEMENT_HEADINGS,
  REIMBURSEMENTS_SEARCH_HELPER,
} from "./constants";
import { handleSearch, objectCheck } from "../../../ui/helpers";
import {
  getClaimsListAutocomplete,
  getHistoryReimbursements,
  getReimbursementsAmount,
} from "../../modules/DrumMuster/Inspections/_api";
import { renderReimbursementsRow } from "../../modules/ReimbursementsApprovals/helpers";
import { modifyOrgReimbursements } from "./helpers";
import { ReimbursementLine } from "./ReimbursementLine";
import { uniq } from "lodash";
import { ServerSearchBar } from "../../../ui/structures/ServerSearchBar";
import { sortHeaderStyle } from "../../constants";

export const OrgReimbursements = ({
  data = [],
  setData,
  organisationId,
  setReimbursements,
  users = [],
}) => {
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [reviewedComponent, setReviewedComponent] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [direction, setDirection] = useState("");
  const [field, setField] = useState("");

  const fetchReimbursements = () => {
    setLoading(true);
    request(
      getHistoryReimbursements,
      organisationId,
      search,
      page,
      pageSize,
      field,
      field && direction
    )
      .then(
        (data) =>
          data && setReimbursements(modifyOrgReimbursements(data, users))
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchReimbursements();
  }, [search, page, pageSize, field, direction]);

  useEffect(() => {
    request(
      getReimbursementsAmount,
      `organisation_id=${organisationId}&${search}`,
      "approved"
    ).then((data) => data && setTotalRecords(data));
  }, [search]);

  const fetchAutocomplete = (
    autocompleteValue,
    autocompleteField,
    searchFields,
    archived
  ) => {
    if (autocompleteValue === "") return;
    setAutocompleteLoading(true);

    request(
      getClaimsListAutocomplete,
      80,
      autocompleteField,
      autocompleteValue,
      searchFields,
      "approved",
      organisationId
    ).then((data) => {
      if (data && Array.isArray(data) && data.length && objectCheck(data)) {
        setAutocompleteData(uniq(data.map((item) => String(item))));
      } else {
        setAutocompleteData(["No option found"]);
      }
      setAutocompleteLoading(false);
    });
  };

  const resetSearch = () => {
    setSearch("");
  };

  const handleExpand = (id) => () => {
    setReviewedComponent(id);
    setExpanded(true);
  };
  const nonSortingHaeders = [
    "approver",
    "reviewer",
    "value",
    "grouped",
    "status",
  ];

  const modifySortKey = (key) => {
    if (key === "region") return "organisation.region.long_name";
    else if (key === "state") return "organisation.physical_state";
    else if (key === "date_requested") return "created_at";
    else if (key === "invoice_number") return "invoice.code";
    else return key;
  };

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) => {
      const sortKey = modifySortKey(key);
      return nonSortingHaeders.includes(key) ? (
        <th key={key} style={sortHeaderStyle} className="px-5 text-nowrap">
          <span>{header}</span>
        </th>
      ) : (
        <th
          key={key}
          style={sortHeaderStyle}
          className="px-5 text-nowrap"
          onClick={() => {
            setDirection(
              sortKey !== field ? "desc" : direction === "desc" ? "asc" : "desc"
            );
            setField(sortKey);
          }}
        >
          <span style={{ cursor: "pointer" }}>{header}</span>
          {sortKey === field && <TableSortLabel active direction={direction} />}
        </th>
      );
    });
  }

  return (
    <>
      {expanded ? (
        <ReimbursementLine
          item={data.find(({ id }) => id === reviewedComponent)}
          setExpanded={setExpanded}
          setReimbursementsData={setData}
          reimbursemenets={data}
          fetchReimbursements={fetchReimbursements}
        />
      ) : (
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="bg-white rounded py-7 px-10">
              <div className="mb-10">
                <ServerSearchBar
                  className="mb-5"
                  onSearch={(data) =>
                    handleSearch(
                      data,
                      reimbursementsSearchMap,
                      setSearch,
                      setPage
                    )
                  }
                  keyMap={REIMBURSEMENTS_SEARCH_HELPER}
                  loading={autocompleteLoading}
                  currentSearchList={autocompleteData}
                  fetchAutocompleteFunction={fetchAutocomplete}
                  placeholder="Filter..."
                  clearSearch={resetSearch}
                  searchMap={reimbursementsSearchMap}
                  searchFields={search}
                  contentLoading={loading}
                />
              </div>
              <Datagrid
                data={data}
                headings={REIMBURSEMENT_HEADINGS}
                renderRow={renderReimbursementsRow}
                loading={loading}
                expandable
                handleExpand={handleExpand}
                expandHeader="More"
                renderHeaderWithSorting={renderHeaderWithSorting}
                expandIcon={
                  <ArrowRightAltIcon className="ml-5" fontSize="large" />
                }
                serverPage={page}
                setServerPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                isServerPagination
                totalRecords={totalRecords}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
