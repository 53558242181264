import React, { useEffect, useState } from "react";
import { ListButton } from "../../../../ui/components/ListButton";
import { FillAddress } from "./FillAddress";
import { ChemaddressDetailsSchema } from "../helpers";
import { ChemStorageAddress } from "./ChemSotrageAddress";
import { Formik } from "formik";
import { ContactAddress } from "./ContactAddress";

export const AddressDetails = ({ handleNextStep, handlePrevStep, addressDetails }) => {
  const [contactControl, setContactControl] = useState({});
  const [contactValues, setContactValues] = useState({});
  const [storageControl, setStorageControl] = useState({});
  const [storageValues, setStorageValues] = useState({});

  const {
    chem_contact_address_1,
    chem_contact_address_2,
    chem_contact_suburb,
    chem_contact_state,
    chem_contact_postcode,
    chem_address_1,
    chem_address_2,
    chem_suburb,
    chem_state,
    chem_postcode,
    as_contact_address,
    address_notes,
  } = addressDetails;

  const initialValues = {
    chem_contact_address_1: chem_contact_address_1 || "",
    chem_contact_address_2: chem_contact_address_2 || "",
    chem_contact_suburb: chem_contact_suburb || "",
    chem_contact_state: chem_contact_state || "",
    chem_contact_postcode: chem_contact_postcode || "",
    chem_address_1: chem_address_1 || "",
    chem_address_2: chem_address_2 || "",
    chem_suburb: chem_suburb || "",
    chem_state: chem_state || "",
    chem_postcode: chem_postcode || "",
    as_contact_address: as_contact_address || false,
    address_notes: address_notes || "",
  };

  const handleSubmit = (values) => {
    handleNextStep(values, "address_details");
  };

  const options = {
    key: "MN71-JP16-MJ29-NK46",
    search: { countries: "AUS" },
  };

  useEffect(() => {
    const pca = window.pca;
    const contactFields = [
      { element: "chem_address", field: "Line2", mode: pca.fieldMode.SEARCH },
      { element: "chem_contact_address_1", field: "Line1", mode: pca.fieldMode.POPULATE },
      { element: "chem_contact_suburb", field: "City", mode: pca.fieldMode.POPULATE },
      {
        element: "chem_contact_postcode",
        field: "PostalCode",
        mode: pca.fieldMode.SEARCH | pca.fieldMode.POPULATE,
      },
      { element: "chem_contact_state", field: "Province", mode: pca.fieldMode.POPULATE },
    ];
    const storageFields = [
      { element: "chem_storage_address", field: "Line3", mode: pca.fieldMode.SEARCH },
      { element: "chem_address_1", field: "Line1", mode: pca.fieldMode.POPULATE },
      { element: "chem_suburb", field: "City", mode: pca.fieldMode.POPULATE },
      {
        element: "chem_postcode",
        field: "PostalCode",
        mode: pca.fieldMode.SEARCH | pca.fieldMode.POPULATE,
      },
      { element: "chem_state", field: "Province", mode: pca.fieldMode.POPULATE },
    ];
    const contactControl = new window.pca.Address(contactFields, options);
    setContactControl(contactControl);
    const storageControl = new window.pca.Address(storageFields, options);
    setStorageControl(storageControl);
    // eslint-disable-next-line
  }, []);

  const handleContactListen = () => {
    contactControl.listen("populate", function(address, variations) {
      const values = {
        chem_contact_address_1: address.FormattedLine1,
        chem_contact_suburb: address.City,
        chem_contact_postcode: address.PostalCode,
        chem_contact_state: address.Province,
      };
      setContactValues(values);
    });
  };

  const handleStorageListen = () => {
    storageControl.listen("populate", function(address, variations) {
      const values = {
        chem_address_1: address.FormattedLine1,
        chem_suburb: address.City,
        chem_postcode: address.PostalCode,
        chem_state: address.Province,
      };
      setStorageValues(values);
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ChemaddressDetailsSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, values }) => (
        <div className="border border-secondary bg-white p-10">
          <>
            <div className="d-flex my-5 justify-content-between">
              <ContactAddress
                contactValues={contactValues}
                handleContactListen={handleContactListen}
              />
              <ChemStorageAddress
                storageValues={storageValues}
                handleStorageListen={handleStorageListen}
              />
            </div>
            <FillAddress />
            <div className="mt-10 d-flex justify-content-end">
              <ListButton
                label="Back"
                size="medium"
                className="px-12 mr-3"
                boxShadow={false}
                onClick={handlePrevStep}
                variant="outlined"
                text="#64A70B"
              />
              <ListButton
                label="Next"
                size="medium"
                className="px-12"
                boxShadow={false}
                onClick={handleSubmit}
              />
            </div>
          </>
        </div>
      )}
    </Formik>
  );
};
