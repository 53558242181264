import React, { useEffect, useState } from "react";
import { Skeleton } from "@material-ui/lab";
import { useFormikContext } from "formik";
import { Autocomplete } from "../../../../ui/components/Autocomplete";

export const OrgSelect = ({
  organisationsLoading,
  organiastionOptions = [],
}) => {
  const [resetKey, setResetKey] = useState(0);
  const { values, setFieldValue, ...formik } = useFormikContext();

  useEffect(() => {
    if (values.site_id) {
      setFieldValue("organisation_id", null);
      setResetKey(Date.now());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.site_id]);
  return (
    <React.Fragment key={resetKey}>
      {organisationsLoading ? (
        <Skeleton variant="rect" width={"100%"} height={55} />
      ) : (
        <>
          <Autocomplete
            name="organisation_id"
            placeholder="Link Organisation"
            setValue={setFieldValue}
            defaultValueField="value"
            options={organiastionOptions}
            defaultValue={values.organisation_id}
          />
          {formik.touched.organisation_id && formik.errors.organisation_id ? (
            <div className="text-danger">{formik.errors.organisation_id}</div>
          ) : null}
        </>
      )}
    </React.Fragment>
  );
};
