export const HEADINGS = [
  ["organisation_name", "Claim Payee Organisation"],
  ["date_requested", "Date"],
  ["volume", "Volume"],
  ["total_value_gst", "Value (inc GST)"],
  ["invoice_number", "Invoice"],
  ["grouped", "Grouped"],
  ["status", "Status"],
  ["type", "Type"]
];

export const searchMap = {
  Type: "type",
  Status: "status",
  "Claim Payee Organisation": "organisation.business_name",
  Invoice: "invoice.code",
  Grouped: "grouped"
};

export const LINE_HEADINGS = [
  ["associated_site", "Site Name"],
  ["drop_off_date", "Date"],
  ["business", "Business"],
  ["name", "Name"],
  ["total_plastic", "Plastic"],
  ["total_steel", "Steel"],
  ["total", "Total"],
  ["rejected", "Rejected"],
  ["inspector", "Inspector"]
];
