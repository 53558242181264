import React, { useState } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { ListButton } from "../../../ui/components/ListButton";
import { renderRow } from "../../../ui/helpers";

const mockedData = [
  {
    id: "WTG001",
    organisation_name: "We’re The Glue Pty Ltd",
    phone: "+61 2 9157 4555",
  },
  {
    id: "CN0001",
    organisation_name: "Concierge Networks Pty Ltd",
    phone: "+61 2 9157 4566",
  },
  {
    id: "IL0001",
    organisation_name: "Illuminance Labs Kft",
    phone: "+36 1 955 5888",
  },
];

const HEADINGS = [
  ["id", "SITE ID"],
  ["organisation_name", "Organisation Name"],
  ["phone", "Phone Number"],
];

export const Location = ({ handleNextStep, handlePrevStep }) => {
  const [selected, setSelected] = useState({});
  return (
    <div className="bg-white py-15 px-10">
      <div className="d-flex justify-content-between">
        <div className="justify-content-center col-7">
          <Datagrid
            data={mockedData}
            headings={HEADINGS}
            renderRow={renderRow}
            selectable
            selected={selected}
            setSelected={setSelected}
            hideSelect
          />
        </div>
        <div className="col-5"></div>
      </div>
      <div className="mt-10 d-flex justify-content-end">
        <ListButton
          label="Back"
          size="medium"
          className="px-12 mr-3"
          boxShadow={false}
          onClick={handlePrevStep}
          variant="outlined"
          text="#64A70B"
        />
        <ListButton
          label="Next"
          size="medium"
          className="px-12"
          boxShadow={false}
          onClick={handleNextStep}
        />
      </div>
    </div>
  );
};
