import * as Yup from "yup";
import { Chip, styled } from "@material-ui/core";

export const DrumContactDetailsSchema = Yup.object({
  first_name: Yup.string()
    .required("First Name is a required field")
    .matches(/(^[A-Za-z ]+$)/, "Only alphabet characters are allowed"),
  last_name: Yup.string()
    .required("Last Name is a required field")
    .matches(/(^[A-Za-z]+$)/, "Only alphabet characters are allowed"),
  phone: Yup.string()
    .required("Phone Number is a required field")
    .matches(/(^[0-9+]+$)/, "Only numbers are allowed"),
  email: Yup.string()
    .required("Email is a required field")
    .email("Must be a valid email")
    .matches(/(^[a-z0-9@.!#$%&'*+-/=?^_`{|}~]+$)/, "Restricted characters"),
});

export const DrumAddressDetailsSchema = Yup.object({
  contact_address_1: Yup.string().required("Address 1 is a required field"),
  contact_address_2: Yup.string().required("Address 2 is a required field"),
  contact_suburb: Yup.string().required("Suburb is a required field"),
  contact_state: Yup.string().required("State is a required field"),
  contact_postcode: Yup.string()
    .required("Postcode is a required field")
    .length(4)
    .matches(/(^[0-9]+$)/, "Only numbers are allowed"),
  drum_address_1: Yup.string().required("Address 1 is a required field"),
  drum_address_2: Yup.string().required("Address 2 is a required field"),
  drum_suburb: Yup.string().required("Suburb is a required field"),
  drum_state: Yup.string().required("State is a required field"),
  drum_postcode: Yup.string()
    .required("Postcode is a required field")
    .length(4)
    .matches(/(^[0-9]+$)/, "Only numbers are allowed"),
});

const statusMap = {
  "FREE DROP OFF": "rgba(100, 167, 11, 0.7)",
  "CONTACT DRUMMUSTER": "#F2994A",
};

const CustomChip = styled(Chip)(({ theme }) => ({
  width: 300,
  color: theme.palette.success.main,
  "& .MuiChip-label": {
    overflowWrap: "break-word",
    whiteSpace: "normal",
    textAlign: "center",
  },
}));

const renderBadges = (item = {}) => {
  return (
    <div>
      <div>
        <CustomChip
          label={item.status}
          color="primary"
          className="text-white py-6"
          style={{
            width: "150px",
            background: statusMap[item.status],
            borderRadius: "4px",
            fontSize: "10px",
            fontWeight: 500,
          }}
        />
      </div>
    </div>
  );
};
export const renderProductsRow = (headings, item) => {
  return headings.map(([key]) => (
    <td key={key} className={`align-middle no-line border-0 px-5 py-5`}>
      {key === "status" ? renderBadges(item) : item[key]}
    </td>
  ));
};
