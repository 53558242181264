import React from "react";
import { Select, MenuItem, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    color: "#2196F3",
    "&:focus": {
      backgroundColor: "white",
    },
  },
});

export const SortSelect = ({
  options,
  onChange,
  value,
  label,
  labelClassName,
  wrapperClassName,
}) => {
  const classes = useStyles();
  return (
    <div className={`d-flex align-items-center ${wrapperClassName}`}>
      {label && <div className={`mr-2 ${labelClassName}`}>{label} :</div>}
      <Select
        value={value}
        onChange={onChange}
        label="Sort By"
        variant="standard"
        classes={{ root: classes.root }}
        disableUnderline
      >
        {options.map(({ value, label }) => (
          <MenuItem key={value} value={value} children={label} />
        ))}
      </Select>
    </div>
  );
};
