import React, { useState, useEffect } from "react";
import Tabs from "@bit/the-glue.frontendcomponents.tabs";
import { SiteInventory } from "./SiteInventory";
import { PlanRoute } from "./PlanRoute";
import { Planning } from "./Planning";
import { useFetch } from "../../../hooks/fetch.hook";
import { getCollectionRuns } from "./_api";
import { modifyCollectionRuns } from "./helpers";

export const Scheduling = () => {
  const { request } = useFetch();
  const [collectionRuns, setCollectionRuns] = useState([]);
  const [runsLoading, setRunsLoading] = useState(false);

  useEffect(() => {
    setRunsLoading(true);
    request(getCollectionRuns, "status=draft")
      .then(data => data && setCollectionRuns(modifyCollectionRuns(data)))
      .finally(() => setRunsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs = [
    {
      label: "Site Inventory",
      tabPanel: <SiteInventory />
    },
    {
      label: "Scheduling",
      tabPanel: (
        <Planning
          collectionRuns={collectionRuns}
          setCollectionRuns={setCollectionRuns}
        />
      )
    },
    {
      label: "Route",
      tabPanel: (
        <PlanRoute collectionRuns={collectionRuns} runsLoading={runsLoading} />
      )
    }
  ];
  return (
    <div className="mt-10">
      <Tabs
        defaultActive="0"
        tabs={tabs}
        align="right"
        text="rgba(241, 251, 243, 0.7)"
        selectedTabColor="white"
        selectedColor="#407A28"
      />
    </div>
  );
};
