import React from "react";
import { ToastContainer, Slide } from "react-toastify";

export const Toaster = (props) => {
  return (
    <ToastContainer
      position="bottom-right"
      autoClose={5001}
      transition={Slide}
      hideProgressBar={true}
      newestOnTop
      closeButton={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      {...props}
    />
  );
};
