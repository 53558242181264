import config from "react-global-configuration";

export const getTasks = (query = "") =>
  fetch(`${config.get("ApiUrl").Rest}/tasks?${query}`, {
    mode: "cors",
    credentials: "include",
  });

export const addTask = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/tasks`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getTaskById = (task_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tasks/${task_id}`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyTask = (task_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/tasks/${task_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const archiveTask = (id) =>
  fetch(`${config.get("ApiUrl").Rest}/tasks/${id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const getTaskComments = (task_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tasks/${task_id}/comments`, {
    mode: "cors",
    credentials: "include",
  });

export const addTaskComment = (task_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/tasks/${task_id}/comments`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const editTaskComment = (task_id, comment_id, data) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tasks/${task_id}/comments/${comment_id}`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include",
    }
  );

export const deleteTaskComment = (task_id, comment_id, data) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tasks/${task_id}/comments/${comment_id}`,
    {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include",
    }
  );

export const addTaskUser = (task_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/tasks/${task_id}/users`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const modifyTaskUser = (task_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/tasks/${task_id}/users`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const createTaskCommentFile = (task_id, comment_id, data) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tasks/${task_id}/comments/${comment_id}/files`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include",
    }
  );

export const modifyTaskCommentFile = (task_id, comment_id, file_id, data) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tasks/${task_id}/comments/${comment_id}/files/${file_id}`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include",
    }
  );

export const downloadTaskCommentFile = (task_id, comment_id, file_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tasks/${task_id}/comments/${comment_id}/files/${file_id[0]}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
