import { reimbursementsTypes } from "./actions";

const initialState = {
  reimbursementsList: [],
};

export function reimbursementsReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case reimbursementsTypes.SET_DATA:
      return {
        ...state,
        reimbursementsList: payload,
      };

    case reimbursementsTypes.REMOVE_DATA:
      return {
        ...state,
        reimbursementsList: [...state.reimbursementsList.filter(({ id }) => id !== payload)],
      };

    case reimbursementsTypes.UPDATE_DATA:
      const index = state.reimbursementsList.findIndex(
        (item) => (item || {}).id === (payload || {}).id
      );
      return {
        ...state,
        reimbursementsList: [
          ...state.reimbursementsList.slice(0, index),
          payload,
          ...state.reimbursementsList.slice(index + 1),
        ],
      };

    default:
      return state;
  }
}
