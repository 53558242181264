export const HEADINGS = [
  ["ccd_material", "Material"],
  ["ccd_size_ui", "Size"],
  ["price", "Price"],
  ["type", "Type"],
  ["organisation_name", "Organisation"],
  ["site_name", "Site"],
  ["status", "Status"],
];

export const SEARCH_HEADINGS = [
  ["ccd_material", "Material"],
  ["ccd_size", "Size"],
  ["price", "Price"],
  ["type", "Type"],
  ["organisation.business_name", "Organisation"],
  ["site.name", "Site"],
  ["status", "Status"],
];

export const searchMap = {
  Material: "ccd_material",
  Size: "ccd_size",
  Type: "type",
  Organisation: "organisation.business_name",
  Site: "site.name",
};
