/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { ABRLookup } from "./ABRLookup";
import { DetailsSubmit } from "./DetailsSubmit";

export const AddBusiness = ({ handleClose, setOrganisations, organisations }) => {
  const [step, setStep] = useState(0);
  const [selectedOrg, setSelectedOrg] = useState({});

  const handleNextStep = () => setStep(step + 1);

  const handlePrevStep = () => setStep(step - 1);

  const stepsContent = [
    <ABRLookup
      handleNextStep={handleNextStep}
      setSelectedOrg={setSelectedOrg}
      handleClose={handleClose}
      organisations={organisations}
    />,
    <DetailsSubmit
      handlePrevStep={handlePrevStep}
      selectedOrg={selectedOrg}
      handleClose={handleClose}
      setOrganisations={setOrganisations}
    />,
  ];

  return <div>{stepsContent.find((item, index) => index === step)}</div>;
};
