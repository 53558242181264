import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import { ListButton } from "../../../ui/components/ListButton";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import "./WelcomeOverlay";

export const WelcomeOverlay = ({
  onClose,
  isOpen,
  maxWidth = "md",
  ...other
}) => {
  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      fullWidth={true}
      maxWidth={maxWidth}
      {...other}
    >
      <DialogContent>
        <div style={{ minHeight: "40vh" }} className="position-relative">
          <div className="text-center pt-7">
            <img
              className="img-fluid"
              src={toAbsoluteUrl("/assets/icons/DDLogo.png")}
              alt="Logo"
            />
          </div>
          <h2 className="text-center py-10">Welcome to drumDRIVE!</h2>
          <div>
            <div style={{ fontSize: "18px" }} className="welcome-overlay-text">
              We are thrilled to finally share it with you. Our team has worked
              hard to create a user-friendly and engaging experience, and we
              can't wait for you to try it out. Please contact the drumMUSTER
              team to get started if this is your first time here:
              <span className="text-primary"> info@drummuster.org.au</span>
            </div>
          </div>
          <div
            className="d-flex justify-content-center align-items-end"
            style={{ height: "140px" }}
          >
            <ListButton label="OK" size="large" onClick={onClose} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
