import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { Formik } from "formik";
import { get } from "lodash";
import React from "react";
import { EndUserORgEditSchema } from "../../../helpers/schemas";
import { info } from "../../../helpers/toasts";
import { useFetch } from "../../../hooks/fetch.hook";
import { SubmitActions } from "../../../ui/structures/SubmitActions";
import { addTask, addTaskComment, addTaskUser } from "../Tasks/_api";

export const EndUserEdit = ({ data = {}, setLoading, handleClose }) => {
  const { request } = useFetch();

  const initialValues = {
    deatil: "",
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const consultantID = get(data, "consultant.user_id", "");
    const taskPayload = {
      detail: values.detail,
      private: false,
      flag: "organisations",
      organisation_id: data.organisation_id,
      task_type: "other",
      task_name: `${data.business_name} Edit Request`,
    };
    setLoading(true);
    handleClose();
    request(addTask, taskPayload)
      .then((data) => {
        if (!data) return;
        setLoading(false);
        if (consultantID) {
          setLoading(true);
          request(addTaskUser, data.id, [{ id: Number(consultantID) }])
            .then((data) => {
              if (!data) return;
              info("Request has been sent!");
            })
            .finally(() => setLoading(false));
        }
        if (values.first_name || values.last_name || values.mobile || values.email) {
          setLoading(true);
          request(addTaskComment, data.id, {
            comment: [values.first_name + values.last_name, values.mobile, values.email].join(", "),
          }).finally(() => setLoading(false));
        }
      })
      .finally(() => setSubmitting(false));
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={EndUserORgEditSchema}
    >
      {({ isSubmitting, handleSubmit }) => (
        <div className="bg-white pb-5">
          <h3 className="mb-5">Edit Business</h3>
          <div className="mb-10">
            We understand that you’d like to make a change to the details associated with your
            Organisation Record in drumDRIVE. For your security our team will make the changes for
            you. Please summarise the changes below and our team will be in touch soon.
          </div>
          <MappedInput name="detail" label="Details" multiline rows={8} />
          <h3 className="mt-10 mb-5">Optional Contact Details (for Online Access)</h3>
          <div className="d-flex flex-wrap">
            <div style={{ width: "47%" }} className="mr-7">
              <MappedInput name="first_name" label="First Name" />
              <MappedInput name="mobile" label="Mobile" />
            </div>
            <div style={{ width: "47%" }}>
              <MappedInput name="last_name" label="Surname" />
              <MappedInput name="email" label="Email" />
            </div>
          </div>
          <SubmitActions
            handleCancel={handleClose}
            disabled={isSubmitting}
            handleSubmit={handleSubmit}
            submitLabel="Submit"
          />
        </div>
      )}
    </Formik>
  );
};
