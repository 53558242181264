export const contactTypes = {
  SET_DATA: "CONTACTS/SET_DATA",
  REMOVE_CONTACT: "CONTACTS/REMOVE_CONTACT",
  UPDATE_DATA: "CONTACTS/UPDATE_DATA",
};

export function setContactsList(data) {
  return {
    type: contactTypes.SET_DATA,
    payload: data,
  };
}

export function removeContact(data) {
  return {
    type: contactTypes.REMOVE_CONTACT,
    payload: data,
  };
}

export function updateContactsList(data) {
  return {
    type: contactTypes.UPDATE_DATA,
    payload: data,
  };
}
