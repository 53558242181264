import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import Button from "@bit/the-glue.frontendcomponents.button";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import {
  addUser,
  modifyUser,
  archiveUser,
  reactivateUser,
  resetAccount,
  getUsers,
} from "./_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { userProfileSchema } from "../../../helpers/schemas";
import { info } from "../../../helpers/toasts";
import { Loader } from "../../../ui/components/Loader";
import { modifyEmptyFields } from "../../../ui/helpers";
import { SubmitActions } from "../../../ui/structures/SubmitActions";

export const UserDetails = ({ data = {}, id, name }) => {
  const { request } = useFetch();
  const history = useHistory();

  const initialValues = {
    first_name: data.first_name || "",
    last_name: data.last_name || "",
    phone_number: data.phone_number || "",
    email: data.email || "",
    region_id: data.region_id || "",
    preferred_mfa: data?.user_info?.preferred_mfa || "SOFTWARE_TOKEN_MFA",
  };

  const [loading, setLoading] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [submitAction, setSubmitAction] = useState("Save");

  useEffect(() => {
    request(getUsers, "", true).then((data) => data && setUsersList(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emailsList = usersList.map(({ user_info: { email } }) => email);

  const findDuplicate = (value) => {
    return emailsList.find((email) => email === value && email !== data.email);
  };

  const createUser = (values = {}, setSubmitting) => {
    request(addUser, {
      ...values,
      name: `${values.first_name} ${values.last_name}`,
    })
      .then((data) => data && history.push("/users-list"))
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const editUser = (values = {}, setSubmitting) => {
    request(
      modifyUser,
      { ...values, name: `${values.first_name} ${values.last_name}` },
      id
    )
      .then((data) => {
        if (!data) return;
        info("User details have been updated!");
        submitAction === "Save & Exit" && history.push("/users-list");
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    modifyEmptyFields(values);
    if (values.preferred_mfa === "SOFTWARE_TOKEN_MFA")
      delete values["phone_number"];
    id ? editUser(values, setSubmitting) : createUser(values, setSubmitting);
  };

  const handleClose = () => history.push("/users-list");

  const revokeAccess = () => {
    setLoading(true);
    request(archiveUser, id)
      .then((data) => data && history.push("/users-list"))
      .finally(() => setLoading(false));
  };

  const inviteUserToPortal = () => {
    setLoading(true);
    request(reactivateUser, id)
      .then((data) => {
        if (!data) return;
        info(`${name} has been Invited to the portal!`);
      })
      .finally(() => setLoading(false));
  };

  const resetAccountData = () => {
    setLoading(true);
    request(resetAccount, id)
      .then((data) => {
        if (!data) return;
        info(`User's account has been reset!`);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="border border-secondary bg-white p-10">
      {loading && (
        <Loader
          isOpen={loading}
          maxWidth="xs"
          disableBackdropClick
          disableEscapeKeyDown
        />
      )}
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={userProfileSchema(findDuplicate)}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <>
            {id && (
              <div className="mt-2 mb-7 row">
                <div className="col-8 d-flex justify-content-end">
                  <div className="mr-3">
                    <Button
                      label="Reset Account"
                      size="small"
                      text="#599D36"
                      background="light"
                      boxShadow
                      textTransform="uppercase"
                      fontWeight="600"
                      className="mr-5"
                      data-testid="reset"
                      onClick={resetAccountData}
                    />
                  </div>
                  <div className="mr-3">
                    <Button
                      label="Revoke Portal Access"
                      size="small"
                      text="#599D36"
                      background="light"
                      boxShadow
                      textTransform="uppercase"
                      fontWeight="600"
                      className="mr-5"
                      data-testid="revoke"
                      onClick={revokeAccess}
                    />
                  </div>
                  {data.status === "archived" && (
                    <Button
                      label="Invite User to Portal"
                      size="small"
                      text="#599D36"
                      background="light"
                      boxShadow
                      textTransform="uppercase"
                      fontWeight="600"
                      className="mr-5"
                      data-testid="revoke"
                      onClick={inviteUserToPortal}
                    />
                  )}
                </div>
              </div>
            )}

            <h3 className="mb-10">
              <strong>{id ? name : "Add User"}</strong>
            </h3>
            <div className="row justify-content-between">
              <div className="col-8">
                <MappedInput
                  label="First Name"
                  name="first_name"
                  data-testid="first_name"
                />
                <MappedInput
                  label="Last Name"
                  name="last_name"
                  data-testid="last_name"
                />
                <MappedInput
                  label="Email"
                  name="email"
                  data-testid="email"
                  disabled={Boolean(id)}
                />
                <MappedSelect
                  label="Multi-factor authentication (MFA)"
                  name="preferred_mfa"
                  options={[
                    {
                      value: "SMS_MFA",
                      label: "SMS",
                    },
                    {
                      value: "SOFTWARE_TOKEN_MFA",
                      label: "Authenticator",
                    },
                  ]}
                />
                {values.preferred_mfa === "SMS_MFA" || values.phone_number ? (
                  <MappedInput
                    label="Phone Number"
                    name="phone_number"
                    data-testid="phone_number"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="mt-10">
              <SubmitActions
                id={id}
                handleCancel={handleClose}
                disabled={isSubmitting}
                submitAction={submitAction}
                handleSubmit={handleSubmit}
                setSubmitAction={setSubmitAction}
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
