import config from "react-global-configuration";

export const addRegion = (data) =>
  fetch(`${config.get("ApiUrl").Rest}/regions`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getRegions = (search = "", archived = "", field, direction) =>
  fetch(
    `${config.get("ApiUrl").Rest}/regions?${search &&
      "&" + search}${(archived && "&status=all") || ""}${(field &&
      "&order_by=" + field) ||
      ""}${(direction && "&order_dir=" + direction) || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getRegionById = (region_id) =>
  fetch(`${config.get("ApiUrl").Rest}/regions/${region_id}`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyRegion = (data, region_id) =>
  fetch(`${config.get("ApiUrl").Rest}/regions/${region_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const deleteRegion = (region_id) =>
  fetch(`${config.get("ApiUrl").Rest}/regions/${region_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const getRegionsAutocomplete = (
  page_size = 80,
  autocompleteField,
  autocompleteText = "",
  search
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/regions?&page_size=${page_size}${(autocompleteField &&
      "&distinct=" +
        autocompleteField +
        "&" +
        autocompleteField +
        "=_like(" +
        autocompleteText +
        ")_") ||
      ""}${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getLGARegionByPostcode = (postcode = "") =>
  fetch(`${config.get("ApiUrl").Rest}/lga-lookup?postcode=${postcode}`, {
    mode: "cors",
    credentials: "include",
  });
