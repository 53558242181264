import React from "react";
import { itemStyle } from "../constants";
import ABRIcon from "../static/ABRIcon.png";

export const Abr = ({ handleConnect }) => {
  return (
    <>
      <div style={itemStyle} className="bg-white p-7 mt-10">
        <div className="text-center mb-25">
          <img src={ABRIcon} alt="logo" />
        </div>
        <h3 className="mb-2 text-dark">
          <strong>ABR</strong>
        </h3>
        <div style={{ fontSize: "14px" }}>
          This integration connects the AgSafe platform to ABR for ABN, Organisation Name and
          Trading Name validation.
        </div>
        <div className="mt-25">
          <button
            className="text-uppercase border-0 bg-transparent text-secondary font-weight-bold"
            disabled
          >
            Connect
          </button>
        </div>
      </div>
    </>
  );
};
