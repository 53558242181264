import React, { useState, useRef } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Button, ButtonGroup, Menu, MenuItem } from "@material-ui/core";
import { ListButton } from "./ListButton";

const defaultOptions = ["Save", "Save & Exit"];

export const SelectButton = ({
  submitAction,
  setSubmitAction,
  options = defaultOptions,
  disabled = false,
  handleSubmit,
}) => {
  const [isOpen, setOpen] = useState(false);

  const anchorRef = useRef(null);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleMenuItemClick = (event, index, option) => {
    setSubmitAction(option);
    setOpen(false);
    setSelectedIndex(index);
  };

  const handleClose = () => setOpen(false);
  const handleOpen = (event) => {
    setOpen(true);
  };

  return (
    <div>
      <ButtonGroup variant="contained" ref={anchorRef}>
        <ListButton
          onClick={handleSubmit}
          label={submitAction}
          size="large"
          boxShadow={false}
          style={{ minWidth: "130px" }}
          disabled={disabled}
        />
        <Button size="small" onClick={handleOpen}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Menu
        id="lock-menu"
        open={isOpen}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorEl={anchorRef.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index, option)}
            style={{ minWidth: "170px" }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
