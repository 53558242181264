import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import { TrainingLine } from "./TrainingLine";
import { ListButton } from "../../../../ui/components/ListButton";

export const MoodleConfig = ({ trainings = [], handleClose, setTrainings }) => {
  return (
    <div className="px-5">
      <div className="d-flex justify-content-between align-items-center mb-10">
        <h3>Configure Trainings</h3>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div>
        {trainings.map(training => (
          <TrainingLine
            trainingsList={trainings}
            training={training}
            key={training?.id}
            setTrainings={setTrainings}
          />
        ))}
      </div>
      <div className="text-right mb-5">
        <ListButton
          label="Cancel"
          size="large"
          onClick={handleClose}
          variant="outlined"
          text="#407A28"
          boxShadow={false}
        />
      </div>
    </div>
  );
};
