/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import SearchBar from "@bit/the-glue.frontendcomponents.search-bar";
import { HEADINGS } from "./constants";
import { renderProductsRow } from "./helpers";
import { ListHeader } from "../../../ui/structures/ListHeader";
import { ListButton } from "../../../ui/components/ListButton";
import { useFetch } from "../../../hooks/fetch.hook";

const mockedProducts = [
  {
    id: "AN0001",
    product: "Steel",
    container_size: "1",
    container_qty: "2",
    qty_remaining: 100,
    integrity: "",
    status: "FREE COLLECTION",
  },
  {
    id: "AN0002",
    product: "Plastic",
    container_size: "22",
    container_qty: "3",
    qty_remaining: 50,
    integrity: "",
    status: "HAVE YOU CONSIDERED DRUMMUSTER?",
  },
  {
    id: "AN0003",
    product: "Steel",
    container_size: "5",
    container_qty: "2",
    qty_remaining: 30,
    integrity: "",
    status: "QUOTE REQUIRED",
  },
];

export const Products = ({ handlePrevStep, handleSubmit }) => {
  const { request } = useFetch();

  const handleExpand = (id) => () => console.log(id);

  const [data, setData] = useState(mockedProducts);
  const [submitting, setSubmitting] = useState(false);

  const handleSearch = (search, isRemoveKey) => {
    setData(
      (isRemoveKey ? mockedProducts : data).filter((item) => {
        const _search = Object.entries(search).map((item) => [
          HEADINGS.find((i) => i[1] === item[0])[0],
          item[1],
        ]);
        return (
          _search.length === 0 ||
          _search.every(([k, v]) => {
            if (Array.isArray(item[k])) {
              return item[k].includes(v);
            }
            return item[k] === v;
          })
        );
      })
    );
  };

  const resetSearch = () => {
    setData(mockedProducts);
  };

  const onSubmit = () => {
    handleSubmit("values", setSubmitting);
  };

  const KEY_MAP = {
    ID: data.map((item) => item.id),
    Material: data.map((item) => item.material),
    "Container Size": data.map((item) => item.container_size),
    "Container Qty": data.map((item) => item.container_qty),
    Status: data.map((item) => item.status),
  };

  const handleSliderChange = (event, value, result = {}) => {
    const index = data.findIndex((item) => (item || {}).id === (result || {}).id);
    const updatedData = [
      ...data.slice(0, index),
      { ...result, qty_remaining: value },
      ...data.slice(index + 1),
    ];
    setData(updatedData);
  };

  return (
    <>
      <hr />
      <div className="bg-white pb-15 pt-5 px-10">
        <div className="col-12 justify-content-center ">
          <h3 className="text-dark mb-7">Add Products</h3>
          <div className="mb-15">
            <SearchBar
              onSearch={handleSearch}
              clearSearch={resetSearch}
              keyMap={KEY_MAP}
              placeholder="Search..."
              elevation={2}
              chipBackgroundColor="#E8F5E1"
              chipColor="#255915"
            />
          </div>
          <ListHeader title="My Products" />
          <Datagrid
            data={data}
            headings={HEADINGS}
            renderRow={renderProductsRow}
            expandable
            handleExpand={handleExpand}
            expandHeader=""
            sliderCell
            sliderCellName="qty_remaining"
            sliderCelleader="Remaining"
            handleSliderChange={handleSliderChange}
            sliderColor="#9de89b"
          />
        </div>
        <p className="my-5" style={{ fontWeight: 500 }}>
          Your submission information is gathered for planning and scheduling purposes. You will be
          notified about the drop off address in your area when the schedule has been finalised.
        </p>
        <div className="mt-10 d-flex justify-content-end">
          <ListButton
            label="Back"
            size="medium"
            className="px-12 mr-3"
            boxShadow={false}
            onClick={handlePrevStep}
            variant="outlined"
            text="#64A70B"
          />
          <ListButton
            label="Submit"
            size="medium"
            className="px-12"
            boxShadow={false}
            onClick={onSubmit}
            disabled={submitting}
          />
        </div>
      </div>
    </>
  );
};
