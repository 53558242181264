/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { PricesList } from "./PricesList";
import { PriceCard } from "./PriceCard";

export const Prices = ({ name, organisationId }) => {
  const [component, setComponent] = useState("list");
  const [componentId, setComponentId] = useState("");
  const [pricesList, setPricesList] = useState([]);

  const getContactById = () => pricesList && pricesList.find(({ id }) => id === componentId);

  const handleExpand = (id) => () => {
    setComponentId(id);
    setComponent("edit");
  };

  const handleNew = () => {
    setComponent("new");
  };

  const handleCancel = () => {
    setComponent("list");
  };

  const componentsMap = {
    list: (
      <PricesList
        setPricesList={setPricesList}
        data={pricesList}
        name={name}
        handleExpand={handleExpand}
        handleNew={handleNew}
        organisationId={organisationId}
      />
    ),
    new: <PriceCard handleClose={handleCancel} organisationId={organisationId} />,
    edit: (
      <PriceCard
        data={getContactById()}
        id={componentId}
        handleClose={handleCancel}
        organisationId={organisationId}
      />
    ),
  };

  return <>{componentsMap[component]}</>;
};
