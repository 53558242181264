/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import Tabs from "@bit/the-glue.frontendcomponents.tabs";
import { getUser } from "./_redux/selectors";
import { getUserById, getUserGroups } from "./_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { UserDetails } from "./UserDetails";
import { modifyUserData } from "./helpers";
import { UserPermissions } from "./UserPermissions";
import { UserOrgs } from "./UserSites";
import { getGroups } from "../../../helpers/permissionsAPI";
import { Activity } from "./Activity";

export const Card = ({
  match: {
    params: { id }
  }
}) => {
  const { request } = useFetch();
  const data = useSelector(getUser(id));

  const [cardData, setCardData] = useState({});
  const [userGroups, setUserGroups] = useState([]);
  const [groupsList, setGroupsList] = useState([]);

  useEffect(() => {
    if (!data) {
      request(getUserById, id).then(
        data => data && setCardData(modifyUserData(data))
      );
    } else {
      setCardData(data);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    Promise.all([request(getUserGroups, id), request(getGroups)]).then(
      ([userGroups, groups]) => {
        userGroups && setUserGroups(userGroups);
        groups && setGroupsList(groups);
      }
    );
  }, []);

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  const { first_name, last_name } = cardData;

  const name = [first_name, last_name].join(" ");

  const tabs = [
    {
      label: "Profile",
      tabPanel: <UserDetails data={cardData} name={name} id={id} />
    },
    {
      label: "Permissions",
      tabPanel: (
        <UserPermissions
          name={name}
          id={id}
          groupsList={groupsList}
          userGroups={userGroups}
          setUserGroups={setUserGroups}
        />
      )
    },
    {
      label: "linked Organisations",
      tabPanel: <UserOrgs name={name} id={id} setCardData={setCardData} />
    },
    { label: "Activity", tabPanel: <Activity id={id} /> }
  ];

  return (
    <div className="mt-10">
      <Tabs
        defaultActive="0"
        tabs={tabs}
        align="right"
        text="rgba(241, 251, 243, 0.7)"
        selectedTabColor="white"
        selectedColor="#407A28"
      />
    </div>
  );
};
