/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { uniq } from "lodash";
import Skeleton from "@material-ui/lab/Skeleton";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { ReactComponent as IAuditorLogo } from "../../static/IAuditorLogo.svg";
import { ReactComponent as MoodleLogo } from "../../static/MoodleLogo.svg";
import { ReactComponent as QuickBooksLogo } from "../../static/QuickBooksLogo.svg";
import { ReactComponent as CampaignMonitor } from "../../static/CampaignMonitor.svg";
import { ReactComponent as MondayLogo } from "../../static/MondayLogo.svg";
import { ColumnChart } from "../../../ui/components/ColumnChart";
import { formatDate } from "../../../ui/helpers";
import { CustomerList } from "../../../ui/structures/CustomersList";
import { args, stateVolumesArgs, yearVolumedArgs } from "./constants";
import { ReimbursementsTile } from "../../../ui/structures/ReimbursementsTile";
import { AccessControl } from "../../../ui/structures/AccessControl";
import { AVAILABLE_ROLES } from "../../constants";
import { AdminTiles } from "../../../ui/structures/StatusTile/AdminTiles";
import { EndUserTiles } from "../../../ui/structures/StatusTile/EndUserTiles";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  getDailyCollections,
  getTopOrganisations,
  getCollectionsByYear,
  getCollectionsByRegion,
} from "./_api";
import { accessControlFunction } from "../../../ui/structures/AccessControl/AccessControlFunction";
import { Calendar } from "./Calendar";
import { getTasks } from "../Tasks/_api";
import { format } from "date-fns";
import "./StyleFixes.css";
import ToolTip from "../../../ui/components/ToolTip";
import { IconButton } from "@material-ui/core";

export const Dashboard = () => {
  const user = useSelector(({ auth: { user } }) => user) || {};
  const { request } = useFetch();

  const isAdminDashboard = accessControlFunction(
    [
      AVAILABLE_ROLES.NATIONAL_ADMIN,
      AVAILABLE_ROLES.AGSAFE_ADMIN,
      AVAILABLE_ROLES.AGSAFE_STAFF,
    ],
    user
  );
  const isConsultantDashboard = accessControlFunction(
    [AVAILABLE_ROLES.RC_ACCESS],
    user
  );
  const isCollectionDashboard = accessControlFunction(
    [AVAILABLE_ROLES.CA_ACCESS],
    user
  );
  const isProcessorDashboard = accessControlFunction(
    [AVAILABLE_ROLES.PROCESSOR],
    user
  );

  const handleOpenLink = (url) => () => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");
    link.setAttribute("rel", "noopener noreferrer");
    link.click();
  };

  const [topCustomers, setTopCustomers] = useState([]);
  const [dailyVolumes, setDailyVolumes] = useState({});
  const [eventTasks, setEventTasks] = useState([]);
  const [otherTasks, setOtherTasks] = useState([]);
  const [calendarLoading, setCalendarLoading] = useState(false);
  const [customersLoading, setCustomersLoading] = useState(false);
  const [collectionsByYear, setCollectionsByYear] = useState({});
  const [collectionsByYearLoading, setCollectionsByYearLoading] =
    useState(false);
  const [collectionsByState, setCollectionsByState] = useState({});
  const [collectionsByStateLoading, setCollectionsByStateLoading] =
    useState(false);

  useEffect(() => {
    setCalendarLoading(true);
    request(
      getTasks,
      `users.id=${user.id}&completed_date=_null_&page=0&page_size=250`
    )
      .then((data) => {
        if (!data) return;
        setEventTasks(
          data
            .filter(({ task_type }) => task_type === "event")
            .map(({ due_date, id, task_name, task_type }) => ({
              date: format(new Date(due_date), "yyyy,M,dd"),
              id,
              task_name,
              task_type,
            }))
        );
        setOtherTasks(
          data
            .filter(({ task_type }) => task_type !== "event")
            .map(({ due_date, id, task_name, task_type }) => ({
              date: format(new Date(due_date), "yyyy,M,dd"),
              id,
              task_name,
              task_type,
            }))
        );
      })
      .finally(() => setCalendarLoading(false));
  }, []);

  useEffect(() => {
    if (isAdminDashboard || isConsultantDashboard) {
      setCustomersLoading(true);
      request(getTopOrganisations)
        .then((data) => data && setTopCustomers(data))
        .finally(() => setCustomersLoading(false));
    }
  }, []);

  useEffect(() => {
    request(getDailyCollections).then((data) => data && setDailyVolumes(data));
  }, []);

  useEffect(() => {
    setCollectionsByYearLoading(true);
    request(getCollectionsByYear)
      .then((data) => data && setCollectionsByYear(data))
      .finally(() => setCollectionsByYearLoading(false));
  }, []);

  useEffect(() => {
    setCollectionsByStateLoading(true);
    request(getCollectionsByRegion)
      .then((data) => data && setCollectionsByState(data))
      .finally(() => setCollectionsByStateLoading(false));
  }, []);

  const getDaysOrder = (dailyVolumes = {}) => {
    if (!dailyVolumes) return;
    const lastWeekTestsCount = Object.keys(dailyVolumes)
      .reverse()
      .map((key) => {
        const dateParts = key.split("/");
        const formatedDate = new Date(
          `${dateParts[0]}/${dateParts[1]}/${dateParts[2]}`
        );
        return formatedDate.toString().split(" ")[0];
      });
    return lastWeekTestsCount;
  };

  const getStateVolumesSeries = () => {
    const materials =
      uniq(
        Object.values(collectionsByState)
          .map((stateObject) => Object.keys(stateObject))
          .reduce((acc, value) => [...acc, ...value], [])
      ) || [];
    return materials.map((name) => ({
      name: name.charAt(0).toUpperCase() + name.slice(1),
      data: Object.values(collectionsByState)
        .map((stateObject) => stateObject[name])
        .filter(Boolean),
    }));
  };

  const getYearVolumeSeries = () => {
    const currentYear = new Date().getFullYear();
    const lastYearData = Object.values(collectionsByYear).map(
      (state) => state[currentYear - 1]
    );
    const thisYearData = Object.values(collectionsByYear).map(
      (state) => state[currentYear]
    );
    const series = [
      {
        name: "This Year",
        data: thisYearData,
      },
      {
        name: "Last Year",
        data: lastYearData,
      },
    ];
    return series;
  };

  return (
    <div>
      <div
        className="calendar position-absolute"
        style={{ top: 0, right: "20px" }}
      >
        <ToolTip
          title={
            <span className="px-4 py-2 d-block">
              <span className="d-flex mb-3">
                <span
                  style={{
                    background: "#2196F3",
                    width: 20,
                    height: 20,
                    borderRadius: "50%",
                  }}
                ></span>
                <span className="ml-3 d-block"> - Event Tasks</span>
              </span>
              <span className="d-flex mb-3">
                <span
                  style={{
                    background: "#64A70B",
                    width: 20,
                    height: 20,
                    borderRadius: "50%",
                  }}
                ></span>
                <span className="ml-3 d-block"> - Other Tasks</span>
              </span>
              <span className="d-flex mb-3">
                <span
                  style={{
                    background: "#5B8D88",
                    width: 20,
                    height: 20,
                    borderRadius: "50%",
                  }}
                ></span>
                <span className="ml-3 d-block">{` - Event & Other (same due date)`}</span>
              </span>
              <span className="d-flex mb-3">
                <span
                  style={{
                    background: "#7fdbff",
                    width: 20,
                    height: 20,
                    borderRadius: "50%",
                  }}
                ></span>
                <span className="ml-3 d-block"> - Current Date</span>
              </span>
            </span>
          }
          arrow
          interactive
          placement="top"
          content={
            <IconButton>
              <HelpOutlineIcon fontSize="large" />
            </IconButton>
          }
        />
      </div>
      <div className="date">{formatDate(new Date(Date.now()))}</div>
      <div className="d-flex justify-content-between dashboard-wrapper">
        <div className="first-column">
          <AccessControl
            desiredRoles={[
              AVAILABLE_ROLES.NATIONAL_ADMIN,
              AVAILABLE_ROLES.AGSAFE_ADMIN,
              AVAILABLE_ROLES.AGSAFE_STAFF,
              AVAILABLE_ROLES.RC_ACCESS,
            ]}
            elseContent={
              <EndUserTiles
                user={user}
                isCollectionDashboard={isCollectionDashboard}
                isProcessorDashboard={isProcessorDashboard}
              />
            }
          >
            <AdminTiles user={user} />
          </AccessControl>
          <div className="bg-white p-5">
            {collectionsByStateLoading ? (
              <Skeleton variant="rect" width={"100%"} height={302} />
            ) : (
              <ColumnChart
                options={{
                  ...stateVolumesArgs,
                  categories: Object.keys(collectionsByState),
                }}
                series={getStateVolumesSeries()}
                id="state"
                height="302"
              />
            )}
          </div>
        </div>
        <div className="second-column">
          <div className="calendar">
            {calendarLoading ? (
              <Skeleton variant="rect" width={"100%"} height={302} />
            ) : (
              <Calendar eventTasks={eventTasks} otherTasks={otherTasks} />
            )}
          </div>
          <div className="bg-white p-5 big-daily">
            {Object.keys(dailyVolumes).length ? (
              <ColumnChart
                options={{ ...args, categories: getDaysOrder(dailyVolumes) }}
                series={[
                  {
                    name: "Daily Test Volumes",
                    data: Object.values(dailyVolumes),
                  },
                ]}
                id="daily"
              />
            ) : (
              <Skeleton variant="rect" width={"100%"} height={250} />
            )}
          </div>
        </div>
      </div>
      <div className="d-flex mt-10 justify-content-between bottom-wrapper">
        <div className="customers">
          <AccessControl
            desiredRoles={[
              AVAILABLE_ROLES.NATIONAL_ADMIN,
              AVAILABLE_ROLES.AGSAFE_ADMIN,
              AVAILABLE_ROLES.AGSAFE_STAFF,
              AVAILABLE_ROLES.RC_ACCESS,
            ]}
            elseContent={
              <ReimbursementsTile
                isCollectionDashboard={isCollectionDashboard}
                isProcessorDashboard={isProcessorDashboard}
              />
            }
          >
            <CustomerList
              data={topCustomers}
              title="Top 5 Organisation Liabilities"
              loading={customersLoading}
            />
          </AccessControl>
        </div>
        <div className="bg-white p-5 small-daily">
          {Object.keys(dailyVolumes).length ? (
            <ColumnChart
              options={{ ...args, categories: getDaysOrder(dailyVolumes) }}
              series={[
                {
                  name: "Daily Test Volumes",
                  data: Object.values(dailyVolumes),
                },
              ]}
              id="daily"
            />
          ) : (
            <Skeleton variant="rect" width={"100%"} height={250} />
          )}
        </div>
        <div className="bg-white px-5 pt-5 sales">
          {collectionsByYearLoading ? (
            <Skeleton variant="rect" width={"100%"} height={302} />
          ) : (
            <ColumnChart
              options={{
                ...yearVolumedArgs,
                categories: Object.keys(collectionsByYear),
              }}
              series={getYearVolumeSeries()}
              id="sales"
              height="307"
            />
          )}
        </div>
      </div>
      <AccessControl
        desiredRoles={[
          AVAILABLE_ROLES.NATIONAL_ADMIN,
          AVAILABLE_ROLES.AGSAFE_ADMIN,
          AVAILABLE_ROLES.AGSAFE_STAFF,
          AVAILABLE_ROLES.RC_ACCESS,
        ]}
      >
        <div className="d-flex justify-content-between align-items-center mt-6">
          <div
            className="bg-white rounded d-flex align-items-center justify-content-center py-2 cursor-pointer"
            style={{ minWidth: "200px" }}
            onClick={handleOpenLink("https://drummuster.Monday.com")}
          >
            <MondayLogo />
          </div>
          <div
            className="bg-white text-center rounded d-flex align-items-center justify-content-center py-2 cursor-pointer"
            style={{ minWidth: "200px" }}
            onClick={handleOpenLink("https://app.createsend.com")}
          >
            <CampaignMonitor />
          </div>
          <div
            className="bg-white text-center rounded d-flex align-items-center justify-content-center py-2 cursor-pointer"
            style={{ minWidth: "200px" }}
            onClick={handleOpenLink("https://app.qbo.intuit.com/")}
          >
            <QuickBooksLogo />
          </div>
          <div
            className="bg-white text-center rounded d-flex align-items-center justify-content-center py-2 cursor-pointer"
            style={{ minWidth: "200px" }}
            onClick={handleOpenLink("https://lms.agsafe.org.au/")}
          >
            <MoodleLogo />
          </div>
          <div
            className="bg-white text-center rounded d-flex align-items-center justify-content-center py-2 cursor-pointer"
            style={{ minWidth: "200px" }}
            onClick={handleOpenLink("https://app.safetyculture.com")}
          >
            <IAuditorLogo />
          </div>
        </div>
      </AccessControl>
    </div>
  );
};
