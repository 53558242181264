import config from "react-global-configuration";
import { error } from "../../../helpers/toasts";

const getDownloadUrl = (data) => {
  if (data.download_url) return data.download_url;
  else if (data.url) return data.url;
  else return "";
};

export const downloadFile = (
  organisation_id,
  file_id,
  setLoading,
  getUrl = getDownloadFileUrl,
  ...props
) => {
  return getUrl(organisation_id, file_id, props)
    .then((data) => data.json())
    .then((data) => data && startDownload(getDownloadUrl(data.data)))
    .catch((err) => error((err || {}).message))
    .finally(() => setLoading && setLoading(false));
};

const getDownloadFileUrl = (organisation_id, file_id) =>
  fetch(`${config.get("ApiUrl").Rest}/organisations/${organisation_id}/files/${file_id}`, {
    mode: "cors",
    credentials: "include",
  });

const startDownload = (url) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("target", "_blank");
  link.setAttribute("rel", "noopener noreferrer");
  link.click();
};
