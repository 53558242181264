const type = "consultants";

export const setConsultantsist = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      consultantsList: payload,
    };
  },
  type,
});

export const removeConsultant = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      consultantsList: state.consultantsList.filter(({ id }) => id !== payload),
    };
  },
  type,
});

export const unArchiveConsultant = (payload) => ({
  execute: (state) => {
    const index = state.consultantsList.findIndex((item) => item.id === payload.id);
    const myState = [...state.consultantsList];
    myState.splice(index, 1, payload);
    return {
      ...state,
      consultantsList: [...myState],
    };
  },
  type,
});
