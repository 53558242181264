/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";
import { format, subDays } from "date-fns";
import { useFetch } from "../../../hooks/fetch.hook";
import { endUsertilesMap } from "../../../app/modules/Dashboard/constants";
import { getTasks } from "../../../app/modules/Tasks/_api";
import { ReactComponent as DrumsIcon } from "../../static/DrumsIcon.svg";
import "./StatusTile.css";
import {
  getInspectedContainers,
  getProcessedContainers,
} from "../../../app/modules/Dashboard/_api";

export const EndUserTiles = ({ user = {}, isCollectionDashboard, isProcessorDashboard }) => {
  const { request } = useFetch();

  const [currentTasks, setCurrentTasks] = useState("");
  const [overdueTasks, setOverduetasks] = useState("");
  const [drums, setDrums] = useState("");

  const quantityMap = {
    Drums: drums,
    "Overdue Tasks": overdueTasks,
    "Current Tasks": currentTasks,
  };

  useEffect(() => {
    request(
      getTasks,
      `count_only=y&users.id=${user.id}&completed_date=_null_&gte-due_date=${format(
        subDays(new Date(Date.now()), 1),
        "yyyy/MM/dd"
      )}`
    ).then((data) => {
      if (!data && data !== 0) return;
      setCurrentTasks(data);
    });
    request(
      getTasks,
      `count_only=y&completed_date=_null_&lte-due_date=${format(
        new Date(Date.now()),
        "yyyy/MM/dd"
      )}`
    ).then((data) => {
      if (!data && data !== 0) return;
      setOverduetasks(data);
    });
  }, []);

  useEffect(() => {
    if (isProcessorDashboard) {
      request(getProcessedContainers).then((data) => data && setDrums(data));
    } else {
      request(getInspectedContainers).then((data) => data && setDrums(data));
    }
  }, []);

  return (
    <div className="d-flex justify-content-between mb-10">
      {endUsertilesMap.map(({ title, icon, path }) => (
        <Link
          to={{
            pathname: path,
          }}
        >
          <div className="py-7 text-center tile-wrapper">
            <div className="mb-5">{icon}</div>
            <div style={{ fontSize: "2rem" }} className="mb-3 text-center">
              {quantityMap[title] || quantityMap[title] === 0 ? (
                quantityMap[title]
              ) : (
                <div style={{ position: "relative", left: "42%" }}>
                  <Skeleton variant="circle" width={40} height={40} />
                </div>
              )}
            </div>
            <div style={{ fontSize: "1rem" }}>{title}</div>
          </div>
        </Link>
      ))}
      <div className="py-7 text-center tile-wrapper">
        <div className="mb-5">{<DrumsIcon />}</div>
        <div style={{ fontSize: "2rem" }} className="mb-3 text-center">
          {drums || drums === 0 ? (
            drums
          ) : (
            <div style={{ position: "relative", left: "42%" }}>
              <Skeleton variant="circle" width={40} height={40} />
            </div>
          )}
        </div>
        <div style={{ fontSize: "1rem" }}>Drums Processed</div>
      </div>
    </div>
  );
};
