export const HEADINGS = [
  ["name", "Badge Name"],
  ["description", "Description"],
  ["contact_name", "Contact"],
  ["training_name", "Training"],
  ["expiry_in_months", "Training Validity"],
  ["status", "Status"]
];

export const searchMap = {
  "Badge Name": "name",
  "Contact First Name": "contact.first_name",
  "Contact Last Name": "contact.last_name"
};
