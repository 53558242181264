import { contactTypes } from "./actions";

const initialState = {
  contactsList: [],
};

export function contactsReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case contactTypes.SET_DATA:
      return {
        ...state,
        contactsList: payload,
      };

    case contactTypes.REMOVE_CONTACT:
      return {
        ...state,
        contactsList: [...state.contactsList.filter(({ id }) => id !== payload)],
      };

    case contactTypes.UPDATE_DATA:
      const index = state.contactsList.findIndex((item) => (item || {}).id === (payload || {}).id);
      return {
        ...state,
        contactsList: [
          ...state.contactsList.slice(0, index),
          payload,
          ...state.contactsList.slice(index + 1),
        ],
      };

    default:
      return state;
  }
}
