export const HEADINGS = [
  ["first_name", "First Name"],
  ["last_name", "Last Name"],
  ["email", "Email"],
  ["status", "Status"],
];

export const searchMap = {
  "First Name": "first_name",
  "Last Name": "last_name",
  Email: "email",
};
