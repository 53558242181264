import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";

export const CalculatedTotal = ({ type = "" }) => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (type === "Inspection") {
      if (!values.inspection_fees && !values.inspection_misc_charge) {
        setFieldValue("calculated_invoice_total", "0.00");
        return;
      }
      setFieldValue(
        "calculated_invoice_total",
        (
          Number(values.inspection_misc_charge) + Number(values.inspection_fees)
        ).toFixed(2)
      );
    } else if (type === "Processing") {
      if (!values.processing_fees && !values.processing_misc_charge) {
        setFieldValue("calculated_invoice_total", "0.00");
        return;
      }
      setFieldValue(
        "calculated_invoice_total",
        (
          Number(values.processing_misc_charge) + Number(values.processing_fees)
        ).toFixed(2)
      );
    } else if (type === "Combined") {
      setFieldValue(
        "calculated_invoice_total",
        (
          Number(values.processing_misc_charge) +
          Number(values.processing_fees) +
          Number(values.inspection_misc_charge) +
          Number(values.inspection_fees)
        ).toFixed(2)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.inspection_misc_charge,
    values.inspection_fees,
    values.processing_misc_charge,
    values.processing_fees
  ]);

  return (
    <MappedInput
      name="calculated_invoice_total"
      label="Calculated Invoice Total (ex GST)"
      inputWrapperStyle={{ marginBottom: "20px" }}
      disabled
    />
  );
};
