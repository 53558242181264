import React, { useState, useEffect, useMemo } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { productDetailsSchema } from "../../../helpers/schemas";
import { useFetch } from "../../../hooks/fetch.hook";
import { Autocomplete } from "../../../ui/components/Autocomplete";
import { SubmitActions } from "../../../ui/structures/SubmitActions";
import { info } from "../../../helpers/toasts";
import { addProduct, modifyProduct } from "./_api";
import { modifyEmptyFields, sortHelper } from "../../../ui/helpers";
import { Loader } from "../../../ui/components/Loader";
import { typeOptions } from "./constants";
import { GroupSelect } from "../Settings/PackSizes/GroupSelect";
import { getSimpleOrganisations } from "../Organisations/_api";
import { Skeleton } from "@material-ui/lab";

export const ProductDetails = ({ data = {}, id, productName }) => {
  const history = useHistory();
  const { request } = useFetch();

  const [submitAction, setSubmitAction] = useState("Save");
  const [loading, setLoading] = useState(false);
  const [organisations, setOrganisations] = useState([]);
  const [orgsLoading, setOrgsLoading] = useState(false);

  const {
    name,
    manufacturer,
    product_type,
    product_group,
    common_product_name,
    expiry_date,
  } = data;

  const initialValues = {
    name: name || "",
    manufacturer: manufacturer || "",
    product_type: product_type || "",
    product_group: product_group || "",
    common_product_name: common_product_name || "",
    expiry_date: expiry_date || "",
    legacy_manufacturer: manufacturer || "",
  };

  useEffect(() => {
    setOrgsLoading(true);
    request(getSimpleOrganisations)
      .then((data) => data && setOrganisations(data))
      .finally(() => setOrgsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const organiastionOptions = useMemo(
    () =>
      organisations
        .map((organiastion) => ({
          value: organiastion.business_name,
          label: organiastion.business_name,
        }))
        .sort(sortHelper),
    [organisations]
  );

  const createProduct = (values, setSubmitting) => {
    setLoading(true);
    request(addProduct, values)
      .then((data) => data && history.push("/products-list"))
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const updateProduct = (values, setSubmitting) => {
    setLoading(true);
    request(modifyProduct, values, id)
      .then((data) => {
        if (!data) return;
        info("Product details have been updated!");
        submitAction === "Save & Exit" && history.push("/products-list");
      })
      .finally(() => {
        setSubmitting(false);
        setLoading(false);
      });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    modifyEmptyFields(values);
    id
      ? updateProduct(values, setSubmitting)
      : createProduct(values, setSubmitting);
  };

  const handleCancel = () => history.push("/products-list");

  return (
    <div className="border border-secondary bg-white p-10">
      <Loader
        isOpen={loading}
        maxWidth="xs"
        disableBackdropClick
        disableEscapeKeyDown
      />
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={productDetailsSchema}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, values, ...formik }) => (
          <>
            <h3 className="mb-5">
              <strong>{id ? productName : "New Product"}</strong>
            </h3>
            <div className="row justify-content-between">
              <div className="col-8">
                <MappedInput
                  label="Product Name"
                  name="name"
                  data-testid="name"
                />
                {id ? (
                  <MappedInput
                    label="Legacy Manufacturer"
                    name="legacy_manufacturer"
                    disabled
                    data-testid="legacy_manufacturer"
                  />
                ) : null}
                <div className="my-4">
                  {orgsLoading ? (
                    <Skeleton variant="rect" width={"100%"} height={45} />
                  ) : (
                    <>
                      <Autocomplete
                        name="manufacturer"
                        placeholder="Select Manufacturer"
                        setValue={setFieldValue}
                        options={organiastionOptions}
                        defaultValue={manufacturer}
                        data-testid="manufacturer"
                        defaultValueField="value"
                      />
                      {formik.touched.manufacturer &&
                      formik.errors.manufacturer ? (
                        <div className="text-danger">
                          {formik.errors.manufacturer}
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
                <MappedSelect
                  label="Type"
                  name="product_type"
                  data-testid="product_type"
                  options={typeOptions}
                />
                <GroupSelect />
                <MappedInput
                  label="Common Product Name"
                  name="common_product_name"
                  data-testid="common_product_name"
                  multiline
                  rows={8}
                />
              </div>
            </div>
            <SubmitActions
              id={id}
              handleCancel={handleCancel}
              disabled={isSubmitting}
              submitAction={submitAction}
              handleSubmit={handleSubmit}
              setSubmitAction={setSubmitAction}
            />
          </>
        )}
      </Formik>
    </div>
  );
};
