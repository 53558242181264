/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { ContactDetails } from "./ContactDetails";
import Tabs from "@bit/the-glue.frontendcomponents.tabs";
import { ContactTrainings } from "../../modules/Contacts/ContactTrainings";

export function ContactCard({
  data = {},
  id,
  handleCancel,
  organiastionOptions = [],
  linkedOrganisations = [],
  organisationId,
  tags = [],
  contacts = [],
  linkedTags = [],
  setLinkedTags,
}) {
  const tabs = [
    {
      label: "Profile",
      tabPanel: (
        <ContactDetails
          data={data}
          id={id}
          handleCancel={handleCancel}
          organiastionOptions={organiastionOptions}
          linkedOrganisations={linkedOrganisations}
          organisationId={organisationId}
          tags={tags}
          contacts={contacts}
          linkedTags={linkedTags}
          setLinkedTags={setLinkedTags}
        />
      ),
    },
    {
      label: "Training",
      tabPanel: <ContactTrainings contact={data} />,
    },
  ];

  return (
    <div>
      <Tabs
        defaultActive="0"
        tabs={tabs}
        align="left"
        background="white"
        selectedColor="#304FFD"
        boxShadow={false}
        text="rgb(138, 144, 153)"
        selectedTabColor="white"
      />
    </div>
  );
}
