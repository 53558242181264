import React from "react";
import Tabs from "@bit/the-glue.frontendcomponents.tabs";
import { Requests } from "./Requests";
import { Statistics } from "./Statistics";
import { Completed } from "./Completed";
import { Scheduling } from "./Scheduling";

const mockedRequests = [
  {
    id: "CC0001",
    state: "NSW",
    region: "NSW-Metro",
    date_requested: "2021-09-10T22:14:59.848000+10:00",
    volume: "1kg",
    group: "1,2",
    source: "Web",
    status: "Quote Accepted",
    nearest_cp: "XYZ Waste Transfer",
  },
  {
    id: "CC0002",
    state: "NSW",
    region: "NSW-Regional",
    date_requested: "2021-09-10T22:14:59.848000+10:00",
    volume: "20L",
    group: "2",
    source: "Phone",
    status: "Quote Accepted",
    nearest_cp: "BSC",
  },
  {
    id: "CC0003",
    state: "NSW",
    region: "NSW-Metro",
    date_requested: "2021-09-10T22:14:59.848000+10:00",
    volume: "1kg",
    group: "1,2",
    source: "Web",
    status: "Quote Accepted",
    nearest_cp: "XYZ Waste Transfer",
  },
  {
    id: "CC0004",
    state: "NSW",
    region: "NSW-Regional",
    date_requested: "2021-09-10T22:14:59.848000+10:00",
    volume: "20L",
    group: "2",
    source: "Phone",
    status: "Quote Accepted",
    nearest_cp: "BSC",
  },
];

export const ChemClear = () => {
  const tabs = [
    {
      label: "Requests",
      tabPanel: <Requests data={mockedRequests} />,
    },
    {
      label: "Scheduling",
      tabPanel: <Scheduling />,
    },
    {
      label: "Completed",
      tabPanel: <Completed />,
    },
    {
      label: "Statistics",
      tabPanel: <Statistics />,
    },
  ];

  return (
    <div>
      <Tabs
        defaultActive="0"
        tabs={tabs}
        align="right"
        text="rgba(241, 251, 243, 0.7)"
        selectedTabColor="white"
        selectedColor="#407A28"
      />
    </div>
  );
};
