import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";

export const InvoiceTotal = () => {
  const { values } = useFormikContext();
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const calculatedTotal = (
      Number(values.calculated_invoice_total) + Number(values.gst)
    ).toFixed(2);
    if (Number(calculatedTotal) === Number(values.invoice_total))
      setMatches(true);
    else setMatches(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.invoice_total, values.calculated_invoice_total, values.gst]);

  return (
    <div>
      <MappedInput name="invoice_total" label="Your Invoice Total (inc GST)" />
      {matches ? (
        <p className="text-primary mt-5">Your Totals Match</p>
      ) : (
        <p className="text-danger mt-5">
          Totals don’t match, please check before submitting
        </p>
      )}
    </div>
  );
};
