import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { Skeleton } from "@material-ui/lab";
import React from "react";

export const LGARegionSelect = ({
  values = {},
  lgaLoading,
  lgaRegions = [],
  lgaRequired = true
}) => {
  const renderLGARegions = () => {
    if (lgaLoading) {
      return (
        <div className="mb-2">
          <Skeleton variant="rect" width={"100%"} height={50} />
        </div>
      );
    } else if (lgaRegions.length) {
      return (
        <MappedSelect
          label="LGA Region"
          options={lgaRegions}
          name="lga_region"
          data-testid="lga_region"
          disabled={!values.physical_postcode || !lgaRegions.length}
        />
      );
    } else
      return (
        <>
          <div
            style={{
              paddingTop: 8,
              paddingLeft: 12,
              backgroundColor: "rgba(0,0,0,0.04)",
              height: 54,
              borderBottom: "1px solid rgb(70, 78, 95)"
            }}
          >
            <div
              style={{
                fontSize: 12,
                color: "#255915",
                letterSpacing: "0.4px"
              }}
            >
              LGA Region
            </div>
            No available LGA Regions for this postcode
          </div>
          {lgaRequired ? (
            <p className="text-danger ml-2 mt-1">
              LGA Region is a required field
            </p>
          ) : null}
        </>
      );
  };
  return renderLGARegions();
};
