import React, { useEffect } from "react";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { useFormikContext } from "formik";

export const NewDropForm = ({ data = {} }) => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue("new_plastic_0", data.plastic_0 - values.plastic_0);
    setFieldValue("new_plastic_1", data.plastic_1 - values.plastic_1);
    setFieldValue("new_plastic_2", data.plastic_2 - values.plastic_2);
    setFieldValue("new_plastic_3", data.plastic_3 - values.plastic_3);
    setFieldValue("new_steel_0", data.steel_0 - values.steel_0);
    setFieldValue("new_steel_1", data.steel_1 - values.steel_1);
    setFieldValue("new_steel_2", data.steel_2 - values.steel_2);
    setFieldValue("new_steel_3", data.steel_3 - values.steel_3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.plastic_0,
    values.plastic_1,
    values.plastic_2,
    values.plastic_3,
    values.steel_0,
    values.steel_1,
    values.steel_2,
    values.steel_3,
  ]);
  return (
    <>
      <h3 className=" mb-7 mt-3">New Drop-off</h3>
      <div className="d-flex justify-content-between">
        <div className="w-50">
          <h4 className="ml-3 mb-5">Plastic</h4>
          <div className="d-flex align-items-center">
            <div className="col-3">Less than 20</div>
            <div className="col-8 ">
              <MappedInput name="new_plastic_0" disabled />
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="col-3">20-25</div>
            <div className="col-8 ">
              <MappedInput name="new_plastic_1" disabled />
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="col-3">26-120</div>
            <div className="col-8 ">
              <MappedInput name="new_plastic_2" disabled />
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="col-3">121-205</div>
            <div className="col-8">
              <MappedInput name="new_plastic_3" disabled />
            </div>
          </div>
        </div>
        <div className="w-50">
          <h4 className="ml-3 mb-5">Steel</h4>
          <div className="d-flex align-items-center">
            <div className="col-3">Less than 20</div>
            <div className="col-8">
              <MappedInput name="new_steel_0" disabled />
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="col-3">20-25</div>
            <div className="col-8">
              <MappedInput name="new_steel_1" disabled />
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="col-3">26-120</div>
            <div className="col-8">
              <MappedInput name="new_steel_2" disabled />
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="col-3">121-205</div>
            <div className="col-8">
              <MappedInput name="new_steel_3" disabled />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
