import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import ButtonGroup from "@bit/the-glue.frontendcomponents.button-group";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";

export const SMSForm = ({
  title,
  handleSubmit,
  handleClose,
  data,
  titleClassName,
}) => {
  const initialValues = {
    text: "",
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={Yup.object({
        text: Yup.string().required("SMS Text is a required field"),
      })}
    >
      {({ handleSubmit, isSubmitting }) => (
        <div className="px-10 py-5" data-testid="sms-form">
          <h3 className={`${titleClassName}`}>
            <strong>{title}</strong>
          </h3>
          <div className="mt-5">
            <MappedInput
              name="text"
              multiline
              rows={16}
              label="SMS (160 Characters Max)"
              data-testid="sms-text"
            />
          </div>
          <div className="mt-15">
            <ButtonGroup
              handleClose={handleClose}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              textTransofrm="uppercase"
              size="medium"
            />
          </div>
        </div>
      )}
    </Formik>
  );
};
