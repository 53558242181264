import React from "react";
import { get } from "lodash";
import { Link } from "react-router-dom";
import { getStatusRowStyle, isLink, isStatus } from "../../../ui/helpers";

export const renderUsers = (headings, item) => {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-5 ${isStatus(key) &&
        getStatusRowStyle(item)}`}
    >
      {isLink(key) ? (
        <Link className="text-dark" to={`/users/${item.id}`}>
          {item[key]}
        </Link>
      ) : (
        item[key]
      )}
    </td>
  ));
};

export const modifyUsers = (data) => data.map((user) => modifyUserData(user));

export const modifyUserData = (user) => {
  return {
    ...user,
    first_name: get(user, "name", "").split(" ")[0],
    last_name: get(user, "name", "").split(" ")[1],
    email: get(user, "user_info.email", ""),
    phone_number: get(user, "user_info.phone_number", ""),
    region: get(user, "region.long_name", ""),
    consultant: [
      get(user, "consultant.first_name", ""),
      get(user, "consultant.last_name", ""),
    ].join(" "),
  };
};

export const modifyUserNameSearchValue = (search) => {
  if (search.includes(encodeURIComponent("first_name"))) {
    return search.replace(encodeURIComponent("first_name"), "name");
  } else if (search.includes(encodeURIComponent("last_name"))) {
    return search.replace(encodeURIComponent("last_name"), "name");
  }
  return search;
};

export const modifySearchValue = (field = "") => {
  if (field.includes("first_name")) {
    return field.replace("first_name", "name");
  }
  return field;
};
