import { Skeleton } from "@material-ui/lab";
import { get, uniqBy } from "lodash";
import React, { useState, useEffect, useMemo } from "react";
import { useFetch } from "../../../hooks/fetch.hook";
import { SuggestedRoute } from "./SuggestedRoute";
import { setID } from "../DrumMuster/Inspections/helpers";
import { Autocomplete } from "../../../ui/components/Autocomplete";
import { ListButton } from "../../../ui/components/ListButton";
import { modifyClearance } from "./_api";
import { info } from "../../../helpers/toasts";
import { Loader } from "../../../ui/components/Loader";

export const PlanRoute = ({ collectionRuns = [], runsLoading }) => {
  const { request } = useFetch();

  const [selectedRun, setSelectedRun] = useState({});
  const [legs, setLegs] = useState([]);
  const [resetKey, setResetKey] = useState(1);
  const [totalVolume, setTotalVolume] = useState(0);
  const [sites, setSites] = useState([]);
  const [processorAddress, setProcessorAddress] = useState("");
  const [actionLoading, setActionLoading] = useState(false);
  const [sitesWithCoordinates, setSitesWithCoordinates] = useState([]);

  const processorOptions = useMemo(
    () =>
      collectionRuns.map(run => ({
        value: [
          get(run, "processor_id", ""),
          get(run, "planned_pickup_date", "")
        ].join(","),
        label: [
          get(run, "processor.business_name", ""),
          get(run, "planned_pickup_date", "")
        ].join(" - ")
      })),
    [collectionRuns]
  );

  const setSelectedProcessor = (name, value) => {
    setSelectedRun(
      collectionRuns.find(
        ({ processor_id, planned_pickup_date }) =>
          [processor_id, planned_pickup_date].join(",") === value
      )
    );
    setResetKey(Date.now());
  };

  useEffect(() => {
    if (!selectedRun) return;
    if (!Object.keys(selectedRun).length) return;
    const totalVolume = (selectedRun.clearance_jobs || [])
      .map(({ volume }) => volume)
      .reduce((acc, value) => acc + value, 0);
    const processorAddress = [
      get(selectedRun, "processor.physical_state"),
      get(selectedRun, "processor.physical_city"),
      get(selectedRun, "processor.physical_address"),
      get(selectedRun, "processor.physical_postcode")
    ].join(", ");

    const sites = (uniqBy(selectedRun.clearance_jobs, "site_id") || []).map(
      job => ({
        address: [
          get(job, "site.physical_state"),
          get(job, "site.physical_city"),
          get(job, "site.physical_address"),
          get(job, "site.physical_postcode")
        ].join(", "),
        site_name: get(job, "site.name"),
        pickup_contact: [
          get(job, "site.processor_pickup_scheduling_contact.first_name", ""),
          get(job, "site.processor_pickup_scheduling_contact.last_name", "")
        ].join(" "),
        pickup_phone: get(
          job,
          "site.processor_pickup_scheduling_contact.phone",
          ""
        ),
        id: get(job, "site.id")
      })
    );

    setTotalVolume(totalVolume);
    setSites(sites);
    setProcessorAddress(processorAddress);
  }, [selectedRun]);

  if (runsLoading) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  const formatDuration = seconds => {
    var h = Math.floor(seconds / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minute " : " minutes ") : "";
    return hDisplay + mDisplay;
  };

  const handleApprove = () => {
    if (!selectedRun) return;
    setActionLoading(true);
    request(modifyClearance, selectedRun.id, { status: "active" })
      .then(data => {
        if (!data) return;
        info("Route has been approved!");
      })
      .finally(() => setActionLoading(false));
  };

  const findSite = location => {
    const locotaion_lat = location.lat();
    const location_lng = location.lng();
    const site = sitesWithCoordinates.find(({ lat, lng }) => {
      if (
        ![lat, lng, location_lng, location_lng].every(
          item => typeof item === "number"
        )
      )
        return {};
      return (
        lat.toFixed(0) === locotaion_lat.toFixed(0) &&
        lng.toFixed(0) === location_lng.toFixed(0)
      );
    });
    if (!site) return {};
    return site;
  };

  const getSiteData = (start_location, end_location, index, legs = []) => {
    if (index === legs.length - 1) {
      return findSite(start_location);
    } else {
      return findSite(end_location);
    }
  };

  const handlePrint = () => window.print();

  return (
    <div className="border border-secondary bg-white p-10">
      <Loader
        isOpen={actionLoading}
        maxWidth="xs"
        disableBackdropClick
        disableEscapeKeyDown
      />
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="pt-3 mb-5">Select Processor</h3>
        <ListButton label="Print" onClick={handlePrint} />
      </div>
      <Autocomplete
        name="1"
        placeholder="Processor"
        setValue={setSelectedProcessor}
        defaultValueField="value"
        options={processorOptions}
        className="mb-3 w-50"
      />
      <h3 className="pt-3 mt-10 mb-5">Suggested Route Plan</h3>
      {collectionRuns.length ? (
        <>
          <div className="d-flex">
            <p className="mr-3">
              <strong>Total volume: </strong>
            </p>
            <p>{totalVolume}</p>
          </div>
          <div className="d-flex">
            <p className="mr-3">
              <strong>Processor Name: </strong>
            </p>
            <p>{get(selectedRun, "processor.business_name", "")}</p>
          </div>
          <div className="d-flex">
            <p className="mr-3">
              <strong>Processor Address: </strong>
            </p>
            <p>{processorAddress}</p>
          </div>
          <h4 className="mb-5">Clearance locations: </h4>
          {sites.map(({ address }) => (
            <p key={`${address} + ${setID(100000000)}`}>{address}</p>
          ))}
        </>
      ) : (
        <p>
          <strong>Not Found</strong>
        </p>
      )}
      <div className="d-flex" key={resetKey}>
        <div className="w-75">
          <SuggestedRoute
            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDue0HjL6FN1DXF6ghAaa_HrWLtZWOjyyE"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `600px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            processorAddress={processorAddress}
            sites={sites}
            setLegs={setLegs}
            selectedRun={selectedRun}
            setSitesWithCoordinates={setSitesWithCoordinates}
          />
        </div>
        <div className="w-25 ml-5">
          {legs.map(
            (
              {
                distance,
                duration,
                start_address,
                end_address,
                start_location,
                end_location
              },
              index
            ) => (
              <div key={index}>
                <h5>{`Route Segment: ${index + 1}`}</h5>
                <p>
                  <strong>Origin: </strong>
                  {start_address}
                </p>
                <p>
                  <strong>Stop: </strong>
                  {end_address}
                </p>
                <p>
                  <strong>Site Name: </strong>
                  {
                    getSiteData(start_location, end_location, index, legs)
                      ?.site_name
                  }
                </p>
                <p>
                  <strong>Site Pickup Contact: </strong>
                  {
                    getSiteData(start_location, end_location, index, legs)
                      ?.pickup_contact
                  }
                </p>
                <p>
                  <strong>Site Pickup Phone: </strong>
                  {
                    getSiteData(start_location, end_location, index, legs)
                      ?.pickup_phone
                  }
                </p>
                <p>
                  <strong>Distance: </strong>
                  {distance.text}
                </p>
                <p>
                  <strong>Duration: </strong>
                  {duration.text}
                </p>
              </div>
            )
          )}
          <div className="pt-5" style={{ borderTop: "1px solid black" }}>
            <p>
              <strong>Total Distance: </strong>
              {(
                legs.reduce((acc, value) => acc + value.distance.value, 0) /
                1000
              ).toFixed(2)}{" "}
              km
            </p>
            <p>
              <strong>Total Duration: </strong>
              {formatDuration(
                legs.reduce((acc, value) => acc + value.duration.value, 0)
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-10">
        <ListButton label="Request" onClick={handleApprove} size="large" />
      </div>
    </div>
  );
};
