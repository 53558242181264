import { get } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import {
  isLink,
  isStatus,
  getStatusRowStyle,
  formatLongRow,
  formatDate,
  isDate
} from "../../../../ui/helpers";

const renderDate = date => {
  if (!date) return null;
  else return formatDate(date);
};

export function renderBadgesRow(headings, item) {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-5 ${isStatus(key) &&
        getStatusRowStyle(item)}`}
    >
      {isLink(key) ? (
        <Link className="text-dark" to={`/badges/${item.id}`}>
          {item[key]}
        </Link>
      ) : key === "description" ? (
        formatLongRow(item[key], 70)
      ) : isDate(key) ? (
        renderDate(item[key])
      ) : (
        item[key]
      )}
    </td>
  ));
}
export function modifyBadge(item = {}) {
  return {
    ...item,
    training_name: get(item, "training.name"),
    expiry_in_months: get(item, "training.expiry_in_months"),
    contact_name: [
      get(item, "contact.first_name", ""),
      get(item, "contact.last_name", "")
    ].join(" ")
  };
}

export function modifyBadgesList(list = []) {
  return list.map(item => modifyBadge(item));
}
