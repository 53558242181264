export const materialOptions = [
  { label: "Plastic", value: "plastic" },
  { label: "Steel", value: "steel" },
];

export const initialValues = {
  volume: "",
  processor_id: "",
  planned_pickup_date: "",
  site_ids_and_materials: {},
};

export const SITE_HEADINGS = [
  ["indicator", ""],
  ["site_name", "Site Name"],
  ["last_stocktake", "Last Stocktake"],
  ["material", "Material"],
  ["size", "Size"],
  ["quantity", "Quantity"],
  ["capacity_left_percentage", "Capacity"],
  ["consultant_name", "Consultant"],
  ["region_long_name", "Region"],
  ["org_business_name", "Organisation"],
];

export const SCHEDULING_HEADINGS = [
  ["site_code", "Site ID"],
  ["site_physical_state", "State"],
  ["region_long_name", "Region"],
  ["last_stocktake", "Last Stocktake"],
  ["material", "Material"],
  ["quantity", "Volume"],
  ["capacity_left_percentage", "Capacity"],
  ["site_name", "Site Name"],
];

export const inventorySearchMap = {
  "Site Name": "name",
  Organisation: "organisation.business_name",
  Material: "material",
  Status: "status",
  "Consultant First Name": "organisation.consultant.first_name",
  "Consultant Last Name": "organisation.consultant.last_name",
  Region: "organisation.region.long_name",
};

export const INVENTORY_SEARCH_HELPER = [
  ["name", "Site Name"],
  ["organisation.business_name", "Organisation"],
  ["material", "Material"],
  ["status", "Status"],
  ["organisation.consultant.first_name", "Consultant First Name"],
  ["organisation.consultant.last_name", "Consultant Last Name"],
  ["organisation.region.long_name", "Region"],
];

export const searchMap = {
  "Site Name": "name",
  "Site Phone": "phone",
  "Site Email": "email",
  Material: "material",
  Organisation: "organisation.business_name",
  Region: "organisation.region.long_name",
  Tag: "tag",
};

export const SEARCH_HELPER = [
  ["name", "Site Name"],
  ["phone", "Site Phone"],
  ["email", "Site Email"],
  ["material", "Material"],
  ["organisation.business_name", "Organisation"],
  ["organisation.region.long_name", "Region"],
  ["tag", "Tag"],
];
