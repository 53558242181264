/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { useFormikContext } from "formik";

export const PhysicalPostcode = ({ fetchLgaRegions }) => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!values.physical_postcode) return;
    if (
      values.physical_postcode.length > 3 &&
      /^[0-9]+$/.test(values.physical_postcode)
    ) {
      fetchLgaRegions(values, setFieldValue);
    }
  }, [values.physical_postcode]);

  return (
    <MappedInput
      label="Postal Code"
      name="physical_postcode"
      data-testid="physical_postcode"
    />
  );
};
