import React from "react";
import { buttonStyle, itemStyle } from "../constants";
import MemberServicesIcon from "../static/MemberServices.png";

export const MemberServices = ({
  handleConnect,
  handleDisconnect,
  connectedComponent,
  tokenExpired = false,
}) => {
  const integrationDisabled = connectedComponent && connectedComponent !== "MemberServices";
  const connected = connectedComponent === "MemberServices";
  return (
    <>
      <div
        style={itemStyle}
        className={`${integrationDisabled ? "bg-secondary" : "bg-white"} p-7 mt-10`}
      >
        <div className="text-center mb-15">
          <img src={MemberServicesIcon} alt="logo" width="40%"/>
        </div>
        <h3 className="mb-2 text-dark">
          <strong>Agsafe Member Services</strong>
        </h3>
        <div style={{ fontSize: "14px" }}>
          This Integration allows for Agsafe Member Services MERLIN database for Inspector training
          registration &amp; completion records, as well as contact information for companies and
          people that are part of Agsafe Member Services Program.
        </div>
        <div className="mt-10">
          {tokenExpired ? (
            <button className={`${buttonStyle} text-primary`}>Reconnect Member Services</button>
          ) : connected ? (
            <button className={`${buttonStyle} text-danger`}>Disconnect</button>
          ) : (
            <button className={`${buttonStyle} text-secondary`} disabled>
              Connect
            </button>
          )}
        </div>
      </div>
    </>
  );
};
