const type = "badges";

export const setBadgesList = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      badgesList: payload,
    };
  },
  type,
});

export const removeBadge = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      badgesList: state.badgesList.filter(({ id }) => id !== payload),
    };
  },
  type,
});

export const unArchiveBadge = (payload) => ({
  execute: (state) => {
    const index = state.badgesList.findIndex((item) => item.id === payload.id);
    const myState = [...state.badgesList];
    myState.splice(index, 1, payload);
    return {
      ...state,
      badgesList: [...myState],
    };
  },
  type,
});
