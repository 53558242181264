export const HEADINGS = [
  ["first_name", "First Name"],
  ["last_name", "Last Name"],
  ["badges", "Badges"],
  ["position_title", "Job Title"],
  ["phone", "Phone Number"],
  ["mobile", "Mobile"],
  ["email", "Email Address"],
  ["status", "Status"],
];
