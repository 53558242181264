import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import { MappedInput } from "@bit/the-glue.frontendcomponents.input";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { getTag } from "./_redux/selectors";
import { categoriesList } from "./constants";
import { useFetch } from "../../../../hooks/fetch.hook";
import { addTag, getTagById, modifyTag } from "./_api";
import { TagsDetailsSchema } from "../../../../helpers/schemas";
import { SubmitActions } from "../../../../ui/structures/SubmitActions";
import { info } from "../../../../helpers/toasts";
import { Loader } from "../../../../ui/components/Loader";
import { modifyEmptyFields } from "../../../../ui/helpers";

export const Card = ({
  match: {
    params: { id },
  },
}) => {
  const { request } = useFetch();
  const data = useSelector(getTag(id));

  const [cardData, setCardData] = useState({});
  const [submitAction, setSubmitAction] = useState("Save");
  const [loading, setLoading] = useState(false);

  const initialValues = {
    name: cardData.name || "",
    type: cardData.type || "",
    category: cardData.category || "",
    description: cardData.description || "",
  };

  useEffect(() => {
    if (id && !data) {
      request(getTagById, id).then((data) => data && setCardData(data));
    } else {
      data && setCardData(data);
    }
    // eslint-disable-next-line
  }, []);

  const history = useHistory();

  const createTag = (values, setSubmitting) => {
    request(addTag, values)
      .then((data) => data && history.push("/settings/tags"))
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  const updateTag = (values, setSubmitting) => {
    request(modifyTag, values, id)
      .then((data) => {
        if (!data) return;
        info("Tag details have been updated!");
        submitAction === "Save & Exit" && history.push("/settings/tags");
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    modifyEmptyFields(values)
    id ? updateTag(values, setSubmitting) : createTag(values, setSubmitting);
  };

  const handleClose = () => history.push("/settings/tags");

  if (id && !Object.keys(cardData).length) {
    return <Skeleton variant="rect" width={"100%"} height={520} />;
  }

  return (
    <div className="border border-secondary bg-white p-10">
      {loading && (
        <Loader isOpen={loading} maxWidth="xs" disableBackdropClick disableEscapeKeyDown />
      )}
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={TagsDetailsSchema}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <>
            <h3 className="mb-10">
              <strong>{id ? cardData.name : "Add Tag"}</strong>
            </h3>
            <div className="row justify-content-between">
              <div className="col-8">
                <MappedInput label="Tag Name" name="name" data-testid="name" />
                <MappedSelect
                  label="Tag Category"
                  options={categoriesList}
                  name="category"
                  data-testid="category"
                />
                <MappedInput
                  label="Tag Description"
                  name="description"
                  multiline
                  rows={6}
                  data-testid="description"
                />
              </div>
            </div>
              <SubmitActions
                id={id}
                handleCancel={handleClose}
                disabled={isSubmitting}
                submitAction={submitAction}
                handleSubmit={handleSubmit}
                setSubmitAction={setSubmitAction}
              />
          </>
        )}
      </Formik>
    </div>
  );
};
