import React, { useState, useMemo, useEffect } from "react";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { ListButton } from "../../../ui/components/ListButton";
import {
  modifyCCDsArray,
  renderExtendedRow
} from "../DrumMuster/Inspections/helpers";
import { renderReimbursementsRow } from "./helpers";
import { useFetch } from "../../../hooks/fetch.hook";
import { getDrops, getDropsCount } from "../DrumMuster/Inspections/_api";
import { ADD_DROPS_HEADINGS } from "./constants";

export const EditReimbursement = ({
  data = [],
  setData,
  setReimbursedData,
  handleClose,
  organisationId
}) => {
  const { request } = useFetch();

  const [selected, setSelected] = useState({});
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    request(
      getDrops,
      `reimbursement_id=_null_&site.organisation_id=${organisationId}&type=inspection`,
      page,
      pageSize
    )
      .then(data => {
        if (!data) return;
        setData(modifyCCDsArray(data));
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    request(
      getDropsCount,
      `reimbursement_id=_null_&site.organisation_id=${organisationId}&type=inspection`
    ).then(data => data && setTotalRecords(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = () => {
    const selectedIDs = Object.keys(selected).filter(key => selected[key]);
    setData(state => state.filter(({ id }) => !selectedIDs.includes(id)));
    setReimbursedData(state => [
      ...state,
      ...data.filter(({ id }) => selectedIDs.includes(id))
    ]);
    handleClose();
  };

  const addDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length < 1,
    [selected]
  );

  return (
    <div className="justify-content-center">
      <div className="col-12">
        <div className="bg-white rounded py-7">
          <h4 className="ml-4 mb-5">Select Drop-offs</h4>
          <Datagrid
            data={data}
            headings={ADD_DROPS_HEADINGS}
            renderRow={renderReimbursementsRow}
            extendable
            renderExtendedRow={renderExtendedRow}
            defaultExtendable
            paginationDefaultValue={20}
            selectable
            selected={selected}
            setSelected={setSelected}
            serverPage={page}
            setServerPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            isServerPagination
            totalRecords={totalRecords}
            loading={loading}
          />
          <div className="d-flex justify-content-end mt-10 mr-1">
            <ListButton
              label="Cancel"
              onClick={handleClose}
              className="mr-3"
              variant="outlined"
              boxShadow={false}
              text="#407A28"
            />
            <ListButton
              label="Add"
              onClick={handleAdd}
              disabled={addDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
