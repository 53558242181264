import config from "react-global-configuration";

export const printReimbursements = () =>
  fetch(`${config.get("ApiUrl").Rest}/reimbursements/export`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ format: "csv" }),
    mode: "cors",
    credentials: "include",
  });

export const exportReimbursements = () =>
  fetch(`${config.get("ApiUrl").Rest}/reimbursements/export`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ format: "pdf" }),
    mode: "cors",
    credentials: "include",
  });
