import { chemClearActions } from "./actions";

const initialState = {
  data: {
    contact_details: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      company_name: "",
      mobile: "",
      qa_program_enabled: "",
      organisation_type: "",
      other_organisation: "",
      qa_program: "",
      ct_number: "",
    },
    address_details: {
      chem_contact_address_1: "",
      chem_contact_address_2: "",
      chem_contact_suburb: "",
      chem_contact_state: "",
      chem_contact_postcode: "",
      chem_address_1: "",
      chem_address_1_address_2: "",
      chem_address_1_suburb: "",
      chem_address_1_state: "",
      chem_address_1_postcode: "",
      as_contact_address: false,
      address_notes: "",
    }
  },
};

export function chemclearBookingReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case chemClearActions.SET_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.key]: { ...payload.data },
        },
      };

    case chemClearActions.RESET:
      return initialState;

    default:
      return state;
  }
}
