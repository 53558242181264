/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { SiteAudits } from "./SiteAudits";
import { SiteCard } from "./SiteCard";
import { SitesList } from "./SitesList";
import { useFetch } from "../../../hooks/fetch.hook";
import { getTags } from "../../modules/Settings/Tags/_api";
import {
  getOrganisationInspectors,
  getSiteTags,
} from "../../modules/Sites/_api";
import { sortHelper } from "../../../ui/helpers";
import { get } from "lodash";
import { getSiteCCDs } from "../../modules/DrumMuster/Inspections/_api";
import { getOrgContactsSimple } from "../../modules/Organisations/_api";

export const Sites = ({
  organisationId,
  organisationName,
  organisations = [],
  organisationsLoading,
}) => {
  const { request } = useFetch();

  const [component, setComponent] = useState("list");
  const [componentId, setComponentId] = useState("");
  const [tags, setTags] = useState([]);
  const [linkedTags, setLinkedTags] = useState([]);
  const [linkedTagsLoading, setLinkedTagsLoading] = useState([]);
  const [sitesList, setSitesList] = useState([]);
  const [inspectorsList, setInspectorsList] = useState([]);
  const [containersAmount, setContainersAmount] = useState(0);
  const [orgContacts, setOrgContacts] = useState([]);
  const [orgContactsLoading, setOrgContactsLoading] = useState(false);

  useEffect(() => {
    setOrgContactsLoading(true);
    request(
      getOrgContactsSimple,
      organisationId,
      "simple=true&fields=id,first_name,last_name"
    )
      .then((data) => data && setOrgContacts(data))
      .finally(() => setOrgContactsLoading(false));
  }, []);

  useEffect(() => {
    request(getTags, "category=_like(Site)_").then(
      (data) => data && setTags(data)
    );
    if (organisationId) {
      request(getOrganisationInspectors, organisationId).then(
        (data) => data && setInspectorsList(data)
      );
    }
  }, []);

  useEffect(() => {
    if (componentId) {
      setLinkedTagsLoading(true);
      request(getSiteTags, componentId)
        .then((data) => data && setLinkedTags(data))
        .finally(() => setLinkedTagsLoading(false));
      request(getSiteCCDs, componentId).then(
        (data = []) =>
          data &&
          setContainersAmount(
            data
              .map(({ quantity }) => quantity)
              .reduce((acc, value) => acc + value, 0)
          )
      );
    }
  }, [componentId]);

  const getSiteById = () =>
    sitesList && sitesList.find(({ id }) => id === componentId);

  const handleExpand = (id) => () => {
    setComponentId(id);
    setComponent("edit");
  };

  const handleNew = () => {
    setComponent("new");
  };

  const handleAudit = () => {
    setComponent("audit");
  };

  const handleCancel = () => {
    setComponent("list");
  };

  const inspectorsOptions = inspectorsList
    .map((contact) => ({
      value: get(contact, "id"),
      label: [get(contact, "first_name"), get(contact, "last_name")].join(" "),
    }))
    .sort(sortHelper);

  const orgContactsOptions = orgContacts
    .map((contact) => ({
      value: get(contact, "id"),
      label: [get(contact, "first_name"), get(contact, "last_name")].join(" "),
    }))
    .sort(sortHelper);

  const organiastionOptions = useMemo(
    () =>
      organisations.map((organiastion) => ({
        value: organiastion.id,
        label: organiastion.business_name,
      })),
    [organisations]
  );

  const componentsMap = {
    list: (
      <SitesList
        data={sitesList}
        handleExpand={handleExpand}
        handleNew={handleNew}
        organisationId={organisationId}
        organisationName={organisationName}
        setSitesList={setSitesList}
      />
    ),
    new: (
      <SiteCard
        handleCancel={handleCancel}
        organisationId={organisationId}
        inspectorsOptions={inspectorsOptions}
        orgContactsOptions={orgContactsOptions}
        orgContactsLoading={orgContactsLoading}
        organiastionOptions={organiastionOptions}
        organisationsLoading={organisationsLoading}
      />
    ),
    edit: (
      <SiteCard
        data={getSiteById()}
        id={componentId}
        handleCancel={handleCancel}
        organisationId={organisationId}
        handleAudit={handleAudit}
        tags={tags}
        linkedTags={linkedTags}
        setLinkedTags={setLinkedTags}
        inspectorsOptions={inspectorsOptions}
        orgContactsOptions={orgContactsOptions}
        containersAmount={containersAmount}
        orgContactsLoading={orgContactsLoading}
        linkedTagsLoading={linkedTagsLoading}
        organiastionOptions={organiastionOptions}
        organisationsLoading={organisationsLoading}
      />
    ),
    audit: (
      <SiteAudits
        handleExpand={handleExpand}
        id={componentId}
        site={getSiteById()}
      />
    ),
  };

  return <>{componentsMap[component]}</>;
};
