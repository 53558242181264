import React from "react";
import { Formik } from "formik";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { MappedSelect } from "@bit/the-glue.frontendcomponents.select";
import { Chip, makeStyles } from "@material-ui/core";
import { mockedDefaultValues, mockedMapData } from "../../../app/modules/Integrations/constants";
import { ListButton } from "../../components/ListButton";

const useStyles = makeStyles({
  root: {
    width: "100%",
    color: "white",
    overflow: "hidden",
  },
});

export const Mapping = ({ handleClose, handleSubmit, componentLabel }) => {
  const classes = useStyles();

  const initialValues = () => {
    return mockedMapData
      .map(({ title }) => ({
        [title]: mockedDefaultValues[title],
      }))
      .reduce(
        (acc, value) => ({
          ...acc,
          ...value,
        }),
        {}
      );
  };

  return (
    <>
      <Formik initialValues={initialValues()} onSubmit={handleSubmit}>
        {({ handleSubmit, isSubmitting, resetForm }) => (
          <div className="bg-white px-5 pb-5">
            <div className="text-center mb-15">
              <h2 className="mb-3">{`Configure Account Mappings for ${componentLabel}`} </h2>
              <p>Choose which account each line item is mapped to. </p>
            </div>
            {mockedMapData.map(({ title, options }) => (
              <div className="d-flex justify-content-between align-items-center" key={title}>
                <div style={{ width: "30%" }}>
                  <Chip label={title} color="primary" classes={classes} />
                </div>
                <div style={{ width: "6%" }}>
                  <ArrowRightAltIcon fontSize="large" />
                </div>
                <div style={{ width: "64%" }}>
                  <MappedSelect options={options} name={title} />
                </div>
              </div>
            ))}
            <div className="mt-10 mb-3 d-flex justify-content-end">
              <div className="mr-3">
                <ListButton
                  label="Cancel"
                  size="small"
                  onClick={handleClose}
                  data-testid="cancel"
                />
              </div>
              <div className="mr-3">
                <ListButton
                  label="Refresh"
                  size="small"
                  onClick={resetForm}
                  data-testid="refresh"
                />
              </div>
              <div>
                <ListButton
                  label="Save"
                  size="small"
                  onClick={handleSubmit}
                  data-testid="submit"
                  disabled={isSubmitting}
                />
              </div>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};
