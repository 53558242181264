import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Datagrid from "@bit/the-glue.frontendcomponents.datagrid";
import { ServerSearchBar } from "../../../../ui/structures/ServerSearchBar";
import { HEADINGS, searchMap, SEARCH_HEADINGS } from "./constants";
import { removePrice, setPricesList, unArchivePrice } from "./_redux/actions";
import { modifyPricesList, renderPricesRow } from "./helpers";
import { ListButton } from "../../../../ui/components/ListButton";
import { ListHeader } from "../../../../ui/structures/ListHeader";
import { useFetch } from "../../../../hooks/fetch.hook";
import {
  deletePrice,
  getPriceById,
  getPrices,
  modifyPrice,
  getPricesAutocomplete,
  getPricesAmount,
} from "./_api";
import { Modal } from "../../../../ui/components/Modal";
import { ConfirmDelete } from "../../../../ui/components/ConfirmDelete";
import { ArchiveCheckbox } from "../../../../ui/components/ArchiveCheckbox";
import {
  getSelectedId,
  handleSearch,
  objectCheck,
} from "../../../../ui/helpers";
import { UnarchiveButton } from "../../../../ui/components/UnarchiveButton";
import { sortHeaderStyle } from "../../../constants";
import { TableSortLabel } from "@material-ui/core";
import { Loader } from "../../../../ui/components/Loader";
import { info } from "../../../../helpers/toasts";
import { uniq } from "lodash";

export const List = () => {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const [loading, setLoading] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [selected, setSelected] = useState({});
  const [unArchiving, setUnarchiving] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const [search, setSearch] = useState("");
  const [field, setField] = useState("");
  const [direction, setDirection] = useState("");
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const handleModalClose = () => setConfirmDeleteOpen(false);

  const handleOpenModal = (id) => {
    setConfirmDeleteOpen(true);
    setSelectedItem(id);
  };

  const data = useSelector(({ prices: { pricesList } }) => pricesList);

  const fetchPrices = () => {
    setLoading(true);
    request(
      getPrices,
      search,
      archived,
      field,
      field && direction,
      page,
      pageSize
    )
      .then((data) => {
        dispatch(setPricesList(modifyPricesList(data)));
        setLoading(false);
      })
      .finally(() => setArchivedLoading(false));
  };

  const fetchSitesAutocomplete = (
    autocompleteValue,
    autocompleteField,
    searchFields,
    archived
  ) => {
    setAutocompleteLoading(true);
    request(
      getPricesAutocomplete,
      80,
      autocompleteField,
      autocompleteValue,
      field,
      field && direction,
      searchFields,
      archived
    ).then((data) => {
      if (data && Array.isArray(data) && data.length && objectCheck(data)) {
        setAutocompleteData(uniq(data.map((item) => String(item))));
      } else {
        setAutocompleteData(["No option found"]);
      }
      setAutocompleteLoading(false);
    });
  };

  useEffect(() => {
    fetchPrices();
    // eslint-disable-next-line
  }, [search, archived, field, direction, page, pageSize]);

  useEffect(() => {
    request(getPricesAmount, search, true).then(
      (data) => data && setTotalRecords(data)
    );
    // eslint-disable-next-line
  }, [search]);

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  const unArchiveDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const handleUnarchive = () => {
    const priceID = getSelectedId(selected);
    const status = (data.find(({ id }) => id === priceID) || {}).status;
    if (status !== "archived") return;
    setUnarchiving(true);
    setActionLoading(true);
    request(modifyPrice, { status: "active" }, priceID)
      .then((data) => {
        if (!data) return;
        dispatch(unArchivePrice(data));
        setSelected({});
        info("Price has been unarchived!");
      })
      .finally(() => {
        setUnarchiving(false);
        setActionLoading(false);
      });
  };

  const handleDelete = (id, setSubmitting) => {
    setActionLoading(true);
    setConfirmDeleteOpen(false);
    request(deletePrice, id)
      .then((data) => {
        if (!data) return;
        if (archived) {
          request(getPriceById, id).then(
            (data) => data && dispatch(unArchivePrice(data))
          );
        } else dispatch(removePrice(id));
        info("Price has been archived!");
        setSelected({});
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const resetSearch = () => {
    setSearch("");
  };

  const renderButtons = () => (
    <>
      <div className="mr-10">
        <Link
          to={{
            pathname: `/add-price`,
          }}
          data-testid="new"
        >
          <ListButton label="New" />
        </Link>
      </div>
      {archived && (
        <UnarchiveButton
          handleClick={handleUnarchive}
          disabled={unArchiveDisabled}
          loading={unArchiving}
        />
      )}
    </>
  );

  function renderHeaderWithSorting() {
    return SEARCH_HEADINGS.map(([key, header]) => (
      <th
        key={key}
        style={sortHeaderStyle}
        className="px-5 text-nowrap"
        onClick={() => {
          setDirection(
            key !== field ? "desc" : direction === "desc" ? "asc" : "desc"
          );
          setField(key);
        }}
      >
        <span style={{ cursor: "pointer" }}>{header}</span>
        {key === field && <TableSortLabel active direction={direction} />}
      </th>
    ));
  }

  return (
    <div className="row justify-content-center mt-10">
      <div className="col-12">
        {actionLoading && <Loader isOpen={actionLoading} maxWidth="xs" />}
        {confirmDeleteOpen && (
          <Modal
            isOpen={confirmDeleteOpen}
            submitable
            onClose={handleModalClose}
            maxWidth="sm"
            modalContent={
              <ConfirmDelete
                handleClose={handleModalClose}
                handleSubmit={handleDelete}
                id={selectedItem}
                name={(data.find(({ id }) => id === selectedItem) || {}).name}
              />
            }
          />
        )}
        <div>
          <ServerSearchBar
            className="mb-5"
            onSearch={(data) =>
              handleSearch(data, searchMap, setSearch, setPage)
            }
            keyMap={SEARCH_HEADINGS}
            loading={autocompleteLoading}
            currentSearchList={autocompleteData}
            fetchAutocompleteFunction={fetchSitesAutocomplete}
            placeholder="Search Prices..."
            clearSearch={resetSearch}
            searchMap={searchMap}
            searchFields={search}
            contentLoading={loading}
          />
        </div>
        <div className="bg-white rounded py-7 px-10">
          <ListHeader title="Prices" renderButtons={renderButtons} />
          <ArchiveCheckbox
            archivedLoading={archivedLoading}
            archived={archived}
            showArchived={showArchived}
          />
          <Datagrid
            data={data}
            headings={HEADINGS}
            renderRow={renderPricesRow}
            renderHeaderWithSorting={renderHeaderWithSorting}
            selectable
            selected={selected}
            setSelected={setSelected}
            editable
            isNewTabLink
            deletable
            handleDelete={handleOpenModal}
            loading={loading}
            link="prices"
            paginationOptions={[50, 100, 200]}
            paginationDefaultValue={50}
            serverPage={page}
            setServerPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            isServerPagination
            totalRecords={totalRecords}
          />
        </div>
      </div>
    </div>
  );
};
