export const flagsList = [
  { label: "Contacts", value: "contacts" },
  { label: "Sites", value: "sites" },
  { label: "Organisations", value: "organisations" },
];

export const typeOptions = [
  { label: "Audit", value: "audit" },
  { label: "Event", value: "event" },
  { label: "Visit", value: "reseller_visit" },
  { label: "Training", value: "training" },
  { label: "Finance", value: "finance" },
  { label: "Contact", value: "contact" },
  { label: "Other", value: "other"}
];

export const statusList = [
  { label: "Current", value: "current" },
  { label: "Overdue", value: "overdue" },
  { label: "Completed", value: "completed" },
];

export const searchOptions = [
  { label: "Audit", value: "audit" },
  { label: "Event", value: "event" },
  { label: "Visit", value: "reseller_visit" },
  { label: "Training", value: "training" },
  { label: "Finance", value: "finance" },
  { label: "Contact", value: "contact" },
  { label: "Other", value: "other"},
  { label: "Group", value: "group" },
  { label: "Individual", value: "individual"}
];

export const statusChangeList = {
  current_to_overdue:
    "If you want to move this ticket to the overdue column please set the due date.",
  current_to_completed: "Please mark this task as completed.",
  overdue_to_current:
    "If you want to move this ticket to the current column please set the due date.",
  overdue_to_completed: "Please mark this task as completed.",
  completed_to_current:
    "If you want to move this ticket to the current column please set the due date.",
  completed_to_overdue:
    "If you want to move this ticket to the overdue column please set the due date.",
};

export const mockedUsersList = [
  { label: "Jones Dermot", value: "Jones Dermot" },
  { label: "Nicci Troiani", value: "Nicci Troiani" },
  { label: "Rebecca Moore", value: "Rebecca Moore" },
  { label: "Jane Doe", value: "Jane Doe" },
];

export const columnsMap = [
  {
    title: "Overdue Tasks",
    status: "overdue",
  },
  {
    title: "Current Tasks",
    status: "current",
  },
  {
    title: "Completed Tasks",
    status: "completed",
  },
];

export const mockedComments = [
  {
    id: 1,
    author: "John Doe",
    position: "AgSafe - drumMUSTER Program Co-ordinator",
    created_at: new Date("2021-02-06T11:10:06+10:00"),
    comment:
      "Admiration stimulated cultivated reasonable be projection possession of. Real no near room ye bred sake if some. Is arranging furnished knowledge agreeable so. Fanny as smile up small.",
  },
  {
    id: 2,
    author: "Kate Blake",
    position: "AgSafe Limited - drumMUSTER Manager",
    created_at: new Date("2021-01-06T11:10:06+10:00"),
    comment:
      "Advice me cousin an spring of needed. Tell use paid law ever yet new. Meant to learn of vexed if style allow he there. Tiled man stand tears ten joy there terms any widen. Procuring continued suspicion its ten.",
  },
  {
    id: 3,
    author: "Aron Lee",
    position: "AgSafe  - Product Manager",
    created_at: new Date("2021-03-06T11:10:06+10:00"),
    comment:
      "Letter wooded direct two men indeed income sister. Impression up admiration he by partiality is. Instantly immediate his saw one day perceived. Old blushes respect but offices hearted minutes effects",
  },
];

export const sortOptions = [
  { label: "Date", value: "created_at" },
  { label: "Author", value: "author" },
];

export const filterOptions = [{ label: "All", value: "all" }];
